import { combineReducers } from "redux";
import {
    GET_LANE_DETAIL_INFO_REQUEST,
    GET_LANE_DETAIL_INFO_SUCCESS,
    GET_LANE_DETAIL_INFO_FAIL,
    GET_LANE_OPTION_LIST_REQUEST,
    GET_LANE_OPTION_LIST_SUCCESS_INFO,
    GET_LANE_OPTION_LIST_FAIL_ERROR,
    GET_LANES_BY_PLAZA_ID_REQUEST,
    GET_LANES_BY_PLAZA_ID_SUCCESS,
    GET_LANES_BY_PLAZA_ID_FAIL_ERROR
} from "../constants/LaneConstants";

function GetLaneDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_LANE_DETAIL_INFO_REQUEST:
            return { loading: true };
        case GET_LANE_DETAIL_INFO_SUCCESS:
            return { loading: false, successgetLaneDetail: action.payload };
        case GET_LANE_DETAIL_INFO_FAIL:
            return { loading: false, failedgetLaneDetail: action.payload }
        default:
            return state;
    }
}

function GetLaneOptionListReducer(state = [], action) {
    switch (action.type) {
        case GET_LANE_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_LANE_OPTION_LIST_SUCCESS_INFO:
            return { loading: false, successLaneOptionList: action.payload };
        case GET_LANE_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failLaneOptionList: action.payload }
        default:
            return state;
    }
}

function GetLaneByPlazaIdReducer(state = [], action) {
    switch (action.type) {
        case GET_LANES_BY_PLAZA_ID_REQUEST:
            return { loading: true };
        case GET_LANES_BY_PLAZA_ID_SUCCESS:
            return { loading: false, successLaneByPlazaId: action.payload };
        case GET_LANES_BY_PLAZA_ID_FAIL_ERROR:
            return { loading: false, failedLaneByPlazaId: action.payload }
        default:
            return state;
    }
}

export default combineReducers({ GetLaneDetailReducer, GetLaneOptionListReducer, GetLaneByPlazaIdReducer });