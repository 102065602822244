import React, { Component } from "react";
import Chart from "react-apexcharts";

class Charts extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <Chart
          options={this.props.options}
          series={this.props.series}
          type={this.props.type}
          width={this.props.width}
          height={this.props.height}
        />
      </>
    );
  }
}

export default Charts;
