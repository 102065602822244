import { GET_LOGS_DETAIL_REQUEST, GET_LOGS_DETAIL_SUCCESS, GET_LOGS_DETAIL_FAILED_ERROR } from "../constants/LogConstants.js";
import { postData } from "../service/Common"

const get_single_log_detail_action = (Id)=>async(dispatch)=>{
    dispatch({ type: GET_LOGS_DETAIL_REQUEST, payload:{} })
    postData(`/api/get_logs_detail`,{id:Id})
    .then((response)=>{
        let res=response.data;
        dispatch({ type: GET_LOGS_DETAIL_SUCCESS, payload:res.data })
    })
    .catch((error)=>{
        dispatch({ type: GET_LOGS_DETAIL_FAILED_ERROR, payload:error.data.message })
    })
}
export { get_single_log_detail_action };