import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import SelectDropdown from "../../components/ReactSelectPlus";

function LaneShowPage(props) {
  const [laneData, setLaneData] = useState({
    name: "",
    slug: "",
    status: 1,
    test: "",
  });
  const [tgl, setTgl] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetForm = () => {
    setLaneData({ name: "", slug: "", status: 1 });
  };
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewRoleForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_role`, laneData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/plazas">Plazas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add New Lane
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title">Add New Role</h6>
              </div>
              <Row className=" justify-content-center">
                <Col lg={12}>
                  <form
                    id="addnewRoleForm"
                    method="POST"
                    className="forms-sample"
                  >
                    <div className="form-group row">
                      <label className="col-lg-3" htmlFor="name">
                        Lane Name
                      </label>
                      <Col lg={9}>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          autoComplete="off"
                          placeholder="Name"
                          value={laneData.name}
                          onChange={(e) =>
                            setLaneData({ ...laneData, name: e.target.value })
                          }
                          minLength={3}
                          maxLength={180}
                          data-rule-required={true}
                        />
                      </Col>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3" htmlFor="slug">
                        Slug
                      </label>
                      <Col lg={9}>
                        <input
                          type="text"
                          className="form-control"
                          id="slug"
                          name="slug"
                          autoComplete="off"
                          placeholder="Slug Name"
                          value={laneData.slug}
                          onChange={(e) =>
                            setLaneData({ ...laneData, slug: e.target.value })
                          }
                          minLength={3}
                          maxLength={180}
                          data-rule-required={true}
                          data-rule-slug={true}
                        />
                      </Col>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3" htmlFor="test">
                        Test
                      </label>
                      <Col lg={9}>
                        <div className="cstm_select">
                          <SelectDropdown
                            options={[
                              { value: "one", label: "One" },
                              { value: "two", label: "Two" },
                              { value: "three", label: "Three" },
                            ]}
                            joinValues={true}
                            name="test1"
                            id="test1"
                            sampleValue={false}
                            placeholder="Select Test"
                            autoComplete="off"
                            className="select-control"
                            value={laneData.test}
                            onChange={(e) =>
                              setLaneData({
                                ...laneData,
                                test: e !== null ? e.value : "",
                              })
                            }
                            required={true}
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="test"
                                data-msg-required="this field is required."
                              />
                            )}
                          />
                        </div>
                        <label
                          className="error select_error_span"
                          htmlFor="test"
                        ></label>
                      </Col>
                    </div>

                    <Row>
                      <Col lg={3}></Col>
                      <Col lg={9}>
                        <button
                          type="submit"
                          className="btn btn-primary mr-2"
                          onClick={submitHandller}
                          disabled={loading ? true : false}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                        <Link className="btn btn-dark" to="/roles">
                          Cancel
                        </Link>
                      </Col>
                    </Row>
                  </form>
                </Col>
                <button
                  type="button"
                  className={`tgl btn ${tgl ? " open" : ""}`}
                  onClick={(e) => setTgl(!tgl ? true : false)}
                >
                  <span className="role"></span>
                  <span className="role"></span>
                  <span className="role"></span>
                </button>
                <div style={{ display: tgl ? "block" : "none" }}>
                  <h1>toggle open</h1>
                  <p>HAHAHA</p>
                </div>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default LaneShowPage;
