import { combineReducers } from "redux";
import {
  GET_PRODUCT_DETAIL_INFO_REQUEST,
  GET_PRODUCT_DETAIL_INFO_SUCCESS,
  GET_PRODUCT_DETAIL_INFO_FAIL,
  GET_PRODUCT_OPTION_LIST_REQUEST,
  GET_PRODUCT_OPTION_LIST_SUCCESS_INFO,
  GET_PRODUCT_OPTION_LIST_FAIL_ERROR,
  GET_PRODUCT_DETAIL_LIST_BYID_FAIL_ERROR,
  GET_PRODUCT_DETAIL_LIST_BYID_SUCCESS_INFO,
  GET_PRODUCT_DETAIL_LIST_BYID_REQUEST,
  GET_SINGLE_PRODUCT_LIST_REQUEST,
  GET_SINGLE_PRODUCT_OPTION_LIST,
  GET_SINGLE_PRODUCT_OPTION_LIST_FAIL_ERROR,
  GET_PRODUCT_DETAIL_WITH_QTY_INFO_REQUEST,
  GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS,
  GET_PRODUCT_DETAIL_WITH_QTY_INFO_FAIL,
  GET_INVENTORY_DETAIL_REQUEST,
  GET_INVENTORY_DETAIL_INFO_SUCCESS,
  GET_INVENTORY_DETAIL_INFO_FAIL,
  
} from "../constants/ProductConstants";

function GetProductDetailReducer(state = {}, action) {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_INFO_REQUEST:
      return { loading: true };
    case GET_PRODUCT_DETAIL_INFO_SUCCESS:
      return { loading: false, successgetProductDetail: action.payload };
    case GET_PRODUCT_DETAIL_INFO_FAIL:
      return { loading: false, failedgetProductDetail: action.payload };
    default:
      return state;
  }
}

function GetProductOptionListReducer(state = [], action) {
  switch (action.type) {
    case GET_PRODUCT_OPTION_LIST_REQUEST:
      return { loading: true };
    case GET_PRODUCT_OPTION_LIST_SUCCESS_INFO:
      return { loading: false, successProductOptionList: action.payload };
    case GET_PRODUCT_OPTION_LIST_FAIL_ERROR:
      return { loading: false, failProductOptionList: action.payload };
    default:
      return state;
  }
}

function GetProductListByFilterIdReducer(state = [], action) {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_LIST_BYID_REQUEST:
      return { loading: true };
    case GET_PRODUCT_DETAIL_LIST_BYID_SUCCESS_INFO:
      return { loading: false, successProductByprojectId: action.payload };
    case GET_PRODUCT_DETAIL_LIST_BYID_FAIL_ERROR:
      return { loading: false, failedProductByprojectId: action.payload };
    default:
      return state;
  }
}

function GetSingleProductListReducer(state = {successSingleProductOptionList:[]}, action) {
  switch (action.type) {
      case GET_SINGLE_PRODUCT_LIST_REQUEST:
          return { ...state,loading: true };
      case GET_SINGLE_PRODUCT_OPTION_LIST:
          return { ...state,loading: false, successSingleProductOptionList: action.payload.data };
      case GET_SINGLE_PRODUCT_OPTION_LIST_FAIL_ERROR:
          return { ...state,loading: false, failSingleProductOptionList: action.payload }
      default:
          return state;
  }
}

function GetProductDetailWithQtyReducer(state = {successgetProductDetailWithQty:{data:{'inventoryDataItem':[]}}}, action) {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_WITH_QTY_INFO_REQUEST:
      return { ...state,loading: true };
    case GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS:
      return { ...state,loading: false, successgetProductDetailWithQty: action.payload };
    case GET_PRODUCT_DETAIL_WITH_QTY_INFO_FAIL:
      return { ...state,loading: false, failedgetProductDetailWithQty: action.payload };
    default:
      return state;
  }
}



function GetInventoryDetailReducer(state = {successgetInventoryDetail:[ ]}, action) {
  switch (action.type) {
    case GET_INVENTORY_DETAIL_REQUEST:
      return { loading: true };
    case GET_INVENTORY_DETAIL_INFO_SUCCESS:
      return { loading: false, successgetInventoryDetail: action.payload };
    case GET_INVENTORY_DETAIL_INFO_FAIL:
      return { loading: false, failedgetInventoryDetail: action.payload };
    default:
      return state;
  }
}

export default combineReducers({
  GetProductDetailReducer,
  GetProductOptionListReducer,
  GetProductListByFilterIdReducer,
  GetSingleProductListReducer,
  GetProductDetailWithQtyReducer,
  GetInventoryDetailReducer
});
