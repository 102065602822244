import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import SelectDropdown from "../../components/ReactSelectPlus";
import { get_company_option_list_action } from "../../actions/CompanyActions";
import { get_person_detail_action } from "../../actions/PersonActions";
const ViewPersonPage = (props) => {
  const [personData, setPersonData] = useState({
    id: props.match.params.personId,
    name: "",
    company_id: null,
    name: "",
    email: "",
    contact_number: "",
  });
  const [loading, setLoading] = useState(false);
  const get_person_detail = useSelector((state) => {
    return {
      company: state.CompanyReducers.GetCompanyOptionListReducer,
      person: state.PersonReducers.GetPersonDetailReducer,
    };
  });
  const dispatch = useDispatch();
  const [companyOptionList, setCompanyOptionList] = useState([]);

  useEffect(() => {
    dispatch(get_company_option_list_action());
    if (props.match.params.personId) {
      dispatch(get_person_detail_action(props.match.params.personId));
    }
  }, [dispatch]);
  useEffect(() => {
    if (get_person_detail.company.successCompanyOptionList) {
      setCompanyOptionList(
        get_person_detail.company.successCompanyOptionList.data
      );
    }
    if (get_person_detail.person.successPersonDetail) {
      setPersonData(get_person_detail.person.successPersonDetail.data);
    }
  }, [get_person_detail.company, get_person_detail.person]);

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/persons">Persons</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Person Detail
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Person Detail</h6>
              </div>

              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {get_person_detail.person.loading ? (
                      <div className="form-control text-center text-info">
                        Loading...
                      </div>
                    ) : get_person_detail.person.successPersonDetail ? (
                      <>
                        <div className="form-group row">
                          <Col lg={4} className="form-group">
                            <label htmlFor="name">Person ID</label>
                            <div className="form-control">
                              {personData._person_id}
                            </div>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="name">Name</label>
                            <div className="form-control">
                              {personData.name}
                            </div>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="company_id1">Select Company</label>
                            <div className="form-control input_inner_box">
                              {personData.company !== undefined ? (
                                <Link
                                  className="input_inner_btn"
                                  to={`/company/${personData.company.id}/show`}
                                >
                                  {personData.company.name}
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="email">Email</label>
                            <div className="form-control">
                              {personData.email}
                            </div>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="contact_number">
                              Contact Number
                            </label>
                            <div className="form-control">
                              {personData.contact_number}
                            </div>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="person_type">Person Type</label>
                            <div className="form-control">
                              {personData.person_type === "1"
                                ? "Employee"
                                : personData.person_type === "2"
                                ? "Service Provider"
                                : personData.person_type === "3"
                                ? "Vendor"
                                : ""}
                            </div>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="project_type">Project</label>
                            <ul className="show_list_click_va1 form-control">
                              {personData.plaza !== undefined &&
                              personData.project.length > 0 ? (
                                personData.project.map((val, i) => (
                                  <li key={i}>
                                    <Link
                                      className="input_inner_btn"
                                      to={`/project_management/${val.value}/show`}
                                    >
                                      {val.label}
                                    </Link>
                                  </li>
                                ))
                              ) : (
                                <li>NA</li>
                              )}
                            </ul>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="plaza_type">Plaza</label>
                            <ul className="show_list_click_va1 form-control">
                              {personData.plaza !== undefined &&
                              personData.plaza.length > 0 ? (
                                personData.plaza.map((val, i) => (
                                  <li key={i}>
                                    <Link
                                      className="input_inner_btn"
                                      to={`/plaza/${val.value}/show`}
                                    >
                                      {val.label}
                                    </Link>
                                  </li>
                                ))
                              ) : (
                                <li>NA</li>
                              )}
                            </ul>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="office_type">Office</label>
                            <ul className="show_list_click_va1 form-control">
                              {personData.office !== undefined &&
                              personData.office.length > 0 ? (
                                personData.office.map((val, i) => (
                                  <li key={i}>
                                    <Link
                                      className="input_inner_btn"
                                      to={`/office/${val.value}/show`}
                                    >
                                      {val.label}
                                    </Link>
                                  </li>
                                ))
                              ) : (
                                <li>NA</li>
                              )}
                            </ul>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="warehouse_type">Warehouse</label>
                            <ul className="show_list_click_va1 form-control">
                              {personData.warehouse !== undefined &&
                              personData.warehouse.length > 0 ? (
                                personData.warehouse.map((val, i) => (
                                  <li key={i}>
                                    <Link
                                      className="input_inner_btn"
                                      to={`/warehouse/${val.value}/show`}
                                    >
                                      {val.label}
                                    </Link>
                                  </li>
                                ))
                              ) : (
                                <li>NA</li>
                              )}
                            </ul>
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="created">Created</label>
                            <div className="form-control">
                              {personData.created}
                            </div>
                          </Col>
                        </div>

                        <Row>
                          <Col lg={12} className="form-group">
                            <Link className="btn btn-dark" to="/persons">
                              Cancel
                            </Link>
                          </Col>
                        </Row>
                      </>
                    ) : get_person_detail.person.failedPersonDetail ? (
                      <div className="text-center">
                        <div className="form-control text-center text-danger">
                          {get_person_detail.person.failedPersonDetail}
                        </div>
                        <Link to={`/persons`} className="btn btn-dark mt-2">
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewPersonPage;
