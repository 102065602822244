export const GET_PRODUCT_DETAIL_INFO_REQUEST =
  "GET_PRODUCT_DETAIL_INFO_REQUEST";
export const GET_PRODUCT_DETAIL_INFO_SUCCESS =
  "GET_PRODUCT_DETAIL_INFO_SUCCESS";
export const GET_PRODUCT_DETAIL_INFO_FAIL = "GET_PRODUCT_DETAIL_INFO_FAIL";

export const GET_PRODUCT_OPTION_LIST_REQUEST =
  "GET_PRODUCT_OPTION_LIST_REQUEST";
export const GET_PRODUCT_OPTION_LIST_SUCCESS_INFO =
  "GET_PRODUCT_OPTION_LIST_SUCCESS_INFO";
export const GET_PRODUCT_OPTION_LIST_FAIL_ERROR =
  "GET_PRODUCT_OPTION_LIST_FAIL_ERROR";

export const GET_PRODUCT_DETAIL_LIST_BYID_REQUEST =
  "GET_PRODUCT_DETAIL_LIST_BYID_REQUEST";
export const GET_PRODUCT_DETAIL_LIST_BYID_SUCCESS_INFO =
  "GET_PRODUCT_DETAIL_LIST_BYID_SUCCESS_INFO";
export const GET_PRODUCT_DETAIL_LIST_BYID_FAIL_ERROR =
  "GET_PRODUCT_DETAIL_LIST_BYID_FAIL_ERROR";

export const GET_SINGLE_PRODUCT_LIST_REQUEST = 
  "GET_SINGLE_PRODUCT_LIST_REQUEST";
export const GET_SINGLE_PRODUCT_OPTION_LIST = 
  "GET_SINGLE_PRODUCT_OPTION_LIST";
export const GET_SINGLE_PRODUCT_OPTION_LIST_FAIL_ERROR = 
"GET_SINGLE_PRODUCT_OPTION_LIST_FAIL_ERROR";

export const COUNT_OF_ATTACHED_SINGLE_PRODUCTS_ON_GROUP_PRODUCT = 5;

export const GET_PRODUCT_DETAIL_WITH_QTY_INFO_REQUEST = 
  "GET_PRODUCT_DETAIL_WITH_QTY_INFO_REQUEST";
export const GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS = 
  "GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS";
export const GET_PRODUCT_DETAIL_WITH_QTY_INFO_FAIL = 
"GET_PRODUCT_DETAIL_WITH_QTY_INFO_FAIL";

export const GET_INVENTORY_DETAIL_REQUEST = "GET_INVENTORY_DETAIL_REQUEST";
export const GET_INVENTORY_DETAIL_INFO_SUCCESS = "GET_INVENTORY_DETAIL_INFO_SUCCESS";
export const GET_INVENTORY_DETAIL_INFO_FAIL = "GET_INVENTORY_DETAIL_INFO_FAIL";