import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { NextSvgIcon, PreviousSvgIcon } from "./../SvgIcon";
import "./ReactTable.scss";

function Table(props) {
  return (
    <>
      {/* <ReactTable
        data={props.data}
        columns={props.columns}
        previousText={<PreviousSvgIcon />}
        nextText={<NextSvgIcon />}
        loadingText={"Loading..."}
        loading={props.loading}
        noDataText={"No rows found"}
        onFetchData={props.fetchData}
        defaultPageSize={props.defaultPageSize}
        showPagination={props.showPagination}
        className={props.className}
        SubComponent={props.SubComponent}
        filter={props.filter}
        pageIndex={props.pageIndex}
        pages={props.pages}
        defaultSorted={[]}
        /*defaultSortMethod={ (a, b, desc) => {
          // force null and undefined to the bottom
          a = a === null || a === undefined ? '' : a
          b = b === null || b === undefined ? '' : b
          // force any string values to lowercase
          a = typeof a === 'string' ? a.toLowerCase() : a
          b = typeof b === 'string' ? b.toLowerCase() : b
          // Return either 1 or -1 to indicate a sort priority
          if (a > b) {
            return 1
          }
          if (a < b) {
            return -1
          }
          // returning 0, undefined or any falsey value will use subsequent sorts or
          // the index as a tiebreaker
          return 0
        }}
        minRows={1}
        pageOptions={console.log("sdsdsd")}
        gotoPage={console.log("pre")}
        nextPage
        previousPage

      /> */}
      {/*<ReactTable
        manual
        data={props.data}
        columns={props.columns}
        previousText={<PreviousSvgIcon />}
        nextText={<NextSvgIcon />}
        loadingText={"Loading..."}
        loading={props.loading??false}
        noDataText={"No rows found"}
        showPagination={props.showPagination}
        defaultPageSize={props.defaultPageSize}
        className={`text_align_left`}
        onFetchData={props.fetchData}
        filter={props.filter}
        pageIndex={props.page}
        pages={props.pages}
        minRows={1}
      />*/}

<ReactTable
        manual={props.manual}
        data={props.data}
        columns={props.columns}
        previousText={<PreviousSvgIcon />}
        nextText={<NextSvgIcon />}
        loadingText={"Loading..."}
        loading={props.loading??false}
        noDataText={"No rows found"}
        showPagination={props.showPagination}
        defaultPageSize={props.defaultPageSize}
        className={`text_align_left`}
        onFetchData={props.fetchData}
        filtered={props.filtered}
        pageIndex={props.page}
        pages={props.pages}
        minRows={1}
        />
    </>
  );
}
export default Table;
