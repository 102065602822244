import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../assets/images/logo_dav_aditya_white.svg";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/AuthActions";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const AuthReducers = useSelector((state) => state.AuthUserReducers);
  const { loading } = AuthReducers;
  const dispatch = useDispatch();
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#loginForm").valid()) {
      dispatch(login(email, password));
    }
  };
  return (
    <div className="main-wrapper logoin_box">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-8 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-5 pr-md-0">
                    <div className="auth-left-wrapper px-5 py-5">
                      <img src={logo} className="img-fluid" alt="Logo" />
                    </div>
                  </div>
                  <div className="col-md-7 pl-md-0">
                    <div className="auth-form-wrapper px-5 py-5">
                      {/* <a href="#" className="noble-ui-logo d-block mb-2">
                        Noble<span>UI</span>
                      </a> */}
                      <h5 className="text-muted font-weight-normal mb-4">
                        Log in to your account.
                      </h5>
                      <form
                        id="loginForm"
                        className="forms-sample"
                        method="POST"
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <input
                            type="email"
                            name={"email"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Email"
                            data-rule-required={true}
                            data-rule-email={true}
                            maxLength={180}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Password
                          </label>
                          <input
                            type="password"
                            name={"password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            autoComplete="current-password"
                            id="exampleInputPassword1"
                            placeholder="Password"
                            data-rule-required={true}
                            maxLength={50}
                          />
                        </div>
                        <div className="form-check form-check-flat form-check-primary">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            Remember me
                            <i className="input-frame"></i>
                          </label>
                        </div>
                        <div className="mt-3">
                          <button
                            type="submit"
                            onClick={submitHandller}
                            className={
                              "btn btn-primary mr-2 mb-2 mb-md-0 text-white"
                            }
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Login"}
                          </button>
                          {/* <button
                            type="button"
                            className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="feather feather-twitter btn-icon-prepend"
                            >
                              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                            </svg>
                            Login with twitter
                          </button> */}
                        </div>
                        {/* <a
                          href="register.html"
                          className="d-block mt-3 text-muted"
                        >
                          Not a user? Sign up
                        </a> */}
                        <Link
                          to="/forgot-password"
                          className="d-block mt-3 text-muted"
                        >
                          Forgot Password
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
