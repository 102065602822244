import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  AiFillFileAdd,
  IoIosCloseCircle,
  FaCloudUploadAlt,
  FaDownload,
  FiEye,
  AiOutlineBarcode,
} from "../../components/SvgIcon";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../components/ReactTable";
import Modals from "../../components/BootstrapModal";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";

function ListGroupProduct() {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const page_size = 10;
  const search = "";
  const sorted = [];
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    /*{
      Header: "Manufactured By",
      accessor: "manufactured_by",
    },
    {
      Header: "Model",
      accessor: "model",
    },
	*/
    {
      Header: () => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <OverlayTrigger placement="left" overlay={<Tooltip>Detail</Tooltip>}>
            <Link
              to={`/group_product/${props.original.id}/view`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
            >
              <FiEye />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger placement="left" overlay={<Tooltip>Edit</Tooltip>}>
            <Link
              to={`/group_product/${props.original.id}/edit`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
            >
              <EditSvgIcon />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <button
              type="button"
              onClick={() => deleteHandller(props.original.id)}
              className="btn btn-danger btn-icon btn-sm-my mr-2"
            >
              <TrashSvgIcon />
            </button>
          </OverlayTrigger>
          {/* <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Generate barcode </Tooltip>}
          >
            <button
              type="button"
              className="btn btn-secondary px-1 py-0 btn-link btn-icon btn-sm-my mr-2"
            >
              <BiBarcodeReader className="BiBarcodeReader" />
            </button>
          </OverlayTrigger>
          <Link to="/product_management" className="btn btn-primary btn-sm-my">Log</Link> */}
        </span>
      ),
      sortable: false,
      width: 200,
    },
  ];
  const deleteHandller = (del_Id) => {
    ConfirmationAlertBox("Are you sure want to remove its?", {}).then(
      (status) => {
        if (status) {
          postData(`/api/delete_product`, { id: del_Id })
            .then((response) => {
              ToastMessage(response.data.message, "s");
              fetchData({ page_size, page, search, sorted });
            })
            .catch((error) => {
              ToastMessage(error.data.message, "e");
            });
        }
      }
    );
  };
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
      product_type:'2'
    };
    setLoading(true);
    postData(`/api/get_products_list`, req)
      .then((result) => {
        let res = result.data;
        setProductData(res.data);
        let tmp_pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmp_pages);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            Product
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Group Product
          </li>
        </ol>
        <div>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
            to="/product_management/add_group_product"
          >
            <PlusSvgIcon className="mr-2" />
            Add New Group Product
          </Link>
        </div>
      </nav>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Group Product List</h6>
              </div>
              <Table
                data={productData}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                pages={pages}
                page={page}
                sorted={sorted}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default ListGroupProduct;
