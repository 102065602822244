import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown from "../../components/ReactSelectPlus";
import $ from "jquery";
import moment from "moment";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { get_master_list } from "../../actions/MasterActions";
import { get_product_detail_action } from "../../actions/ProductActions.js";
import { IoMdClose } from "../../components/SvgIcon";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";

const AddProductPage = (props) => {
  var API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [productData, setProductData] = useState({
    assign_id:
      props.match.params.assignId !== undefined
        ? props.match.params.assignId
        : "",
    assign_with:
      props.match.params.assignWith !== undefined
        ? props.match.params.assignWith
        : "",
    prodName: "",
    brand: "",
    unit: "",
    unitvalue: "",
    serialNumber: "",
    model: "",
    manufacturedBy: "",
    manufacturedDate: null,
    has_warranty: "1",
    serviceProvider: "",
    purchaseFrom: "",
    purchasedate: null,
    publisher: "",
    version: "",
    warrantyDuration: "0",
    image_uploads: [],
    doc_uploads: [],
    extendWarranty: "0",
  });
  const [loading, setLoading] = useState(false);

  const getMasterList = useSelector(
    (state) => state.MasterReducers.GetMasterListReducer.successMasterList
  );

  const [masterList, setMasterList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_master_list());
  }, [dispatch]);
  useEffect(() => {
    if (getMasterList) {
      setMasterList(getMasterList.data);
    }
  }, [getMasterList]);

  const formReset = () => {
    setProductData({
      prodName: "",
      brand: "",
      serialNumber: "",
      model: "",
      manufacturedBy: "",
      unit: "",
      unitvalue: "",
      manufacturedDate: null,
      has_warranty: "1",
      serviceProvider: "",
      purchaseFrom: "",
      purchaseDate: null,
      publisher: "",
      version: "",
      warrantyDuration: "0",
      image_uploads: [],
      doc_uploads: [],
    });
  };

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#Product_Form").valid()) {
      postData(`/api/addnew_product`, productData)
        .then((response) => {
          let data = response.data;
          ToastMessage(data.message, "s");
          formReset();
          setLoading(false);
          props.history.push("/product_management");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  const setExpiryDate = (n) => {
    let d = productData.purchaseDate;
    if (n !== null && d !== null) {
      let temp = moment(d).add(n, "y").format("Y-MM-DD");
      setProductData({
        ...productData,
        warrantyDuration: n,
        warrantyExpiryDate: temp,
      });
    } else {
      setProductData({ ...productData, warrantyDuration: n });
    }
  };
  const uploadHandller = (e) => {
    var formData = new FormData();
    if (e.target.files.length && e.target.files.length < 6) {
      let tes = e.target.files;
      if (e.target.name === "imgFiles") {
        for (let x = 0; x < e.target.files.length; x++) {
          if (tes[x].name === "") {
            ToastMessage(`The File ${x} invalid file name.`, "e");
            e.target.value = null;
            return false;
          }
          if (tes[x].size > 2500000) {
            ToastMessage(`The File ${x} upload minimum 2 MB.`, "e");
            e.target.value = null;
            return false;
          }
          if (
            ["image/jpeg", "image/jpg", "image/png"].includes(tes[x].type) ===
            false
          ) {
            ToastMessage(`The File ${x} invalid file format.`, "e");
            e.target.value = null;
            return false;
          }
          formData.append("imgFiles[]", tes[x]);
        }
        postData(`/api/upload_tempimagefiles`, formData)
          .then((result) => {
            let res = result.data;
            setProductData({ ...productData, image_uploads: res.data });
          })
          .catch((err) => {
            ToastMessage(err.data.message, "e");
            e.target.value = null;
          });
      } else {
        for (let i = 0; i < e.target.files.length; i++) {
          if (tes[i].name === "") {
            ToastMessage(`The File ${i} invalid file name.`, "e");
            e.target.value = null;
            return false;
          }
          if (tes[i].size > 2500000) {
            ToastMessage(`The File ${i} upload minimum 2 MB.`, "e");
            e.target.value = null;
            return false;
          }
          if (
            [
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(tes[i].type) === false
          ) {
            ToastMessage(`The File ${i} invalid file format.`, "e");
            e.target.value = null;
            return false;
          }
          formData.append("docFiles[]", tes[i]);
        }
        postData(`/api/upload_tempdocfiles`, formData)
          .then((result) => {
            let res = result.data;
            setProductData({ ...productData, doc_uploads: res.data });
          })
          .catch((err) => {
            ToastMessage(err.data.message, "e");
            e.target.value = null;
          });
      }
    } else {
      ToastMessage("Maximum 5 files uplaod allowed.", "e");
      e.target.value = null;
    }
  };

  var manufactureOptions = [];
  var modelOptions = [];
  var brandOptions = [];
  var unitOptions = [];
  const allOptions = masterList.map((option) => {
    if (option.type === "1") {
      manufactureOptions.push({
        value: option.id,
        label: option.name,
      });
    }
    if (option.type === "2") {
      modelOptions.push({
        value: option.id,
        label: option.name,
      });
    }
    if (option.type === "3") {
      brandOptions.push({
        value: option.id,
        label: option.name,
      });
    }
    if (option.type === "5") {
      unitOptions.push({
        value: option.id,
        label: option.name,
      });
    }
  });

  const [productDataerr, setProductDataerr] = useState("");
  const [editProductId, setEditProductId] = useState(false);
  const getProductDetails = useSelector(
    (state) => state.ProductReducers.GetProductDetailReducer
  );

  useEffect(() => {
    if (props.match.params.prodId) {
      setEditProductId(true);
      dispatch(get_product_detail_action(props.match.params.prodId,1));
    }
  }, [props.match.params.prodId, dispatch]);
  useEffect(() => {
    if (
      props.match.params.prodId &&
      getProductDetails.successgetProductDetail !== undefined
    ) {
		setProductData(getProductDetails.successgetProductDetail.data.dataArr);
    }
    if (
      props.match.params.prodId &&
      getProductDetails.failedgetProductDetail !== undefined
    ) {
      setProductDataerr(getProductDetails.failedgetProductDetail.message);
    }
  }, [props.match.params.prodId, getProductDetails]);

  const updateHandller = (e) => {
    e.preventDefault();
    if ($("#Product_Form").valid()) {
      setLoading(true);
      postData(`/api/update_product`, productData)
        .then((response) => {
          let data = response.data;
          ToastMessage(data.message, "s");
          formReset();
          setLoading(false);
          props.history.push("/product_management");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const removeAttachment = (id) => {
    ConfirmationAlertBox({
      title: "Confirmation",
      message: "Are You sure want to delete?",
    }).then((result) => {
      if (result.status && id !== null) {
        postData(`/api/remove_attachment_by_id`, { id: id })
          .then((response) => {
            let res = response.data;
            ToastMessage(res.message, "s");
            dispatch(get_product_detail_action(props.match.params.prodId));
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/product_management">Product</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {editProductId ? "Edit Product" : "Add New Product"}
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">
                  {editProductId ? "Edit Product" : "Add New Product"}
                </h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="Product_Form"
                      method="POST"
                      encType="multipart/form-data"
                      className="forms-sample row"
                    >
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="prodName">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="prodName"
                          name="prodName"
                          autoComplete="off"
                          placeholder="Name"
                          onChange={(e) =>
                            setProductData({
                              ...productData,
                              prodName: e.target.value,
                            })
                          }
                          value={productData.prodName}
                          data-rule-required={true}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="manufacturedBy1">
                          Manufactured By
                        </label>
                        <div className="cstm_select">
                          <SelectDropdown
                            id={"manufacturedBy1"}
                            name={"manufacturedBy1"}
                            required={true}
                            options={manufactureOptions}
                            sampleValue={true}
                            joinValues={true}
                            searchable={true}
                            clearable={true}
                            autoComplete="off"
                            placeholder="Select Manufactured By"
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                manufacturedBy: e != null ? e.value : null,
                              })
                            }
                            value={productData.manufacturedBy}
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="manufacturedBy"
                                data-msg-required={
                                  "Please select manufactured by."
                                }
                              />
                            )}
                          />

                          <label
                            className="error select_error_span"
                            htmlFor="manufacturedBy"
                          ></label>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="model">
                          Model
                        </label>
                        <div className="cstm_select">
                          <SelectDropdown
                            options={modelOptions}
                            id={"model1"}
                            name={"model1"}
                            required={true}
                            sampleValue={true}
                            joinValues={true}
                            searchable={true}
                            clearable={true}
                            placeholder="Select Model"
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                model: e !== null ? e.value : "",
                              })
                            }
                            value={productData.model}
                            autoComplete="off"
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="model"
                                data-msg-required={"Please select model."}
                              />
                            )}
                          />
                        </div>
                        <label
                          className="error select_error_span"
                          htmlFor="model"
                        ></label>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="brand">
                          Brands
                        </label>
                        <div className="cstm_select">
                          <SelectDropdown
                            options={brandOptions}
                            id="brand2"
                            name="brand2"
                            sampleValue={true}
                            joinValues={true}
                            required={true}
                            placeholder="Select Brand"
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                brand: e !== null ? e.value : "",
                              })
                            }
                            value={productData.brand}
                            autoComplete="off"
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="brand"
                                data-msg-required={"Please select brand."}
                              />
                            )}
                          />
                        </div>
                        <label
                          className="error select_error_span"
                          htmlFor="brand"
                        ></label>
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="unit">
                          Measuring Unit
                        </label>
                        <div className="cstm_select">
                          <SelectDropdown
                            options={unitOptions}
                            id="unit2"
                            name="unit2"
                            sampleValue={true}
                            joinValues={true}
                            //  required={true}
                            placeholder="Select Unit"
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                unit: e !== null ? e.value : "",
                              })
                            }
                            value={productData.unit}
                            autoComplete="off"
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="unit"
                                data-msg-required={"Please select unit."}
                              />
                            )}
                          />
                        </div>
                        <label
                          className="error select_error_span"
                          htmlFor="unit"
                        ></label>
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="unitvalue">
                          Value
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="unitvalue"
                          name="unitvalue"
                          autoComplete="off"
                          placeholder="Value"
                          onChange={(e) =>
                            setProductData({
                              ...productData,
                              unitvalue: e.target.value,
                            })
                          }
                          value={productData.unitvalue}
                        />
                      </div>

                      <div className="form-group  col-md-4">
                        <label className="w-100" htmlFor="product_url">
                          Product Url
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="productUrl"
                          name="productUrl"
                          autoComplete="off"
                          placeholder="https://example.com"
                          onChange={(e) =>
                            setProductData({
                              ...productData,
                              productUrl: e.target.value,
                            })
                          }
                          value={productData.productUrl}
                          //   data-rule-required={true}
                          //   data-rule-validurl={true}
                        />
                      </div>
                      <div className="form-group  col-md-4">
                        <label className="w-100" htmlFor="version">
                          Version
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="version"
                          name="version"
                          autoComplete="off"
                          placeholder="Version"
                          onChange={(e) =>
                            setProductData({
                              ...productData,
                              version: e.target.value,
                            })
                          }
                          value={productData.version}
                          //  data-rule-required={true}
                          mixlength="180"
                        />
                      </div>
                      <div></div>

                      {productData.imgFiles !== undefined &&
                      productData.imgFiles.length > 0 &&
                      productData.imgFiles.length < 6 ? (
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                            Image Uploads{" "}
                            <span>
                              Max limit {5 - productData.imgFiles.length}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="imgFiles"
                            name="imgFiles"
                            multiple={true}
                            accept="image/*"
                            onChange={uploadHandller}
                          />
                        </div>
                      ) : (
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="imgFiles">
                            Image Uploads <span>Max limit 5</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="imgFiles"
                            name="imgFiles"
                            multiple={true}
                            accept="image/*"
                            onChange={uploadHandller}
                          />
                        </div>
                      )}

                      {productData.docFiles !== undefined &&
                      productData.docFiles.length > 0 &&
                      productData.docFiles.length < 6 ? (
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                            Document Uploads{" "}
                            <span>
                              Max limit {5 - productData.docFiles.length}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="docFiles"
                            name="docFiles"
                            multiple={true}
                            accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={uploadHandller}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {productData.imgFiles !== undefined &&
                      productData.imgFiles.length > 0 &&
                      productData.imgFiles.length < 6 ? (
                        <div className="form-group col-md-12 docslist">
                          <label className="w-100">Image Attachment</label>
                          {productData.imgFiles.map((val, i) => (
                            <div className=" upload_image_AR " key={i}>
                              <span
                                className="cgl-btn"
                                onClick={() => removeAttachment(val.id)}
                              >
                                <IoMdClose />
                              </span>
                              <Link
                                to={{
                                  pathname: val.src,
                                }}
                                target="_blank"
                              >
                                <img
                                  src={val.src}
                                  alt={val.file_name}
                                  height="35px"
                                  width="auto"
                                />
                              </Link>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="docFiles">
                            Document Uploads <span>Max limit 5</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="docFiles"
                            name="docFiles"
                            multiple={true}
                            accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={uploadHandller}
                          />
                        </div>
                      )}
                      {productData.docFiles !== undefined &&
                      productData.docFiles.length > 0 &&
                      productData.docFiles.length < 6 ? (
                        <div className="form-group col-md-12 docslist">
                          <label className="w-100">Doc Attachment</label>
                          {productData.docFiles.map((val, x) => (
                            <div
                              className="doc_file_view_box_v1 edit_doc_file_view_box_v1"
                              key={x}
                            >
                              <Link
                                to={{
                                  pathname: API_BASE_URL + "/" + val.src,
                                }}
                                target="_blank"
                              >
                                {val.file_name}
                              </Link>
                              <span
                                className="clg-btn"
                                onClick={() => removeAttachment(val.id)}
                              >
                                <IoMdClose />
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group  col-md-12 align-self-end">
                        {editProductId ? (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={updateHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "loading..." : "Update"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "loading..." : "Submit"}
                          </button>
                        )}
                        <Link className="btn btn-dark" to="/product_management">
                          Cancel
                        </Link>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AddProductPage;
