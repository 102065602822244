import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import SelectDropdown from "../../components/ReactSelectPlus";
import Select from "react-select-plus";
import "react-select-plus/dist/react-select-plus.css";
import { get_company_option_list_action } from "../../actions/CompanyActions";
import { get_project_option_list_action } from "../../actions/ProjectActions";
import { get_plaza_option_list_action } from "../../actions/PlazaActions";
import { get_office_option_list_action } from "../../actions/OfficeActions";
import { get_warehouse_option_list_action } from "../../actions/WarehouseActions";

const AddPersonPage = (props) => {
  const [personData, setPersonData] = useState({
    name: "",
    company_id: null,
    name: "",
    email: "",
    contact_number: "",
    person_type: null,
    project_ids: [],
    plaza_ids: [],
    office_ids: [],
    warehouse_ids: [],
  });
  const [loading, setLoading] = useState(false);
  const resetForm = () => {
    setPersonData({
      name: "",
      company_id: null,
      name: "",
      email: "",
      contact_number: "",
      person_type: null,
      project_ids: [],
      plaza_ids: [],
      office_ids: [],
      warehouse_ids: [],
    });
  };
  const get_person_detail = useSelector((state) => {
    return {
      company: state.CompanyReducers.GetCompanyOptionListReducer,
      project: state.ProjectReducers.GetProjectOptionListReducer,
      // plaza: state.PlazaReducers.GetPlazaOptionListReducer,
      office: state.OfficeReducers.GetOfficeOptionListReducer,
      warehouse: state.WarehouseReducers.GetWarehouseOptionListReducer,
    };
  });
  const dispatch = useDispatch();
  const [companyOptionList, setCompanyOptionList] = useState([]);
  const [projectOptionList, setProjectOptionList] = useState([]);
  const [plazaOptionList, setPlazaOptionList] = useState([]);
  const [officeOptionList, setOfficeOptionList] = useState([]);
  const [warehouseOptionList, setWarehouseOptionList] = useState([]);

  useEffect(() => {
    dispatch(get_company_option_list_action());
    dispatch(get_project_option_list_action());
    // dispatch(get_plaza_option_list_action())
    // dispatch(get_office_option_list_action())
    // dispatch(get_warehouse_option_list_action())
  }, [dispatch]);
  useEffect(() => {
    if (get_person_detail.company.successCompanyOptionList) {
      setCompanyOptionList(
        get_person_detail.company.successCompanyOptionList.data
      );
    }
    if (get_person_detail.project.successProjectOptionList) {
      setProjectOptionList(
        get_person_detail.project.successProjectOptionList.data
      );
    }
    // if (get_person_detail.plaza.successPlazaOptionList) {
    //     setPlazaOptionList(get_person_detail.plaza.successPlazaOptionList.data)
    // }
    // if (get_person_detail.office.successOfficeOptionList) {
    //     setOfficeOptionList(get_person_detail.office.successOfficeOptionList.data)
    // }
    // if (get_person_detail.warehouse.successWarehouseOptionList) {
    //     setWarehouseOptionList(get_person_detail.warehouse.successWarehouseOptionList.data)
    // }
  }, [get_person_detail]);
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewPersonForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_person`, personData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
          props.history.push(`/persons`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  const onChangeLoadPlza = (e, type) => {
    if (e !== null) {
      let req =
        type === "project"
          ? { project_ids: e }
          : { project_ids: personData.project_ids, plaza_ids: e };
      postData(`/api/get_filter_option_list`, req)
        .then((response) => {
          let res = response.data;
          if (type === "project") {
            setPlazaOptionList(res.plaza);
          }
          setOfficeOptionList(res.office);
          setWarehouseOptionList(res.warehouse);
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/persons">Persons</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add New Peroson
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Add New Person</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="addnewPersonForm"
                      name="addnewPersonForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className=" row">
                        <Col className="form-group" lg={4}>
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            autoComplete="off"
                            placeholder="Name"
                            value={personData.name}
                            onChange={(e) =>
                              setPersonData({
                                ...personData,
                                name: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col className="form-group" lg={4}>
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            data-rule-required={true}
                            data-rule-email={true}
                            className="form-control"
                            autoComplete="off"
                            placeholder="abc@example.com"
                            onChange={(e) =>
                              setPersonData({
                                ...personData,
                                email: e.target.value,
                              })
                            }
                            value={personData.email}
                          />
                        </Col>
                        <Col className="form-group" lg={4}>
                          <label htmlFor="contact_number">Contact Number</label>
                          <input
                            type="text"
                            id="contact_number"
                            name="contact_number"
                            data-rule-required={true}
                            data-rule-phonenumber={true}
                            className="form-control"
                            autoComplete="off"
                            placeholder="9xxxxxxxxx"
                            onChange={(e) =>
                              setPersonData({
                                ...personData,
                                contact_number: e.target.value,
                              })
                            }
                            value={personData.contact_number}
                            minLength={10}
                            maxLength={12}
                          />
                        </Col>
                        <Col className="form-group" lg={4}>
                          <label htmlFor="company_id1">Company</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="company_id1"
                              name="company_id1"
                              options={companyOptionList}
                              // required={true}
                              joinValues={true}
                              className="form-"
                              autoComplete="off"
                              placeholder="Company"
                              onChange={(e) =>
                                setPersonData({
                                  ...personData,
                                  company_id: e !== null ? e.value : null,
                                })
                              }
                              value={personData.company_id}
                              inputRenderer={(props) => (
                                <input
                                  type="text"
                                  {...props}
                                  name="company_id"
                                  data-msg-required="Please select the company."
                                />
                              )}
                            />
                          </div>
                          {/* <label className="error" htmlFor="company_id"></label> */}
                        </Col>
                        <Col className="form-group" lg={4}>
                          <label htmlFor="person_type1">Category</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="person_type1"
                              name="person_type1"
                              options={[
                                { value: 1, label: "Employee" },
                                { value: 2, label: "Service Provider" },
                                { value: 3, label: "Vendor" },
                              ]}
                              required={true}
                              joinValues={true}
                              className="form-"
                              autoComplete="off"
                              placeholder="Category"
                              onChange={(e) =>
                                setPersonData({
                                  ...personData,
                                  person_type: e !== null ? e.value : null,
                                })
                              }
                              value={personData.person_type}
                              inputRenderer={(props) => (
                                <input
                                  type="text"
                                  {...props}
                                  name="person_type"
                                  data-msg-required="Please select category"
                                />
                              )}
                            />
                          </div>
                          <label
                            className="error select_error_span"
                            htmlFor="person_type"
                          ></label>
                        </Col>

                        {get_person_detail.project.successProjectOptionList ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="project_id1">
                              Select The Project
                            </label>
                            <div className="cstm_select">
                              <Select
                                id="project_id1"
                                name="project_id1"
                                options={projectOptionList}
                                className="form-"
                                joinValues={true}
                                simpleValue={true}
                                required={true}
                                multi={true}
                                clearable={true}
                                placeholder="Select the project"
                                autoComplete="off"
                                onChange={(e) => {
                                  setPersonData({
                                    ...personData,
                                    project_ids: e !== null ? e : null,
                                  });
                                  onChangeLoadPlza(e, "project");
                                }}
                                value={personData.project_ids}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="project_ids"
                                    data-msg-required="Please select the project."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="project_ids"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {personData.project_ids.length ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="plaza_id1">Select The Plaza</label>
                            <div className="cstm_select">
                              <Select
                                id="plaza_id1"
                                name="plaza_id1"
                                options={plazaOptionList}
                                className="form-"
                                joinValues={true}
                                simpleValue={true}
                                multi={true}
                                clearable={true}
                                placeholder="Select the plaza"
                                autoComplete="off"
                                onChange={(e) => {
                                  setPersonData({
                                    ...personData,
                                    plaza_ids: e !== null ? e : null,
                                  });
                                  onChangeLoadPlza(e, "plaza");
                                }}
                                value={personData.plaza_ids}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="plaza_ids"
                                    data-msg-required="Please select the plaza."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="plaza_ids"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {personData.project_ids.length ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="office_id1">
                              Select The Office
                            </label>
                            <div className="cstm_select">
                              <Select
                                id="office_id1"
                                name="office_id1"
                                options={officeOptionList}
                                className="form-"
                                joinValues={true}
                                simpleValue={true}
                                multi={true}
                                clearable={true}
                                placeholder="Select the office"
                                autoComplete="off"
                                onChange={(e) =>
                                  setPersonData({
                                    ...personData,
                                    office_ids: e !== null ? e : null,
                                  })
                                }
                                value={personData.office_ids}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="office_ids"
                                    data-msg-required="Please select the office."
                                  />
                                )}
                              />
                            </div>
                            <span
                              className="error select_error_span"
                              htmlFor="office_ids"
                            ></span>
                          </Col>
                        ) : (
                          ""
                        )}
                        {personData.project_ids.length ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="warehouse_id1">
                              Select The Warehouse
                            </label>
                            <div className="cstm_select">
                              <Select
                                multi={true}
                                id="warehouse_id1"
                                name="warehouse_id1"
                                options={warehouseOptionList}
                                className="form-"
                                joinValues={true}
                                simpleValue={true}
                                clearable={true}
                                placeholder="Select the warehouse"
                                autoComplete="off"
                                onChange={(e) =>
                                  setPersonData({
                                    ...personData,
                                    warehouse_ids: e !== null ? e : null,
                                  })
                                }
                                value={personData.warehouse_ids}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="warehouse_ids"
                                    data-msg-required="Please select the warehouse."
                                  />
                                )}
                              />
                            </div>
                            <span
                              className="error select_error_span"
                              htmlFor="warehouse_ids"
                            ></span>
                          </Col>
                        ) : (
                          ""
                        )}
                      </div>
                      <Row>
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                          <Link className="btn btn-dark" to="/persons">
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AddPersonPage;
