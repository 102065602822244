import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_roles_optionlist } from "../../actions/RoleActions";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../../components/ReactSelectPlus";

function AddNewUser(props) {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role_id: "",
    password: "",
  });
  const getRolesOptList = useSelector(
    (state) => state.GetRolesOptionListReducer.successRolesOptionList
  );
  const [loading, setLoading] = useState(false);
  const [rolesOptionList, setRolesOptionList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_roles_optionlist());
  }, [dispatch]);
  useEffect(() => {
    if (getRolesOptList) {
      setRolesOptionList(getRolesOptList.data);
    }
  }, [getRolesOptList]);
  const resetForm = () => {
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      role_id: "",
      password: "",
    });
  };
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewUserForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_user`, userData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
          props.history.push(`/usermanagement`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/usermanagement">User Management</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add New User
          </li>
        </ol>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Add New User</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="addnewUserForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col lg={4} className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            autoComplete="off"
                            placeholder="First Name"
                            value={userData.firstName}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                firstName: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            autoComplete="off"
                            placeholder="Last Name"
                            value={userData.lastName}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                lastName: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>
                        <Col lg={4} className="form-group">
                          <label htmlFor="email">Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="abc@example.com"
                            value={userData.email}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                email: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                            data-rule-email={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="mobile">Mobile</label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            placeholder="+91 xxxxx-xxxxx"
                            value={userData.mobile}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                mobile: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                            data-rule-phonenumber={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="role_id">Role</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="role_id"
                              name="role_id"
                              required={true}
                              options={rolesOptionList}
                              className={"mr-2"}
                              joinValues={true}
                              searchable={true}
                              clearable={true}
                              autoComplete="off"
                              placeholder="Select Role"
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  role_id: e !== null ? e.value : null,
                                })
                              }
                              value={userData.role_id}
                              inputRenderer={(props) => (
                                <input
                                  {...props}
                                  name="role_id"
                                  data-msg-required={"Please select role."}
                                />
                              )}
                            />
                            <label
                              className="error select_error_span"
                              htmlFor="role_id"
                            ></label>
                          </div>

                          {/* <select
                            type="text"
                            className="form-control"
                            id="role_id"
                            name="role_id"
                            placeholder="Select"
                            value={userData.role_id}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                role_id: e.target.value,
                              })
                            }
                            data-rule-required={true}
                          >
                            {rolesOptionList.map((val, x) => {
                              return (
                                <option value={val.id} key={val.id}>
                                  {val.name}
                                </option>
                              );
                            })}
                          </select> */}
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            autoComplete="off"
                            placeholder="**********"
                            value={userData.password}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                password: e.target.value,
                              })
                            }
                            maxLength={180}
                            data-rule-strongpassword={true}
                          />
                        </Col>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                          <Link className="btn btn-dark" to="/usermanagement">
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddNewUser;
