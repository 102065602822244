export const GET_LANE_DETAIL_INFO_REQUEST = "GET_LANE_DETAIL_INFO_REQUEST";
export const GET_LANE_DETAIL_INFO_SUCCESS = "GET_LANE_DETAIL_INFO_SUCCESS";
export const GET_LANE_DETAIL_INFO_FAIL = "GET_LANE_DETAIL_INFO_FAIL";

export const GET_LANE_OPTION_LIST_REQUEST = "GET_LANE_OPTION_LIST_REQUEST";
export const GET_LANE_OPTION_LIST_SUCCESS_INFO = "GET_LANE_OPTION_LIST_SUCCESS_INFO";
export const GET_LANE_OPTION_LIST_FAIL_ERROR = "GET_LANE_OPTION_LIST_FAIL_ERROR";

export const GET_LANES_BY_PLAZA_ID_REQUEST = "GET_LANES_BY_PLAZA_ID_REQUEST";
export const GET_LANES_BY_PLAZA_ID_SUCCESS = "GET_LANES_BY_PLAZA_ID_SUCCESS";
export const GET_LANES_BY_PLAZA_ID_FAIL_ERROR = "GET_LANES_BY_PLAZA_ID_FAIL_ERROR";