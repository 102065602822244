import {
  NOTIFICATION_COUNT_REQUEST,
  NOTIFICATION_COUNT_SUCCESS,
  NOTIFICATION_COUNT_FAIL,
  SAVE_NOTIFICATION_COUNT,
} from "../constants/NotificationConstants";

import { postData } from "../service/Common";

const get_notification_count = (Id) => async (dispatch) => {
  dispatch({ type: NOTIFICATION_COUNT_REQUEST, payload: {} });
  postData(`/api/notification_count`)
    .then((response) => {
      let data = response.data;
      dispatch({ type: NOTIFICATION_COUNT_SUCCESS, payload: data });

      if (data.count > 0) {
        let tempCount = data.count;
        dispatch({ type: SAVE_NOTIFICATION_COUNT, payload: tempCount });
      }
    })
    .catch((error) => {
      dispatch({ type: NOTIFICATION_COUNT_FAIL, payload: error.data });
    });
};

export { get_notification_count };
