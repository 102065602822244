import React from "react";
import { ReactBingmaps } from 'react-bingmaps';
const Map = (props)=> {
    return (
        <ReactBingmaps
            bingmapKey="Alk-jbL9XHopwK4VWmSczq7UQLJaY-eCkQDVyACPf0t5nT3Uz5SGckllrU7Fcp9v"
            center={props.center}
            zoom={props.zoom}
            mapTypeId={"road"}
            navigationBarMode={"compact"}
            // supportedMapTypes = {["road","canvasDark"]}
            heading={180}
            infoboxesWithPushPins={props.infoboxesWithPushPins}
        ></ReactBingmaps>
        )
}
Map.defaultProps = {
    zoom:4,
    mapTypeId:"road",
    center: [20.203040, 78.719391],
    infoboxesWithPushPins:[
        {
            location: [26.540457, 88.719391],
            addHandler: "mouseover", //on mouseover the pushpin, infobox shown
            infoboxOption: {
                title: "Toll 1",
                description: "Infobox",
            },
            pushPinOption: {
                title: "Toll 1 Title",
                description: "Pushpin",
            },
            infoboxAddHandler: {
                type: "click",
                callback: () => null,
            },
            pushPinAddHandler: {
                type: "click",
                callback: () => null,
            },
        }
    ]
       
};
export default Map;