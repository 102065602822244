import {
    GET_ASSIGN_DETAIL_REQUEST,
    GET_ASSIGN_DETAIL_SUCCESS_INFO,
    GET_ASSIGN_DETAIL_FAIL_ERROR
} from "../constants/AssignConstants";

function GetAssignDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_ASSIGN_DETAIL_REQUEST:
            return { loading: true };
        case GET_ASSIGN_DETAIL_SUCCESS_INFO:
            return { loading: false, successGetAssignDetail: action.payload };
        case GET_ASSIGN_DETAIL_FAIL_ERROR:
            return { loading: false, failedGetAssignDetail: action.payload }
        default:
            return state;
    }
}

export { GetAssignDetailReducer };