export const GET_WAREHOUSE_DETAIL_REQUEST = "GET_WAREHOUSE_DETAIL_REQUEST";
export const GET_WAREHOUSE_DETAIL_SUCCESS_INFO = "GET_WAREHOUSE_DETAIL_SUCCESS_INFO";
export const GET_WAREHOUSE_DETAIL_FAIL_ERROR = "GET_WAREHOUSE_DETAIL_FAIL_ERROR";

export const GET_WAREHOUSES_BY_PROJECT_ID_REQUEST = "GET_WAREHOUSES_BY_PROJECT_ID_REQUEST";
export const GET_WAREHOUSES_BY_PROJECT_ID_SUCCESS = "GET_WAREHOUSES_BY_PROJECT_ID_SUCCESS";
export const GET_WAREHOUSES_BY_PROJECT_ID_FAIL_ERROR = "GET_WAREHOUSES_BY_PROJECT_ID_FAIL_ERROR";

export const GET_WAREHOUSES_BY_PLAZA_ID_REQUEST = "GET_WAREHOUSES_BY_PLAZA_ID_REQUEST";
export const GET_WAREHOUSES_BY_PLAZA_ID_SUCCESS = "GET_WAREHOUSES_BY_PLAZA_ID_SUCCESS";
export const GET_WAREHOUSES_BY_PLAZA_ID_FAIL_ERROR = "GET_WAREHOUSES_BY_PLAZA_ID_FAIL_ERROR";


export const GET_WAREHOUSE_OPTION_LIST_REQUEST = "GET_WAREHOUSE_OPTION_LIST_REQUEST";
export const GET_WAREHOUSE_OPTION_LIST_SUCCESS = "GET_WAREHOUSE_OPTION_LIST_SUCCESS";
export const GET_WAREHOUSE_OPTION_LIST_FAIL_ERROR = "GET_WAREHOUSE_OPTION_LIST_FAIL_ERROR";