import {
	GET_PERMISSION_REQUEST,
	GET_PERMISSION_SUCCESS_INFO,
	GET_PERMISSION_FAIL_ERROR,
	GET_PERMISSIONS_OPTLIST_REQUEST,
	GET_PERMISSIONS_OPTLIST_SUCCESS_INFO,
	GET_PERMISSIONS_OPTLIST_FAIL_ERROR,
	GET_SELF_PERMISSIONS_ACCESSLIST_REQUEST,
	GET_SELF_PERMISSIONS_ACCESSLIST_SUCCESS_INFO,
	GET_SELF_PERMISSIONS_ACCESSLIST_FAIL_ERROR,
} from "../constants/PermissionContants";
import { postData } from "../service/Common";

const get_permission = (id) => async (dispatch) => {
	dispatch({ type: GET_PERMISSION_REQUEST, payload: {} });
	postData("/api/get_permission", { id: id })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_PERMISSION_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_PERMISSION_FAIL_ERROR, payload: error.data });
		});
};

const get_permission_option_list = () => async (dispatch) => {
	dispatch({ type: GET_PERMISSIONS_OPTLIST_REQUEST, payload: {} });
	postData("/api/get_permissions_option_list", {})
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_PERMISSIONS_OPTLIST_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_PERMISSIONS_OPTLIST_FAIL_ERROR, payload: error.data });
		});
};

const get_self_permission_slug_list = () => async (dispatch) => {
	dispatch({ type: GET_SELF_PERMISSIONS_ACCESSLIST_REQUEST, payload: {} });
	postData("/api/get_self_permission_access_list", '', { method_type: 'GET' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_SELF_PERMISSIONS_ACCESSLIST_SUCCESS_INFO, payload: data.data });
		})
		.catch((error) => {
			dispatch({ type: GET_SELF_PERMISSIONS_ACCESSLIST_FAIL_ERROR, payload: error.data });
		});
};

export { get_permission, get_permission_option_list, get_self_permission_slug_list };