import { combineReducers } from "redux";
import {
  GET_MASTER_REQUEST,
  GET_MASTER_SUCCESS_INFO,
  GET_MASTER_FAIL_ERROR,
  GET_MASTER_DETAIL_REQUEST,
  GET_MASTER_DETAIL_SUCCESS_INFO,
  GET_MASTER_DETAIL_FAIL_ERROR,
} from "../constants/MasterConstants";
// const initialState = {
//   successMasterList: [],
//   failedMasterList: [],
// };
function GetMasterListReducer(state = {}, action) {
  switch (action.type) {
    case GET_MASTER_REQUEST:
      return { loading: true };
    case GET_MASTER_SUCCESS_INFO:
      return { loading: false, successMasterList: action.payload };
    case GET_MASTER_FAIL_ERROR:
      return { loading: false, failedMasterList: action.payload };
    default:
      return state;
  }
}

function GetMasterDetailReducer(state = {}, action) {
  switch (action.type) {
    case GET_MASTER_DETAIL_REQUEST:
      return { loading: true };
    case GET_MASTER_DETAIL_SUCCESS_INFO:
      return { loading: false, successMasterDetail: action.payload };
    case GET_MASTER_DETAIL_FAIL_ERROR:
      return { loading: false, failedMasterDetail: action.payload };
    default:
      return state;
  }
}

export default combineReducers({
  GetMasterListReducer,
  GetMasterDetailReducer,
});
