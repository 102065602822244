import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import $ from "jquery";
import { ToastMessage } from "../service/ToastMessage";
import { postData } from "../service/Common";
const ChangePasswordPage = (props) => {
  const [userData, setUserData] = useState(() => {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  });
  const [loading, setLoading] = useState(false);

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#changePasswordForm").valid()) {
      setLoading(true);
      postData("/api/update_password", userData)
        .then((response) => {
          setLoading(false);
          ToastMessage(response.data.message, "s");
          setUserData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch((error) => {
          setLoading(false);
          ToastMessage(error.data.message, "e");
        });
    }
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Change Password Page
          </li>
        </ol>
        {/* <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
        >
          <PlusSvgIcon className="mr-2" />
          Add New User
        </Link> */}
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Change Password</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <form
                      id="changePasswordForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col className="form-group " lg={12}>
                          <label htmlFor="oldPassword">Old Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="oldPassword"
                            autoComplete="off"
                            placeholder="***************"
                            name={"oldPassword"}
                            value={userData.oldPassword || ""}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                oldPassword: e.target.value,
                              })
                            }
                            maxLength={80}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col className="form-group " lg={12}>
                          <label htmlFor="newPassword">New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            autoComplete="off"
                            placeholder="***************"
                            name={"newPassword"}
                            value={userData.newPassword || ""}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                newPassword: e.target.value,
                              })
                            }
                            maxLength={80}
                            data-rule-required={true}
                            data-rule-strongpassword={true}
                          />
                        </Col>

                        <Col className="form-group " lg={12}>
                          <label htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            autoComplete="off"
                            placeholder="***************"
                            name={"confirmPassword"}
                            value={userData.confirmPassword || ""}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                confirmPassword: e.target.value,
                              })
                            }
                            maxLength={80}
                            data-rule-required={true}
                            data-rule-equelto={"newPassword"}
                          />
                        </Col>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Update"}
                          </button>
                          <Link className="btn btn-dark" to="/dashboard">
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ChangePasswordPage;
