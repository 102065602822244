import React, { useState } from "react";
import classnames from "classnames";

//Layout styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./../assets/css/style.scss";

import Sidebar from "./../components/Sidebar";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
// import SideMenu from "components/SideMenu";

function LoginLayout(props) {
  const { children } = props;

  const closeSidebar = () => {
    setDefaultClass(!defaultClass);
  };

  const openSidebar = () => {
    setDefaultClassMobile(!openSidebarMobile);
  };

  const [defaultClass, setDefaultClass] = useState(false);
  const [openSidebarMobile, setDefaultClassMobile] = useState(false);

  return (
    <div
      className={classnames(
        defaultClass ? "sidebar-folded" : "",
        openSidebarMobile ? "sidebar-open" : ""
      )}
    >
      <div className="main-wrapper">
        <Sidebar
          {...props}
          sidebarClose={closeSidebar}
          activeToggleClass={defaultClass ? "active" : "not-active"}
          sidebarCloseMobile={openSidebar}
          activeToggleClassMobile={
            setDefaultClassMobile ? "active" : "not-aactive"
          }
        />
        {/* <Header {...props} />
      <SideMenu {...props} /> */}
        <div className="page-wrapper">
          <Header {...props} sidebarOpenMobile={openSidebar} />
          <div className="page-content">{children}</div>
          <Footer {...props} />
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;
