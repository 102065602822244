import React, { createRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  LocationSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  DownloadSvgIcon,
  PrinterSvgIcon,
  DownloadCloudSvgIcon,
  VscDebugBreakpointLogUnverified,
} from "./../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "./../components/ReactTable";
import SelectDropdown from "./../components/ReactSelectPlus";
import LocationModal from "./LocationModalPage";

function ReportList() {
  const [modalShow, setModalShow] = React.useState(false);
  const data = [
    {
      username: "Joye",
      toll_name: "SOUTH BIHAR",
      time_attendance: "Tuesday, 27 of Apr, 2021 15:08",
      location: "Nagarhole National",
    },
    {
      username: "Tommy tornary",
      toll_name: "Sharda Toll",
      time_attendance: "Tuesday, 28 of Apr, 2021 15:08",
      location: "SouthIndia National",
    },
  ];

  const columns = [
    {
      Header: "User Name",
      accessor: "username", // String-based value accessors!
      sortable: false,
    },
    {
      Header: "Toll Name",
      accessor: "toll_name",
      Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Time of attendance",
      accessor: "time_attendance",
      Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: (props) => (
        <span className="d-flex justify-content-between">
          {props.value}
          <button
            onClick={() => setModalShow(true)}
            type="button"
            class="btn btn-primary btn-icon btn-sm-my mr-2"
          >
            <LocationSvgIcon />
          </button>
        </span>
      ), // Custom cell components!
    },
  ];
  var options = [
    { value: "Today", label: "Today" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
  ];

  function logChange(val) {}
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Report
          </li>
        </ol>

        <div className="d-flex">
          <SelectDropdown
            options={options}
            logChange={logChange}
            className={"mr-2"}
            style={{ width: "180px" }}
            value={options.value}
          />
          <button
            type="button"
            class="btn btn-primary btn-icon-text mb-2 mb-md-0 btn-sm"
          >
            <DownloadCloudSvgIcon />
            Download Report
          </button>
        </div>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0"> Report List</h6>
              </div>
              {/* <Table
                data={data}
                columns={columns}
                defaultPageSize={data.length == 0 ? 4 : data.length}
                showPagination={data.length == 0 ? false : true}
                className={"text_align_left"}
              /> */}

              <div className="card_box_border mb-3">
                <div className="row">
                  <div className="pb-3 col-lg-4">
                    <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                      <h6 className="card-title">Inventory Reports</h6>
                    </div>
                    <div>
                      <ul className="listing_top_new">
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            User Report
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Inventory Summary
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Inventory Report
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Stock Summary
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Purchase Order Summary
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="pb-3 col-lg-4">
                    <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                      <h6 className="card-title">Logs Reports</h6>
                    </div>
                    <div>
                      <ul className="listing_top_new">
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Activity Log
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            System Log
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="pb-3 col-lg-4">
                    <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                      <h6 className="card-title">Sales & Purchases</h6>
                    </div>
                    <div>
                      <ul className="listing_top_new">
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Invoice Details
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Sales Order Details
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Delivery Challan Details
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Purchase Order Details
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Purchase Order by Vendor
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Purchase Order by Vendor
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Purchase by Item
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <VscDebugBreakpointLogUnverified
                              className={"link-icon"}
                            />
                            Recieve history
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <LocationModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
export default ReportList;
