import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import { get_plaza_detail_action } from "../../actions/PlazaActions";
import {
  get_project_detail_action,
  get_unassigned_option_list_action,
} from "../../actions/ProjectActions";
import SelectDropdown from "../../components/ReactSelectPlus";

const AddNewAssignPage = (props) => {
  const [assignData, setAssignData] = useState({
    typeIn: props.match.params.type,
    InId: props.match.params.Id,
    assign_to: "",
    id: "",
  });
  const [loading, setLoading] = useState(false);
  const [projectDetail, setProjectDetail] = useState({});
  const getOptionsList = useSelector((state) => {
    return {
      plaza: state.PlazaReducers.GetPlazaDetailReducer,
      project: state.ProjectReducers.GetProjectDetailReducer,
      unAssignedOptions: state.ProjectReducers.GetUnAssignedOptionList,
    };
  });
  const dispatch = useDispatch();
  const resetForm = () => {
    setAssignData({
      typeIn: props.match.params.type,
      InId: props.match.params.Id,
      assign_to: "",
      id: "",
    });
  };
  const [plazaDetail, setPlazaDetail] = useState([]);
  const [plazaOptionList, setPlazaOptionList] = useState([]);
  const [laneOptionList, setLaneOptionList] = useState([]);
  const [officeOptionList, setOfficeOptionList] = useState([]);
  const [warehouseOptionList, setWarehouseOptionList] = useState([]);
  const [inventoriesOptionList, setInventoriesOptionList] = useState([]);

  useEffect(() => {
    if (props.match.params.type === "project") {
      dispatch(get_project_detail_action(props.match.params.Id));
    }
    if (props.match.params.type === "plaza") {
      dispatch(get_plaza_detail_action(props.match.params.Id));
    }
    dispatch(get_unassigned_option_list_action());
  }, [dispatch, props]);
  useEffect(() => {
    if (getOptionsList.plaza) {
      setPlazaDetail(getOptionsList.plaza ? getOptionsList.plaza : []);
    }
    if (getOptionsList.project) {
      setProjectDetail(getOptionsList.project ? getOptionsList.project : []);
    }
    if (getOptionsList.unAssignedOptions.successUnAssignedOptionList) {
      setPlazaOptionList(
        getOptionsList.unAssignedOptions.successUnAssignedOptionList.data.plaza
      );
      setLaneOptionList(
        getOptionsList.unAssignedOptions.successUnAssignedOptionList.data.lane
      );
      setOfficeOptionList(
        getOptionsList.unAssignedOptions.successUnAssignedOptionList.data.office
      );
      setWarehouseOptionList(
        getOptionsList.unAssignedOptions.successUnAssignedOptionList.data
          .warehouse
      );
      setInventoriesOptionList(
        getOptionsList.unAssignedOptions.successUnAssignedOptionList.data
          .inventory
      );
    }
  }, [getOptionsList]);
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewAssignForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_assign`, assignData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
          dispatch(get_unassigned_option_list_action());
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  const assignOptions =
    props.match.params.type === "project"
      ? [
          { value: "plaza", label: "Plaza" },
          { value: "office", label: "Offices" },
          { value: "warehouse", label: "Warehouse" },
          // { value: "inventory", label: "Inventory" },
        ]
      : [
          { value: "lane", label: "Lane" },
          { value: "office", label: "Offices" },
          { value: "warehouse", label: "Warehouse" },
          // { value: "inventory", label: "Inventory" },
        ];
  const changeHandller = (e) => {
    setAssignData({ ...assignData, assign_to: e !== null ? e.value : null });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          {props.match.params.type !== undefined &&
          props.match.params.Id > 0 ? (
            <li className="breadcrumb-item">
              {props.match.params.type === "project" ? (
                <Link to={`/project_management/${props.match.params.Id}/show`}>
                  Project
                </Link>
              ) : (
                ""
              )}
              {props.match.params.type === "plaza" ? (
                <Link to={`/plaza/${props.match.params.Id}/show`}>Plaza</Link>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}
          {/* <li className="breadcrumb-item">
            <Link to="/assigns">Assign</Link>
          </li> */}
          <li className="breadcrumb-item active" aria-current="page">
            Add New Assign
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Add New Assign</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {props.match.params.type === "project" ? (
                      projectDetail.loading ? (
                        <div className="text-warning text-center form-control mb-5">
                          <label>Loading...</label>
                        </div>
                      ) : projectDetail.successProjectDetail ? (
                        <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 ">
                          <div className="pb-2">
                            Project:
                            <Link
                              className="input_inner_btn ml-2"
                              to={`/project_management/${projectDetail.successProjectDetail.data.id}/show`}
                            >
                              {projectDetail.successProjectDetail.data.name}
                            </Link>
                          </div>
                        </div>
                      ) : projectDetail.failedProjectDetail ? (
                        <div className="text-center text-danger form-control mb-5">
                          <h5>{projectDetail.failedProjectDetail.message}</h5>
                        </div>
                      ) : (
                        ""
                      )
                    ) : plazaDetail.loading ? (
                      <div className="text-warning text-center form-control mb-5">
                        <label>Loading...</label>
                      </div>
                    ) : plazaDetail.successPlazaDetail ? (
                      <div>
                        <h6>
                          <Link
                            to={`/plaza/${plazaDetail.successPlazaDetail.data.id}/show`}
                          >
                            Plaza: {plazaDetail.successPlazaDetail.data.name}
                          </Link>
                        </h6>
                      </div>
                    ) : plazaDetail.failedPlazaDetail ? (
                      <div className="text-center text-danger form-control mb-5">
                        <h5>{plazaDetail.failedPlazaDetail.message}</h5>
                      </div>
                    ) : (
                      ""
                    )}
                    <form
                      id="addnewAssignForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="row">
                        <Col className="form-group" lg={4}>
                          <label htmlFor="type">Assign To</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="inventory1"
                              name="inventory1"
                              options={assignOptions}
                              joinValues={true}
                              required={true}
                              className="form-"
                              autoComplete="off"
                              placeholder="Select Assign to"
                              onChange={(e) => changeHandller(e)}
                              value={assignData.assign_to}
                              inputRenderer={(props) => (
                                <input
                                  type="text"
                                  {...props}
                                  name="assign_to"
                                  data-msg-required="Please select the assign to."
                                />
                              )}
                            />
                          </div>
                          <label
                            className="error select_error_span"
                            htmlFor="assign_to"
                          ></label>
                        </Col>

                        {assignData.assign_to === "plaza" ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="type">Plaza</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="plaza1"
                                name="plaza1"
                                options={plazaOptionList}
                                zzzz
                                required={true}
                                joinValues={true}
                                className="form-"
                                autoComplete="off"
                                placeholder="Select the plaza"
                                onChange={(e) =>
                                  setAssignData({
                                    ...assignData,
                                    id: e !== null ? e.value : null,
                                  })
                                }
                                value={assignData.id}
                                inputRenderer={(props) => (
                                  <input
                                    type="text"
                                    {...props}
                                    name="id"
                                    data-msg-required="Please select the plaza."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="id"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {assignData.assign_to === "lane" ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="type">Lane</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="lane1"
                                name="lane1"
                                options={laneOptionList}
                                required={true}
                                joinValues={true}
                                className="form-"
                                autoComplete="off"
                                placeholder="Select the lane"
                                onChange={(e) =>
                                  setAssignData({
                                    ...assignData,
                                    id: e !== null ? e.value : null,
                                  })
                                }
                                value={assignData.id}
                                inputRenderer={(props) => (
                                  <input
                                    type="text"
                                    {...props}
                                    name="id"
                                    data-msg-required="Please select the lane."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="id"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {assignData.assign_to === "office" ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="type">Office</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="Office1"
                                name="Office1"
                                options={officeOptionList}
                                required={true}
                                joinValues={true}
                                className="form-"
                                autoComplete="off"
                                placeholder="Select the office"
                                onChange={(e) =>
                                  setAssignData({
                                    ...assignData,
                                    id: e !== null ? e.value : null,
                                  })
                                }
                                value={assignData.id}
                                inputRenderer={(props) => (
                                  <input
                                    type="text"
                                    {...props}
                                    name="id"
                                    data-msg-required="Please select the Office."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="id"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {assignData.assign_to === "warehouse" ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="type">Warehouse</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="warehouse1"
                                name="warehouse1"
                                options={warehouseOptionList}
                                required={true}
                                joinValues={true}
                                className="form-"
                                autoComplete="off"
                                placeholder="Select the warehouse"
                                onChange={(e) =>
                                  setAssignData({
                                    ...assignData,
                                    id: e !== null ? e.value : null,
                                  })
                                }
                                value={assignData.id}
                                inputRenderer={(props) => (
                                  <input
                                    type="text"
                                    {...props}
                                    name="id"
                                    data-msg-required="Please select the warehouse."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="id"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                        {assignData.assign_to === "inventory" ? (
                          <Col className="form-group" lg={4}>
                            <label htmlFor="Inventorytype">Inventory</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="inventory1"
                                name="inventory1"
                                options={inventoriesOptionList}
                                required={true}
                                joinValues={true}
                                className="form-"
                                autoComplete="off"
                                placeholder="Select the inventory"
                                onChange={(e) =>
                                  setAssignData({
                                    ...assignData,
                                    id: e !== null ? e.value : null,
                                  })
                                }
                                value={assignData.id}
                                inputRenderer={(props) => (
                                  <input
                                    type="text"
                                    {...props}
                                    name="id"
                                    data-msg-required="Please select the inventory."
                                  />
                                )}
                              />
                            </div>
                            <label
                              className="error select_error_span"
                              htmlFor="id"
                            ></label>
                          </Col>
                        ) : (
                          ""
                        )}
                      </div>
                      <Row>
                        <Col className="form-group" lg={12}>
                          <button
                            type="submit"
                            name="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                          {/* <Link className="btn btn-dark" to="/assigns">
                          Cancel
                        </Link> */}
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AddNewAssignPage;
