
export const GET_PERMISSION_REQUEST = "GET_PERMISSION_REQUEST";
export const GET_PERMISSION_SUCCESS_INFO = "GET_PERMISSION_SUCCESS_INFO";
export const GET_PERMISSION_FAIL_ERROR= "GET_PERMISSION_FAIL_ERROR";

export const GET_PERMISSIONS_OPTLIST_REQUEST = "GET_PERMISSIONS_OPTLIST_REQUEST";
export const GET_PERMISSIONS_OPTLIST_SUCCESS_INFO = "GET_PERMISSIONS_OPTLIST_SUCCESS_INFO";
export const GET_PERMISSIONS_OPTLIST_FAIL_ERROR = "GET_PERMISSIONS_OPTLIST_FAIL_ERROR";

export const GET_SELF_PERMISSIONS_ACCESSLIST_REQUEST = "GET_SELF_PERMISSIONS_ACCESSLIST_REQUEST";
export const GET_SELF_PERMISSIONS_ACCESSLIST_SUCCESS_INFO = "GET_SELF_PERMISSIONS_ACCESSLIST_SUCCESS_INFO";
export const GET_SELF_PERMISSIONS_ACCESSLIST_FAIL_ERROR = "GET_SELF_PERMISSIONS_ACCESSLIST_FAIL_ERROR";
