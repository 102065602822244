import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
export class ComponentToPrintMultiple extends React.PureComponent {
  render() {
    console.log("Here", this.props.data);
    return (
      <>
        <Row className=" justify-content-center">
          <Col lg={12} className="grid-margin stretch-card">
            <div
              style={{
                width: "100%",
                verticalAlign: "top",
                float: "left",
              }}
            >
              {this.props.data
                ? this.props.data.map((val, i) => (
                    <>
                      <div
                        style={{
                          width: "14.28%",
                          verticalAlign: "top",
                          float: "left",
                          textAlign: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={
                            "data:image/jpg;base64, " + (val.src ? val.src : "")
                          }
                          alt="base64"
                        />
                        {/* {val.barcode_key} */}
                      </div>
                      {/* <br /> */}
                    </>
                  ))
                : ""}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
