import { Modal } from "react-bootstrap";

function LocationModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Toll Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235526.90924712367!2d75.7237618214095!3d22.724228429904368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1619504220750!5m2!1sen!2sin"
          width="100%"
          height="350"
          allowfullscreen=""
          loading="lazy"
          style={{ border: "0" }}
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <span className={"btn btn-primary btn-sm"} onClick={props.onHide}>
          Close
        </span>
      </Modal.Footer>
    </Modal>
  );
}
export default LocationModal;
