import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <>
        <Row className=" justify-content-center">
          <Col lg={12} className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card_box_border">
                  <Row className="justify-content-center">
                    <Col lg={12}>
                      <div className="forms-sample row printme">
                        <div className="form-group col-md-4">
                          <div className="w-100" htmlFor="serialNumber">
                            <br />
                            <img
                              src={
                                "data:image/jpg;base64, " +
                                (this.props.data ? this.props.data : "")
                              }
                              alt="base64"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
