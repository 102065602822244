import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../assets/css/style.scss";

function LoginLayout(props) {
  const { children } = props;
  return <>{children}</>;
}

export default LoginLayout;
