import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import $ from "jquery";
import { get_plaza_detail_action } from "../../actions/PlazaActions";
import { useDispatch, useSelector } from "react-redux";

function AddNewPlazaPage(props) {
  const [plazaData, setPlazaData] = useState({
    project_id: props.match.params.projId,
    name: "",
    plazaContactNumber: "",
    plazaType: "1",
    npciPazaId: "",
    agencyCode: "",
    address: "",
    plazaIncharge: "",
    plazaInchargeContactNumber: "",
    latitude: "",
    longitude: "",
    area: "",
    city: "",
    district: "",
    state: "",
    country: "",
    editId: props.match.params.pID !== undefined ? props.match.params.pID : "",
  });

  const [editId, setEditId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectDetail, setProjectDetail] = useState({});
  const get_project_detail = useSelector(
    (state) => state.ProjectReducers.GetProjectDetailReducer
  );
  useEffect(() => {
    if (get_project_detail.successProjectDetail) {
      setProjectDetail(get_project_detail.successProjectDetail.data);
    }
  }, [get_project_detail]);

  const resetForm = () => {
    setPlazaData({
      project_id: props.match.params.projId,
      name: "",
      plazaContactNumber: "",
      plazaType: "1",
      agencyCode: "",
      address: "",
      plazaIncharge: "",
      plazaInchargeContactNumber: "",
      latitude: "",
      longitude: "",
      editId:
        props.match.params.pID !== undefined ? props.match.params.pID : "",
    });
  };
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#PlazaForm").valid()) {
      postData(`/api/addnew_plaza`, plazaData)
        .then((result) => {
          ToastMessage(result.data.message, "s");
          resetForm();
          setLoading(false);
          props.history.push(`/plazas`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  // Edit Plaza

  const get_plaza_details = useSelector((state) => {
    return {
      plaza: state.PlazaReducers.GetPlazaDetailReducer,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.pID !== undefined) {
      setEditId(true);
      dispatch(get_plaza_detail_action(props.match.params.pID));
    } else {
      setEditId(false);
      resetForm();
    }
  }, [dispatch, props.match.params.pID]);

  useEffect(() => {
    if (props.match.params.pID && get_plaza_details.plaza.successPlazaDetail) {
      setPlazaData(get_plaza_details.plaza.successPlazaDetail.data);
    }
  }, [get_plaza_details.plaza]);

  const updateHandller = (e) => {
    e.preventDefault();
    if ($("#PlazaForm").valid()) {
      setLoading(true);
      postData(`/api/update_plaza`, plazaData)
        .then((result) => {
          ToastMessage(result.data.message, "s");
          dispatch(get_plaza_detail_action(props.match.params.pID));
          setLoading(false);
          setEditId(false);
          resetForm();
          props.history.push(`/plazas`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  return (
    <>
      {console.log(props)}
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            {props.match.params.projId ? (
              <Link
                to={`/project_management/${props.match.params.projId}/show`}
              >
                Projects
              </Link>
            ) : (
              <Link to="/project_management">Projects</Link>
            )}
          </li>
          <li className="breadcrumb-item">
            <Link to="/plazas">Plaza</Link>
          </li>

          {editId ? (
            <li
              className="breadcrumb-item active"
              aria-current="page"
              onClick={() => {
                setEditId(true);
              }}
            >
              {"Edit Plaza"}
            </li>
          ) : (
            <li
              className="breadcrumb-item active"
              aria-current="page"
              onClick={() => {
                setEditId(false);
                resetForm();
              }}
            >
              {"Add New Plaza"}
            </li>
          )}
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">
                  {editId ? "Edit Plaza" : "Add New Plaza"}
                </h6>
              </div>
              <div className="card_box_border">
                <h6 className="mb-3">
                  {props.match.params.projId ? (
                    <span>
                      {" "}
                      In "
                      <Link
                        to={`/project_management/${props.match.params.projId}/show`}
                      >
                        {projectDetail.name} Project
                      </Link>
                      "
                    </span>
                  ) : (
                    ""
                  )}
                </h6>
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="PlazaForm"
                      method="POST"
                      className="forms-sample row"
                    >
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="Plaza_name">
                          Plaza name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Plaza_name"
                          name="name"
                          autoComplete="off"
                          placeholder="Plaza name"
                          value={plazaData.name}
                          onChange={(e) =>
                            setPlazaData({ ...plazaData, name: e.target.value })
                          }
                          data-rule-required={true}
                          minLength={3}
                          maxLength={180}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="npci_paza_id">
                          NPCI Plaza Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="npci_paza_id"
                          name="npci_paza_id"
                          autoComplete="off"
                          placeholder="NPCI Plaza Id"
                          value={plazaData.npci_paza_id}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              npci_paza_id: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="plaza_contact">
                          Plaza contact no
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="plaza_contact"
                          name="plazaContactNumber"
                          autoComplete="off"
                          placeholder="Plaza contact no"
                          value={plazaData.plazaContactNumber}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              plazaContactNumber: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-phonenumber={true}
                          minLength={10}
                          maxLength={18}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100">Plaza type</label>
                        <div className="cstm_select select1 ">
                          <label
                            htmlFor="nationalplazaType"
                            className="d-inline-flex align-items-center"
                          >
                            <input
                              type="radio"
                              id="nationalplazaType"
                              name="plazaType"
                              className="mr-1"
                              value={"1"}
                              checked={
                                plazaData.plazaType === "1" ? true : false
                              }
                              onChange={(e) => {
                                setPlazaData({
                                  ...plazaData,
                                  plazaType: e.target.value,
                                });
                              }}
                            />{" "}
                            National
                          </label>
                          <label
                            htmlFor="stateplazaType"
                            className="d-inline-flex align-items-center ml-4"
                          >
                            <input
                              type="radio"
                              id="stateplazaType"
                              name="plazaType"
                              className="mr-1"
                              value={"2"}
                              checked={
                                plazaData.plazaType === "2" ? true : false
                              }
                              onChange={(e) => {
                                setPlazaData({
                                  ...plazaData,
                                  plazaType: e.target.value,
                                });
                              }}
                            />{" "}
                            State
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="agency_code">
                          Agency code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="agency_code"
                          name="agencyCode"
                          autoComplete="off"
                          placeholder="Agency code"
                          value={plazaData.agencyCode}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              agencyCode: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          minLength={3}
                          maxLength={50}
                        />
                      </div>

                      <div className="form-group  col-md-4">
                        <label className="w-100" htmlFor="plaza_incharge">
                          Plaza incharge
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="plaza_incharge"
                          name="plazaIncharge"
                          placeholder="Plaza incharge"
                          value={plazaData.plazaIncharge}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              plazaIncharge: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          minLength={3}
                          maxLength={180}
                        ></input>
                      </div>

                      <div className="form-group  col-md-4">
                        <label
                          className="w-100"
                          htmlFor="plaza_incharge_contact"
                        >
                          In charge contact no
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="plaza_incharge_contact"
                          name="plazaInchargeContactNumber"
                          autoComplete="off"
                          placeholder="In charge contact no"
                          value={plazaData.plazaInchargeContactNumber}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              plazaInchargeContactNumber: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-phonenumber={true}
                          minLength={10}
                          maxLength={15}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="Address">
                          Address
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className="form-control"
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              address: e.target.value,
                            })
                          }
                          value={plazaData.address}
                          placeholder="Address"
                          data-rule-required={true}
                          minLength={3}
                          maxLength={180}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="city">
                          City
                        </label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="form-control"
                          onChange={(e) =>
                            setPlazaData({ ...plazaData, city: e.target.value })
                          }
                          value={plazaData.city}
                          placeholder="City"
                          data-rule-required={true}
                          minLength={3}
                          maxLength={180}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="state">
                          State
                        </label>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          className="form-control"
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              state: e.target.value,
                            })
                          }
                          value={plazaData.state}
                          placeholder="State"
                          data-rule-required={true}
                          minLength={2}
                          maxLength={180}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="zipCode">
                          Zip Code
                        </label>
                        <input
                          type="text"
                          id="zipCode"
                          name="zipCode"
                          className="form-control"
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              zipCode: e.target.value,
                            })
                          }
                          value={plazaData.zipCode}
                          placeholder="xxxxxx"
                          data-rule-required={true}
                          data-rule-postcodecheck={true}
                          minLength={3}
                          maxLength={180}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="latitude">
                          Latitude
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="latitude"
                          name="latitude"
                          placeholder="Latitude"
                          value={plazaData.latitude}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              latitude: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-latitude={true}
                          maxLength={25}
                        ></input>
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="longitude">
                          Longitude
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="longitude"
                          name="longitude"
                          placeholder="Longitude"
                          value={plazaData.longitude}
                          onChange={(e) =>
                            setPlazaData({
                              ...plazaData,
                              longitude: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-longitude={true}
                          maxLength={25}
                        ></input>
                      </div>

                      <div className="form-group  col-md-12 align-self-end">
                        {editId ? (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={updateHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading" : "Update"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading" : "Submit"}
                          </button>
                        )}

                        <Link
                          className="btn btn-dark"
                          to="/plazas"
                          onClick={() => {
                            setEditId(false);
                            resetForm();
                          }}
                        >
                          Cancel
                        </Link>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddNewPlazaPage;
