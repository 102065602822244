import {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL_ERROR,
} from "../constants/DashboadConstants.js";
import { postData } from "../service/Common";
const get_dashboard_data_action = () => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_DATA_REQUEST, payload: {} });
  postData(`/api/get_dashboard_map_locations`, {})
    .then((response) => {
      let data = response.data;
      dispatch({ type: GET_DASHBOARD_DATA_SUCCESS, payload: data });
    })
    .catch((error) => {
      if (error) {
        return dispatch({
          type: GET_DASHBOARD_DATA_FAIL_ERROR,
          payload: error,
        });
      }
    });
};
export { get_dashboard_data_action };
