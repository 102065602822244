import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "./../assets/images/logo_dav_aditya.svg";
import {
  DashboardSvgIcon,
  UserMangementSvgIcon,
  LocationSvgIcon,
  ReportSvgIcon,
  RiFileList3Line,
  BiMessageRoundedError,
  BiBuildingHouse,
  IoHardwareChipOutline,
  IoIosArrowDown,
  IoIosArrowUp,
  MdSettingsInputComposite,
  VscDebugBreakpointLogUnverified,
  CgProfile,
  RiLockPasswordLine,
  FaUsersCog,
  SiOpenaccess,
  GiCardboardBox,
  BsBuilding,
  FiUsers,
  AiOutlineLogin,
  GiBrodieHelmet,
  Inventory,
  Notify,
} from "./SvgIcon";

import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/AuthActions";
import { get_self_permission_slug_list } from "../actions/PermissionActions";

function Sidebar(props) {
  const [openDropDn, setOpenDropDn] = useState(false);
  const [openProductDropDn, setOpenProductDropDn] = useState(false);
  const [openInventoryDropDn, setInventoryDropDn] = useState(false);
  const [openShippingDropDn, setShippingDropDn] = useState(false);
  const selfAccess = useSelector(
    (state) =>
      state.GetSelfPermissoinAccessListReducer.successSelfPermissionAccessList
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (selfAccess === undefined) {
      dispatch(get_self_permission_slug_list());
    }
  });
  const logoutHandller = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <>
      <nav className="sidebar">
        <div className="sidebar-header">
          <Link to="/" className="sidebar-brand">
            <img src={Logo} alt="Logo-img" />
          </Link>
          <div
            onClick={props.sidebarClose}
            className={`sidebar-toggler   ${props.activeToggleClass} d-none d-xl-block`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            onClick={props.sidebarCloseMobile}
            className={`sidebar-toggler    ${props.activeToggleClassMobile} d-block d-xl-none`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="sidebar-body ps ps--active-y">
          <ul className="nav">
            <li className="nav-item nav-category">Main</li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/dashboard">
                <DashboardSvgIcon className={"link-icon"} />
                <span className="link-title">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item hover_issue_fixed">
              <div
                className={`w-100 nav-link ${openDropDn ? "open" : "close"}`}
                to="/dashboard"
                onClick={() => setOpenDropDn(openDropDn ? false : true)}
              >
                <MdSettingsInputComposite className={"link-icon"} />
                <span className="link-title">Projects</span>

                {!openDropDn ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
              <ul
                className={`${openDropDn ? "drop_list_v0 d-block" : "d-none"}`}
              >
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_projects_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/project_management">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Projects List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_plazas_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/plazas">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Plaza List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_lanes_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/lanes">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Lane List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}

                {selfAccess !== undefined &&
                selfAccess.indexOf("get_offices_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/offices">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Office List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_warehouses_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/warehouses">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Warehouse List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>

            <li className="nav-item hover_issue_fixed">
              <div
                className={`w-100 nav-link ${
                  openProductDropDn ? "open" : "close"
                }`}
                to="/dashboard"
                onClick={() =>
                  setOpenProductDropDn(openProductDropDn ? false : true)
                }
              >
                {/* <MdSettingsInputComposite className={"link-icon"} /> */}
                <GiCardboardBox />
                <span className="link-title">Products</span>

                {!openProductDropDn ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
              <ul
                className={`${
                  openProductDropDn ? "drop_list_v0 d-block" : "d-none"
                }`}
              >
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_products_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/product_management">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Product List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_plazas_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/list_group_product">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Grouped Product</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>
			

			<li className="nav-item hover_issue_fixed">
              <div className={`w-100 nav-link ${
					openInventoryDropDn ? "open" : "close"
                }`}
                onClick={() => setInventoryDropDn(openInventoryDropDn ? false : true) } >
              
                <GiCardboardBox />
                <span className="link-title">Inventory</span>

                {!openInventoryDropDn ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
              <ul
                className={`${
					openInventoryDropDn ? "drop_list_v0 d-block" : "d-none"
                }`}
              >
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_inventories_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/inventory_management">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Inventory</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {/*selfAccess !== undefined &&
                selfAccess.indexOf("get_inventories_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/list_group_inventory">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Group Inventory</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )*/}
              </ul>
            </li>

			<li className="nav-item hover_issue_fixed">
              <div className={`w-100 nav-link ${
					openShippingDropDn ? "open" : "close"
                }`}
                onClick={() => setShippingDropDn(openShippingDropDn ? false : true) } >
              
                <GiCardboardBox />
                <span className="link-title">Shipping</span>

                {!openShippingDropDn ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
              <ul
                className={`${
					openShippingDropDn ? "drop_list_v0 d-block" : "d-none"
                }`}
              >
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_inventories_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/carriers_list">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Carriers List</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {selfAccess !== undefined &&
                selfAccess.indexOf("get_inventories_list") > -1 ? (
                  <li>
                    <NavLink className="nav-link" to="/tracking">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">Tracking</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                 <li>
                    <NavLink className="nav-link" to="/shipping_history">
                      <VscDebugBreakpointLogUnverified
                        className={"link-icon"}
                      />
                      <span className="link-title">History</span>
                    </NavLink>
                  </li>
              </ul>
            </li>

            <li className="nav-item nav-category">Other</li>
            {selfAccess !== undefined &&
            selfAccess.indexOf("users_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/usermanagement">
                  <UserMangementSvgIcon className={"link-icon"} />
                  <span className="link-title">User</span>
                </NavLink>
              </li>
            ) : null}
            
            {selfAccess !== undefined &&
            selfAccess.indexOf("get_companies_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/companies">
                  <BsBuilding className={"link-icon"} />
                  <span className="link-title">Company</span>
                </NavLink>
              </li>
            ) : null}
            {selfAccess !== undefined &&
            selfAccess.indexOf("get_persons_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/persons">
                  <FiUsers className={"link-icon"} />
                  <span className="link-title">Persons</span>
                </NavLink>
              </li>
            ) : null}

            <li className="nav-item">
              <NavLink className="nav-link" to="/requests">
                <IoHardwareChipOutline />
                <span className="link-title">Hardware Request</span>
              </NavLink>
            </li>
            {selfAccess !== undefined &&
            selfAccess.indexOf("get_plaza_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/plazas">
                  <BiBuildingHouse />
                  <span className="link-title">Plaza</span>
                </NavLink>
              </li>
            ) : null}
            {selfAccess !== undefined &&
            selfAccess.indexOf("get_idle_lane_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/idle_lane">
                  <BiBuildingHouse />
                  <span className="link-title">Idle LANE</span>
                </NavLink>
              </li>
            ) : null}
            <li className="nav-item">
              <NavLink className="nav-link" to="/report">
                <ReportSvgIcon />
                <span className="link-title">Report</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/report_issue">
                <BiMessageRoundedError />
                <span className="link-title">Report issue</span>
              </NavLink>
            </li>
            <li className="nav-item nav-category">Settings </li>
            {selfAccess !== undefined &&
            selfAccess.indexOf("get_profile") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/profile">
                  <CgProfile className={"link-icon"} />
                  <span className="link-title">Profile</span>
                </NavLink>
              </li>
            ) : null}
            {selfAccess !== undefined &&
            selfAccess.indexOf("update_password") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/change-password">
                  <RiLockPasswordLine className={"link-icon"} />
                  <span className="link-title">Change Password</span>
                </NavLink>
              </li>
            ) : null}
            {selfAccess !== undefined &&
            selfAccess.indexOf("roles_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/roles">
                  <FaUsersCog className={"link-icon"} />
                  <span className="link-title">Roles</span>
                </NavLink>
              </li>
            ) : null}

            {selfAccess !== undefined &&
            selfAccess.indexOf("get_master_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/masters">
                  <GiBrodieHelmet className={"link-icon"} />
                  <span className="link-title">Masters</span>
                </NavLink>
              </li>
            ) : null}

            <li className="nav-item">
              <NavLink className="nav-link" to="/tracking">
                <GiBrodieHelmet className={"link-icon"} />
                <span className="link-title">Tracking</span>
              </NavLink>
            </li>

            {selfAccess !== undefined &&
            selfAccess.indexOf("roles_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/notifications">
                  <Notify className={"link-icon"} />
                  <span className="link-title">Notifications</span>
                </NavLink>
              </li>
            ) : null}

            {selfAccess !== undefined &&
            selfAccess.indexOf("permissions_list") > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to="/permissions">
                  <SiOpenaccess className={"link-icon"} />
                  <span className="link-title">Permissions</span>
                </NavLink>
              </li>
            ) : null}
            <li className="nav-item" onClick={logoutHandller}>
              <div className="nav-link">
                <AiOutlineLogin className={"link-icon"} />
                <span className="link-title">Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
