import { combineReducers } from "redux";
import {
    GET_INVENTORY_DETAIL_INFO_REQUEST,
    GET_INVENTORY_DETAIL_INFO_SUCCESS,
    GET_INVENTORY_DETAIL_INFO_FAIL,
    GET_INVENTORY_OPTION_LIST_REQUEST,
    GET_INVENTORY_OPTION_LIST_SUCCESS_INFO,
    GET_INVENTORY_OPTION_LIST_FAIL_ERROR,
    GET_INVENTORY_DETAIL_LIST_BYID_FAIL_ERROR,
    GET_INVENTORY_DETAIL_LIST_BYID_SUCCESS_INFO,
    GET_INVENTORY_DETAIL_LIST_BYID_REQUEST,
    GET_BARCODE_DETAIL_REQUEST, 
    GET_BARCODE_DETAIL_SUCCESS, 
    GET_BARCODE_DETAIL_FAIL,
    GET_SELECTED_BARCODE_DETAIL_REQUEST,
    GET_SELECTED_BARCODE_DETAIL_SUCCESS,
    GET_SELECTED_BARCODE_DETAIL_FAIL
} from "../constants/InventoryConstants";

function GetInventoryDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_INVENTORY_DETAIL_INFO_REQUEST:
            return { loading: true };
        case GET_INVENTORY_DETAIL_INFO_SUCCESS:
            return { loading: false, successgetInventoryDetail: action.payload };
        case GET_INVENTORY_DETAIL_INFO_FAIL:
            return { loading: false, failedgetInventoryDetail: action.payload }
        default:
            return state;
    }
}

function GetInventoryOptionListReducer(state = [], action) {
    switch (action.type) {
        case GET_INVENTORY_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_INVENTORY_OPTION_LIST_SUCCESS_INFO:
            return { loading: false, successInventoryOptionList: action.payload };
        case GET_INVENTORY_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failInventoryOptionList: action.payload }
        default:
            return state;
    }
}

function GetInventoryListByFilterIdReducer(state = [], action) {
    switch (action.type) {
        case GET_INVENTORY_DETAIL_LIST_BYID_REQUEST:
            return { loading: true };
        case GET_INVENTORY_DETAIL_LIST_BYID_SUCCESS_INFO:
            return { loading: false, successInventoryByprojectId: action.payload };
        case GET_INVENTORY_DETAIL_LIST_BYID_FAIL_ERROR:
            return { loading: false, failedInventoryByprojectId: action.payload }
        default:
            return state;
    }
}

function GetBarcodeDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_BARCODE_DETAIL_REQUEST:
            return { loading: true };
        case GET_BARCODE_DETAIL_SUCCESS:
            return { loading: false, successgetBarcodeDetail: action.payload };
        case GET_BARCODE_DETAIL_FAIL:
            return { loading: false, failedgetBarcodeDetail: action.payload }
        default:
            return state;
    }
}

function GetSelectedBarcodeDetailReducer(state = {successGetSelectedBarcodeDetail:[]}, action) { 
    switch (action.type) {
        case GET_SELECTED_BARCODE_DETAIL_REQUEST:
            return { ...state,loading: true };
        case GET_SELECTED_BARCODE_DETAIL_SUCCESS:
            return { ...state,loading: false, successGetSelectedBarcodeDetail: action.payload.data };
        case GET_SELECTED_BARCODE_DETAIL_FAIL:
            return { ...state,loading: false, failedgetSelectedBarcodeDetail: action.payload.data }
        default:
            return state;
    }
}
export default combineReducers( { GetInventoryDetailReducer, GetInventoryOptionListReducer, GetInventoryListByFilterIdReducer, GetBarcodeDetailReducer, GetSelectedBarcodeDetailReducer });