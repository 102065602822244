import { postData } from "../service/Common";
import { GET_COMPANY_DETAIL_REQUEST, GET_COMPANY_DETAIL_SUCCESS, GET_COMPANY_DETAIL_FAIED_ERROR,
    GET_COMPANT_OPTION_LIST_REQUEST,GET_COMPANT_OPTION_LIST_SUCCESS, GET_COMPANT_OPTION_LIST_FAILED } from "../constants/CompanyConstants.js";

const get_company_detail_action = (id) => async (dispatch) => {
    dispatch({ type: GET_COMPANY_DETAIL_REQUEST, payload: {} });
    postData(`/api/get_company_detail`, { id: id })
        .then((response) => {
            dispatch({ type: GET_COMPANY_DETAIL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: GET_COMPANY_DETAIL_FAIED_ERROR, payload: error.data.message });
        })
}

const get_company_option_list_action=()=>async(dispatch)=>{
    dispatch({type: GET_COMPANT_OPTION_LIST_REQUEST, payload:{}})
    postData(`/api/get_company_option_list`,'',{method_type:'get'})
    .then((response)=>{
        dispatch({type: GET_COMPANT_OPTION_LIST_SUCCESS, payload: response.data})
    })
    .catch((error)=>{
        dispatch({type: GET_COMPANT_OPTION_LIST_FAILED, payload: error.data})
    })
}
export { get_company_detail_action, get_company_option_list_action };