import {
    GET_PERSON_DETAIL_FAILED_ERROR, GET_PERSON_DETAIL_REQUEST, GET_PERSON_DETAIL_SUCCESS,
    GET_PERSON_OPTION_LIST_REQUEST,
    GET_PERSON_OPTION_LIST_SUCCESS,
    GET_PERSON_OPTION_LIST_FAILED_ERROR
} from "../constants/PersonConstants";
import { combineReducers } from "redux";
function GetPersonDetailReducer(state = [], action) {
    switch (action.type) {
        case GET_PERSON_DETAIL_REQUEST:
            return { loading: true };
        case GET_PERSON_DETAIL_SUCCESS:
            return { loading: false, successPersonDetail: action.payload };
        case GET_PERSON_DETAIL_FAILED_ERROR:
            return { loading: false, failedPersonDetail: action.payload }
        default:
            return state;
    }
}

function GetPersonOptionListReducer(state = [], action) {
    switch (action.type) {
        case GET_PERSON_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_PERSON_OPTION_LIST_SUCCESS:
            return { loading: false, successPersonOptionList: action.payload };
        case GET_PERSON_OPTION_LIST_FAILED_ERROR:
            return { loading: false, failedPersonOptionList: action.payload }
        default:
            return state;
    }
}
export default combineReducers({ GetPersonDetailReducer, GetPersonOptionListReducer });