import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import React, { useState } from "react";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";

function WarehouseListPage() {
  const columns = [
    {
      Header: "ID",
      accessor: "_warehouse_id",
    },
    {
      Header: "Warehouse Name",
      accessor: "name",
    },
    {
      Header: "Warehouse Contact",
      accessor: "incharge_contact_no",
    },
    {
      Header: "Project Name",
      accessor: "projectName",
      Cell: (props) => <span>{props.original.projectName}</span>,
    },
    {
      Header: "Palza Name",
      accessor: "projectName",
      Cell: (props) => (
        <span>
          {props.original.plazaName} {}
        </span>
      ),
    },
    {
      Header: "Incharge Name",
      accessor: "incharge_name",
    },
    {
      Header: (props) => <span>Created</span>,
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created}</span>,
    },
    {
      Header: (props) => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <Link
            to={`/warehouse/${props.original.id}/show`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Show Detail"
          >
            <FiEye />
          </Link>
          <Link
            to={`/warehouse/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Update"
          >
            <EditSvgIcon />
          </Link>
          <button
            onClick={() => deleteHandller(props.original.id)}
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Delete"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];
  const [warehouseData, setWarehouseData] = useState([]);
  const page_size = 10;
  const search = "";
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const sortedBy = "";

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    setLoading(true);
    postData(`/api/get_warehouses_list`, req)
      .then((result) => {
        var res = result.data;
        setWarehouseData(res.data);
        let tmppages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmppages);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
  const deleteHandller = (pID) => {
    ConfirmationAlertBox(`Are You sure want to remove it?`, {}).then(
      (status) => {
        if (status) {
          postData(`/api/delete_warehouse`, { id: pID })
            .then((result) => {
              ToastMessage(result.data.message, "s");
              fetchData(page_size, page, search, sortedBy);
            })
            .catch((error) => {
              ToastMessage(error.data.message, "e");
            });
        }
      }
    );
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/project_management">Projects</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Warehouses
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/warehouse/addnew_warehouse"
        >
          <PlusSvgIcon className="mr-2" />
          Add New Warehouse
        </Link>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0"> Warehouses List</h6>
              </div>
              <Table
                data={warehouseData}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default WarehouseListPage;
