import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_lane_detail_action } from "../../actions/LaneActions";
import { useDispatch, useSelector } from "react-redux";
import { get_plaza_option_list_action } from "../../actions/PlazaActions";
import SelectDropdown from "../../components/ReactSelectPlus";

const AddNewLanePage = (props) => {
  const [laneData, setLaneData] = useState({
    plaza_id: props.match.params.plazaId,
    lane_id: props.match.params.laneId,
    name: "",
    icdtwopointfive: "",
    icdtwopointfour: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const get_plaza_detail = useSelector(
    (state) => state.PlazaReducers.GetPlazaDetailReducer
  );
  const resetForm = () => {
    setLaneData({
      name: "",
      plaza_id: props.match.params.plazaId,
      direction: "",
      icdtwopointfive: "",
      icdtwopointfour: "",
      type: "",
    });
  };
  const [plazaData, setPlazaData] = useState({});
  const typeOptionList = [
    { value: "1", label: "Dedicated" },
    { value: "2", label: "Handheld" },
    { value: "3", label: "Hybrid" },
  ];

  const plazaDirectionOptionList = [
    { value: "east", label: "East" },
    { value: "west", label: "West" },
    { value: "North", label: "North" },
    { value: "south", label: "South" },
  ];

  // useEffect(()=>{
  //   dispatch(get_plaza_option_list_action())
  // },[dispatch]);
  useEffect(() => {
    if (get_plaza_detail.successPlazaDetail) {
      setPlazaData(get_plaza_detail.successPlazaDetail.data);
    }
  }, [get_plaza_detail]);
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#LaneForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_lane`, laneData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
          setEditLaneId(false);
          props.history.push(`/lanes`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  //Edit Lane

  const getLaneDetail = useSelector(
    (state) => state.LaneReducers.GetLaneDetailReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_plaza_option_list_action());
    if (props.match.params.laneId) {
      setEditLaneId(true);
      dispatch(get_lane_detail_action(props.match.params.laneId));
    }
  }, [dispatch, props]);
  useEffect(() => {
    if (props.match.params.laneId && getLaneDetail.successgetLaneDetail) {
      setLaneData(getLaneDetail.successgetLaneDetail.data);
    }
  }, [getLaneDetail]);
  const [editLaneId, setEditLaneId] = useState(false);
  const updateHandller = (e) => {
    e.preventDefault();
    if ($("#LaneForm").valid()) {
      setLoading(true);
      postData(`/api/update_lane`, laneData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          setLoading(false);
          resetForm();
          setEditLaneId(false);
          props.history.push("/lanes");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/plazas">Plazas</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/lanes">Lane</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {editLaneId ? "Edit Lane" : "Add New Lane"}
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">
                  {editLaneId ? "Edit Lane" : "Add New Lane"}
                </h6>
              </div>
              <div className="card_box_border">
                <h6>
                  {props.match.params.plazaId ? (
                    <span>
                      {" "}
                      In "
                      <Link to={`/plaza/${props.match.params.plazaId}/show`}>
                        {plazaData.name} Plaza
                      </Link>
                      "
                    </span>
                  ) : (
                    ""
                  )}
                </h6>
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <form id="LaneForm" method="POST" className="forms-sample">
                      <div className="form-group row">
                        <Col lg={12}>
                          <label htmlFor="name">
                            Lane Name (Like L1,L10,L12)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            autoComplete="off"
                            placeholder="Name"
                            value={laneData.name}
                            onChange={(e) =>
                              setLaneData({ ...laneData, name: e.target.value })
                            }
                            minLength={2}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>
                      </div>

                      <div className="form-group row">
                        <Col lg={12}>
                          <label htmlFor="icdtwopointfive1">
                            ICD 2.5 Direction
                          </label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="icdtwopointfive1"
                              name="icdtwopointfive1"
                              options={plazaDirectionOptionList}
                              // required={true}
                              joinValues={true}
                              className="form-"
                              autoComplete="off"
                              placeholder="Select the 2.5 direction"
                              onChange={(e) =>
                                setLaneData({
                                  ...laneData,
                                  icdtwopointfive: e !== null ? e.value : null,
                                })
                              }
                              value={laneData.icdtwopointfive}
                              inputRenderer={(props) => (
                                <input
                                  type="text"
                                  {...props}
                                  name="icdtwopointfive"
                                  data-msg-required="Please select the plaza."
                                />
                              )}
                            />
                          </div>
                          <label
                            className="error select_error_span"
                            htmlFor="icdtwozerofive"
                          ></label>
                        </Col>
                      </div>

                      <div className="form-group row">
                        <Col lg={12}>
                          <label htmlFor="icdtwopointfour1">
                            ICD 2.4 Direction
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="icdtwopointfour1"
                            name="icdtwopointfour1"
                            autoComplete="off"
                            placeholder="From city to city"
                            value={laneData.icdtwopointfour}
                            onChange={(e) =>
                              setLaneData({
                                ...laneData,
                                icdtwopointfour: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>
                      </div>

                      <div className="form-group row">
                        <Col lg={12}>
                          <label htmlFor="type">Type</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="type1"
                              name="type1"
                              options={typeOptionList}
                              required={true}
                              joinValues={true}
                              className={"se"}
                              autoComplete="off"
                              placeholder="Select the type"
                              onChange={(e) =>
                                setLaneData({
                                  ...laneData,
                                  type: e !== null ? e.value : null,
                                })
                              }
                              value={laneData.type}
                              inputRenderer={(props) => (
                                <input
                                  type="text"
                                  {...props}
                                  name="type"
                                  data-msg-required="Please select type."
                                />
                              )}
                            />
                          </div>
                          <label
                            className="error select_error_span"
                            htmlFor="type"
                          ></label>
                        </Col>
                      </div>

                      <Row>
                        <Col lg={12}>
                          {!editLaneId ? (
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              onClick={submitHandller}
                              disabled={loading ? true : false}
                            >
                              {loading ? "Loading..." : "Submit"}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              onClick={updateHandller}
                              disabled={loading ? true : false}
                            >
                              {loading ? "Loading..." : "Update"}
                            </button>
                          )}
                          <Link className="btn btn-dark" to="/lanes">
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AddNewLanePage;
