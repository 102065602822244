import { postData } from "../service/Common";
import {
  GET_PRODUCT_DETAIL_INFO_REQUEST,
  GET_PRODUCT_DETAIL_INFO_SUCCESS,
  GET_PRODUCT_DETAIL_INFO_FAIL,
  GET_PRODUCT_OPTION_LIST_REQUEST,
  GET_PRODUCT_OPTION_LIST_SUCCESS_INFO,
  GET_PRODUCT_OPTION_LIST_FAIL_ERROR,
  GET_PRODUCT_DETAIL_LIST_BYID_REQUEST,
  GET_PRODUCT_DETAIL_LIST_BYID_SUCCESS_INFO,
  GET_PRODUCT_DETAIL_LIST_BYID_FAIL_ERROR,
  GET_SINGLE_PRODUCT_LIST_REQUEST,
  GET_SINGLE_PRODUCT_OPTION_LIST,
  GET_SINGLE_PRODUCT_OPTION_LIST_FAIL_ERROR,
  GET_PRODUCT_DETAIL_WITH_QTY_INFO_REQUEST,
  GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS,
  GET_PRODUCT_DETAIL_WITH_QTY_INFO_FAIL,
  GET_INVENTORY_DETAIL_REQUEST,
  GET_INVENTORY_DETAIL_INFO_SUCCESS,
  GET_INVENTORY_DETAIL_INFO_FAIL,

} from "../constants/ProductConstants";
const get_product_detail_action = (prodID,productType) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_DETAIL_INFO_REQUEST, payload: {} });
  postData(`/api/get_product_detail`, { id: prodID,productType:productType })
    .then((response) => {
      let data = response.data;
      dispatch({ type: GET_PRODUCT_DETAIL_INFO_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: GET_PRODUCT_DETAIL_INFO_FAIL, payload: error.data });
    });
};
const get_product_option_list_action = (productType) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_OPTION_LIST_REQUEST, payload: {} });
  postData(`/api/get_products_option_list`, {productType:productType})
    .then((response) => {
      var data = response.data;
      dispatch({ type: GET_PRODUCT_OPTION_LIST_SUCCESS_INFO, payload: data });
    })
    .catch((error) => {
      dispatch({
        type: GET_PRODUCT_OPTION_LIST_FAIL_ERROR,
        payload: error.data.message,
      });
    });
};
const get_product_by_project_id_action = (type, id) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_DETAIL_LIST_BYID_REQUEST, payload: {} });
  postData(`/api/get_inventories_filter_by_id`, { filter_by: type, id: id })
    .then((response) => {
      var data = response.data;
      dispatch({
        type: GET_PRODUCT_DETAIL_LIST_BYID_SUCCESS_INFO,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PRODUCT_DETAIL_LIST_BYID_FAIL_ERROR,
        payload: error.data.message,
      });
    });
};

const get_single_product_list_action = () => async (dispatch) => {
	dispatch({ type: GET_SINGLE_PRODUCT_LIST_REQUEST, payload: {} })
	postData(`/api/get_single_product_option_list`, '', { method_type: 'GET' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_SINGLE_PRODUCT_OPTION_LIST, payload: data });
		}).catch((error) => {
			dispatch({ type: GET_SINGLE_PRODUCT_OPTION_LIST_FAIL_ERROR, payload: error.data.message });
		})
}

const get_product_detail_with_qty_action = (prodID,productType,qty) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_DETAIL_WITH_QTY_INFO_REQUEST, payload: {} });
  postData(`/api/get_group_inventory_detail`, { id: prodID,productType:productType,qty:qty })
    .then((response) => {
      let data = response.data;
      dispatch({ type: GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: GET_PRODUCT_DETAIL_WITH_QTY_INFO_FAIL, payload: error.data });
    });
};

const get_grp_inventory_detail_action = (inventoryId) => async (dispatch) => {
  dispatch({ type: GET_INVENTORY_DETAIL_REQUEST, payload: {} });
  postData(`/api/grp_inventory_detail`, { inventoryId: inventoryId })
    .then((response) => {
      let data = response.data;
      dispatch({ type: GET_INVENTORY_DETAIL_INFO_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: GET_INVENTORY_DETAIL_INFO_FAIL, payload: error.data });
    });
};

export {
  get_product_detail_action,
  get_product_option_list_action,
  get_product_by_project_id_action,
  get_single_product_list_action,
  get_product_detail_with_qty_action,
  get_grp_inventory_detail_action,
};
