import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NumericInputBox from "../../components/NumericInput";
import $ from "jquery";
import { GrAddCircle, FiMinusCircle } from "../../components/SvgIcon";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import SelectDropdown from "../../components/ReactSelectPlus";
import {
  postData,
  handleRemoveInputElements,
  handleAddInputElements,
  handleShareholderNameChange,
} from "../../service/Common";
import {
  get_single_product_list_action,
  get_product_detail_action,
} from "../../actions/ProductActions";
import { COUNT_OF_ATTACHED_SINGLE_PRODUCTS_ON_GROUP_PRODUCT } from "../../constants/ProductConstants";
import { ToastMessage } from "../../service/ToastMessage";
import { IoMdClose } from "../../components/SvgIcon";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";

function AddGroupProduct(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editProductId, setEditProductId] = useState(false);
  const [pageTitle, setPageTitle] = useState("Add Grouped Product");
  const [viewProduct, setViewProduct] = useState(false);
  const [grpProducts, setGrpProducts] = useState([{ product_id: "", qty: "" }]);
  const [productdropDown, setproductdropDown] = useState([]);
  const [productData, setProductData] = useState({
    prodName: "",
    description: null,
    imgFiles: [],
  });

  const uploadHandller = (e) => {
    var formData = new FormData();
    if (e.target.files.length && e.target.files.length < 6) {
      let tes = e.target.files;
      if (e.target.name === "imgFiles") {
        for (let x = 0; x < e.target.files.length; x++) {
          if (tes[x].name === "") {
            ToastMessage(`The File ${x} invalid file name.`, "e");
            e.target.value = null;
            return false;
          }
          if (tes[x].size > 2500000) {
            ToastMessage(`The File ${x} upload minimum 2 MB.`, "e");
            e.target.value = null;
            return false;
          }
          if (
            ["image/jpeg", "image/jpg", "image/png"].includes(tes[x].type) ===
            false
          ) {
            ToastMessage(`The File ${x} invalid file format.`, "e");
            e.target.value = null;
            return false;
          }
          formData.append("imgFiles[]", tes[x]);
        }
        postData(`/api/upload_tempimagefiles`, formData)
          .then((result) => {
            let res = result.data;
            setProductData({ ...productData, image_uploads: res.data });
          })
          .catch((err) => {
            ToastMessage(err.data.message, "e");
            e.target.value = null;
          });
      }
    } else {
      ToastMessage("Maximum 5 files uplaod allowed.", "e");
      e.target.value = null;
    }
  };

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#GrpProductForm").valid()) {
      postData(`/api/add_group_product`, {
        productData: productData,
        grpProducts: grpProducts,
      })
        .then((response) => {
          let data = response.data;
          ToastMessage(data.message, "s");
          formReset();
          setLoading(false);
          props.history.push("/list_group_product");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const formReset = () => {
    setProductData({
      prodName: "",
      description: null,
      imgFiles: [],
    });
  };

  const getProductDetails = useSelector(
    (state) => state.ProductReducers.GetProductDetailReducer
  );

  const checkOperatioMode = () => {
    if (props.location.pathname.includes("view")) {
      setViewProduct(true);
      setPageTitle("View Grouped Product");
    } else {
      setPageTitle("Edit Grouped Product");
    }
  };

  useEffect(() => {
    if (
      props.match.params.prodId &&
      getProductDetails.successgetProductDetail !== undefined
    ) {
      setProductData(getProductDetails.successgetProductDetail.data.dataArr);
      if (getProductDetails.successgetProductDetail.data.grpData.length > 0) {
        setGrpProducts(getProductDetails.successgetProductDetail.data.grpData);
      }
    }

	if( getoptionsData.product.successSingleProductOptionList !== undefined){
		setproductdropDown(getoptionsData.product.successSingleProductOptionList)
	}
  }, [props.match.params.prodId, getProductDetails]);

  useEffect(() => {
    if (props.match.params.prodId) {
      setEditProductId(true);
      checkOperatioMode();
      dispatch(get_product_detail_action(props.match.params.prodId, 2));
    }
  }, [props.match.params.prodId, dispatch]);

  useEffect(()=>{
	productOnSelect()
  },[grpProducts])

  const getoptionsData = useSelector((state) => {
    return {
      product: state.ProductReducers.GetSingleProductListReducer,
    };
  });

  const removeAttachment = (id) => {
    ConfirmationAlertBox({
      title: "Confirmation",
      message: "Are You sure want to delete?",
    }).then((result) => {
      if (result.status && id !== null) {
        postData(`/api/remove_attachment_by_id`, { id: id })
          .then((response) => {
            let res = response.data;
            ToastMessage(res.message, "s");
            dispatch(get_product_detail_action(props.match.params.prodId));
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };

  const productOnSelect =()=>{
    var xx = getoptionsData.product.successSingleProductOptionList;
    var selectedProductId = [];
    grpProducts.map((value, idx) => {
		if(value.product_id){
			selectedProductId.push( value.product_id 	);
			//grpProducts.filter((item) => item.product_id !== value.product_id);
		}
	})

	xx.map((val, idx) => {
		if(selectedProductId.includes(val.value))  
		{  
			val.disabled = true;
		}   
		else  
		{  
			val.disabled = false;
		} 
	})
    setproductdropDown(xx)
  }

  useEffect(() => {
    dispatch(get_single_product_list_action());
  }, [dispatch]);
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/list_group_product">Grouped Product</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {pageTitle}
          </li>
        </ol>
      </nav>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Product Group</h6>
              </div>
              <div className="card_box_border">
                <form
                  method="POST"
                  encType="multipart/form-data"
                  id="GrpProductForm"
                >
                  <div className=" justify-content-center row">
                    <div className="col-lg-12">
                      <Row>
                        <Col lg={3} className="form-group">
                          <label className="w-100" for="prodName">
                            Name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            data-rule-required={true}
                            name="prodName"
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                prodName: e.target.value,
                              })
                            }
                            value={productData.prodName}
                            readOnly={viewProduct}
                          />
                        </Col>

                        <Col lg={6} className="form-group">
                          <label className="w-100" for="prodName">
                            Description
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="description"
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                description: e.target.value,
                              })
                            }
                            value={productData.description}
                            readOnly={viewProduct}
                          />
                        </Col>

                        <Col lg={3} className="form-group">
                          {!viewProduct ? (
                            productData.imgFiles !== undefined &&
                            productData.imgFiles.length > 0 &&
                            productData.imgFiles.length < 6 ? (
                              <>
                                <label className="w-100" htmlFor="version">
                                  Image Uploads{" "}
                                  <span>
                                    {" "}
                                    Max limit {5 - productData.imgFiles.length}
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="imgFiles"
                                  name="imgFiles"
                                  multiple={true}
                                  accept="image/*"
                                  onChange={uploadHandller}
                                />
                              </>
                            ) : (
                              <>
                                <label className="w-100" htmlFor="imgFiles">
                                  Image Uploads <span>Max limit 5</span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="imgFiles"
                                  name="imgFiles"
                                  multiple={true}
                                  accept="image/*"
                                  onChange={uploadHandller}
                                />
                              </>
                            )
                          ) : (
                            ""
                          )}

                          {productData.imgFiles !== undefined &&
                          productData.imgFiles.length > 0 &&
                          productData.imgFiles.length < 6 ? (
                            <>
                              <label className="w-100">Image Attachment</label>
                              {productData.imgFiles.map((val, i) => (
                                <div className=" upload_image_AR " key={i}>
                                  {!viewProduct ? (
                                    <span
                                      className="cgl-btn"
                                      onClick={() => removeAttachment(val.id)}
                                    >
                                      <IoMdClose />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <Link
                                    to={{ pathname: val.src }}
                                    target="_blank"
                                  >
                                    <img
                                      src={val.src}
                                      alt={val.file_name}
                                      height="35px"
                                      width="auto"
                                    />
                                  </Link>
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col lg={3} className="form-group"></Col>
                      </Row>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 ">
                    <h6 className="card-title">Select Products</h6>
                  </div>

                  <Row>
                    {grpProducts !== undefined && grpProducts.map((value, idx) => (
                      <Col lg={6}>
                        <div className="product_itme">
                          <div className="cstm_select">
                            <SelectDropdown
                              id={"manufacturedBy1"}
                              name={"product" + idx}
							  cache={false}
                              required={true}
                              sampleValue={true}
                              joinValues={true}
                              searchable={true}
                              clearable={true}
                              autoComplete="off"
                              placeholder="Select Product"
                              onChange={(e) =>
                                handleShareholderNameChange(
                                  setGrpProducts,
                                  grpProducts,
                                  idx,
                                  "product_id",
                                  e?.value
                                )
                              }
                              value={value.product_id || ''}
                              options={productdropDown}
                              inputRenderer={(props) => (
                                <input
                                  {...props}
                                  name="manufacturedBy"
                                  data-msg-required={"Please select Product."}
                                />
                              )}
                              disabled={viewProduct}
                              onOpen={(e)=>productOnSelect()}
							 // isOptionUnique={true}
                            />

                            <label
                              className="error select_error_span"
                              htmlFor="manufacturedBy"
                            ></label>
                          </div>

                          {/*<NumericInputBox value={value.qty} name={'qty'+idx} required onChange={(e) => handleShareholderNameChange(setGrpProducts,grpProducts,idx,'qty',e.target.value)}/>*/}
                          <div>
                            <input
                              data-rule-numberOnly={true}
                              value={value.qty}
                              name={"qty" + idx}
                              className="form-control"
                              type="number"
                              required
                              onChange={(e) =>
                                handleShareholderNameChange(
                                  setGrpProducts,
                                  grpProducts,
                                  idx,
                                  "qty",
                                  e.target.value
                                )
                              }
                              readOnly={viewProduct}
                            />
                          </div>
                          <div className="d-flex pt-2">
                            {!viewProduct ? (
                              idx > 0 ? (
                                <button
                                  className="btn_plus_minus"
                                  onClick={(e) =>
                                    handleRemoveInputElements(
                                      e,
                                      idx,
                                      setGrpProducts
                                    )
                                  }
                                >
                                  <FiMinusCircle />
                                </button>
                              ) : grpProducts.length ==
                                COUNT_OF_ATTACHED_SINGLE_PRODUCTS_ON_GROUP_PRODUCT ? (
                                ""
                              ) : (
                                <button
                                  className="btn_plus_minus"
                                  onClick={(e) =>
                                    handleAddInputElements(
                                      e,
                                      setGrpProducts,
                                      { product_id: "", qty: "" },
                                      grpProducts
                                    )
                                  }
                                >
                                  <GrAddCircle />
                                </button>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  {!viewProduct ? (
                    <button
                      type="submit"
                      className="btn btn-primary mr-3 mt-2"
                      onClick={submitHandller}
                      disabled={loading ? true : false}
                    >
                      {" "}
                      {loading ? "loading..." : "Submit"}{" "}
                    </button>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddGroupProduct;
