import React, { useEffect, useState } from "react";
import { SearchSvgIcon, ToggleSvgIcon, Notify } from "./SvgIcon";
import { useDispatch, useSelector } from "react-redux";
import { get_notification_count } from "./../actions/NotificationActions";

function Header(props) {
  const user_Info = useSelector((state) => state.AuthUserReducers.userInfo);
  const notification_count = useSelector(
    (state) => state.NotificationReducers.getNotificationReducer.countInfo
  );
  const [notifyCount, setNotifyCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_notification_count());
  }, [dispatch, props]);

  useEffect(() => {
    console.log(notification_count);
    if (notification_count) {
      setNotifyCount(notification_count);
    }
  }, [notification_count]);
  const getSingleCharacter = (str) => {
    let fiteredChar = str.match(/\b(\w)/g);
    let acronym = fiteredChar.join("");
    return acronym;
  };
  return (
    <>
      <nav className="navbar">
        <button
          type="button"
          onClick={props.sidebarOpenMobile}
          className="sidebar-toggler"
        >
          <ToggleSvgIcon />
        </button>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  {/* <SearchSvgIcon /> */}
                </div>
              </div>
              {/* <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Search here..."
              /> */}
            </div>
          </form>

          <div className="userName_detail_header">
            <div className="notification_header noti_d__">
              <Notify />
              {notifyCount > 0 ? (
                <span className={"notiCount"}>{notifyCount}</span>
              ) : (
                ""
              )}
            </div>
            <div className="box_details">
              &nbsp;
              <span>
                {user_Info.fullName
                  ? getSingleCharacter(user_Info.fullName)
                  : null}
              </span>
              <div>{user_Info.fullName}</div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
