import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
function UserManagement() {
  const columns = [
    {
      Header: "ID",
      accessor: "id",
      width: 80,
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      width: 130,
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Role",
      accessor: "role",
      width: 130,
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: (props) => <span>Status</span>,
      accessor: "status",
      width: 80,
      Cell: (props) => (
        <span className="">
          <span
            className={`badge badge-${
              props.value === "Active" ? "success" : "danger"
            }`}
          >
            {props.value}
          </span>
        </span>
      ),
    },
    {
      Header: "Created On",
      accessor: "created_at",
      width: 150,
      Cell: (props) => <span>{props.original.created}</span>,
    },

    {
      Header: (props) => <span>Action</span>, // Custom header components!
      width: 150,
      Cell: (props) => (
        <span className="">
          <Link
            to={`user/${props.original.id}/show`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Show Detail"
          >
            <FiEye />
          </Link>
          <Link
            to={`user/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Edit Detail"
          >
            <EditSvgIcon />
          </Link>
          <button
            onClick={() => deleteHandller(props.original.id)}
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Remove User"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];
  const [loading, setLoading] = useState(false);
  const pageSise = 10;
  const [page, setPage] = useState();
  const [pages, setPages] = useState();
  const search = "";
  const sorted = [];
  const [usersList, setUsersList] = useState([]);
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    postData("/api/get_users_list", req)
      .then((response) => {
        var res = response.data;
        setUsersList(res.data);
        let tmp_pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmp_pages);
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        if (err.status === 404) {
          ToastMessage(err.statusText, "e");
        } else {
          ToastMessage(err.data.message, "e");
        }
        setLoading(false);
      });
  }, []);
  const deleteHandller = (iD) => {
    ConfirmationAlertBox("Are you sure want to remove it", {}).then((res) => {
      if (res.status) {
        postData(`/api/delete_user`, { id: iD })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            fetchData({ pageSise, page, search, sorted });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            User
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/addnewuser"
        >
          <PlusSvgIcon className="mr-2" />
          Add New User
        </Link>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">User List</h6>
              </div>
              <Table
                data={usersList}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSise}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sorted}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default UserManagement;
