import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <>
      <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-center">
        <p className="text-muted text-center text-md-left">
          Copyright © 2021{" "}
          <Link target={"_blank"} to={{pathname:"https://cornerstonesolutiononline.com"}}>
            Corner Stone Solutions
          </Link>
          . All rights reserved
        </p>
        {/* <p className="text-muted text-center text-md-left mb-0 d-none d-md-block">
          Handcrafted With{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="feather feather-heart mb-1 text-primary ml-1 icon-small"
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
        </p> */}
      </footer>
    </>
  );
}

export default Footer;
