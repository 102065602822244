import { combineReducers } from "redux";

import {
  AuthUserReducers,
  LogoutUserReducers,
  UserProfileReducers,
} from "./AuthUserReducers";
import {
  GetPermissionReducer,
  GetPermissoinOptionListReducer,
  GetSelfPermissoinAccessListReducer,
} from "./PermissionReducers";
import {
  GetRolesOptionListReducer,
  GetRoleDetailReducer,
} from "./RoleReducers";
import { GetUserDetailReducer } from "./UserReducers";
import InventoryReducers from "./InventoryReducers";
import LaneReducers from "./LaneReducers";
import { GetAssignDetailReducer } from "./AssignReducers";
import ProjectReducers from "./ProjectReducers";
import OfficeReducers from "./OfficeReducers";
import WarehouseReducers from "./WarehouseReducers";
import { GetDashboardDetailReducer } from "./DashboardReducers";
import PlazaReducers from "./PlazaReducers";
import LogsReducers from "./LogsReducers";
import HardwareRequestReducers from "./HardwareRequestReducers";
import CompanyReducers from "./CompanyReducers";
import PersonReducers from "./PersonReducers";
import ProductReducers from "./ProductReducers";
import MasterReducers from "./MasterReducers";
import NotificationReducers from "./NotificationReducers";

export default combineReducers({
  AuthUserReducers,
  LogoutUserReducers,
  UserProfileReducers,
  GetDashboardDetailReducer,
  GetUserDetailReducer,
  GetPermissionReducer,
  GetRolesOptionListReducer,
  GetRoleDetailReducer,
  GetPermissoinOptionListReducer,
  LaneReducers,
  GetSelfPermissoinAccessListReducer,
  InventoryReducers,
  GetAssignDetailReducer,
  ProjectReducers,
  WarehouseReducers,
  OfficeReducers,
  LogsReducers,
  PlazaReducers,
  HardwareRequestReducers,
  CompanyReducers,
  PersonReducers,
  ProductReducers,
  MasterReducers,
  NotificationReducers,
});
