import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { PlusSvgIcon, EditSvgIcon, FiEye } from "../../components/SvgIcon";
import { get_project_detail_action } from "../../actions/ProjectActions";
import { get_plaza_by_project_id_action } from "../../actions/PlazaActions";
import { get_office_by_project_id_action } from "../../actions/OfficeActions";
import { get_warehouse_by_project_id_action } from "../../actions/WarehouseActions";
import { get_inventory_by_project_id_action } from "../../actions/InventoryActions";
import { useDispatch, useSelector } from "react-redux";

function ShowProjectPage(props) {
  const [projectData, setProjectData] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [plazaList, setPlazaList] = useState([]);
  const [officeList, setOfficeList] = useState({ loading: false });
  const [warehouseList, setWarehouseList] = useState({ loading: false });
  const [inventoryList, setInventoriesList] = useState({});
  const [activeTab, setActiveTab] = useState("plaza");
  const get_project_detail = useSelector((state) => {
    return {
      project: state.ProjectReducers.GetProjectDetailReducer,
      plaza: state.PlazaReducers.GetPlazaByProjectIdReducer,
      office: state.OfficeReducers.GetOfficeByProjectIdReducer,
      warehoues: state.WarehouseReducers.GetWarehouseByProjectIdReducer,
      inventory: state.InventoryReducers.GetInventoryListByFilterIdReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.match.params.projId) {
      dispatch(get_project_detail_action(props.match.params.projId));
      dispatch(get_plaza_by_project_id_action(props.match.params.projId));
    }
  }, [dispatch, props]);
  useEffect(() => {
    if (get_project_detail.project.successProjectDetail) {
      setProjectData(get_project_detail.project.successProjectDetail.data);
    }
    if (get_project_detail.plaza) {
      setPlazaList(get_project_detail.plaza);
    }
    if (get_project_detail.office) {
      setOfficeList(get_project_detail.office);
    }
    if (get_project_detail.warehoues) {
      setWarehouseList(get_project_detail.warehoues);
    }
    if (get_project_detail.inventory) {
      setInventoriesList(get_project_detail.inventory);
    }
  }, [get_project_detail]);

  const ShowOfficeList = (prop) => {
    let listData = prop.data.office;
    return (
      <div className=" mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Incharge Name</th>
              <th>Incharge Contact</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._office_id}</td>
                  <td>{val.name}</td>
                  <td>{val.incharge_name}</td>
                  <td>{val.incharge_contact_no}</td>
                  <td>
                    <Link
                      to={`/office/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/office/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const ShowWareHouseList = (prop) => {
    let wlistData = prop.data.warehouse;
    return (
      <div className=" mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Incharge Name</th>
              <th>Incharge Contact</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {wlistData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._warehouse_id}</td>
                  <td>{val.name}</td>
                  <td>{val.incharge_name}</td>
                  <td>{val.incharge_contact_no}</td>
                  <td>
                    <Link
                      to={`/warehouse/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/warehouse/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const ShowplazaList = (prop) => {
    let dplazaList = prop.data.plaza;
    return (
      <div className="mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Agency Code</th>
              <th>Plaza Contact</th>
              <th>Plaza Incharge</th>
              <th>Plaza Incharge Contact</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {dplazaList.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._plaza_id}</td>
                  <td>{val.name}</td>
                  <td>{val.agency_code}</td>
                  <td>{val.plaza_contact_no}</td>
                  <td>{val.plaza_incharge}</td>
                  <td>{val.plaza_incharge_contact_no}</td>
                  <td>{val.plaza_type === 1 ? "National" : "State"}</td>
                  <td>
                    <Link
                      to={`/plaza/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/plaza/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const ShowInventoryList = (prop) => {
    let invtList = prop.data;
    return (
      <div className=" mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>Inventory ID</th>
              <th>Product Name</th>
              <th>Inventory Serial Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invtList.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._inventory_id}</td>
                  <td>{val.name}</td>
                  <td>{val.serial_number}</td>
                  <td>
                    <Link
                      to={`/inventory_management/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    {/* <Link
                      to={`/inventory_management/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/project_management">Project Management</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Show Project
          </li>
        </ol>
        <div>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center  mr-2"
            to={`/plaza/${projectData.id}/add_plaza`}
          >
            <PlusSvgIcon className="mr-2" />
            Add Plaza
          </Link>

          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center mr-2"
            to={`/office/project/${projectData.id}/addnew_office`}
          >
            <PlusSvgIcon className="mr-2" />
            Add New Office
          </Link>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center mr-2"
            to={`/warehouse/project/${projectData.id}/addnew_warehouse`}
          >
            <PlusSvgIcon className="mr-2" />
            Add New Warehouse
          </Link>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
            to={`/inventory_management/project/${projectData.id}/add_inventory`}
          >
            <PlusSvgIcon className="mr-2" />
            Add New Inventory
          </Link>
        </div>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Show Project</h6>
              </div>

              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <Row className=" justify-content-center">
                      {get_project_detail.project.loading ? (
                        <div className="form-control text-center text-info">
                          Loading...
                        </div>
                      ) : get_project_detail.project.successProjectDetail ? (
                        <Col lg={12}>
                          <div className="form-group row">
                            <Col lg={12}>
                              <label htmlFor="toll_name">Project Name</label>
                              <div className="form-control">
                                {projectData.name}
                              </div>
                            </Col>
                          </div>
                          {projectData.description ? (
                            <div className="form-group row">
                              <Col lg={12}>
                                <label htmlFor="created">Description</label>
                                <div className="form-control h-auto">
                                  {projectData.description}
                                </div>
                              </Col>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group row">
                            <Col lg={12}>
                              <label htmlFor="created">Created On</label>
                              <div className="form-control">
                                {projectData.created}
                              </div>
                            </Col>
                          </div>
                          <Row>
                            <Col lg={12}>
                              <Link
                                className="btn btn-info mr-2"
                                to={`/assign/new/project/${projectData.id}`}
                              >
                                Assign
                              </Link>
                              <Link
                                className="btn btn-dark"
                                to="/project_management"
                              >
                                Cancel
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                      ) : get_project_detail.project.failedProjectDetail ? (
                        <div className="text-center">
                          <div className="form-control text-center text-danger">
                            {
                              get_project_detail.project.failedProjectDetail
                                .message
                            }
                          </div>
                          <Link
                            to={`/project_management`}
                            className="btn btn-warning mt-2"
                          >
                            Back
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {projectData.id ? (
        <Row>
          <Col lg={12} className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs nav_tabs_custom">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "plaza" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#home"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("plaza");
                      }}
                    >
                      Plaza
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "office" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu1"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("office");
                        dispatch(
                          get_office_by_project_id_action(
                            props.match.params.projId
                          )
                        );
                      }}
                    >
                      Office
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "warehouse" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu2"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("warehouse");
                        dispatch(
                          get_warehouse_by_project_id_action(
                            props.match.params.projId
                          )
                        );
                      }}
                    >
                      WareHouse
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "inventory" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu3"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("inventory");
                        dispatch(
                          get_inventory_by_project_id_action(
                            "project",
                            props.match.params.projId
                          )
                        );
                      }}
                    >
                      Inventory
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className={`tab-pane ${
                      activeTab === "plaza" ? "active" : "fade"
                    }`}
                    id="plaza"
                  >
                    {plazaList.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : plazaList.successPlazaByprojectId ? (
                      <ShowplazaList
                        data={plazaList.successPlazaByprojectId.data}
                      />
                    ) : plazaList.failedPlazaByprojectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-danger mt-3 form-control">
                            {plazaList.failedPlazaByprojectId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "office" ? "active" : "fade"
                    }`}
                    id="office"
                  >
                    {officeList.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : officeList.successOfficeByProjectId ? (
                      <ShowOfficeList
                        data={officeList.successOfficeByProjectId.data}
                      />
                    ) : officeList.failedOfficeByProjectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-danger mt-3 form-control">
                            {officeList.failedOfficeByProjectId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "warehouse" ? "active" : "fade"
                    }`}
                    id="warehouse"
                  >
                    {warehouseList.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : warehouseList.successWarehouseByPorjectId ? (
                      <ShowWareHouseList
                        data={warehouseList.successWarehouseByPorjectId.data}
                      />
                    ) : warehouseList.failedWarehouseByPorjectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-danger mt-3 form-control">
                            {warehouseList.failedWarehouseByPorjectId.message}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "inventory" ? "active" : "fade"
                    }`}
                    id="inventory"
                  >
                    {inventoryList.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : inventoryList.successInventoryByprojectId ? (
                      <ShowInventoryList
                        data={inventoryList.successInventoryByprojectId.data}
                      />
                    ) : inventoryList.failedInventoryByprojectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-danger mt-3 form-control">
                            {inventoryList.failedInventoryByprojectId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
}
export default ShowProjectPage;
