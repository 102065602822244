import { Link } from "react-router-dom";
import { PlusSvgIcon, EditSvgIcon, TrashSvgIcon } from "../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../components/ReactTable";
import React, { useState } from "react";
import { postData } from "../service/Common";
import ConfirmationAlertBox from "../components/ConfirmationAlertBox";
import { ToastMessage } from "../service/ToastMessage";

function PermissionsListPage() {
  const deleteHandller = (id) => {
    ConfirmationAlertBox("Are yuo sure want to remove this permissin.").then(
      (status) => {
        if (status) {
          postData(`/api/delete_permission`, { id: id })
            .then((response) => {
              ToastMessage(response.data.message, "s");
              fetchData({
                pageSize: pageSise,
                page: page,
                filter: search,
                sorted: sorted,
              });
            })
            .catch((error) => {
              ToastMessage(error.data.message, "e");
            });
        }
      }
    );
  };
  const columns = [
    {
      Header: "ID",
      accessor: "id", // String-based value accessors!
      sortable: true,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Created On",
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created}</span>, // Custom cell components!
    },
    {
      Header: (props) => <span>Action</span>, // Custom header components!
      Cell: (props) => (
        <span className="">
          <Link
            to={`/permission/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
          >
            <EditSvgIcon />
          </Link>
          <button
            type="button"
            onClick={(e) => deleteHandller(props.original.id)}
            className="btn btn-danger btn-icon btn-sm-my"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const pageSise = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const search = "";
  const sorted = [];
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    setLoading(true);
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    postData("/api/get_permissions_list", req)
      .then((response) => {
        var res = response.data;
        setData(res.data);
        let pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(pages);
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        ToastMessage(err.data.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Permission Management
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/permission/add"
        >
          <PlusSvgIcon className="mr-2" />
          Add Permission
        </Link>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">User Permission List</h6>
              </div>
              <Table
                data={data}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSise}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sorted}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default PermissionsListPage;
