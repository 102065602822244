export const ArrowUpSvgIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={"feather feather-arrow-up " + props.className}
    >
      <line x1="12" y1="19" x2="12" y2="5"></line>
      <polyline points="5 12 12 5 19 12"></polyline>
    </svg>
  );
};

export const ArrowDownSvgIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={"feather feather-arrow-down " + props.className}
    >
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <polyline points="19 12 12 19 5 12"></polyline>
    </svg>
  );
};

export const SearchSvgIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={"feather feather-search" + props.className}
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
  );
};

export const DashboardSvgIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={"feather feather-box" + props.className}
    >
      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
      <line x1="12" y1="22.08" x2="12" y2="12"></line>
    </svg>
  );
};

export const UserMangementSvgIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={"feather feather-tablet" + props.className}
    >
      <rect x="4" y="2" width="16" height="20" rx="2" ry="2"></rect>
      <line x1="12" y1="18" x2="12.01" y2="18"></line>
    </svg>
  );
};

export const LocationSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`"css-i6dzq1" ${props.className}`}
    >
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
      <circle cx="12" cy="10" r="3"></circle>
    </svg>
  );
};

export const ReportSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`"css-i6dzq1" ${props.className}`}
    >
      <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
      <line x1="4" y1="22" x2="4" y2="15"></line>
    </svg>
  );
};

export const PlusSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`"css-i6dzq1" ${props.className}`}
    >
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  );
};

export const MinusSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`"css-i6dzq1" ${props.className}`}
    >
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  );
};

export const NextSvgIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="#626262"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6l-6-6" />
      </g>
    </svg>
  );
};
export const PreviousSvgIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="#626262"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15 18l-6-6l6-6" />
      </g>
    </svg>
  );
};

export const EditSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
    </svg>
  );
};

export const TrashSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <polyline points="3 6 5 6 21 6"></polyline>
      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    </svg>
  );
};

export const DownloadSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-download btn-icon-prepend ${props.className}`}
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  );
};

export const PrinterSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-printer btn-icon-prepend ${props.className}`}
    >
      <polyline points="6 9 6 2 18 2 18 9"></polyline>
      <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
      <rect x="6" y="14" width="12" height="8"></rect>
    </svg>
  );
};

export const DownloadCloudSvgIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-download-cloud btn-icon-prepend ${props.className}`}
    >
      <polyline points="8 17 12 21 16 17"></polyline>
      <line x1="12" y1="12" x2="12" y2="21"></line>
      <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
    </svg>
  );
};

export const ToggleSvgIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-menu"
    >
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </svg>
  );
};

export const Invertory = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 377.621 426.545"
      enableBackground="new 0 0 377.621 426.545"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FEFEFE"
          d="M289.807,384.144c-79.985,0.002-159.974,0.006-239.959,0.006
     c-6.943,0-6.95-0.004-6.95-7.123c0-51.49,0-102.98,0-154.471c0-44.66,0.07-89.319-0.102-133.976
     c-0.017-4.492,1.29-5.688,5.633-5.527c8.82,0.324,17.664,0.227,26.491,0.035c3.27-0.071,4.926,0.725,6.148,4.113
     c4.409,12.225,14.539,19.088,27.612,19.112c27.995,0.053,55.99,0.048,83.984-0.008c14.093-0.028,23.627-6.655,28.556-19.917
     c0.911-2.453,2.095-3.332,4.673-3.285c7.829,0.143,15.668,0.215,23.493-0.025c3.641-0.113,4.514,1.284,4.443,4.66
     c-0.186,8.994-0.16,17.997-0.028,26.993c0.188,12.918,8.778,21.813,20.997,21.95c12.271,0.139,21.541-8.423,21.642-21.26
     c0.142-17.978,0.944-35.982-0.625-53.947c-5.911-15.633-17.313-21.781-33.834-21.314c-22.808,0.644-45.646,0.096-68.472,0.219
     c-3.245,0.018-5.081-0.638-5.001-4.239c0.029-1.303-0.433-2.617-0.682-3.925c-4.139-21.736-26.6-36.278-48.189-31.2
     c-16.76,3.942-28.277,16.005-30.898,33.262c-0.777,5.116-2.824,6.204-7.638,6.153c-23.823-0.25-47.649-0.153-71.475-0.104
     C12.621,40.362,0.058,52.581,0.046,69.504c-0.07,109.295-0.05,218.591-0.01,327.886c0.002,7.021,2.157,13.449,6.617,18.971
     c4.242,5.252,10.086,7.939,16.323,9.949c3.664,0.066,7.327,0.186,10.991,0.188c73.154,0.01,146.308,0.008,219.461,0.004
     c5.499,0,11.005,0.143,16.495-0.088c8.291-0.348,14.92-4.084,20.226-10.416c6.149-7.34,6.626-16.053,6.451-25.012
     C296.467,384.152,296.568,384.144,289.807,384.144z M148.092,22.422c9.771-0.158,18.407,8.291,18.375,17.977
     c-0.032,9.462-8.432,17.922-17.894,18.021c-9.797,0.104-18.077-8.039-18.346-18.042C129.98,31.156,138.516,22.576,148.092,22.422z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FEFEFE"
          d="M377.524,254.599c-2.481-58.624-50.876-101.755-107.48-99.922
     c-58.081,1.882-103.56,50.967-100.939,108.071c2.781,60.633,54.389,103.229,108.819,99.895
     C332.576,361.255,380.05,314.298,377.524,254.599z M323.136,221.355c-12.274,24.74-24.676,49.418-37.044,74.111
     c-3.129,6.248-6.242,12.506-9.479,18.697c-5.724,10.951-18.491,12.682-26.327,3.127c-9.918-12.096-19.295-24.637-28.844-37.031
     c-1.98-2.572-2.962-5.578-2.788-8.184c-0.064-7.412,3.804-13.197,9.871-15.477c6.21-2.334,13.3-0.465,17.583,4.834
     c3.661,4.529,7.282,9.104,10.655,13.85c2.185,3.072,3.235,2.906,4.901-0.461c10.559-21.34,21.296-42.592,31.927-63.896
     c2.2-4.408,4.666-8.42,9.604-10.305c6.038-2.304,12.644-0.979,17.011,3.618C324.567,208.827,325.947,215.689,323.136,221.355z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FEFEFE"
          d="M128.926,353.947c-0.183-6.982-0.057-13.975-0.053-20.963
     c0.004-7.154-0.098-14.311,0.052-21.461c0.063-3.021-0.57-4.539-4.076-4.5c-14.308,0.158-28.618,0.119-42.926,0.02
     c-2.883-0.02-4.181,0.711-4.153,3.895c0.126,14.475,0.12,28.951-0.014,43.426c-0.028,3.125,1.12,3.965,4.085,3.939
     c14.308-0.115,28.618-0.148,42.926,0.016C128.177,358.357,129.009,357.093,128.926,353.947z M103.405,343.687
     c-11.459,0-11.459-0.198-11.459-11.628c0-10.965,0-11.157,10.893-11.157c0.332,0,0.663,0,0.995,0
     c10.969,0,10.969,0.2,10.969,11.079C114.802,343.489,114.802,343.687,103.405,343.687z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFD"
          d="M128.93,279.044c-0.177-6.986-0.056-13.979-0.06-20.967
     c-0.005-7.156-0.114-14.313,0.047-21.465c0.068-3.051-0.589-4.506-4.083-4.465c-14.309,0.164-28.621,0.121-42.932,0.021
     c-2.888-0.021-4.171,0.707-4.141,3.895c0.136,14.475,0.141,28.955,0.007,43.43c-0.029,3.148,1.141,3.951,4.088,3.93
     c14.31-0.111,28.622-0.143,42.932,0.021C128.215,283.484,129.009,282.164,128.93,279.044z M103.52,269.248
     c-11.572,0.008-11.572,0.008-11.573-11.436c-0.001-11.432-0.001-11.432,11.465-11.426c11.391,0.006,11.391,0.006,11.391,11.631
     C114.802,269.242,114.802,269.242,103.52,269.248z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FEFEFE"
          d="M128.916,161.884c0.022-3.105-0.827-4.524-4.216-4.495
     c-14.326,0.127-28.654,0.125-42.98-0.005c-3.107-0.028-3.979,1.079-3.953,4.069c0.122,14.326,0.147,28.655-0.006,42.98
     c-0.037,3.384,1.202,4.275,4.376,4.19c6.992-0.187,13.993-0.058,20.99-0.055c7.163,0.003,14.33-0.125,21.489,0.062
     c3.235,0.084,4.358-0.957,4.323-4.266C128.79,190.206,128.812,176.044,128.916,161.884z M103.781,194.339
     c-11.837,0.004-11.837,0.004-11.837-11.651c0-11.166,0-11.166,11.345-11.165c11.516,0.001,11.516,0.001,11.525,11.519
     C114.824,194.335,114.824,194.336,103.781,194.339z"
        />
      </g>
    </svg>
  );
};

export const AiFillFileAdd = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M480 580H372a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h108v108a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8V644h108a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8H544V472a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v108zm374.6-291.3c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2z"></path>
      </svg>
    </>
  );
};

export const BiBarcodeReader = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M5 7H7V17H5zM14 7H15V17H14zM10 7H13V17H10zM8 7H9V17H8zM16 7H19V17H16z"></path>
        <path d="M4 5h4V3H4C2.897 3 2 3.897 2 5v4h2V5zM4 21h4v-2H4v-4H2v4C2 20.103 2.897 21 4 21zM20 3h-4v2h4v4h2V5C22 3.897 21.103 3 20 3zM20 19h-4v2h4c1.103 0 2-.897 2-2v-4h-2V19z"></path>
      </svg>
    </>
  );
};

export const IoIosCloseCircle = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm52.7 283.3L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3l52.7-52.7-52.7-52.7c-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3 6.2-6.2 16.4-6.2 22.6 0l52.7 52.7 52.7-52.7c6.2-6.2 16.4-6.2 22.6 0 6.2 6.2 6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0z"></path>
      </svg>
    </>
  );
};

export const FaCloudUploadAlt = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 640 512"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path>
      </svg>
    </>
  );
};

export const FaDownload = (props) => {
  return (
    <>
      {" "}
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
      </svg>
    </>
  );
};

export const BiMessageRoundedError = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M12,2C6.486,2,2,5.589,2,10c0,2.908,1.898,5.515,5,6.934V22l5.34-4.005C17.697,17.852,22,14.32,22,10 C22,5.589,17.514,2,12,2z M12,16h-0.333L9,18v-2.417l-0.641-0.247C5.67,14.301,4,12.256,4,10c0-3.309,3.589-6,8-6s8,2.691,8,6 S16.411,16,12,16z"></path>
        <path d="M11 6H13V11H11zM11 12H13V14H11z"></path>
      </svg>
    </>
  );
};

export const BiBuildingHouse = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M19,2H9C7.897,2,7,2.897,7,4v5.586l-4.707,4.707c-0.286,0.286-0.372,0.716-0.217,1.09S2.596,16,3,16v1v4 c0,0.553,0.448,1,1,1h8h8c0.553,0,1-0.447,1-1V4C21,2.897,20.103,2,19,2z M11,20H5v-3v-2v-0.586l3-3l3,3V15v3V20z M19,20h-6v-2v-2 c0.404,0,0.77-0.243,0.924-0.617c0.155-0.374,0.069-0.804-0.217-1.09L9,9.586V4h10V20z"></path>
        <path d="M11 6H13V8H11zM15 6H17V8H15zM15 10.031H17V12H15zM15 14H17V16H15zM7 15H9V17H7z"></path>
      </svg>
    </>
  );
};
export const IoHardwareChipOutline = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="352"
          height="352"
          x="80"
          y="80"
          fill="none"
          strokeLinejoin="round"
          strokeWidth="32"
          rx="48"
          ry="48"
        ></rect>
        <rect
          width="224"
          height="224"
          x="144"
          y="144"
          fill="none"
          strokeLinejoin="round"
          strokeWidth="32"
          rx="16"
          ry="16"
        ></rect>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="32"
          d="M256 80V48m80 32V48M176 80V48m80 416v-32m80 32v-32m-160 32v-32m256-176h32m-32 80h32m-32-160h32M48 256h32m-32 80h32M48 176h32"
        ></path>
      </svg>
    </>
  );
};

export const IoIosArrowDown = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
      </svg>
    </>
  );
};

export const IoIosArrowUp = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"></path>
      </svg>
    </>
  );
};

export const MdSettingsInputComposite = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H1v6h6V6H5V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2H9v2zm-8 0c0 1.3.84 2.4 2 2.82V23h2v-4.18C6.16 18.4 7 17.3 7 16v-2H1v2zM21 6V2c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6V6h-2zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4H9v6h6V6h-2V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z"></path>
      </svg>
    </>
  );
};

export const VscDebugBreakpointLogUnverified = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.02 7.98L8 3l4.98 4.98L8 12.96 3.02 7.98zM8 10.77l2.79-2.79L8 5.19 5.21 7.98 8 10.77z"
        ></path>
      </svg>
    </>
  );
};

export const CgProfile = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="none"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
          fill="currentColor"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
          fill="currentColor"
        ></path>
      </svg>
    </>
  );
};

export const RiLockPasswordLine = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zM5 10v10h14V10H5zm6 4h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2zm1-6V7a4 4 0 1 0-8 0v1h8z"></path>
        </g>
      </svg>
    </>
  );
};

export const FaUsersCog = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 640 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
      </svg>
    </>
  );
};

export const SiOpenaccess = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        role="img"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title></title>
        <path d="M18.972 13.487a7.56 7.56 0 0 0-.54-1.043V6.416a6.376 6.376 0 0 0-1.88-4.536A6.375 6.375 0 0 0 12.016 0h-.002a6.375 6.375 0 0 0-4.536 1.878 6.376 6.376 0 0 0-1.88 4.538v.877h2.57v-.877c0-1.027.4-1.993 1.127-2.72a3.822 3.822 0 0 1 2.72-1.126 3.852 3.852 0 0 1 3.847 3.846v3.508A7.52 7.52 0 0 0 12 8.866a7.54 7.54 0 0 0-5.35 2.216 7.54 7.54 0 0 0-2.217 5.35 7.54 7.54 0 0 0 2.216 5.35A7.54 7.54 0 0 0 12 24.002a7.54 7.54 0 0 0 5.35-2.216 7.54 7.54 0 0 0 2.217-5.351c0-1.021-.2-2.012-.595-2.946zM12 21.43c-2.755 0-4.997-2.242-4.997-4.997S9.245 11.436 12 11.436s4.997 2.241 4.997 4.997S14.755 21.43 12 21.43zm2.145-4.974a2.12 2.12 0 1 1-4.24 0 2.12 2.12 0 0 1 4.24 0z"></path>
      </svg>
    </>
  );
};

export const FiEye = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="none"
        stroke-width="2"
        viewBox="0 0 24 24"
        stroke-linecap="round"
        stroke-linejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
      </svg>
    </>
  );
};

export const RiFileList3Line = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M19 22H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12h4v4a3 3 0 0 1-3 3zm-1-5v2a1 1 0 0 0 2 0v-2h-2zm-2 3V4H4v15a1 1 0 0 0 1 1h11zM6 7h8v2H6V7zm0 4h8v2H6v-2zm0 4h5v2H6v-2z"></path>
        </g>
      </svg>
    </>
  );
};

export const GrAddCircle = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          stroke="#000"
          stroke-width="2"
          d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,18 L12,6 M6,12 L18,12"
        ></path>
      </svg>
    </>
  );
};

export const FiMinusCircle = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="none"
        stroke-width="2"
        viewBox="0 0 24 24"
        stroke-linecap="round"
        stroke-linejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </>
  );
};

export const BiUpload = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11 15L13 15 13 9 16 9 12 4 8 9 11 9z"></path>
        <path d="M20,18H4v-7H2v7c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-7h-2V18z"></path>
      </svg>
    </>
  );
};

export const GiCardboardBox = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M185.424 52.607L30.404 130.04l55.375 55.317 155.017-77.43-55.373-55.32zm141.152 0l-55.373 55.32 155.018 77.43 55.376-55.316-155.02-77.433zM256 120.45l-9 4.497v142.715l9 4.496 9-4.496V124.947l-9-4.496zM86.482 207.605l-57.59 71.917 139.545 77.45 72.358-72.286-154.313-77.08zm339.036 0l-154.313 77.08 72.358 72.287 139.544-77.45-57.59-71.916zM247 303.93l-75.436 75.36-78.562-43.6v44.058L247 456.67V303.93zm18 0v152.74l153.998-76.922v-44.06l-78.562 43.603L265 303.93z"></path>
      </svg>
    </>
  );
};

export const BsBuilding = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M15.285.089A.5.5 0 0115.5.5v15a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V14h-1v1.5a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-6a.5.5 0 01.418-.493l5.582-.93V3.5a.5.5 0 01.324-.468l8-3a.5.5 0 01.46.057zM7.5 3.846V8.5a.5.5 0 01-.418.493l-5.582.93V15h8v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15h2V1.222l-7 2.624z"
          clip-rule="evenodd"
        ></path>
        <path
          fill-rule="evenodd"
          d="M6.5 15.5v-7h1v7h-1z"
          clip-rule="evenodd"
        ></path>
        <path d="M2.5 11h1v1h-1v-1zm2 0h1v1h-1v-1zm-2 2h1v1h-1v-1zm2 0h1v1h-1v-1zm6-10h1v1h-1V3zm2 0h1v1h-1V3zm-4 2h1v1h-1V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm-2 2h1v1h-1V7zm2 0h1v1h-1V7zm-4 0h1v1h-1V7zm0 2h1v1h-1V9zm2 0h1v1h-1V9zm2 0h1v1h-1V9zm-4 2h1v1h-1v-1zm2 0h1v1h-1v-1zm2 0h1v1h-1v-1z"></path>
      </svg>
    </>
  );
};

export const FiUsers = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="none"
        stroke-width="2"
        viewBox="0 0 24 24"
        stroke-linecap="round"
        stroke-linejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
    </>
  );
};

export const AiOutlineLogin = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 1024 1024"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs></defs>
        <path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z"></path>
      </svg>
    </>
  );
};

export const GiBrodieHelmet = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M256 74.246c-87.892 0-159.154 61.235-159.154 136.786 0 33.817 88.216 54.075 171.766 54.075 75.156 0 146.49-16.393 146.49-54.075.05-70.7-71.208-136.786-159.1-136.786zm172.12 107.03a124.91 124.91 0 0 1 3.657 29.703c0 17.66-9.82 41.857-56.59 56.984-27.346 8.84-65.194 13.715-106.565 13.715-44.06 0-88.442-5.467-121.782-15.005-18.7-5.34-33.6-11.843-44.383-19.333-10.14-7.044-22.224-19.075-22.224-36.363a135.414 135.414 0 0 1 3.263-29.59C50.74 195.395 31 213.275 31 232.797c0 44.216 100.776 80.07 225 80.07 124.226 0 225-35.812 225-80.07 0-19.605-19.894-37.59-52.88-51.52zM118.613 313.46c20.176 64.746 73.618 111.165 136.213 111.165 62.336 0 115.675-46.066 136.006-110.46a416.83 416.83 0 0 1-19.096 4.384c-20.145 52.662-64.963 89.42-116.91 89.42-52.133 0-97.1-37.016-117.11-90.002a413.867 413.867 0 0 1-19.103-4.508z"></path>
      </svg>
    </>
  );
};

export const Inventory = (props) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="188px"
        height="190px"
        viewBox="0 0 188 190"
        enable-background="new 0 0 188 190"
      >
        <path
          d="M165.17,0H121h-8H75h-8H22.83C10.242,0,0,10.242,0,22.83v144.34C0,179.759,10.242,190,22.83,190h142.34
	c12.589,0,22.83-10.241,22.83-22.83V22.83C188,10.242,177.759,0,165.17,0z M75,8h38v42.594L93.885,31.537L75,50.364V8z M180,167.17
	c0,8.178-6.652,14.83-14.83,14.83H22.83C14.653,182,8,175.348,8,167.17V22.83C8,14.653,14.653,8,22.83,8H67v61.636l26.885-26.802
	L121,69.866V8h44.17C173.348,8,180,14.653,180,22.83V167.17z"
        />
        <path d="M49.667,122.667L77,151l72.667-70.667c0,0,3.166-10.167-6.334-6l-66,63.333l-19-19.549c0,0-11-4.785-8.667,3.215" />
      </svg>
    </>
  );
};

export const IoDocumentTextOutline = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          stroke-linejoin="round"
          stroke-width="32"
          d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
        ></path>
        <path
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="32"
          d="M256 56v120a32 32 0 0032 32h120m-232 80h160m-160 80h160"
        ></path>
      </svg>
    </>
  );
};

export const IoMdClose = (props) => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
      </svg>
    </>
  );
};

export const Notify = (props) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="27.75px"
        height="29.488px"
        viewBox="0 0 27.75 29.488"
        enable-background="new 0 0 27.75 29.488"
      >
        <g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M14.471,0.616c1.025,0.386,1.922,0.983,2.031,2.14
		c0.075,0.797,0.45,1.03,1.102,1.288c3.717,1.475,6.135,5.063,6.157,9.064c0.014,2.332,0.079,4.667-0.02,6.995
		c-0.085,2.016,0.849,3.968,3.583,4.302c0,0.551,0,1.105,0,1.712c-3.15,0-6.293,0-9.438,0c-0.68,2.021-1.999,3.214-4.142,3.139
		c-1.959-0.069-3.168-1.224-3.788-3.102c-3.126,0-6.269,0-9.446,0c0-0.617,0-1.185,0-1.598c0.769-0.343,1.531-0.565,2.153-0.99
		c0.966-0.659,1.392-1.674,1.394-2.848c0.003-2.426,0-4.851,0.001-7.276c0.002-4.551,2.506-8.109,6.778-9.659
		c0.159-0.058,0.363-0.271,0.371-0.422c0.078-1.375,0.754-2.263,2.066-2.672c0.031-0.009,0.052-0.049,0.077-0.074
		C13.724,0.616,14.097,0.616,14.471,0.616z M4.512,24.313c6.312,0,12.509,0,18.795,0c-1.159-1.342-1.383-2.868-1.347-4.498
		c0.048-2.182,0.022-4.365,0.004-6.547c-0.005-0.575-0.045-1.16-0.164-1.721c-0.857-4.048-4.583-6.825-8.591-6.43
		c-4.222,0.417-7.342,3.846-7.359,8.099c-0.009,2.164-0.039,4.328,0.011,6.491C5.899,21.373,5.707,22.941,4.512,24.313z
		 M11.925,26.166c0.386,0.864,1.155,1.345,2.056,1.32c0.859-0.025,1.606-0.538,1.893-1.32
		C14.566,26.166,13.268,26.166,11.925,26.166z M14.792,3.293c-0.048-0.549-0.344-0.834-0.852-0.878
		c-0.48-0.042-0.799,0.287-0.889,0.878C13.625,3.293,14.196,3.293,14.792,3.293z"
          />
        </g>
      </svg>
    </>
  );
};

export const AiOutlineBarcode = () => {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 1024 1024"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M120 160H72c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zm833 0h-48c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zM200 736h112c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm321 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm126 0h178c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H647c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm-255 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8zm-79 64H201c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm257 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm256 0H648c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h178c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-385 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
      </svg>
    </>
  );
};
