import { Modal, Button } from "react-bootstrap";

function Modals(props) {
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={props.className}
        backdrop="static"
        keyboard={false}
      >
        {props.children}
      </Modal>
    </>
  );
}

export default Modals;
