import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { postData } from "../../service/Common";
import Table from "../../components/ReactTable";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";

function ShippingHistory(props) {
  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Docket Number",
      accessor: "docket_number",
    },
    {
      Header: "Carrier Name",
      accessor: "courier_name",
    },
	{
		Header: "Request Date",
		accessor: "created_at",
	  },
    {
      Header: (props) => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <Link to={`/docket_detail/${props.original.id}/show`} className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Show Detail" target="_blank">
            <FiEye />
          </Link>
        </span>
      ),
      sortable: false,
    },
  ];
  const [CarrierData, setCarrierData] = useState([]);
  const page_size = 20;
  const search = "";
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const sortedBy = "";

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    setLoading(true);
    postData(`/api/get_shipping_history`, req)
      .then((result) => {
        var res = result.data;
        setCarrierData(res.data);
        let tmppages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmppages);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
		//ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
 
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
		  Shipping History List
          </li>
        </ol>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Shipping History </h6>
              </div>
              <Table
                data={CarrierData}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={false}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ShippingHistory;
