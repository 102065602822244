import { combineReducers } from "redux";
import {
    GET_OFFICE_DETAIL_REQUEST,
    GET_OFFICE_DETAIL_SUCCESS_INFO,
    GET_OFFICE_DETAIL_FAIL_ERROR,
    GET_OFFICES_BY_PROJECT_ID_REQUEST,
    GET_OFFICES_BY_PROJECT_ID_SUCCESS,
    GET_OFFICES_BY_PROJECT_ID_FAIL_ERROR,
    GET_OFFICES_BY_PLAZA_ID_REQUEST,
    GET_OFFICES_BY_PLAZA_ID_SUCCESS,
    GET_OFFICES_BY_PLAZA_ID_FAIL_ERROR,
    GET_OFFICE_OPTION_LIST_REQUEST,
    GET_OFFICE_OPTION_LIST_SUCCESS,
    GET_OFFICE_OPTION_LIST_FAIL_ERROR,
} from "../constants/OfficeConstants";
function GetOfficeDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_OFFICE_DETAIL_REQUEST:
            return { loading: true };
        case GET_OFFICE_DETAIL_SUCCESS_INFO:
            return { loading: false, successOfficeDetail: action.payload };
        case GET_OFFICE_DETAIL_FAIL_ERROR:
            return { loading: false, failedOfficeDetail: action.payload }
        default:
            return state;
    }
}

function GetOfficeByProjectIdReducer(state = [], action) {
    switch (action.type) {
        case GET_OFFICES_BY_PROJECT_ID_REQUEST:
            return { loading: true };
        case GET_OFFICES_BY_PROJECT_ID_SUCCESS:
            return { loading: false, successOfficeByProjectId: action.payload };
        case GET_OFFICES_BY_PROJECT_ID_FAIL_ERROR:
            return { loading: false, failedOfficeByProjectId: action.payload }
        default:
            return state;
    }
}

function GetOfficeByPlazaIdReducer(state = [], action) {
    switch (action.type) {
        case GET_OFFICES_BY_PLAZA_ID_REQUEST:
            return { loading: true };
        case GET_OFFICES_BY_PLAZA_ID_SUCCESS:
            return { loading: false, successOfficeByPlazaId: action.payload };
        case GET_OFFICES_BY_PLAZA_ID_FAIL_ERROR:
            return { loading: false, failedOfficeByPlazaId: action.payload }
        default:
            return state;
    }
}

function GetOfficeOptionListReducer(state=[], action){
    switch (action.type) {
        case GET_OFFICE_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_OFFICE_OPTION_LIST_SUCCESS:
            return { loading: false, successOfficeOptionList: action.payload };
        case GET_OFFICE_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failedOfficeOptionList: action.payload }
        default:
            return state;
    }
}
export default combineReducers({
    GetOfficeDetailReducer,
    GetOfficeByProjectIdReducer, 
    GetOfficeByPlazaIdReducer,
    GetOfficeOptionListReducer,
});
