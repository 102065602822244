import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { get_single_log_detail_action } from "../../actions/LogsActions";

const ViewLogsPage = (props) => {
  const [logDetail, setLogDetail] = useState({});
  const get_log_details = useSelector(
    (state) => state.LogsReducers.GetLogDetailReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.match.params.logId) {
      dispatch(get_single_log_detail_action(props.match.params.logId));
    }
  }, [props, dispatch]);
  useEffect(() => {
    if (get_log_details.successGetLogDetail) {
      setLogDetail(get_log_details.successGetLogDetail);
    }
  }, [get_log_details]);

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          {logDetail.type === "inventory" ? (
            <li className="breadcrumb-item">
              <Link to={`/inventory_management/${logDetail.riId}/show`}>
                Inventories
              </Link>
            </li>
          ) : (
            ""
          )}
          <li className="breadcrumb-item active" aria-current="page">
            View Log
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">View Log Details</h6>
              </div>
              <div className="card_box_border">
                <Row className="justify-content-center">
                  <Col lg={12}>
                    {get_log_details.loading ? (
                      <div className="text-center text-info dvdata">
                        <strong>Loading...</strong>
                      </div>
                    ) : get_log_details.successGetLogDetail ? (
                      <div className="">
                        <Row>
                          <Col md={4} className="form-group">
                            <label>Log ID</label>
                            <div className="dvdata form-control">
                              {logDetail.logId}
                            </div>
                          </Col>
                          <Col md={4} className="form-group">
                            <label>Title</label>
                            <div className="dvdata form-control">
                              {logDetail.title}
                            </div>
                          </Col>
                          {/* <Col md={4}>
                                                        <label>Response Status</label>
                                                        <div className="dvdata">{logDetail.responseStatus}</div>
                                                    </Col> */}
                          {logDetail.inventoryName ? (
                            <Col md={4} className="form-group">
                              <label>Inventory Name</label>
                              <div className="dvdata form-control input_inner_box">
                                <Link
                                  className="input_inner_btn"
                                  to={`/inventory_management/${logDetail.riId}/show`}
                                >
                                  {logDetail.inventoryName}
                                </Link>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {logDetail.hw_requestName ? (
                            <Col md={4} className="form-group">
                              <label>Hardware Request Title</label>
                              <div className="dvdata form-control input_inner_box">
                                <Link
                                  className="input_inner_btn"
                                  to={`/request/${logDetail.riId}/show`}
                                >
                                  {logDetail.hw_requestName}
                                </Link>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {/* <Col md={4}>
                                                        <label>Ip Address</label>
                                                        <div className="dvdata">{logDetail.ipAddress}</div>
                                                    </Col> */}
                          <Col md={4} className="form-group">
                            <label>Created</label>
                            <div className="dvdata form-control">
                              {logDetail.created}
                            </div>
                          </Col>

                          <Col md={4} className="form-group">
                            <label>User by</label>
                            <div className="dvdata form-control">
                              {logDetail.userName}
                            </div>
                          </Col>
                          {/* <Col md={4}>
                                                        <label>Type</label>
                                                        <div className="dvdata">{logDetail.type}</div>
                                                    </Col> */}
                          <Col md={4} className="form-group">
                            <label>Description</label>
                            <div className="dvdata form-control h-auto">
                              {logDetail.description}
                            </div>
                          </Col>
                          {/* <Col md={8}>
                                                        <label>Request Url</label>
                                                        <div className="dvdata">{logDetail.requestUrl}</div>
                                                    </Col> */}
                          {/* <Col md={4}>
                                                        <label>User responseData</label>
                                                        <div className="dvdata">{logDetail.responseData}</div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <label>User Agent</label>
                                                        <div className="dvdata">{logDetail.userAgent}</div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <label>Request Data</label>
                                                        <div className="dvdata">{logDetail.requestData}</div>
                                                    </Col> */}
                        </Row>
                      </div>
                    ) : get_log_details.failedGetLogsDetail ? (
                      <div className="justify-content-center row">
                        <div className="text-center text-danger dvdata">
                          <strong>{get_log_details.failedGetLogDetail}</strong>
                        </div>
                        <Link to={`/dashboard`} className="mt-2 btn btn-dark">
                          Go Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewLogsPage;
