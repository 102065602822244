import { combineReducers } from "redux";
import { GET_HARDWARE_REQUEST_DETAIL_REQUEST, GET_HARDWARE_REQUEST_DETAIL_SUCCESS, GET_HARDWARE_REQUEST_DETAIL_FAILED_ERROR } from "../constants/HardwareRequestConstants";

function GetHardwareRequestDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_HARDWARE_REQUEST_DETAIL_REQUEST:
            return { loading: true, payload: {} };
        case GET_HARDWARE_REQUEST_DETAIL_SUCCESS:
            return { loading: false, successgetHwRequestDetail: action.payload };
        case GET_HARDWARE_REQUEST_DETAIL_FAILED_ERROR:
            return { loading: false, failedgetHwRequestDetail: action.payload };
        default:
            return state;
    }
}
export default combineReducers({ GetHardwareRequestDetailReducer });