import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { get_warehouse_action } from "../../actions/WarehouseActions";
import { useDispatch, useSelector } from "react-redux";
import { get_inventory_by_project_id_action } from "../../actions/InventoryActions";
import { get_lanes_option_list_action } from "../../actions/LaneActions";
import { EditSvgIcon, PlusSvgIcon } from "../../components/SvgIcon";

function ShowWarehousePage(props) {
  const [warehouseData, setWarehouseData] = useState({
    project_id: props.match.params.projId,
    office_id: props.match.params.officeId,
    name: "",
    type: "",
    inchargeName: "",
    inchargeContactNumber: "",
    address: "",
    distance: "",
    latitude: "",
    longitude: "",
  });
  const [activeTab, setActiveTab] = useState("inventory");
  const [inventoriesListData, setInventoriesListData] = useState([]);
  const get_warehouseinfo = useSelector((state) => {
    return {
      warehouse: state.WarehouseReducers.GetWareHouseDetailReducer,
      inventory: state.InventoryReducers.GetInventoryListByFilterIdReducer,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_lanes_option_list_action());
    if (props.match.params.wrhseId) {
      dispatch(get_warehouse_action(props.match.params.wrhseId));
      dispatch(
        get_inventory_by_project_id_action(
          "warehouse",
          props.match.params.wrhseId
        )
      );
    }
  }, [props, dispatch]);
  useEffect(() => {
    if (get_warehouseinfo.warehouse.successwarehouseDetail) {
      setWarehouseData(get_warehouseinfo.warehouse.successwarehouseDetail.data);
    }
    if (get_warehouseinfo.inventory) {
      setInventoriesListData(get_warehouseinfo.inventory);
    }
  }, [get_warehouseinfo]);
  const ShowInventoryList = (prop) => {
    let invtlistData = prop.data;
    return (
      <div className="mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Serial Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invtlistData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._inventory_id}</td>
                  <td>{val.name}</td>
                  <td>{val.serial_number}</td>
                  <td>
                    <Link
                      to={`/inventory_management/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <EditSvgIcon />
                    </Link>
                    <Link
                      to={`/inventory_management/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          {warehouseData.projectId !== null ||
          warehouseData.plazaId !== null ? (
            <li className="breadcrumb-item">
              {warehouseData.projectId > 0 ? (
                <Link
                  to={`/project_management/${warehouseData.projectId}/show`}
                >
                  Project
                </Link>
              ) : warehouseData.plazaId > 0 ? (
                <Link to={`/plaza/${warehouseData.plazaId}/show`}>Plaza</Link>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}
          <li className="breadcrumb-item">
            <Link to={"/warehouses"}>Warehouses</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Show Warehouse Detail
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to={`/inventory_management/warehouse/${warehouseData.id}/add_inventory`}
        >
          <PlusSvgIcon className="mr-2" />
          Add New Inventory
        </Link>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Show Warehouse Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  {get_warehouseinfo.warehouse.loading ? (
                    <div className="form-control mb-5 text-center text-info">
                      <strong>Loading</strong>
                    </div>
                  ) : get_warehouseinfo.warehouse.successwarehouseDetail ? (
                    <Col lg={12}>
                      <div className="forms-sample row">
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="name">
                            Warehouse Name
                          </label>
                          <div className="form-control">
                            {warehouseData.name}
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="Incharge">
                            Incharge Name
                          </label>
                          <div className="form-control">
                            {warehouseData.inchargeName}
                          </div>
                        </div>
                        {warehouseData.projectName ? (
                          <div className="form-group col-md-4">
                            <label className="w-100" htmlFor="longitude">
                              Project Name
                            </label>
                            <div className="form-control input_inner_box">
                              <Link
                                className="input_inner_btn"
                                to={`/project_management/${warehouseData.projectId}/show`}
                              >
                                {warehouseData.projectName}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {warehouseData.plazaName ? (
                          <div className="form-group col-md-4">
                            <label className="w-100" htmlFor="longitude">
                              Plaza Name
                            </label>
                            <div className="form-control">
                              <Link to={`/plaza/${warehouseData.plazaId}/show`}>
                                {warehouseData.plazaName}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group col-md-4">
                          <label
                            className="w-100"
                            htmlFor="in_charge_contact_no"
                          >
                            In Charge Contact No
                          </label>
                          <div className="form-control">
                            {warehouseData.inchargeContactNumber}
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="address">
                            Address
                          </label>
                          <div className="form-control h-auto">
                            {warehouseData.address}
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="latitude">
                            Latitude
                          </label>
                          <div className="form-control">
                            {warehouseData.latitude}
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="longitude">
                            Longitude
                          </label>
                          <div className="form-control">
                            {warehouseData.longitude}
                          </div>
                        </div>

                        {warehouseData.plazaId !== null ? (
                          <div className="form-group  col-md-4">
                            <label className="w-100" htmlFor="distance">
                              Distance From Plaza In Km
                            </label>
                            <div className="form-control">
                              {warehouseData.distance}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="longitude">
                            Created
                          </label>
                          <div className="form-control">
                            {warehouseData.created}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : get_warehouseinfo.warehouse.failwarehouseDetail ? (
                    <Col lg={12}>
                      <div className="text-danger text-center form-control">
                        {
                          get_warehouseinfo.warehouse.failwarehouseDetail
                            .message
                        }
                      </div>
                      <div className="mt-3 text-center">
                        <Link to={`/warehouses`} className="btn btn-dark">
                          Back
                        </Link>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {warehouseData.id !== undefined ? (
        <Row>
          <Col lg={12} className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs nav_tabs_custom">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "inventory" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu1"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("inventory");
                      }}
                    >
                      Inventory
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className={`tab-pane ${
                      activeTab === "inventory" ? "active" : "fade"
                    }`}
                    id="inventory"
                  >
                    {inventoriesListData.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : inventoriesListData.successInventoryByprojectId ? (
                      <ShowInventoryList
                        data={
                          inventoriesListData.successInventoryByprojectId.data
                        }
                      />
                    ) : inventoriesListData.failedInventoryByprojectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-info mt-3 form-control">
                            {inventoriesListData.failedInventoryByprojectId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
}
export default ShowWarehousePage;
