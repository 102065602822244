import {
    GET_DASHBOARD_DATA_REQUEST,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAIL_ERROR
} from "../constants/DashboadConstants";

function GetDashboardDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_DASHBOARD_DATA_REQUEST:
            return { loading: true };
        case GET_DASHBOARD_DATA_SUCCESS:
            return { loading: false, successGetDashboardDetail: action.payload };
        case GET_DASHBOARD_DATA_FAIL_ERROR:
            return { loading: false, failedGetDashboardDetail: action.payload }
        default:
            return state;
    }
}

export { GetDashboardDetailReducer };