import {
    GET_USER_DETAIL_REQUEST,
    GET_USER_DETAIL_SUCCESS,
    GET_USER_DETAIL_FAIL,
} from "../constants/UserContants";

function GetUserDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_USER_DETAIL_REQUEST:
            return { loading: true };
        case GET_USER_DETAIL_SUCCESS:
            return { loading: false, successgetuserDetail: action.payload };
        case GET_USER_DETAIL_FAIL:
            return { loading: false, failedgetuserDetail: action.payload }
        default:
            return state;
    }
}

export { GetUserDetailReducer };