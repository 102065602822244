import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_user_detail } from "../../actions/UserActions";
import { get_roles_optionlist } from "../../actions/RoleActions";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../../components/ReactSelectPlus";

function EditUserPage(props) {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role_id: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const getUserInformation = useSelector((state) => state.GetUserDetailReducer);
  const getRolesOptList = useSelector(
    (state) => state.GetRolesOptionListReducer.successRolesOptionList
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.match.params.uIde) {
      setLoading(true);
      dispatch(get_roles_optionlist());
      dispatch(get_user_detail(props.match.params.uIde));
    }
  }, [props.match.params.uIde, dispatch]);

  useEffect(() => {
    if (getRolesOptList) {
      setRoleOptions(getRolesOptList.data);
    }
    if (getUserInformation.successgetuserDetail) {
      setUserData(getUserInformation.successgetuserDetail.data);
      setLoading(getUserInformation.loading);
    }
  }, [getRolesOptList, getUserInformation, props.history]);
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#editUserForm").valid()) {
      setLoading(true);
      postData(`/api/update_user`, userData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          setLoading(false);
          props.history.push(`/usermanagement`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/usermanagement">User</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit User Detail
          </li>
        </ol>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Edit User Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {getUserInformation.loading ? (
                      <div className="text-center text-info mb-3 form-control">
                        <strong>Loading...</strong>
                      </div>
                    ) : getUserInformation.successgetuserDetail ? (
                      <form
                        id="editUserForm"
                        method="POST"
                        className="forms-sample"
                      >
                        <div className=" row">
                          <Col lg={4} className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              name="firstName"
                              autoComplete="off"
                              placeholder="First Name"
                              value={userData.firstName}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  firstName: e.target.value,
                                })
                              }
                              minLength={3}
                              maxLength={180}
                              data-rule-required={true}
                            />
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              name="lastName"
                              autoComplete="off"
                              placeholder="Last Name"
                              value={userData.lastName}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  lastName: e.target.value,
                                })
                              }
                              minLength={3}
                              maxLength={180}
                              data-rule-required={true}
                            />
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="abc@example.com"
                              value={userData.email}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  email: e.target.value,
                                })
                              }
                              minLength={3}
                              maxLength={180}
                              data-rule-required={true}
                              data-rule-email={true}
                            />
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              name="mobile"
                              placeholder="+91 xxxxx-xxxxx"
                              value={userData.mobile}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  mobile: e.target.value,
                                })
                              }
                              minLength={3}
                              maxLength={180}
                              data-rule-required={true}
                              data-rule-phonenumber={true}
                            />
                          </Col>

                          <Col lg={4} className="form-group">
                            <label htmlFor="role_id">Role</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="role_id"
                                name="role_id"
                                required={true}
                                options={roleOptions}
                                className={"mr-2"}
                                joinValues={true}
                                searchable={true}
                                clearable={true}
                                autoComplete="off"
                                placeholder="Select Role"
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    role_id: e !== null ? e.value : null,
                                  })
                                }
                                value={userData.role_id}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="role_id"
                                    data-msg-required={"Please select role."}
                                  />
                                )}
                              />
                              <label
                                className="error select_error_span"
                                htmlFor="role_id"
                              ></label>
                            </div>
                            {/* <label htmlFor="role_id">Role</label>
                            <select
                              type="text"
                              className="form-control"
                              id="role_id"
                              name="role_id"
                              placeholder="Select"
                              value={userData.role_id}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  role_id: e.target.value,
                                })
                              }
                              data-rule-required={true}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {roleOptions.map((val, i) => {
                                return (
                                  <option value={val.id} key={i + 1}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select> */}
                          </Col>
                          <Col lg={4} className="form-group add_top_space_2">
                            <div className="d-inline-flex">
                              <label>Status: </label>
                              <label
                                htmlFor="statusactive"
                                className="mr-2 ml-2 d-inline-flex align-items-center"
                              >
                                <input
                                  type="radio"
                                  className="mr-1"
                                  id="statusactive"
                                  name="status"
                                  value={1}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      status: e.target.value,
                                    })
                                  }
                                  checked={
                                    userData.status === "1" ? true : false
                                  }
                                />{" "}
                                Active
                              </label>

                              <label
                                htmlFor="statusdeactive"
                                className=" d-inline-flex align-items-center"
                              >
                                <input
                                  type="radio"
                                  className="mr-1"
                                  id="statusdeactive"
                                  name="status"
                                  value={0}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      status: e.target.value,
                                    })
                                  }
                                  checked={
                                    userData.status === "0" ? true : false
                                  }
                                />{" "}
                                Deactive
                              </label>
                            </div>
                          </Col>
                        </div>
                        <Row>
                          <Col lg={12}>
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              onClick={submitHandller}
                              disabled={loading ? true : false}
                            >
                              {loading ? "Loading..." : "Update"}
                            </button>
                            <Link className="btn btn-dark" to="/usermanagement">
                              Cancel
                            </Link>
                          </Col>
                        </Row>
                      </form>
                    ) : getUserInformation.failedgetuserDetail ? (
                      <Row>
                        <Col>
                          <div className="form-control mb-4">
                            <h6 className="text-center text-danger">
                              {getUserInformation.failedgetuserDetail.message}
                            </h6>
                          </div>
                          <div className="text-center">
                            <Link
                              to={`/usermanagement`}
                              className=" btn btn-dark"
                            >
                              Back
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default EditUserPage;
