import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { get_office_action } from "../../actions/OfficeActions";
import { get_inventory_by_project_id_action } from "../../actions/InventoryActions";
import { useDispatch, useSelector } from "react-redux";
import { EditSvgIcon, PlusSvgIcon } from "../../components/SvgIcon";
function ShowOfficePage(props) {
  const [officeData, setOfficeData] = useState({
    project_id: props.match.params.projId,
    office_id: props.match.params.officeId,
    name: "",
    type: "",
    inchargeName: "",
    inchargeContactNumber: "",
    address: "",
    distance: "",
    latitude: "",
    longitude: "",
    projectName: "",
    plazaName: "",
    created: "",
  });
  const [activeTab, setActiveTab] = useState("inventory");
  const [inventoriesListData, setInventoriesListData] = useState([]);
  const get_officeinfo = useSelector((state) => {
    return {
      office: state.OfficeReducers.GetOfficeDetailReducer,
      inventory: state.InventoryReducers.GetInventoryListByFilterIdReducer,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.ofcID) {
      dispatch(get_office_action(props.match.params.ofcID));
      dispatch(
        get_inventory_by_project_id_action("office", props.match.params.ofcID)
      );
    }
  }, [props, dispatch]);
  useEffect(() => {
    if (get_officeinfo.office.successOfficeDetail) {
      setOfficeData(get_officeinfo.office.successOfficeDetail.data);
    }
    if (get_officeinfo.inventory) {
      setInventoriesListData(get_officeinfo.inventory);
    }
  }, [get_officeinfo]);
  const ShowInventoryList = (prop) => {
    let invtlistData = prop.data;
    return (
      <div className=" mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Serial Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invtlistData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._inventory_id}</td>
                  <td>{val.name}</td>
                  <td>{val.serial_number}</td>
                  <td>
                    <Link
                      to={`/inventory_management/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <EditSvgIcon />
                    </Link>
                    <Link
                      to={`/inventory_management/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          {officeData.projectId || officeData.plazaId ? (
            <li className="breadcrumb-item">
              {officeData.projectId ? (
                <Link to={`/project_management/${officeData.projectId}/show`}>
                  Project
                </Link>
              ) : officeData.plazaId ? (
                <Link to={`/plaza/${officeData.plazaId}/show`}>Plaza</Link>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}
          <li className="breadcrumb-item">
            <Link to={"/offices"}>Office</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Show Office Detail
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to={`/inventory_management/office/${officeData.id}/add_inventory`}
        >
          <PlusSvgIcon className="mr-2" />
          Add New Inventory
        </Link>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Show Office Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  {get_officeinfo.office.loading ? (
                    <div className="form-control mb-5 text-center text-info">
                      <strong>Loading...</strong>
                    </div>
                  ) : get_officeinfo.office.successOfficeDetail ? (
                    <Col lg={12}>
                      <div className="forms-sample row">
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="name">
                            Office Name
                          </label>
                          <div className="form-control">{officeData.name}</div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="Incharge">
                            Incharge Name
                          </label>
                          <div className="form-control">
                            {officeData.inchargeName}
                          </div>
                        </div>
                        {officeData.projectName ? (
                          <div className="form-group col-md-4">
                            <label className="w-100" htmlFor="longitude">
                              Project Name
                            </label>
                            <div className="form-control input_inner_box">
                              <Link
                                className="input_inner_btn"
                                to={`/project_management/${officeData.projectId}/show`}
                              >
                                {officeData.projectName} Project
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {officeData.plazaName ? (
                          <div className="form-group col-md-4">
                            <label className="w-100" htmlFor="longitude">
                              Plaza Name
                            </label>
                            <div className="form-control input_inner_box">
                              <Link
                                className="input_inner_btn"
                                to={`/plaza/${officeData.plazaId}/show`}
                              >
                                {officeData.plazaName} Plaza
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group col-md-4">
                          <label
                            className="w-100"
                            htmlFor="in_charge_contact_no"
                          >
                            In Charge Contact No
                          </label>
                          <div className="form-control">
                            {officeData.inchargeContactNumber}
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="address">
                            Address
                          </label>
                          <div className="form-control">
                            {officeData.address}
                          </div>
                        </div>

                        {officeData.plazaId !== null ? (
                          <div className="form-group  col-md-4">
                            <label className="w-100" htmlFor="distance">
                              Distance From Plaza In Km
                            </label>
                            <div className="form-control">
                              {officeData.distance}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="latitude">
                            Latitude
                          </label>
                          <div className="form-control">
                            {officeData.latitude}
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="longitude">
                            Longitude
                          </label>
                          <div className="form-control">
                            {officeData.longitude}
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="longitude">
                            Created
                          </label>
                          <div className="form-control">
                            {officeData.created}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : get_officeinfo.office.failedOfficeDetail ? (
                    <Col lg={12}>
                      <div className="text-danger text-center form-control">
                        {get_officeinfo.office.failedOfficeDetail.message}
                      </div>
                      <div className="mt-3 text-center">
                        <Link to={`/offices`} className="btn btn-dark">
                          Back
                        </Link>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {officeData.id !== undefined ? (
        <Row>
          <Col lg={12} className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs nav_tabs_custom">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "inventory" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu3"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("inventory");
                      }}
                    >
                      Inventory
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className={`tab-pane ${
                      activeTab === "inventory" ? "active" : "fade"
                    }`}
                    id="inventory"
                  >
                    {inventoriesListData.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : inventoriesListData.successInventoryByprojectId ? (
                      <ShowInventoryList
                        data={
                          inventoriesListData.successInventoryByprojectId.data
                        }
                      />
                    ) : inventoriesListData.failedInventoryByprojectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-info mt-3 form-control">
                            {inventoriesListData.failedInventoryByprojectId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
}
export default ShowOfficePage;
