import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  AiFillFileAdd,
  IoIosCloseCircle,
  FaCloudUploadAlt,
  FaDownload,
  FiEye,
  AiOutlineBarcode,
} from "../../components/SvgIcon";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../components/ReactTable";
import Modals from "../../components/BootstrapModal";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import { ComponentToPrintMultiple } from "./BarcodeToPrintMultiple";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { get_barcode_of_selected_inventory_action } from "../../actions/InventoryActions.js";

function InventoryManagement(props) {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [selectedInventoryList, setSelectedInventoryList] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const page_size = 10;
  const search = "";
  const sorted = [];
  const [selectAll, setselectAll] = useState(0);
  const [selected, setselected] = useState([]);
  const [selectedBarCodeData, setSelectedBarcodeData] = useState("");

  const getInventoryDetails1 = useSelector(
    (state) => state.InventoryReducers.GetSelectedBarcodeDetailReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (getInventoryDetails1.successGetSelectedBarcodeDetail.length > 0) {
      handlePrint();
    }
  }, [getInventoryDetails1.successGetSelectedBarcodeDetail.length]);

  const toggleRow = (id) => {
    const newSelected = Object.assign({}, selected);
    newSelected[id] = !selected[id];
    let selectedList = inventoryData;
    var userSelectedList = selectedInventoryList;

    var columnIndex = selectedList.findIndex((x) => x.id == id);
    if (newSelected[id]) {
      setSelectedInventoryList(
        userSelectedList.concat(selectedList[columnIndex])
      );
    } else {
      var tempState = {};
      var selectedColumnIndex = userSelectedList.findIndex((x) => x.id == id);
      tempState["userSelectedList"] = userSelectedList.filter(
        (s, sidx) => selectedColumnIndex !== sidx
      );
      setSelectedInventoryList(tempState["userSelectedList"]);
    }
    setselected(newSelected);
    setselectAll(2);
  };

  const toggleSelectAll = () => {
    let newSelected = {};
    let selectedList = inventoryData;

    if (selectAll === 0) {
      inventoryData.forEach((x) => {
        newSelected[x.id] = true;
      });
    }
    setSelectedInventoryList(selectedList);
    setselected(newSelected);
    setselectAll(selectAll === 0 ? 1 : 0);
  };

  const columns = [
    {
      id: "checkbox",
      accessor: "",
      Cell: ({ original }) => {
        return (
          <span className="w_50 w-100  mb-2">
            <input
              type="checkbox"
              className="checkbox1"
              checked={selected[original.id] === true}
              onChange={() => toggleRow(original.id)}
            />
            <label>
              <div className="d_table-cell">
                {" "}
                <span onClick={() => toggleRow(original.id)}></span>
              </div>
            </label>
          </span>
        );
      },
      Header: (x) => {
        return (
          <span className="w_50 w-100  mb-2">
            <input
              type="checkbox"
              className="checkbox1"
              checked={selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = selectAll === 2;
                }
              }}
              onChange={() => toggleSelectAll()}
            />
            <label>
              <div className="d_table-cell">
                {" "}
                <span onClick={() => toggleSelectAll()}></span>
              </div>
            </label>
          </span>
        );
      },
      sortable: false,
      width: 50,
    },
    {
      Header: "Serial Number",
      accessor: "serial_number",
      Cell: (row) => {
        row.styles["color"] = "#fff";
        row.styles["background-color"] = row.original.color;
        return row.original.serial_number.toUpperCase();
      },
    },
    {
      Header: "Product name",
      accessor: "product",
    },
    {
      Header: "Purchase Date",
      accessor: "purchase_date",
    },
    {
      Header: "Warranty Date",
      accessor: "warranty_expiry_date",
    },
    {
      Header: "Purchase Price",
      accessor: "purchase_price",
    },
    {
      Header: "Created at",
      accessor: "created",
    },
    {
      Header: () => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <OverlayTrigger placement="top" overlay={<Tooltip>Detail</Tooltip>}>
            <Link
              to={`/inventory_management/${props.original.id}/show`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
            >
              <FiEye />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <Link
              to={`/inventory_management/${props.original.id}/edit`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
            >
              <EditSvgIcon />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <button
              type="button"
              onClick={() => deleteHandller(props.original.id)}
              className="btn btn-danger btn-icon btn-sm-my mr-2"
            >
              <TrashSvgIcon />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Generate barcode </Tooltip>}
          >
            <Link
              to={`/inventory_barcode/${props.original.id}`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
            >
              <AiOutlineBarcode />
            </Link>
          </OverlayTrigger>
          {/*<Link to="/inventory_management" className="btn btn-primary btn-sm-my">Log</Link>*/}
        </span>
      ),
      sortable: false,
      width: 200,
    },
  ];

  const deleteHandller = (del_Id) => {
    ConfirmationAlertBox("Are you sure want to remove its?", {}).then(
      (status) => {
        if (status) {
          postData(`/api/delete_inventory`, { id: del_Id })
            .then((response) => {
              ToastMessage(response.data.message, "s");
              fetchData({ page_size, page, search, sorted });
            })
            .catch((error) => {
              ToastMessage(error.data.message, "e");
            });
        }
      }
    );
  };

  const generateBarCode = (inventoryId) => {
    console.log("selectedInventoryList", Object.keys(selected).length);
    console.log("selectedInventoryList", selected);
    if (selected) {
      ToastMessage("Select atleast", "e");
    } else {
    }
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const tempMethod = () => {
    dispatch(get_barcode_of_selected_inventory_action(selected));
  };

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    setLoading(true);
    postData(`/api/get_inventories_list`, req)
      .then((result) => {
        let res = result.data;
        setInventoryData(res.data);
        let tmp_pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmp_pages);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div style={{ display: "none" }}>
        <ComponentToPrintMultiple
          ref={componentRef}
          data={getInventoryDetails1.successGetSelectedBarcodeDetail}
        />
      </div>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Inventory
          </li>
        </ol>
        <div>
          <span
            type="button"
            className="btn btn-info btn-icon-text btn-sm mb-2 mb-md-0 align-self-center  mr-2"
            onClick={() => setModalShow(true)}
          >
            <AiFillFileAdd className="bluk_upload mr-2" />
            Bluk upload
          </span>

          <span
            type="button"
            className="btn btn-info btn-icon-text btn-sm mb-2 mb-md-0 align-self-center  mr-2"
            onClick={tempMethod}
          >
            <AiFillFileAdd className="bluk_upload mr-2" />
            Print Selected Barcode
          </span>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
            to="/inventory_management/add_inventory"
          >
            <PlusSvgIcon className="mr-2" />
            Add New Inventory
          </Link>
        </div>
      </nav>

      <Modals
        className={"CustomModalCls bulkUploadModal"}
        size={"md"}
        show={modalShow}
      >
        <div className="modal-header">
          <div className="modal-title h5">Bluk Upload</div>
          <i onClick={() => setModalShow(false)}>
            <IoIosCloseCircle className={"closeIcon"} />
          </i>
        </div>
        <div className="modal-body">
          <div className="form-group col-md-12 text-center">
            <label className="upload_file">
              <input className="d-none" type="file" />
              <div>
                <FaCloudUploadAlt />
                <span>Upload CSv File</span>
              </div>
            </label>
          </div>
          <div className="form-group col-md-12 mb-0 text-center">
            <label className="upload_file">
              <input className="d-none" type="file" />
              <div>
                <FaDownload />
                <span>Download sample</span>
              </div>
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary">Submmit</button>
          <button onClick={() => setModalShow(false)} className="btn btn-dark">
            Cancel
          </button>
        </div>
      </Modals>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Inventory List</h6>
              </div>
              <Table
                data={inventoryData}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                pages={pages}
                page={page}
                sorted={sorted}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default InventoryManagement;
