import { Link } from "react-router-dom";
import { PlusSvgIcon } from "./../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import SelectDropdown from "./../components/ReactSelectPlus";

var options = [
  { value: "shon", label: "shon" },
  { value: "root", label: "root" },
];

function logChange(val) {}

function ReportIssue() {
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Report Issue
          </li>
        </ol>
        {/* <Link
          type="button"
          class="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
        >
          <PlusSvgIcon className="mr-2" />
          Report Issue
        </Link> */}
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Report Issue</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <form class="forms-sample">
                      <div class="form-group row">
                        <Col lg={12} className="form-group">
                          <label for="tiitle">Tiitle</label>
                          <input
                            type="text"
                            class="form-control"
                            id="tiitle"
                            autocomplete="off"
                            placeholder="tiitle"
                          />
                        </Col>

                        <Col lg={12} className="form-group">
                          <label for="issue_detail">Issue Detail</label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="issue_detail"
                            placeholder="issue detail"
                          />
                        </Col>

                        <Col lg={12} className="form-group">
                          <label for="contact_person_detail">
                            Contact Person Detail
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="contact_person_detail"
                            placeholder="Contact Person Detail"
                          ></input>
                        </Col>

                        <Col lg={12} className="form-group">
                          <label for="assign_to">Assign to</label>
                          <div className="d-flex w-100">
                            <SelectDropdown
                              options={options}
                              logChange={logChange}
                              className={"w-100"}
                              value={options.value}
                            />
                          </div>
                        </Col>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <a
                            type="button"
                            class="btn btn-primary mr-2"
                            href="/ReportIssue"
                          >
                            Submit
                          </a>
                          <button class="btn btn-dark" href="/ReportIssue">
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default ReportIssue;
