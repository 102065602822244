import {
    GET_PERMISSION_REQUEST,
    GET_PERMISSION_SUCCESS_INFO,
    GET_PERMISSION_FAIL_ERROR,
    GET_PERMISSIONS_OPTLIST_REQUEST,
    GET_PERMISSIONS_OPTLIST_SUCCESS_INFO,
    GET_PERMISSIONS_OPTLIST_FAIL_ERROR,
    GET_SELF_PERMISSIONS_ACCESSLIST_REQUEST,
    GET_SELF_PERMISSIONS_ACCESSLIST_SUCCESS_INFO,
    GET_SELF_PERMISSIONS_ACCESSLIST_FAIL_ERROR,
} from "../constants/PermissionContants";

function GetPermissionReducer(state = {}, action) {
    switch (action.type) {
        case GET_PERMISSION_REQUEST:
            return { loading: true };
        case GET_PERMISSION_SUCCESS_INFO:
            return { loading: false, getSinglePermission: action.payload };
        case GET_PERMISSION_FAIL_ERROR:
            return { loading: false, failedSinglePermission: action.payload }
        default:
            return state;
    }
}

function GetPermissoinOptionListReducer(state = {}, action) {
    switch (action.type) {
        case GET_PERMISSIONS_OPTLIST_REQUEST:
            return { loading: true };
        case GET_PERMISSIONS_OPTLIST_SUCCESS_INFO:
            return { loading: false, successPermissionOptionList: action.payload };
        case GET_PERMISSIONS_OPTLIST_FAIL_ERROR:
            return { loading: false, failedPermissionOptionList: action.payload }
        default:
            return state;
    }
}

function GetSelfPermissoinAccessListReducer(state = [], action) {
    switch (action.type) {
        case GET_SELF_PERMISSIONS_ACCESSLIST_REQUEST:
            return { loading: true };
        case GET_SELF_PERMISSIONS_ACCESSLIST_SUCCESS_INFO:
            return { loading: false, successSelfPermissionAccessList: action.payload };
        case GET_SELF_PERMISSIONS_ACCESSLIST_FAIL_ERROR:
            return { loading: false, failedSelfPermissionAccessList: action.payload }
        default:
            return state;
    }
}
export { GetPermissionReducer, GetPermissoinOptionListReducer, GetSelfPermissoinAccessListReducer };