import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import React, { useEffect, useState } from "react";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import $ from "jquery";
//import { get_tracking_details } from "../../actions/MasterActions";
import SelectDropdown from "../../components/ReactSelectPlus";
import { useDispatch, useSelector } from "react-redux";
//import { get_master_list } from "../../actions/MasterActions";

function TrackingPage(props) {
  const [loading, setLoading] = useState(false);
  const [trackingData, setMasterData] = useState({
    tracking_number: "",
    courier_service: "",
	carrierList:[]
  });

  const dispatch = useDispatch();

  useEffect(() => {
	getCourierList();
  }, []);


  const getCourierList=()=>{
	postData(`/api/get_courier_name`)
		.then((result) => {
		var res = result.data;
		setMasterData({
			...trackingData,
			carrierList: res.data,
		  })
		})
		.catch((error) => {
		ToastMessage('Error', "e");
		});	  
  }

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#trackingForm").valid()) {
      setLoading(true);
      postData(`/api/save_tracking`, trackingData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          //formReset();
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const formReset = () => {
    setMasterData({ tracking_number: "", courier_service: "" });
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tracking
          </li>
        </ol>
      </nav>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Tracking System</h6>
              </div>

              <div className="card_box_border mb-3">
                <Row>
                  <Col>
                    <form
                      id="trackingForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col lg={12}>
                          <label for="tracking_number">Tracking Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="tracking_number"
                            name="tracking_number"
                            autocomplete="off"
                            placeholder="Tracking Number"
                            onChange={(e) =>
                              setMasterData({
                                ...trackingData,
                                tracking_number: e.target.value,
                              })
                            }
                            value={trackingData.tracking_number}
                            data-rule-required={true}
                          />
                        </Col>
                      </div>
                      <div className="form-group row">
                        <Col lg={12}>
                          <label for="courier_service">Select Type</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id={"courier_service"}
                              name={"courier_service"}
                              required={true}
                              options={trackingData.carrierList}
                              sampleValue={true}
                              joinValues={true}
                              searchable={true}
                              clearable={true}
                              autoComplete="off"
                              placeholder="Select Courier"
                              onChange={(e) =>
                                setMasterData({
                                  ...trackingData,
                                  courier_service: e != null ? e.value : null,
                                })
                              }
                              value={trackingData.courier_service}
                              inputRenderer={(props) => (
                                <input
                                  {...props}
                                  name="courier_service"
                                  data-msg-required={"Please select courier."}
                                />
                              )}
                            />
                            <label
                              className="error select_error_label"
                              htmlFor="courier_service"
                            ></label>
                          </div>
                        </Col>
                      </div>

                      <Row>
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading" : "Submit"}
                          </button>

							<Link type="button" className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center mr-2"
							to="/shipping_history" > History </Link>

                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={() => {
                              formReset();
                            }}
                          >
                            {"Cancel"}
                          </button>
                        </Col>
                      </Row>
                    </form>
                    <br />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default TrackingPage;
