import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_inventory_detail_action } from "../../actions/InventoryActions.js";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/ReactTable";

const ViewInventoryPage = (props) => {
  var IMG_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [inventoryData, setInventoryData] = useState({
    invtName: "",
    brand: "",
    serialNumber: "",
    model: "",
    manufacturedBy: "",
    manufacturedDate: null,
    hasWarranty: "1",
    serviceProvider: "",
    purchaseFrom: "",
    purchaseDate: null,
    publisher: "",
    version: "",
    warrantyDuration: "0",
    warrantyExpiryDate: "",
    imgFiles: [],
    docFiles: [],
    productName: "",
    product_id: "",
  });
  const [inventoryDataerr, setInventoryDataerr] = useState("");
  const [loading, setLoading] = useState(false);
  const pageSize = 10;
  const search = "";
  const [logDataList, setLogDataList] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const sortBy = [];
  const getInventoryDetails = useSelector(
    (state) => state.InventoryReducers.GetInventoryDetailReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.match.params.invtId) {
      dispatch(get_inventory_detail_action(props.match.params.invtId));
    }
  }, [props.match.params.invtId, dispatch]);
  useEffect(() => {
    if (getInventoryDetails.successgetInventoryDetail !== undefined) {
      setInventoryData(getInventoryDetails.successgetInventoryDetail.data);
    }
    if (getInventoryDetails.failedgetInventoryDetail !== undefined) {
      setInventoryDataerr(getInventoryDetails.failedgetInventoryDetail.message);
    }
  }, [getInventoryDetails]);

  /* fetchData() */
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_log_id",
        Cell: (prop) => (
          <span>
            <Link to={`/logs/${prop.original.id}/show`}>
              {prop.original._log_id}
            </Link>
          </span>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "User By",
        accessor: "u_name",
      },
      {
        Header: "Created",
        accessor: "created",
      },
    ],
    []
  );
  const fetchData = React.useCallback(
    ({ pageSize, page, filter, sorted }) => {
      let req = {
        page_size: pageSize,
        start: page,
        search: filter,
        sorted: sorted,
        inventory_id: props.match.params.invtId,
      };
      setLoading(true);
      postData(`/api/get_logs_of_inventory`, req)
        .then((result) => {
          let datares = result.data;
          setLogDataList(datares.data);
          let tmp_pages = Math.ceil(parseInt(datares.total) / pageSize);
          setPages(tmp_pages);
          setPage(page);
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    },
    [props]
  );

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/inventory_management">Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Inventory
          </li>
        </ol>
        <Link className="btn btn-dark pull-right" to="/inventory_management">
          Back
        </Link>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">View Inventory</h6>
              </div>
              <div className="card_box_border">
                <Row className="justify-content-center">
                  <Col lg={12}>
                    {getInventoryDetails.loading ? (
                      <div className="form-control text-info text-center">
                        Loading...
                      </div>
                    ) : getInventoryDetails.successgetInventoryDetail ? (
                      <div className="forms-sample row">
                        {/* <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="invtName">
                            Name: <strong className="text-primary">{inventoryData._inventory_id}</strong>
                          </label>
                          <div className="form-control">{inventoryData.invtName}</div>
                        </div> */}
                        {inventoryData.assign_with ? (
                          <div className="form-group col-md-4">
                            <label className="w-100" htmlFor="invtName">
                              Assign With:{" "}
                              <strong className="text-primary">
                                {inventoryData.assign_with}
                              </strong>
                            </label>
                            <div className="form-control input_inner_box">
                              <Link
                                className="input_inner_btn"
                                to={
                                  inventoryData.assign_with === "project"
                                    ? "/project_management/" +
                                      inventoryData.assign_id +
                                      "/show"
                                    : inventoryData.assign_with === "plaza"
                                    ? "/plaza/" +
                                      inventoryData.assign_id +
                                      "/show"
                                    : inventoryData.assign_with === "office"
                                    ? "/office/" +
                                      inventoryData.assign_id +
                                      "/show"
                                    : inventoryData.assign_with === "warehouse"
                                    ? "/warehouse/" +
                                      inventoryData.assign_id +
                                      "/show"
                                    : ""
                                }
                              >
                                {inventoryData.assignName}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="serialNumber">
                            Product Name :{" "}
                          </label>
                          <Link
                            className="form-control"
                            to={`/product_management/${inventoryData.product_id}/show`}
                          >
                            {inventoryData.productName}
                          </Link>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="serialNumber">
                            Serial Number
                          </label>
                          <div className="form-control">
                            {inventoryData.serialNumber}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="manufacturedDate">
                            Date Of Manufacture
                          </label>
                          <div className="form-control">
                            {inventoryData.manufacturedDate}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="purchaseDate">
                            Purchase Date
                          </label>
                          <div className="form-control">
                            {inventoryData.purchaseDate}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="hasWarranty">
                            Has A Warranty
                          </label>
                          <div className="form-control">
                            {inventoryData.hasWarranty === "1" ? "Yes" : "No"}
                          </div>
                        </div>
                        {inventoryData.has_warranty === "1" ? (
                          <div className="form-group  col-md-4">
                            <label className="w-100" htmlFor="warrantyDuration">
                              Warranty Duration
                            </label>
                            <div className="form-control">
                              {inventoryData.warrantyDuration} Yr.
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {inventoryData.has_warranty === "1" ? (
                          <div className="form-group  col-md-4">
                            <label
                              className="w-100"
                              htmlFor="warrantyExpiryDate"
                            >
                              Warranty Expiry Date
                            </label>
                            <div className="form-control">
                              {inventoryData.warrantyExpiryDate}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="serviceProvider">
                            Service Provide
                          </label>
                          <div className="form-control">
                            {inventoryData.serviceProvider}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="purchaseFrom">
                            Purchase From (seller)
                          </label>
                          <div className="form-control">
                            {inventoryData.purchaseFrom}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="publisher">
                            Publisher
                          </label>
                          <div className="form-control">
                            {inventoryData.publisher_name}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="purchasePrice">
                            Purchase Price
                          </label>
                          <div className="form-control">
                            {inventoryData.purchasePrice}
                          </div>
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="created">
                            Created
                          </label>
                          <div className="form-control">
                            {inventoryData.created}
                          </div>
                        </div>
                      </div>
                    ) : getInventoryDetails.failedgetInventoryDetail ? (
                      <div className="text-center">
                        <div className=" form-control text-danger mb-3">
                          {inventoryDataerr}
                        </div>
                        <Link
                          to="/inventory_management"
                          className="btn btn-danger"
                        >
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                {getInventoryDetails.successgetInventoryDetail ? (
                  <Row>
                    <Col lg={12} className="grid-margin stretch-card">
                      <div className="w-100">
                        <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                          <h6 className="card-title ">Inventory Log's</h6>
                        </div>
                        <div>
                          <Table
                            data={logDataList}
                            columns={columns}
                            defaultPageSize={pageSize}
                            loading={loading}
                            fetchData={fetchData}
                            pages={pages}
                            page={page}
                            filter={search}
                            sorted={sortBy}
                            showPagination={false}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewInventoryPage;
