import { combineReducers } from "redux";
import {
    GET_PLAZA_DETAIL_REQUEST,
    GET_PLAZA_DETAIL_SUCCESS_INFO,
    GET_PLAZA_DETAIL_FAIL_ERROR,
    GET_PLAZA_OPTION_LIST_REQUEST, GET_PLAZA_OPTION_LIST_SUCCESS_INFO, GET_PLAZA_OPTION_LIST_FAIL_ERROR,
    GET_PLAZAS_BY_PORJECT_ID_REQUEST, GET_PLAZAS_BY_PORJECT_ID_SUCCESS, GET_PLAZAS_BY_PORJECT_ID_FAIL_ERROR,
    GET_ASSIGN_PLAZA_LIST_REQUEST, GET_ASSIGN_PLAZA_OPTION_LIST, GET_ASSIGN_PLAZA_OPTION_LIST_FAIL_ERROR,
} from "../constants/PlazaContants";

function GetPlazaDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_PLAZA_DETAIL_REQUEST:
            return { loading: true };
        case GET_PLAZA_DETAIL_SUCCESS_INFO:
            return { loading: false, successPlazaDetail: action.payload };
        case GET_PLAZA_DETAIL_FAIL_ERROR:
            return { loading: false, failedPlazaDetail: action.payload }
        default:
            return state;
    }
}

function GetPlazaOptionListReducer(state = [], action) {
    switch (action.type) {
        case GET_PLAZA_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_PLAZA_OPTION_LIST_SUCCESS_INFO:
            return { loading: false, successPlazaOptionList: action.payload };
        case GET_PLAZA_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failPlazaOptionList: action.payload }
        default:
            return state;
    }
}
function GetPlazaByProjectIdReducer(state = [], action) {
    switch (action.type) {
        case GET_PLAZAS_BY_PORJECT_ID_REQUEST:
            return { loading: true };
        case GET_PLAZAS_BY_PORJECT_ID_SUCCESS:
            return { loading: false, successPlazaByprojectId: action.payload };
        case GET_PLAZAS_BY_PORJECT_ID_FAIL_ERROR:
            return { loading: false, failedPlazaByprojectId: action.payload };
        default:
            return state;
    }
}
function GetAssignPlazaOptionListReducer(state = {successAssignPlazaOptionList:[]}, action) {
    switch (action.type) {
        case GET_ASSIGN_PLAZA_LIST_REQUEST:
            return { ...state,loading: true };
        case GET_ASSIGN_PLAZA_OPTION_LIST:
            return { ...state,loading: false, successAssignPlazaOptionList: action.payload.data };
        case GET_ASSIGN_PLAZA_OPTION_LIST_FAIL_ERROR:
            return { ...state,loading: false, failPlazaOptionList: action.payload }
        default:
            return state;
    }
}
export default combineReducers({ GetPlazaDetailReducer, GetPlazaOptionListReducer, GetPlazaByProjectIdReducer, GetAssignPlazaOptionListReducer });