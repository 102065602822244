import {
    GET_LANE_DETAIL_INFO_REQUEST, GET_LANE_DETAIL_INFO_SUCCESS, GET_LANE_DETAIL_INFO_FAIL,
    GET_LANE_OPTION_LIST_REQUEST, GET_LANE_OPTION_LIST_SUCCESS_INFO, GET_LANE_OPTION_LIST_FAIL_ERROR,
    GET_LANES_BY_PLAZA_ID_REQUEST,
    GET_LANES_BY_PLAZA_ID_SUCCESS,
    GET_LANES_BY_PLAZA_ID_FAIL_ERROR
} from "../constants/LaneConstants.js";
import { postData } from "../service/Common"

const get_lane_detail_action = (laneID) => async (dispatch) => {
    dispatch({ type: GET_LANE_DETAIL_INFO_REQUEST, payload: {} })
    postData(`/api/get_lane_detail`, { id: laneID })
        .then((response) => {
            let data = response.data
            dispatch({ type: GET_LANE_DETAIL_INFO_SUCCESS, payload: data })
            return data;
        })
        .catch((err) => {
            dispatch({ type: GET_LANE_DETAIL_INFO_FAIL, payload: err.data.message })
            return err;
        })
}
const get_lanes_option_list_action = () => async (dispatch) => {
    dispatch({ type: GET_LANE_OPTION_LIST_REQUEST, payload: {} })
    postData(`/api/get_lane_option_list`, '', { method_type: 'GET' })
        .then((response) => {
            var data = response.data;
            dispatch({ type: GET_LANE_OPTION_LIST_SUCCESS_INFO, payload: data });
        }).catch((error) => {
            dispatch({ type: GET_LANE_OPTION_LIST_FAIL_ERROR, payload: error.data.message });
        })
}
const get_Lane_by_plaza_id_action = (Id) => async (dispatch) => {
    dispatch({ type: GET_LANES_BY_PLAZA_ID_REQUEST, payload: {} })
    postData(`/api/get_lane_by_plaza_id`, { plaza_id: Id })
        .then((response) => {
            var data = response.data;
            dispatch({ type: GET_LANES_BY_PLAZA_ID_SUCCESS, payload: data });
        }).catch((error) => {
            dispatch({ type: GET_LANES_BY_PLAZA_ID_FAIL_ERROR, payload: error.data.message });
        })
}
export { get_lane_detail_action, get_lanes_option_list_action, get_Lane_by_plaza_id_action };