import $ from 'jquery';

$.validator.addMethod("strongPassword", function (value, element) {
    return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/i.test(value);
}, "Passwords are 8-16 characters with uppercase letters, lowercase letters and at least one number."); 

$.validator.addMethod("strongPassword", function(value) {
    return /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) // consists of only these
        && /[a-z]/.test(value) // has a lowercase letter
        && /\d/.test(value) // has a digit
 }, "Passwords are 8-16 characters with letters, spacial character and at least one number.");

 $.validator.addMethod("phonenumber",
    function (value, element) {
        if (value != '') {
            return /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]{8,18}$/.test(value);
        } else {
            return true;
        }
    },
    "Please enter valid phone number"
);

$.validator.addMethod("numberOnly",
function (value, element) {
    if (value != '') {
        return /^[0-9]+$/.test(value);
    } else {
        return true;
    }
},
"Please enter Number only"
);

$.validator.addMethod("postcodecheck",
    function (value, element) {
        if (value != '') {
            return /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/.test(value);
        } else {
            return true;
        }
    },
    "Please enter valid 4 digit postcode number"
);

$.validator.addMethod("email", function (value, element) {
    // var patternM = /^([A-Z|a-z|0-9](\.|[_+]){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2,3}\.[a-z]{2,3}$/gm;
    var pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (value != '') {
        return pattern.test(value);
    } else {
        return true;
    }
}, "Please enter valid email address"
);

$.validator.addMethod("notequalto", function(value, element, param) {
    var temp = (param)?$(param).val():'';
    return this.optional(element) || value !== temp;
  }, "Please specify different value from old password.");


$.validator.addMethod("unique", function(value, element, params) {
    var prefix = params;
    var selector = $.validator.format("[name!='{0}'][unique='{1}']", element.name, prefix);
    var matches = new Array();
    $(selector).each(function(index, item) {
        if (value == $(item).val()) {
            matches.push(item);
        }
    });
    return matches.length == 0;
}, "Value is not unique.");
$.validator.classRuleSettings.unique = {
    unique: true
};




$.validator.addMethod("slug", function(value, element, param) {
    var pattern = /^[a-z0-9\^\&\_-]{6,}$/;
    if (value !== '') {
        return pattern.test(value);
    } else {
        return true;
    }
  }, "The slug should be min 6 character and valid lower case string.");

  $.validator.addMethod("validurl", function(value, element, param) {
    var pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (value !== '') {
        return pattern.test(value);
    } else {
        return true;
    }
  }, "Please enter valid url.");

  $.validator.addMethod("latitude", function(value, element, param) {
    var pattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (value !== '') {
        return pattern.test(value);
    } else {
        return true;
    }
  }, "Please enter valid latitude.");
  $.validator.addMethod("longitude", function(value, element, param) {
    var pattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (value !== '') {
        return pattern.test(value);
    } else {
        return true;
    }
  }, "Please enter valid longitude.");