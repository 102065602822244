import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import moment from "moment";
import { get_assign_plaza_list_action } from "../../actions/PlazaActions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import SelectDropdown from "../../components/ReactSelectPlus";

function LaneListPage() {
  const dispatch = useDispatch();
  const columns = [
    {
      Header: "LANE id",
      accessor: "lane_name",
      Cell: (row) => {
        row.styles["color"] = "#fff";
        row.styles["backgroundColor"] = row.original.color;
        return row.value.toUpperCase();
      },
    },
    {
      Header: "Plaza",
      accessor: "plaza",
    },
    {
      Header: "Transaction Date Time",
      accessor: "last_txn_time",
      Cell: (props) => (
        <span>
          {(props.original.last_txn_time!=null)?moment(props.original.last_txn_time).format("YYYY-MM-DD HH:mm:ss"):'-'}
        </span>
      ), // Custom cell components!
    },
    {
      Header: "Last SYNC Time",
      accessor: "txn_update_time",
      Cell: (props) => (
        <span>
          {(props.original.txn_update_time!=null)?moment(props.original.txn_update_time).format("YYYY-MM-DD HH:mm:ss"):'-'}
        </span>
      ), // Custom cell components!
    },
  ];
  const [pageno, setPageno] = useState(0);
  const [pages, setPages] = useState(0);
  const pageSise = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const search = "";
  const sortedBy = [];
  const [srchParam, setSrchParam] = useState({ filterBy: null, searchTxt: "" });
  const fetchData = React.useCallback(
    ({ pageSize, page, filtered, sorted }) => {
      setLoading(true);
      let req = {
        page_size: pageSize,
        start: page,
        search: filtered,
        sorted: sorted,
      };
      postData("/api/get_idle_lanes", req)
        .then((response) => {
          var res = response.data;
          setData(res.data);
          let tmp_pages = Math.ceil(parseInt(res.total) / pageSize);
          setPages(tmp_pages);
          setPageno(page);
          setLoading(false);
        })
        .catch((err) => {
          ToastMessage(err.data.message, "e");
          setLoading(false);
        });
    },
    []
  );

  const getopionsData = useSelector((state) => {
    return {
      plaza: state.PlazaReducers.GetAssignPlazaOptionListReducer,
    };
  });

  useEffect(() => {
    dispatch(get_assign_plaza_list_action());
  }, [dispatch]);

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/plazas">Plazas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Idle LANE
          </li>
        </ol>
        <div className="d-flex">
          <label className="mb-0 mr-2 align-self-center" htmlFor="plaza">
            Plaza :
          </label>
          <div className="cstm_select">
            <SelectDropdown
              id="filterBy"
              name="filterBy"
              options={getopionsData.plaza.successAssignPlazaOptionList}
              required={true}
              joinValues={true}
              autoComplete="off"
              placeholder="Select Plaza"
              className={"w-100"}
              onChange={(e) => {
                setSrchParam({
                  ...srchParam,
                  filterBy: e !== null ? e.value : null,
                });
              }}
              value={srchParam.filterBy}
              inputRenderer={(e) => <input {...e} name="filterBy" />}
            />
            <label
              className="error select_error_span"
              htmlFor="assign_id"
            ></label>
          </div>
        </div>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Lane List</h6>
              </div>
              <Table
                manual={true}
                data={data}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSise}
                fetchData={fetchData}
                page={pageno}
                pages={pages}
                sorted={sortedBy}
                filtered={srchParam}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default LaneListPage;
