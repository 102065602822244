export const GET_PROJECT_DETAIL_INFO_REQUEST ="GET_PROJECT_DETAIL_INFO_REQUEST";
export const GET_PROJECT_DETAIL_INFO_SUCCESS ="GET_PROJECT_DETAIL_INFO_SUCCESS";
export const GET_PROJECT_DETAIL_INFO_FAIL_ERROR="GET_PROJECT_DETAIL_INFO_FAIL_ERROR";

export const GET_PROJECT_OPTION_LIST_REQUEST = "GET_PROJECT_OPTION_LIST_REQUEST";
export const GET_PROJECT_OPTION_LIST_SUCCESS = "GET_PROJECT_OPTION_LIST_SUCCESS";
export const GET_PROJECT_OPTION_LIST_FAIL_ERROR = "GET_PROJECT_OPTION_LIST_FAIL_ERROR";

export const GET_UN_ASSIGNED_OPTION_LIST_REQUEST = "GET_UN_ASSIGNED_OPTION_LIST_REQUEST";
export const GET_UN_ASSIGNED_OPTION_LIST_SUCCESS = "GET_UN_ASSIGNED_OPTION_LIST_SUCCESS";
export const GET_UN_ASSIGNED_OPTION_LIST_FAIL_ERROR = "GET_UN_ASSIGNED_OPTION_LIST_FAIL_ERROR";