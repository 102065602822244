import { Link,Redirect  } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { postData } from "../../service/Common";
import Table from "../../components/ReactTable";
import { ToastMessage } from "../../service/ToastMessage";
import moment from "moment";
import Loader from "react-loader-spinner";


function DocketDetail(props) {
  const columns = [
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Details",
      accessor: "status_detail",
    },
    {
      Header: "Date Time",
      accessor: "time",
    }
  ];
  const [shippingData, setShippingData] = useState({
    awbno: "",
    order_id: "",
    carrier: "",
    pickup_date: "",
    current_status: "",
    current_status_code: "",
    order_data: "",
    recipient: "",
    from: "",
    to: "",
    time: "",
    pickupdate: "",
    tracking_url:"",
    scan: [],
    extra_fields:{reference_newWaybill:"",expected_delivery_date:""}
  });
  const [CarrierData, setCarrierData] = useState([]);
  const page_size = 20;
  const search = "";
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [redirectToList, setRedirectToList] = useState(false);
  const [last_fetch_date, setlast_fetch_date] = "";
  const sortedBy = "";

  const fetchLiveStatus=()=>{ 
    setLoading(true)
    postData(`/api/get_shippment_live_detail`,{id:props.match.params.id})
		.then((result) => {
		var res = result.data;
        console.log(result)
        if(res.status == 'failed'){
            ToastMessage(res.msg, "e");
        }else{
            setShippingData( res.response)
        }
        setLoading(false)
		})
		.catch((error) => {  
		  ToastMessage('Error', "e");
          setLoading(false)
		});
  }

  const getDataOnLoad=()=>{
    setLoading(true)
    postData(`/api/get_shippment_detail_from_db`,{id:props.match.params.id})
    .then((result) => {
    var res = result.data;
    //console.log(res.data.response)
    if(res.data.status == 'failed'){
        ToastMessage(res.msg, "e");
        setLoading(false)
    }else{
       setShippingData( res.data.response)
       setLoading(false)
       //alert(res.updated_at)
       //setlast_fetch_date(moment(res?.updated_at).format("YYYY/MM/DD kk:mm:ss"));
    }
    })
    .catch((error) => {  
      ToastMessage('Error', "e");
      setLoading(false)
      setRedirectToList(true)
    });
  }

  useEffect(() => {
	getDataOnLoad();
  }, []);
 
  return (
    <>
    {redirectToList ?  <Redirect to={'/shipping_history'} />:''}
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
          <Link to="/shipping_history">Shipping History</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
		  Tracking Details
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Tracking Details</h6>
              </div>
            
             {loading?
              <div className=" text-center">
                    <Loader type="ThreeDots" color="#092347" height={40} width={40} />
                </div>:''}

              <div className="card_box_border">                
                <Row className="justify-content-center">                  
                  <Col lg={12}>

                      <div className="forms-sample row">
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="prodName">AWB</label>
                          <div className="form-control">{shippingData.awbno}</div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="productUrl">
                          Current status
                          </label>
                          <div className="form-control">
                            {shippingData.current_status}
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="manufacturedBy">Order id</label>
                          <div className="form-control">{shippingData.order_id} </div>
                        </div>
                        
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="model">
                          Carrier
                          </label>
                          <div className="form-control">
                            {shippingData.carrier}
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="brand">
                          Pickup date
                          </label>
                          <div className="form-control">
                            {shippingData.pickup_date}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                          Current status code
                          </label>
                          <div className="form-control">
                            {shippingData.current_status_code}
                          </div>
                        </div>

                        {/*<div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                          Order data: 
                          </label>
                          <div className="form-control">
                            {shippingData.order_data}
                          </div>
                        </div>*/}

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                          Recipient
                          </label>
                          <div className="form-control">
                            {shippingData.recipient}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="created">
                          From
                          </label>
                          <div className="form-control">
                            {shippingData.from}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="created">
                          To
                          </label>
                          <div className="form-control">
                            {shippingData.to}
                          </div>
                        </div>
                        
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="created">
                          Time
                          </label>
                          <div className="form-control">
                            {shippingData.time}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="created">
                          Pickup Date
                          </label>
                          <div className="form-control">
                            {shippingData.pickupdate}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          {/*<label className="w-100" htmlFor="created">
                          Last fetch Time
                          </label>
                          <div className="form-control">
                            {//last_fetch_date}
                    </div>*/}
                        </div>

                        <div className="form-group  col-md-4">                       
                        <Button className="btn btn-danger" onClick={fetchLiveStatus}>
                          Refresh
                        </Button>
                      </div>                         
                        
                      </div>
                    
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0"> Status and Scans </h6>
              </div>
              <Table
                data={shippingData.scan}
                //data={CarrierData || shippingData.scan}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                //fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={false}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default DocketDetail;
