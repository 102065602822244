import Select from "react-select-plus";
import "react-select-plus/dist/react-select-plus.css";

function SelectDropdown(props) {
  return (
      <Select
        joinValues={props.joinValues}
        id={props.id}
        name={props.name}
        options={props.options}
        required={props.required}
        className={props.className}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        style={props.style}
        inputRenderer={props.inputRenderer}
        disabled={props.disabled}
        onOpen={props.onOpen}
      />
  );
}

export default SelectDropdown;
