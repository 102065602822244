import { postData } from "../service/Common";
import { GET_HARDWARE_REQUEST_DETAIL_REQUEST, GET_HARDWARE_REQUEST_DETAIL_SUCCESS, GET_HARDWARE_REQUEST_DETAIL_FAILED_ERROR } from "../constants/HardwareRequestConstants";
const get_hardware_request_detail_action = (Id) => async (dispatch) => {
    dispatch({ type: GET_HARDWARE_REQUEST_DETAIL_REQUEST, payload: {} })
    postData(`/api/get_hardware_request_detail`, { id: Id })
        .then((response) => {
            let res = response.data;
            dispatch({ type: GET_HARDWARE_REQUEST_DETAIL_SUCCESS, payload: res })
        })
        .catch((error) => {
            dispatch({ type: GET_HARDWARE_REQUEST_DETAIL_FAILED_ERROR, payload: error.data.message })
        })
}
export { get_hardware_request_detail_action };