import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_role_details } from "../../actions/RoleActions";
import { useDispatch, useSelector } from "react-redux";

function EditRolePage(props) {
  const get_roleDetail = useSelector((state) => state.GetRoleDetailReducer);
  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState({ name: "", slug: "", status: 1 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.rId) {
      dispatch(get_role_details(props.match.params.rId));
      setLoading(true);
    }
  }, [props.match.params.rId, dispatch]);

  useEffect(() => {
    if (get_roleDetail.loading === false) {
      if (get_roleDetail.successRolesDetail) {
        setRoleData(get_roleDetail.successRolesDetail.data);
        setLoading(get_roleDetail.loading);
      }
      if (get_roleDetail.failedRolesDetail) {
        ToastMessage(get_roleDetail.failedRolesDetail.message);
        setLoading(get_roleDetail.loading);
        props.history.push(`/roles`);
      }
    }
  }, [get_roleDetail, props]);
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#EditRoleForm").valid()) {
      setLoading(true);
      postData(`/api/update_role`, roleData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          setLoading(false);
          props.history.push(`/roles`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/roles">Roles</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit Role Detail
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Edit Role Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <form
                      id="EditRoleForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col lg={12} className="form-group">
                          <label htmlFor="name">Role Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            autoComplete="off"
                            placeholder="Name"
                            value={roleData.name}
                            onChange={(e) =>
                              setRoleData({ ...roleData, name: e.target.value })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                            disabled={loading ? true : false}
                          />
                        </Col>

                        <Col lg={12} className="form-group">
                          <label htmlFor="slug">Slug</label>
                          <input
                            type="text"
                            className="form-control"
                            id="slug"
                            name="slug"
                            autoComplete="off"
                            placeholder="Slug Name"
                            value={roleData.slug}
                            onChange={(e) =>
                              setRoleData({ ...roleData, slug: e.target.value })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                            data-rule-slug={true}
                            disabled={loading ? true : false}
                          />
                        </Col>

                        <Col lg={12} className="form-group">
                          <label>Status:</label>
                          <label
                            htmlFor="statiusactive"
                            className="mr-2 ml-2 d-inline-flex align-items-center"
                          >
                            <input
                              type="radio"
                              id="statiusactive"
                              name="status"
                              value={1}
                              onChange={(e) =>
                                setRoleData({
                                  ...roleData,
                                  status: e.target.value,
                                })
                              }
                              checked={roleData.status === "1" ? true : false}
                            />{" "}
                            Active
                          </label>
                          <label
                            htmlFor="statusdeactive"
                            className=" d-inline-flex align-items-center"
                          >
                            <input
                              type="radio"
                              id="statusdeactive"
                              name="status"
                              value={0}
                              onChange={(e) =>
                                setRoleData({
                                  ...roleData,
                                  status: e.target.value,
                                })
                              }
                              checked={roleData.status === "0" ? true : false}
                            />{" "}
                            Deactive
                          </label>
                        </Col>

                        <Col lg={12} className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Update"}
                          </button>
                          <Link className="btn btn-dark" to="/roles">
                            Cancel
                          </Link>
                        </Col>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default EditRolePage;
