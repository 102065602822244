import {
  GET_MASTER_REQUEST,
  GET_MASTER_SUCCESS_INFO,
  GET_MASTER_FAIL_ERROR,
  GET_MASTER_DETAIL_REQUEST,
  GET_MASTER_DETAIL_SUCCESS_INFO,
  GET_MASTER_DETAIL_FAIL_ERROR,
} from "../constants/MasterConstants";
import { postData } from "../service/Common";

const get_master_list = () => async (dispatch) => {
  dispatch({ type: GET_MASTER_REQUEST, payload: {} });
  postData("/api/get_master_list", {})
    .then((response) => {
      var data = response.data;
      console.log(data);
      dispatch({ type: GET_MASTER_SUCCESS_INFO, payload: data });
    })
    .catch((error) => {
      dispatch({ type: GET_MASTER_FAIL_ERROR, payload: error.data });
    });
};

const get_master_details = (id) => async (dispatch) => {
  dispatch({ type: GET_MASTER_DETAIL_REQUEST, payload: {} });
  postData("/api/get_master_details", { id: id })
    .then((response) => {
      var data = response.data;
      dispatch({ type: GET_MASTER_DETAIL_SUCCESS_INFO, payload: data });
    })
    .catch((error) => {
      dispatch({ type: GET_MASTER_DETAIL_FAIL_ERROR, payload: error.data });
    });
};
export { get_master_list, get_master_details };
