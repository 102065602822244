import { combineReducers } from "redux";
import {
  NOTIFICATION_COUNT_REQUEST,
  NOTIFICATION_COUNT_SUCCESS,
  NOTIFICATION_COUNT_FAIL,
  SAVE_NOTIFICATION_COUNT,
} from "../constants/NotificationConstants";

function getNotificationReducer(state = {}, action) {
  switch (action.type) {
    case NOTIFICATION_COUNT_REQUEST:
      return { loading: true };
    case NOTIFICATION_COUNT_SUCCESS:
      return { loading: false, successNotifyCount: action.payload };
    case NOTIFICATION_COUNT_FAIL:
      return { loading: false, failedNotifyCount: action.payload };
    case SAVE_NOTIFICATION_COUNT:
      return { loading: false, countInfo: action.payload };
    default:
      return state;
  }
}
export default combineReducers({
  getNotificationReducer,
});
