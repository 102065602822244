import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import SelectDropdown from "../../components/ReactSelectPlus";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_project_option_list_action } from "../../actions/ProjectActions";
import { get_plaza_option_list_action } from "../../actions/PlazaActions";
import { get_office_option_list_action } from "../../actions/OfficeActions";
import { get_warehouse_option_list_action } from "../../actions/WarehouseActions";
import { get_hardware_request_detail_action } from "../../actions/HardwareRequestActions";
import { get_product_option_list_action } from "../../actions/ProductActions";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import {
  GrAddCircle,
  FiMinusCircle,
  BiUpload,
  IoMdClose,
} from "../../components/SvgIcon";

const IMAGE_SIZE_ACCEPT = 25304060;
const IMAGE_TYPE_ACCEPT = ["image/jpg", "image/jpeg", "image/png"];
var typeOptions = [
  { value: "new", label: "New" },
  { value: "repair", label: "Repair" },
  { value: "replace", label: "Replace" },
];
var statusOptions = [
  { value: "0", label: "Pending" },
  { value: "1", label: "Processing" },
  { value: "2", label: "Completed" },
  { value: "3", label: "Cancel" },
];

function EditHardwareRequest(props) {
  var IMG_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [hardwareRequestData, setHardwareRequestData] = useState({
    id: props.match.params.reqId,
    title: "",
    type_with: null,
    officeId: null,
    plazaId: null,
    warehouseId: null,
    item: "",
    quantity: "",
    status: null,
    type: null,
    description: "",
    items: [],
  });
  const [loading, setLoading] = useState(false);
  const [projectOptionList, setProjectOptionList] = useState([]);
  const [plazaOptionList, setPlazaOptionList] = useState([]);
  const [officeOptionList, setOfficeOptionList] = useState([]);
  const [warehouseoptionList, setWarehouseOptionList] = useState([]);
  const [productOptionList, setProductOptionList] = useState([]);
  const getopionsData = useSelector((state) => {
    return {
      request: state.HardwareRequestReducers.GetHardwareRequestDetailReducer,
      project: state.ProjectReducers.GetProjectOptionListReducer,
      plaza: state.PlazaReducers.GetPlazaOptionListReducer,
      office: state.OfficeReducers.GetOfficeOptionListReducer,
      warehouse: state.WarehouseReducers.GetWarehouseOptionListReducer,
    };
  });
  const getProdOptionsList = useSelector(
    (state) =>
      state.ProductReducers.GetProductOptionListReducer.successProductOptionList
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.reqId) {
      dispatch(get_hardware_request_detail_action(props.match.params.reqId));
    }
    dispatch(get_project_option_list_action());
    dispatch(get_plaza_option_list_action());
    dispatch(get_office_option_list_action());
    dispatch(get_warehouse_option_list_action());
    dispatch(get_product_option_list_action(0));
  }, [props, dispatch]);

  useEffect(() => {
    if (getopionsData.project.successProjectOptionList) {
      setProjectOptionList(getopionsData.project.successProjectOptionList.data);
    }
    if (getopionsData.plaza.successPlazaOptionList) {
      setPlazaOptionList(getopionsData.plaza.successPlazaOptionList.data);
    }
    if (getopionsData.office.successOfficeOptionList) {
      setOfficeOptionList(getopionsData.office.successOfficeOptionList.data);
    }
    if (getopionsData.warehouse.successWarehouseOptionList) {
      setWarehouseOptionList(
        getopionsData.warehouse.successWarehouseOptionList.data
      );
    }
    if (getopionsData.request.successgetHwRequestDetail) {
      setHardwareRequestData(
        getopionsData.request.successgetHwRequestDetail.data
      );
    }
    if (getProdOptionsList && typeof getProdOptionsList.data !== undefined) {
      setProductOptionList(getProdOptionsList ? getProdOptionsList.data : []);
    }
  }, [
    getopionsData.project,
    getopionsData.plaza,
    getopionsData.office,
    getopionsData.warehouse,
    getopionsData.request,
  ]);

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#edithardwareRequestForm").valid()) {
      setLoading(true);
      postData(`/api/update_hardware_request`, hardwareRequestData)
        .then((result) => {
          setLoading(false);
          ToastMessage(result.data.message, "s");
          props.history.push("/requests");
        })
        .catch((error) => {
          setLoading(false);
          ToastMessage(error.data.message, "e");
        });
    }
  };
  const deleteAttachmentFile = (id) => {
    ConfirmationAlertBox({
      title: "Confirmation",
      message: "Are you sure want to delete?",
    }).then((result) => {
      if (result.status) {
        postData(`/api/remove_attachment_by_id`, { id: id })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            dispatch(
              get_hardware_request_detail_action(props.match.params.reqId)
            );
          })
          .catch((err) => {
            ToastMessage(err.data.message, "e");
          });
      }
    });
  };
  const fileHandller = (e, index) => {
    var formData = new FormData();
    let hWRtemp = hardwareRequestData.items;
    if (
      e.target.files.length &&
      e.target.files.length + hWRtemp[index].attachments.length <= 5
    ) {
      let tes = e.target.files;
      for (let x = 0; x < e.target.files.length; x++) {
        if (tes[x].name === "") {
          ToastMessage(`The File ${x} invalid file name.`, "e");
          e.target.value = null;
          return false;
        }
        if (tes[x].size > IMAGE_SIZE_ACCEPT) {
          ToastMessage(`The File ${x} upload minimum 2 MB.`, "e");
          e.target.value = null;
          return false;
        }
        if (IMAGE_TYPE_ACCEPT.includes(tes[x].type) === false) {
          ToastMessage(`The File ${x} invalid file format.`, "e");
          e.target.value = null;
          return false;
        }
        formData.append("imgFiles[]", tes[x]);
      }
      postData(`/api/upload_tempimagefiles`, formData)
        .then((result) => {
          let res = result.data;
          let temp = hardwareRequestData.items;
          temp[index]["image_uploads"] = res.data;
          setHardwareRequestData({ ...hardwareRequestData, temp });
        })
        .catch((err) => {
          ToastMessage(err.data.message, "e");
          e.target.value = null;
        });
    } else {
      ToastMessage(
        `Maximum upload ${
          5 - hWRtemp[index].attachments.length
        } files allowed only.`,
        "e"
      );
      e.target.value = null;
      return false;
    }
  };
  const changeItemHandller = (value, field, index) => {
    let temp = hardwareRequestData.items;
    temp[index][field] = value;
    setHardwareRequestData({ ...hardwareRequestData, items: temp });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/requests`}>Requests</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit Hardware Request
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Edit Hardware Request</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {getopionsData.request.loading ? (
                      <>
                        <div className="text-center text-info form-control">
                          Loading...
                        </div>
                      </>
                    ) : getopionsData.request.successgetHwRequestDetail ? (
                      <form
                        id="edithardwareRequestForm"
                        name="edithardwareRequestForm"
                        method="POST"
                        className="forms-sample"
                      >
                        <div className="row">
                          <Col lg={4} className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              id="title"
                              name="title"
                              className="form-control"
                              onChange={(e) =>
                                setHardwareRequestData({
                                  ...hardwareRequestData,
                                  title: e.target.value,
                                })
                              }
                              value={hardwareRequestData.title}
                              placeholder="Title"
                              autoComplete="off"
                              data-rule-required={true}
                              minLength={3}
                              maxLength={180}
                            />
                          </Col>
                          <Col lg={4} className="form-group">
                            <label htmlFor="type_with1">Type With</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="type_with1"
                                name="type_with1"
                                options={[
                                  { value: "office", label: "Office" },
                                  { value: "project", label: "Project" },
                                  { value: "plaza", label: "Plaza" },
                                  { value: "warehouse", label: "Warehouse" },
                                ]}
                                required={true}
                                joinValues={true}
                                autoComplete="off"
                                placeholder="Select the type with"
                                className={"w-100"}
                                onChange={(e) =>
                                  setHardwareRequestData({
                                    ...hardwareRequestData,
                                    type_with: e !== null ? e.value : null,
                                  })
                                }
                                value={hardwareRequestData.type_with}
                                inputRenderer={(e) => (
                                  <input
                                    {...e}
                                    name="type_with"
                                    data-msg-required="This field is required"
                                  />
                                )}
                              />
                              <label
                                className="error select_error_span"
                                htmlFor="type_with"
                              ></label>
                            </div>
                          </Col>
                          <Col lg={4} className="form-group">
                            {hardwareRequestData.type_with === "project" ? (
                              <>
                                <label htmlFor="projectId1">Project</label>

                                <div className="cstm_select">
                                  <SelectDropdown
                                    id="projectId1"
                                    name="projectId1"
                                    options={projectOptionList}
                                    required={true}
                                    joinValues={true}
                                    autoComplete="off"
                                    placeholder="Select the project"
                                    className={"w-100"}
                                    onChange={(e) =>
                                      setHardwareRequestData({
                                        ...hardwareRequestData,
                                        projectId: e !== null ? e.value : null,
                                      })
                                    }
                                    value={hardwareRequestData.projectId}
                                    inputRenderer={(e) => (
                                      <input
                                        {...e}
                                        name="projectId"
                                        data-msg-required="This field is required"
                                      />
                                    )}
                                  />
                                  <label
                                    className="error select_error_span"
                                    htmlFor="projectId"
                                  ></label>
                                </div>
                              </>
                            ) : hardwareRequestData.type_with === "plaza" ? (
                              <>
                                <label htmlFor="plazaId1">Plaza</label>
                                <div className="cstm_select">
                                  <SelectDropdown
                                    id="plazaId1"
                                    name="plazaId1"
                                    options={plazaOptionList}
                                    required={true}
                                    joinValues={true}
                                    autoComplete="off"
                                    placeholder="Select the plaza"
                                    className={"w-100"}
                                    onChange={(e) =>
                                      setHardwareRequestData({
                                        ...hardwareRequestData,
                                        plazaId: e !== null ? e.value : null,
                                      })
                                    }
                                    value={hardwareRequestData.plazaId}
                                    inputRenderer={(e) => (
                                      <input
                                        {...e}
                                        name="plazaId"
                                        data-msg-required="This field is required"
                                      />
                                    )}
                                  />
                                  <label
                                    className="error select_error_span"
                                    htmlFor="plazaId"
                                  ></label>
                                </div>
                              </>
                            ) : hardwareRequestData.type_with === "office" ? (
                              <>
                                <label htmlFor="officeId1">Office</label>
                                <div className="cstm_select">
                                  <SelectDropdown
                                    id="officeId1"
                                    name="officeId1"
                                    options={officeOptionList}
                                    required={true}
                                    joinValues={true}
                                    autoComplete="off"
                                    placeholder="Select the office"
                                    className={"w-100"}
                                    onChange={(e) =>
                                      setHardwareRequestData({
                                        ...hardwareRequestData,
                                        officeId: e !== null ? e.value : null,
                                      })
                                    }
                                    value={hardwareRequestData.officeId}
                                    inputRenderer={(e) => (
                                      <input
                                        {...e}
                                        name="officeId"
                                        data-msg-required="This field is required"
                                      />
                                    )}
                                  />
                                  <label
                                    className="error select_error_span"
                                    htmlFor="officeId"
                                  ></label>
                                </div>
                              </>
                            ) : hardwareRequestData.type_with ===
                              "warehouse" ? (
                              <>
                                <label
                                  className="col-lg-3"
                                  htmlFor="warehouseId1"
                                >
                                  Warehouse
                                </label>
                                <div className="cstm_select">
                                  <SelectDropdown
                                    id="warehouseId1"
                                    name="warehouseId1"
                                    options={warehouseoptionList}
                                    required={true}
                                    joinValues={true}
                                    autoComplete="off"
                                    placeholder="Select the warehouse"
                                    className={"w-100"}
                                    onChange={(e) =>
                                      setHardwareRequestData({
                                        ...hardwareRequestData,
                                        warehouseId:
                                          e !== null ? e.value : null,
                                      })
                                    }
                                    value={hardwareRequestData.warehouseId}
                                    inputRenderer={(e) => (
                                      <input
                                        {...e}
                                        name="warehouseId"
                                        data-msg-required="This field is required"
                                      />
                                    )}
                                  />
                                  <label
                                    className="error select_error_span"
                                    htmlFor="warehouseId"
                                  ></label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </div>
                        {hardwareRequestData.items.map((val, i) => (
                          <div className=" row" key={i}>
                            <Col lg={3} className="form-group">
                              <label htmlFor="item_name">Item</label>
                              <SelectDropdown
                                id={"product_id" + i}
                                name={"product_id" + i}
                                required={true}
                                options={productOptionList}
                                sampleValue={true}
                                joinValues={true}
                                searchable={true}
                                clearable={true}
                                autoComplete="off"
                                placeholder="Select Product"
                                onChange={(e) =>
                                  changeItemHandller(
                                    e !== null ? e.value : "",
                                    "item_name",
                                    i
                                  )
                                }
                                value={val.item_name}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name={"product_id" + i}
                                    data-msg-required={"Please select product."}
                                  />
                                )}
                              />
                              <label
                                className="error select_error_span"
                                htmlFor={"product_id" + i}
                              ></label>
                              {/* <label htmlFor="issue_detail">Item</label>
                            <input
                              type="text"
                              name={`name_` + i}
                              className={"form-control"}
                              onChange={(e) =>
                                changeItemHandller(
                                  e.target.value,
                                  "item_name",
                                  i
                                )
                              }
                              value={val.item_name}
                              placeholder="Item"
                              data-rule-required={true}
                              minLength={3}
                              maxLength={180}
                            /> */}
                            </Col>
                            <Col lg={2} className="form-group">
                              <label htmlFor="quantity">Quantity</label>
                              <input
                                type="number"
                                id={`quantity_` + i}
                                name={`quantity_` + i}
                                className={"form-control"}
                                onChange={(e) =>
                                  changeItemHandller(
                                    e.target.value,
                                    "quantity",
                                    i
                                  )
                                }
                                value={val.quantity}
                                placeholder="1"
                                data-rule-required={true}
                                min={1}
                                max={1000}
                              />
                            </Col>
                            <Col lg={2} className="form-group">
                              <label htmlFor="type1">Add/Replace</label>
                              <div className="cstm_select">
                                <SelectDropdown
                                  id="type1"
                                  name="type1"
                                  options={typeOptions}
                                  required={true}
                                  joinValues={true}
                                  autoComplete="off"
                                  placeholder="Select the type"
                                  className={"w-100"}
                                  onChange={(e) =>
                                    changeItemHandller(
                                      e !== null ? e.value : null,
                                      "type",
                                      i
                                    )
                                  }
                                  value={val.type}
                                  inputRenderer={(e) => (
                                    <input
                                      {...e}
                                      name={`type_` + i}
                                      data-msg-required="This field is required"
                                    />
                                  )}
                                />
                                <label
                                  className="error select_error_span"
                                  htmlFor={`type_` + i}
                                ></label>
                              </div>
                            </Col>
                            {val.type === "repair" || val.type === "replace" ? (
                              <Col lg={4} className="form-group">
                                <>
                                  <label htmlFor="images">
                                    Upload {5 - val.attachments.length} images
                                    allowed
                                  </label>

                                  <label className="upload_btn_custom">
                                    <input
                                      type="file"
                                      className="form-control"
                                      id={`imgFiles_` + i}
                                      name={`imgFiles_` + i}
                                      accept="image/*"
                                      multiple={true}
                                      onChange={(e) => fileHandller(e, i)}
                                    />
                                    <div>
                                      <span>Upload Your Images</span>
                                      <BiUpload />
                                    </div>
                                  </label>
                                  <div className="d-none">
                                    <small>
                                      <b>File Name:</b> Testing
                                    </small>
                                  </div>
                                </>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={1} className="form-group d-flex">
                              {i + 1 === hardwareRequestData.items.length ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    let aa = hardwareRequestData.items;
                                    aa.push({
                                      id: "",
                                      item_name: "",
                                      quantity: "",
                                      type: null,
                                      attachments: [],
                                    });
                                    setHardwareRequestData({
                                      ...hardwareRequestData,
                                      items: aa,
                                    });
                                  }}
                                  className="btn_plus_minus add_top_space_1"
                                >
                                  <GrAddCircle />
                                </button>
                              ) : (
                                ""
                              )}
                              {i !== 0 ||
                              hardwareRequestData.items.length > 1 ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    let temp = hardwareRequestData.items;
                                    temp.splice(i, 1);
                                    setHardwareRequestData({
                                      ...hardwareRequestData,
                                      items: temp,
                                    });
                                  }}
                                  className="btn_plus_minus add_top_space_1"
                                >
                                  <FiMinusCircle />
                                </button>
                              ) : (
                                ""
                              )}
                            </Col>
                            {val.attachments.length ? (
                              <Col md={12} className="mb-3">
                                <div>
                                  {val.attachments.map((val22, x) => (
                                    <div className=" upload_image_AR " key={x}>
                                      <span
                                        className="cgl-btn"
                                        onClick={() =>
                                          deleteAttachmentFile(val22.id)
                                        }
                                      >
                                        <IoMdClose />
                                      </span>
                                      <Link
                                        to={{
                                          pathname:
                                            IMG_BASE_URL + "/" + val22.src,
                                        }}
                                        target="_blank"
                                      >
                                        <img
                                          src={IMG_BASE_URL + "/" + val22.src}
                                          alt={val22.file_name}
                                          height="30px"
                                          width="auto"
                                        />
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                        <div className="row">
                          <Col lg={3}>
                            <div className="form-group">
                              <label htmlFor="status1">Status</label>

                              <div className="cstm_select">
                                <SelectDropdown
                                  id="status1"
                                  name="status1"
                                  options={statusOptions}
                                  required={true}
                                  joinValues={true}
                                  autoComplete="off"
                                  placeholder="Select the status"
                                  className={"w-100"}
                                  onChange={(e) =>
                                    setHardwareRequestData({
                                      ...hardwareRequestData,
                                      status: e !== null ? e.value : null,
                                    })
                                  }
                                  value={hardwareRequestData.status}
                                  inputRenderer={(e) => (
                                    <input
                                      {...e}
                                      name="status"
                                      data-msg-required="This field is required"
                                    />
                                  )}
                                />
                                <label
                                  className="error select_error_span"
                                  htmlFor="type"
                                ></label>
                              </div>
                            </div>
                          </Col>
                        </div>
                        <div className="row">
                          <Col lg={12}>
                            <div className="form-group">
                              <label htmlFor="description">Description</label>

                              <textarea
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                placeholder="Something write here..."
                                data-rule-required={true}
                                onChange={(e) =>
                                  setHardwareRequestData({
                                    ...hardwareRequestData,
                                    description: e.target.value,
                                  })
                                }
                                value={hardwareRequestData.description}
                                minLength={3}
                                maxLength={300}
                              />
                            </div>
                          </Col>
                        </div>

                        <Row>
                          <Col lg={12}>
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              name="submit"
                              disabled={loading ?? false}
                              onClick={submitHandller}
                            >
                              {loading ? "Loading..." : "Update"}
                            </button>
                            <Link className="btn btn-dark" to={"/requests"}>
                              Cancel
                            </Link>
                          </Col>
                        </Row>
                      </form>
                    ) : getopionsData.request.failedgetHwRequestDetail ? (
                      <div className="text-left">
                        <div className="text-danger text-center form-control">
                          {getopionsData.request.failedgetHwRequestDetail}
                        </div>
                        <Link to={`/requests`} className="mt-2 btn btn-dark">
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default EditHardwareRequest;
