import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import { get_lanes_option_list_action } from "../../actions/LaneActions";
import { useDispatch } from "react-redux";

function PlazaListPage() {
  const dispatch = useDispatch();
  const page_size = 10;
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const search = "";
  const sortedBy = [];
  const [loading, setLoading] = useState(false);
  const [plazaList, setPlazaList] = useState([]);

  useEffect(() => {
    dispatch(get_lanes_option_list_action());
  }, [dispatch]);
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    postData(`/api/get_plaza_list`, req)
      .then((response) => {
        var res = response.data;
        setPlazaList(res.data);
        let tmp_pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmp_pages);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
        if (error.status === 404) {
          ToastMessage(error.statusText, "e");
        } else {
          ToastMessage(error.data.message, "e");
        }
        setLoading(false);
      });
  }, []);

  const deleteHandller = (iD) => {
    ConfirmationAlertBox("Are you sure want to remove it", {}).then((res) => {
      if (res.status) {
        postData(`/api/delete_plaza`, { id: iD })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            fetchData({ page_size, page, search, sortedBy });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };
  const columns = [
    {
      Header: "Plaza ID",
      accessor: "id",
    },
    {
      Header: "Plaza Name",
      accessor: "name",
      minWidth: 180,
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Project Name",
      accessor: "project_name",
      minWidth: 180,
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Plaza Contact No",
      accessor: "plaza_contact_no",
      minWidth: 150,
      Cell: (props) => <span>{props.value}</span>,
    },
    {
      Header: "Plaza Type",
      accessor: "plaza_type",
      Cell: (props) => (
        <span style={{ "text-transform": "capitalize" }}>
          {props.value === "1" ? "National" : "State"}
        </span>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => (
        <span
          title="Status"
          className={`btn btn-${
            props.original.status === "Active" ? "success" : "danger"
          } btn-sm-my mr-2`}
        >
          {props.original.status}
        </span>
      ),
    },
    {
      Header: (props) => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <Link
            to={`/plaza/${props.original.id}/show`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Details"
          >
            <FiEye />
          </Link>
          <Link
            to={`/plaza/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Edit"
          >
            <EditSvgIcon />
          </Link>
          <button
            type="button"
            onClick={() => deleteHandller(props.original.id)}
            title="Remove"
            className="btn btn-danger btn-icon btn-sm-my mr-2"
          >
            <TrashSvgIcon />
          </button>
          {/* <Link
            to={`/plaza/${props.original.id}/add_office`}
            type="button"
            className="btn btn-primary btn-sm-my "
            title="Add office"
          >
            Add office / Ware house
          </Link> */}
        </span>
      ),
      sortable: false,
      width: 330,
    },
  ];
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/project_management">Projects</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            All Plaza List
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/plaza/add_plaza"
        >
          <PlusSvgIcon className="mr-2" />
          Add New Plaza
        </Link>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Plaza List</h6>
              </div>
              <Table
                data={plazaList}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default PlazaListPage;
