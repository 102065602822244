import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { get_lanes_option_list_action } from "../../actions/LaneActions";
import { get_warehouse_action } from "../../actions/WarehouseActions";

function AddnewWarehouse(props) {
  const [warehouseData, setWarehouseData] = useState({
    project_id:
      props.match.params.type === "project" ? props.match.params.Id : null,
    plaza_id:
      props.match.params.type === "plaza" ? props.match.params.Id : null,
    office_id: props.match.params.officeId,
    name: "",
    inchargeName: "",
    inchargeContactNumber: "",
    address: "",
    distance: "",
    latitude: "",
    longitude: "",
  });
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState({ name: "" });
  const [plazaData, setPlazaData] = useState({ name: "" });
  const get_project_detail = useSelector((state) => {
    return {
      project: state.ProjectReducers.GetProjectDetailReducer,
      plaza: state.PlazaReducers.GetPlazaDetailReducer,
    };
  });

  useEffect(() => {
    if (get_project_detail.project.successProjectDetail) {
      setProjectData(get_project_detail.project.successProjectDetail.data);
    }
    if (get_project_detail.plaza.successPlazaDetail) {
      setPlazaData(get_project_detail.plaza.successPlazaDetail.data);
    }
  }, [get_project_detail]);

  const resetForm = () => {
    setWarehouseData({
      project_id:
        props.match.params.type === "project" ? props.match.params.Id : null,
      plaza_id:
        props.match.params.type === "plaza" ? props.match.params.Id : null,
      name: "",
      inchargeName: "",
      inchargeContactNumber: "",
      address: "",
      distance: "",
      latitude: "",
      longitude: "",
    });
  };
  const [editWarehouseId, setEditWarehouseId] = useState(false);
  const get_warehouseinfo = useSelector(
    (state) => state.WarehouseReducers.GetWareHouseDetailReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_lanes_option_list_action());
    if (props.match.params.wrhseId) {
      setEditWarehouseId(true);
      dispatch(get_warehouse_action(props.match.params.wrhseId));
    }
  }, [props, dispatch]);
  useEffect(() => {
    if (
      props.match.params.wrhseId &&
      get_warehouseinfo.successwarehouseDetail
    ) {
      setWarehouseData(get_warehouseinfo.successwarehouseDetail.data);
    }
  }, [get_warehouseinfo]);

  const updateHandller = (e) => {
    e.preventDefault();
    if ($("#addnewWarehouseForm").valid()) {
      setLoading(true);
      postData(`/api/update_warehouse`, warehouseData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          dispatch(
            get_warehouse_action(
              props.match.params.projId,
              props.match.params.wrhseId
            )
          );
          setEditWarehouseId(false);
          resetForm();
          setLoading(false);
          props.history.push(`/warehouses`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewWarehouseForm").valid()) {
      postData(`/api/addnew_warehouse`, warehouseData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
          props.history.push(`/warehouses`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          {props.match.params.type !== undefined ? (
            <li className="breadcrumb-item">
              {props.match.params.type === "project" ? (
                <Link to={`/project_management/${props.match.params.Id}/show`}>
                  Project
                </Link>
              ) : props.match.params.type === "plaza" ? (
                <Link to={`/plaza/${props.match.params.Id}/show`}>Plaza</Link>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}
          <li className="breadcrumb-item">
            <Link to={"/warehouses"}>Warehouses</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            {editWarehouseId ? "Edit Warehouse" : "Add New Warehouse"}
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">
                  {editWarehouseId ? "Edit Warehouse" : "Add New Warehouse"}
                </h6>
              </div>
              <div className="card_box_border">
                <h6>
                  {props.match.params.type === "project" ? (
                    <span>
                      &nbsp;In{" "}
                      <Link
                        to={`/project_management/${props.match.params.Id}/show`}
                      >
                        {projectData.name} Project
                      </Link>
                    </span>
                  ) : props.match.params.type === "plaza" ? (
                    <span>
                      &nbsp;In{" "}
                      <Link to={`/plaza/${props.match.params.Id}/show`}>
                        {plazaData.name} Plaza
                      </Link>
                    </span>
                  ) : (
                    ""
                  )}
                </h6>
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="addnewWarehouseForm"
                      method="POST"
                      className="forms-sample row"
                    >
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="name">
                          Warehouse Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          neme="name"
                          autoComplete="off"
                          placeholder="Name"
                          value={warehouseData.name}
                          onChange={(e) =>
                            setWarehouseData({
                              ...warehouseData,
                              name: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          minLength={3}
                          maxLength={180}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="Incharge">
                          Incharge Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Incharge"
                          name="inchargeName"
                          placeholder="Incharge Name"
                          autoComplete="off"
                          value={warehouseData.inchargeName}
                          onChange={(e) =>
                            setWarehouseData({
                              ...warehouseData,
                              inchargeName: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          minLength={3}
                          maxLength={180}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="in_charge_contact_no">
                          In Charge Contact No
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="in_charge_contact_no"
                          name="inchargeContactNumber"
                          placeholder="In Charge Contact No"
                          autoComplete="off"
                          value={warehouseData.inchargeContactNumber}
                          onChange={(e) =>
                            setWarehouseData({
                              ...warehouseData,
                              inchargeContactNumber: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-phonenumber={true}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="address">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          placeholder="Address"
                          autoComplete="off"
                          value={warehouseData.address}
                          onChange={(e) =>
                            setWarehouseData({
                              ...warehouseData,
                              address: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          maxLength={350}
                        ></input>
                      </div>

                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="latitude">
                          Latitude
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="latitude"
                          name="latitude"
                          placeholder="Latitude"
                          autoComplete="off"
                          value={warehouseData.latitude}
                          onChange={(e) =>
                            setWarehouseData({
                              ...warehouseData,
                              latitude: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-latitude={true}
                          maxLength={25}
                        ></input>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="w-100" htmlFor="longitude">
                          Longitude
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="longitude"
                          name="longitude"
                          placeholder="Longitude"
                          autoComplete="off"
                          value={warehouseData.longitude}
                          onChange={(e) =>
                            setWarehouseData({
                              ...warehouseData,
                              longitude: e.target.value,
                            })
                          }
                          data-rule-required={true}
                          data-rule-longitude={true}
                          maxLength={25}
                        ></input>
                      </div>
                      {props.match.params.type === "plaza" ? (
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="distance">
                            Distance From Plaza In km
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="distance"
                            name="distance"
                            placeholder="Distance From Plaza In km"
                            autoComplete="off"
                            value={warehouseData.distance}
                            onChange={(e) =>
                              setWarehouseData({
                                ...warehouseData,
                                distance: e.target.value,
                              })
                            }
                            data-rule-required={true}
                            maxLength={350}
                          ></input>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group  col-md-12 align-self-end">
                        {!editWarehouseId ? (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ?? false}
                          >
                            {loading ? "loading..." : "Submit"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={updateHandller}
                            disabled={loading ?? false}
                          >
                            {loading ? "loading..." : "Update"}
                          </button>
                        )}
                        <Link className="btn btn-dark" to={`/warehouses`}>
                          Cancel
                        </Link>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddnewWarehouse;
