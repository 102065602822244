import {
	USER_LOGIN_LOCALSTORAGE_TOKEN_KEY,
	USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY,
	USER_LOGIN_LOCALSTORAGE_TOKEN_EXPIRE,
} from "../config/Constants";

export const isLoggedIn = () => {
	return !!localStorage.getItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY);
};

export const userInfo = () => {
	return JSON.parse(localStorage.getItem(USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY)) || {};
};

export const login_token = () => {
	return localStorage.getItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY);
};

export const clear_auth_token = () => {
	localStorage.removeItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY);
	localStorage.removeItem(USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY);
	localStorage.removeItem(USER_LOGIN_LOCALSTORAGE_TOKEN_EXPIRE);
};

export const isExpiredTokenTime = () => {

	var unix_timestamp = localStorage.getItem(USER_LOGIN_LOCALSTORAGE_TOKEN_EXPIRE);
	var unixdate = new Date(unix_timestamp * 1000);
	var nowdate = new Date();
	/*var hours = date.getHours();
	var minutes = "0" + date.getMinutes();
	var seconds = "0" + date.getSeconds();
	var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);*/
	return unixdate <= nowdate ? true : false;
}