import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ArrowUpSvgIcon, ArrowDownSvgIcon } from "./../components/SvgIcon";
import Charts from "./../components/Apexcharts/index";
import { ReactBingmaps, Polyline } from "react-bingmaps";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { ToastMessage } from "../service/ToastMessage";
import { postData } from "../service/Common";
import Map from "../components/MapShow/Map";
import { get_dashboard_data_action } from "../actions/DashboardActions";

const Dashboard = (props) => {
  const get_mapInfo = useSelector(
    (state) => state.GetDashboardDetailReducer.successGetDashboardDetail
  );

  const [loading, setLoading] = useState(false);
  const [allDashboardData, setDashboardData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_dashboard_data_action());
  }, [dispatch]);
  useEffect(() => {
    if (get_mapInfo && typeof get_mapInfo.data !== undefined) {
      setDashboardData(get_mapInfo.data);
    }
  }, [get_mapInfo]);

  const options = {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    chart: {
      id: "basic-bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April','May', 'June', 'July', 'August', ' September','October','November','December'],
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: '#00e396',
      width: 1.7,
      dashArray: 0,
    },
  };

  const series = [
    {
      name: "Completed Request",
      data: [50, 70, 95, 100, 0, 55, 169, 291, 348,500,856,912],
    },
  ];

  const options1 = {
    fill: {
      colors: ["#00e396", "#727cf5", "#ff4560", "#feb019"],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  const series2 = [
    {
      name: "Migration",
      data: [2, 0, 1, 2, 1, 1, 3, 1, 4],
    },
  ];

  const options2 = {
    fill: {
      colors: ["#727cf5"],
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April','May', 'June', 'July', 'August', ' September','October','November','December'],
      labels: {
        show: false,
      },
    },
    title: {
      show: false,
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
  };

  /** */
  const series3 = [
    {
      name: "Total Hardware Request",
      type: "column",
      data: [100, 201, 110, 300, 0, 100, 201, 352, 752, 620, 900, 1000],
    },
    {
      name: "Completed Hardware Request",
      type: "line",
      data: [50, 70, 95, 100, 0, 55, 169, 291, 348,500,856,912],
    },
  ];

  const options3 = {
    fill: {
      colors: ["#727cf5"],
    },
    // grid: {
    //   row: {
    //     colors: ["#F44336", "#E91E63", "#9C27B0"],
    //   },
    //   column: {
    //     colors: ["#F44336", "#E91E63", "#9C27B0"],
    //   },
    // },
    chart: {
      height: 350,
      type: "line",
    },
    stroke: {
      width: [0, 3],
    },
    title: {
      // text: "Traffic Sources",
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      style: {
        colors: ["#F44336", "#0c1427", "#9C27B0"],
      },
    },
    labels: [
      "Jan 2001",
      "Feb 2001",
      "Mar 2001",
      "Apr 2001",
      "May 2001",
      "Jun 2001",
      "Jul 2001",
      "Aug 2001",
      "Sep 2001",
      "Oct 2001",
      "Nov 2001",
      "Dec 2001",
    ],
    xaxis: {
      type: "date",
    },
    yaxis: [
      {
        title: {
           text: "Total Hardware Request",
        },
      },
      {
        opposite: true,
        title: {
           text: "Completed Hardware Request",
        },
      },
    ],
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
        <div>
          <h5 className="mb-3 mb-md-0">Welcome to Dashboard</h5>
        </div>
      </div>

      <Row>
        <Col lg={12} className="stretch-card">
          <Row className="flex-grow w-100">
            <Col lg={4} className="grid-margin stretch-card grey_box_">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="card-title mb-0">Current hardware request</h6>
                  </div>
                  <Row>
                    <Col lg={5} md={12} className="pt-3">
                      <h3 className="mb-2">3,446</h3>
                      <div className="d-flex align-items-baseline">
                        <p className="text-success">
                          <span>+43.67%</span>
                          <ArrowUpSvgIcon className={"icon-sm mb-1"} />
                        </p>
                      </div>
                    </Col>
                    <Col lg={7} md={12} className="pl-0">
                      <Charts
                        options={options}
                        series={series}
                        width="140"
                        type="line"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col lg={4} className="grid-margin stretch-card grey_box_">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="card-title mb-0">ICD 2.5 Plaza</h6>
                  </div>
                  <Row>
                    <Col lg={5} md={12} className="pt-3">
                      <h3 className="mb-2">5</h3>
                     
                    </Col>
                    <Col lg={7} md={12}>
                      <Charts

                        options={options1}
                        series={[44, 55, 13, 33]}
                        type="donut"
                        height="140"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col lg={4} className="grid-margin stretch-card grey_box_">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="card-title mb-0">Migration</h6>
                  </div>
                  <Row>
                    <Col lg={5} md={12} className="pt-3">
                      <h3 className="mb-2">15</h3>
                      <div className="d-flex align-items-baseline">
                        <p className="text-success">
                          <span>+300%</span>
                          <ArrowUpSvgIcon className={"icon-sm mb-1"} />
                        </p>
                      </div>
                    </Col>
                    <Col lg={7} md={12}>
                      <Charts
                        options={options2}
                        series={series2}
                        type="bar"
                        height="110"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="stretch-card">
          <Row className="flex-grow w-100">
            <Col lg={12} className="grid-margin stretch-card grey_box_">
              <div className="card">
                <div className="card-body">
                  <div className="mb-2">
                    <span className="m-2" style={{ color: "red" }}>
                      Plaza
                    </span>
                    <span className="m-2" style={{ color: "green" }}>
                      Office
                    </span>
                    <span className="m-2" style={{ color: "#1291f1" }}>
                      Warehouse
                    </span>
                  </div>
                  <div style={{ height: 500 }}>
                    <ReactBingmaps
                      bingmapKey="Alk-jbL9XHopwK4VWmSczq7UQLJaY-eCkQDVyACPf0t5nT3Uz5SGckllrU7Fcp9v"
                      center={[21.3527, 80.2707]}
                      zoom={5}
                      infoboxesWithPushPins={
                        allDashboardData.length
                          ? allDashboardData.map((val, x) => ({
                              location: [
                                Number(val.latitude),
                                Number(val.longitude),
                              ],
                              addHandler: "mouseover", //on mouseover the pushpin, infobox shown
                              infoboxOption: {
                                title: val.name,
                                description: val.address ? val.address : "",
                              },
                              pushPinOption: {
                                title: val.name,
                                description: val.address ? val.address : "",
                                color: val.color,
                              },
                              infoboxAddHandler: {
                                type: "click",
                                callback: () =>
                                  (window.location.href = `/${val.pagename}/${val.id}/show`),
                              },
                            }))
                          : []
                      }
                    ></ReactBingmaps>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="flex-grow w-100">
        <Col lg={12} className="grid-margin stretch-card grey_box_">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline mb-2">
                <h6 className="card-title mb-0">Hardware Request Log</h6>
              </div>
              <Charts
                options={options3}
                series={series3}
                type="line"
                height="340"
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
