import { combineReducers } from "redux";
import { GET_LOGS_DETAIL_REQUEST, GET_LOGS_DETAIL_SUCCESS, GET_LOGS_DETAIL_FAILED_ERROR } from "../constants/LogConstants";

function GetLogDetailReducer(state={},action){
    switch(action.type){
        case GET_LOGS_DETAIL_REQUEST:
            return { loading: true, payload: {} };
        case GET_LOGS_DETAIL_SUCCESS:
            return { loading: false, successGetLogDetail: action.payload };
        case GET_LOGS_DETAIL_FAILED_ERROR:
            return { loading: false, failedGetLogDetail: action.payload };
        default:
            return state;
    }
}
export default combineReducers({ GetLogDetailReducer });