import { combineReducers } from "redux";
import {
    GET_PROJECT_DETAIL_INFO_REQUEST,
    GET_PROJECT_DETAIL_INFO_SUCCESS,
    GET_PROJECT_DETAIL_INFO_FAIL_ERROR,
    GET_PROJECT_OPTION_LIST_SUCCESS,
    GET_PROJECT_OPTION_LIST_REQUEST,
    GET_PROJECT_OPTION_LIST_FAIL_ERROR,
    GET_UN_ASSIGNED_OPTION_LIST_REQUEST,
    GET_UN_ASSIGNED_OPTION_LIST_SUCCESS,
    GET_UN_ASSIGNED_OPTION_LIST_FAIL_ERROR
} from "../constants/ProjectContants";

function GetProjectDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_PROJECT_DETAIL_INFO_REQUEST:
            return { loading: true };
        case GET_PROJECT_DETAIL_INFO_SUCCESS:
            return { loading: false, successProjectDetail: action.payload };
        case GET_PROJECT_DETAIL_INFO_FAIL_ERROR:
            return { loading: false, failedProjectDetail: action.payload }
        default:
            return state;
    }
}

function GetProjectOptionListReducer(state = [], action) {
    switch (action.type) {
        case GET_PROJECT_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_PROJECT_OPTION_LIST_SUCCESS:
            return { loading: false, successProjectOptionList: action.payload };
        case GET_PROJECT_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failedProjectOptionList: action.payload }
        default:
            return state;
    }
}
function GetUnAssignedOptionList(state = {}, action) {
    switch (action.type) {
        case GET_UN_ASSIGNED_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_UN_ASSIGNED_OPTION_LIST_SUCCESS:
            return { loading: false, successUnAssignedOptionList: action.payload };
        case GET_UN_ASSIGNED_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failedUnAssignedOptionList: action.payload }
        default:
            return state;
    }
}
export default combineReducers({ GetProjectDetailReducer, GetProjectOptionListReducer, GetUnAssignedOptionList });