import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { get_company_detail_action } from "../../actions/CompanyActions";
import { useSelector, useDispatch } from "react-redux";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";

function EditCompanyPage(props) {
  const [companyData, setCompanyData] = useState({
    id: "",
    name: "",
    url: "",
    address: "",
    lat: "",
    long: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getCompantDetail = useSelector((state) => {
    return {
      company: state.CompanyReducers.GetCompanyDetailReducer,
    };
  });
  useEffect(() => {
    if (props.match.params.cmpId) {
      dispatch(get_company_detail_action(props.match.params.cmpId));
    } else {
      props.history.push("/companied");
    }
  }, [dispatch, props]);

  useEffect(() => {
    if (getCompantDetail.company.successCompanyDetail) {
      setCompanyData(getCompantDetail.company.successCompanyDetail.data);
    }
  }, [getCompantDetail.company]);

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#editcompanyForm").valid()) {
      setLoading(true);
      postData(`/api/update_company`, companyData)
        .then((result) => {
          setLoading(false);
          ToastMessage(result.data.message, "s");
          props.history.push("/companies");
        })
        .catch((error) => {
          setLoading(false);
          ToastMessage(error.data.message, "e");
        });
    }
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/companies`}>Companies</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit Company
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Edit Company</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {getCompantDetail.company.loading ? (
                      <div className="form-control text-center text-info">
                        Loading...
                      </div>
                    ) : getCompantDetail.company.successCompanyDetail ? (
                      <form
                        id="editcompanyForm"
                        name="editcompanyForm"
                        method="POST"
                        className="forms-sample"
                      >
                        <div className="row">
                          <Col className="form-group" lg={4}>
                            <label htmlFor="name">Name</label>
                            <input
                              id="name"
                              name="name"
                              className="form-control"
                              onChange={(e) =>
                                setCompanyData({
                                  ...companyData,
                                  name: e.target.value,
                                })
                              }
                              value={companyData.name}
                              placeholder="Name"
                              autoComplete="off"
                              data-rule-required={true}
                              minLength={3}
                              maxLength={180}
                            />
                          </Col>
                          <Col className="form-group" lg={4}>
                            <label htmlFor="url">Company Url</label>
                            <input
                              type="text"
                              className="form-control"
                              id="url"
                              name="url"
                              placeholder="https://example.com"
                              data-rule-required={true}
                              data-rule-validurl={true}
                              onChange={(e) =>
                                setCompanyData({
                                  ...companyData,
                                  url: e.target.value,
                                })
                              }
                              value={companyData.url}
                              maxLength={180}
                            />
                          </Col>
                          <Col className="form-group" lg={4}>
                            <label htmlFor="address">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              placeholder="Someting write here"
                              data-rule-required={true}
                              onChange={(e) =>
                                setCompanyData({
                                  ...companyData,
                                  address: e.target.value,
                                })
                              }
                              value={companyData.address}
                              minLength={3}
                              maxLength={180}
                            />
                          </Col>
                        </div>
                        <div className="row">
                          <Col className="form-group" lg={4}>
                            <label htmlFor="lat">Latitude</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lat"
                              name="lat"
                              placeholder="25.36"
                              data-rule-latitude={true}
                              onChange={(e) =>
                                setCompanyData({
                                  ...companyData,
                                  lat: e.target.value,
                                })
                              }
                              value={companyData.lat}
                              maxLength={12}
                            />
                          </Col>
                          <Col className="form-group" lg={4}>
                            <label htmlFor="long">Longitude</label>
                            <input
                              type="text"
                              className="form-control"
                              id="long"
                              name="long"
                              placeholder="75.36"
                              data-rule-longitude={true}
                              onChange={(e) =>
                                setCompanyData({
                                  ...companyData,
                                  long: e.target.value,
                                })
                              }
                              value={companyData.long}
                              maxLength={12}
                            />
                          </Col>
                        </div>
                        <Row>
                          <Col lg={12}>
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              name="submit"
                              disabled={loading ?? false}
                              onClick={submitHandller}
                            >
                              {loading ? "Loading..." : "Update"}
                            </button>
                            <Link className="btn btn-dark" to={"/companies"}>
                              Cancel
                            </Link>
                          </Col>
                        </Row>
                      </form>
                    ) : getCompantDetail.company.failedCompanyDetail ? (
                      <div className="text-left">
                        <div className="form-control text-center text-danger">
                          {getCompantDetail.company.failedCompanyDetail}
                        </div>
                        <Link to={"/companies"} className="mt-2 btn btn-dark ">
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default EditCompanyPage;
