import { GET_COMPANT_OPTION_LIST_FAILED, GET_COMPANT_OPTION_LIST_REQUEST, GET_COMPANT_OPTION_LIST_SUCCESS, GET_COMPANY_DETAIL_FAIED_ERROR, GET_COMPANY_DETAIL_REQUEST, GET_COMPANY_DETAIL_SUCCESS } from "../constants/CompanyConstants";
import { combineReducers } from "redux";
function GetCompanyDetailReducer(state = [], action) {
    switch (action.type) {
        case GET_COMPANY_DETAIL_REQUEST:
            return { loading: true };
        case GET_COMPANY_DETAIL_SUCCESS:
            return { loading: false, successCompanyDetail: action.payload };
        case GET_COMPANY_DETAIL_FAIED_ERROR:
            return { loading: false, failedCompanyDetail: action.payload }
        default:
            return state;
    }
}

function GetCompanyOptionListReducer(state=[],action){
    switch(action.type){
        case GET_COMPANT_OPTION_LIST_REQUEST:
            return {loading:true};
        case GET_COMPANT_OPTION_LIST_SUCCESS:
            return { loading: false, successCompanyOptionList: action.payload };
        case GET_COMPANT_OPTION_LIST_FAILED:
            return { loading: false, failedCompanyOptionList: action.payload }
        default:
            return state;
    }
}
export default combineReducers({ GetCompanyDetailReducer, GetCompanyOptionListReducer });