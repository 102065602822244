import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";

function AddNewRolePage() {
  const [roleData, setRoleData] = useState({ name: "", slug: "", status: 1 });
  const [loading, setLoading] = useState(false);
  const resetForm = () => {
    setRoleData({ name: "", slug: "", status: 1 });
  };
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewRoleForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_role`, roleData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          resetForm();
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/roles">Roles</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add New Role
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Add New Role</h6>
              </div>

              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <form
                      id="addnewRoleForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col className="form-group" lg={12}>
                          <label htmlFor="name">Role Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            autoComplete="off"
                            placeholder="Name"
                            value={roleData.name}
                            onChange={(e) =>
                              setRoleData({ ...roleData, name: e.target.value })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col className="form-group" lg={12}>
                          <label htmlFor="slug">Slug</label>
                          <input
                            type="text"
                            className="form-control"
                            id="slug"
                            name="slug"
                            autoComplete="off"
                            placeholder="Slug Name"
                            value={roleData.slug}
                            onChange={(e) =>
                              setRoleData({ ...roleData, slug: e.target.value })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                            data-rule-slug={true}
                          />
                        </Col>

                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                          <Link className="btn btn-dark" to="/roles">
                            Cancel
                          </Link>
                        </Col>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddNewRolePage;
