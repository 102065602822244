import { postData } from "../service/Common";
import {
    GET_INVENTORY_DETAIL_INFO_REQUEST, GET_INVENTORY_DETAIL_INFO_SUCCESS, GET_INVENTORY_DETAIL_INFO_FAIL
    , GET_INVENTORY_OPTION_LIST_REQUEST, GET_INVENTORY_OPTION_LIST_SUCCESS_INFO, GET_INVENTORY_OPTION_LIST_FAIL_ERROR,
    GET_INVENTORY_DETAIL_LIST_BYID_REQUEST,GET_INVENTORY_DETAIL_LIST_BYID_SUCCESS_INFO,GET_INVENTORY_DETAIL_LIST_BYID_FAIL_ERROR,GET_BARCODE_DETAIL_REQUEST, GET_BARCODE_DETAIL_SUCCESS, GET_BARCODE_DETAIL_FAIL,
    GET_SELECTED_BARCODE_DETAIL_REQUEST,GET_SELECTED_BARCODE_DETAIL_SUCCESS,GET_SELECTED_BARCODE_DETAIL_FAIL
} from "../constants/InventoryConstants";
const get_inventory_detail_action = (invtID) => async (dispatch) => {
    dispatch({ type: GET_INVENTORY_DETAIL_INFO_REQUEST, payload: {} })
    postData(`/api/get_inventory_detail`, { id: invtID })
        .then((response) => {
            let data = response.data
            dispatch({ type: GET_INVENTORY_DETAIL_INFO_SUCCESS, payload: data })
        })
        .catch((error) => {
            dispatch({ type: GET_INVENTORY_DETAIL_INFO_FAIL, payload: error.data })
        })
}
const get_inventory_option_list_action = () => async (dispatch) => {
    dispatch({ type: GET_INVENTORY_OPTION_LIST_REQUEST, payload: {} })
    postData(`/api/get_inventories_option_list`, '', { method_type: 'GET' })
        .then((response) => {
            var data = response.data;
            dispatch({ type: GET_INVENTORY_OPTION_LIST_SUCCESS_INFO, payload: data });
        }).catch((error) => {
            dispatch({ type: GET_INVENTORY_OPTION_LIST_FAIL_ERROR, payload: error.data.message });
        })
}
const get_inventory_by_project_id_action = (type,id) => async (dispatch) => {
    dispatch({ type: GET_INVENTORY_DETAIL_LIST_BYID_REQUEST, payload: {} })
    postData(`/api/get_inventories_filter_by_id`,{filter_by:type,id:id})
        .then((response) => {
            var data = response.data;
            dispatch({ type: GET_INVENTORY_DETAIL_LIST_BYID_SUCCESS_INFO, payload: data });
        }).catch((error) => {
            dispatch({ type: GET_INVENTORY_DETAIL_LIST_BYID_FAIL_ERROR, payload: error.data.message });
        })
}
const get_barcode_action = (invtID) => async (dispatch) => {
    dispatch({ type: GET_BARCODE_DETAIL_REQUEST, payload: {} })
    postData(`/api/generate_bar_code`, { id: invtID })
        .then((response) => {
            let data = response.data
            dispatch({ type: GET_BARCODE_DETAIL_SUCCESS, payload: data })
        })
        .catch((error) => {
            dispatch({ type: GET_BARCODE_DETAIL_FAIL, payload: error.data })
        })
}

const get_barcode_of_selected_inventory_action = (selectedItem) => async (dispatch) => {
    dispatch({ type: GET_SELECTED_BARCODE_DETAIL_REQUEST, payload: {} })
    console.log('selectedItem',selectedItem)
    postData(`/api/generate_bar_code_of_selected_inventory`, { selectedItem: selectedItem })
        .then((response) => {
            let data = response.data
            dispatch({ type: GET_SELECTED_BARCODE_DETAIL_SUCCESS, payload: data })
        })
        .catch((error) => {
            dispatch({ type: GET_SELECTED_BARCODE_DETAIL_FAIL, payload: error.data })
        })
}
export { get_inventory_detail_action, get_inventory_option_list_action, get_inventory_by_project_id_action,get_barcode_action,get_barcode_of_selected_inventory_action };