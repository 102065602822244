import {
	USER_PROFILE_REQUEST,
	USER_PROFILE_SUCCESS_INFO,
	USER_PROFILE_FAIL_ERROR,
	GET_USER_DETAIL_REQUEST,
	GET_USER_DETAIL_SUCCESS,
	GET_USER_DETAIL_FAIL,
} from "../constants/UserContants";
import { USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY } from "../config/Constants";
import { SAVE_HEADER_USER_INFO } from "../constants/AuthContants";
import { postData } from "../service/Common";

const get_profile = () => async (dispatch) => {
	dispatch({ type: USER_PROFILE_REQUEST, payload: {} });
	postData("/api/get_profile", '')
		.then((response) => {
			var data = response.data;
			dispatch({ type: USER_PROFILE_SUCCESS_INFO, payload: data });
			let temp = {
				fullName: data.data.firstName + ' ' + data.data.lastName,
				email: data.data.email,
				phone: data.data.mobile,
				profile_image: data.data.image,
				role_id: data.data.role_id,
				role: data.data.role,
			};
			localStorage.setItem(USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY, JSON.stringify(temp));
			dispatch({ type: SAVE_HEADER_USER_INFO, payload: temp });
		})
		.catch((error) => {
			dispatch({ type: USER_PROFILE_FAIL_ERROR, payload: error.data });
		});
};

const get_user_detail = (Id) => async (dispatch) => {
	dispatch({ type: GET_USER_DETAIL_REQUEST, payload: {} });
	postData(`/api/get_user`, { id: Id })
		.then((response) => {
			dispatch({ type: GET_USER_DETAIL_SUCCESS, payload: response.data })
		})
		.catch((error) => {
			dispatch({ type: GET_USER_DETAIL_FAIL, payload: error.data })
		})
}

export { get_profile, get_user_detail };