import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import React, { useState } from "react";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
function LaneListPage() {
  const columns = [
    /*{
      Header: "ID",
      accessor: "id", // String-based value accessors!
      sortable: true
    },*/
    {
      Header: "Lane_ID",
      accessor: "_lane_id",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "ICD 2.5",
      accessor: "icd_two_point_five",
    },
    {
      Header: "ICD 2.4",
      accessor: "icd_two_point_four",
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (props) => (
        <span className="">
          {props.original.type === "1" ? (
            <span>Dedicated</span>
          ) : props.original.type === "2" ? (
            <span>Handheld</span>
          ) : props.original.type === "3" ? (
            <span>Hybrid</span>
          ) : (
            ""
          )}
        </span>
      ),
    },
    {
      Header: "Created On",
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created}</span>, // Custom cell components!
    },
    {
      Header: (props) => <span>Action</span>, // Custom header components!
      Cell: (props) => (
        <span className="">
          {/* <Link to={`lane/${props.original.id}/show`} className="btn btn-primary btn-icon btn-sm-my mr-2" title="Show Detail">
            <EditSvgIcon />
          </Link> */}
          <Link
            to={`/lane/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Edit Detail"
          >
            <EditSvgIcon />
          </Link>
          <button
            onClick={() => deleteHandller(props.original.id)}
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Remove User"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];

  const [pageno, setPageno] = useState(0);
  const [pages, setPages] = useState(0);
  const pageSise = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const search = "";
  const sortedBy = [];
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    setLoading(true);
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    postData("/api/get_lanes_list", req)
      .then((response) => {
        var res = response.data;
        setData(res.data);
        let tmp_pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmp_pages);
        setPageno(page);
        setLoading(false);
      })
      .catch((err) => {
        ToastMessage(err.data.message, "e");
        setLoading(false);
      });
  }, []);
  const deleteHandller = (iD) => {
    ConfirmationAlertBox("Are you sure want to remove it", {}).then((res) => {
      if (res.status) {
        postData(`/api/delete_lane`, { id: iD })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            fetchData({ pageSise, pageno, search, sortedBy });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/plazas">Plazas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Lane Management
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/lane/add"
        >
          <PlusSvgIcon className="mr-2" />
          Add New Lane
        </Link>
      </nav>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Lane List</h6>
              </div>
              <Table
                data={data}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSise}
                fetchData={fetchData}
                page={pageno}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default LaneListPage;
