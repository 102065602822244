export const GET_OFFICE_DETAIL_REQUEST = "GET_OFFICE_DETAIL_REQUEST";
export const GET_OFFICE_DETAIL_SUCCESS_INFO = "GET_OFFICE_DETAIL_SUCCESS_INFO";
export const GET_OFFICE_DETAIL_FAIL_ERROR = "GET_OFFICE_DETAIL_FAIL_ERROR";

export const GET_OFFICES_BY_PROJECT_ID_REQUEST = "GET_OFFICES_BY_PROJECT_ID_REQUEST";
export const GET_OFFICES_BY_PROJECT_ID_SUCCESS = "GET_OFFICES_BY_PROJECT_ID_SUCCESS";
export const GET_OFFICES_BY_PROJECT_ID_FAIL_ERROR = "GET_OFFICES_BY_PROJECT_ID_FAIL_ERROR";

export const GET_OFFICES_BY_PLAZA_ID_REQUEST = "GET_OFFICES_BY_PLAZA_ID_REQUEST";
export const GET_OFFICES_BY_PLAZA_ID_SUCCESS = "GET_OFFICES_BY_PLAZA_ID_SUCCESS";
export const GET_OFFICES_BY_PLAZA_ID_FAIL_ERROR = "GET_OFFICES_BY_PLAZA_ID_FAIL_ERROR";

export const GET_OFFICE_OPTION_LIST_REQUEST = "GET_OFFICE_OPTION_LIST_REQUEST";
export const GET_OFFICE_OPTION_LIST_SUCCESS = "GET_OFFICE_OPTION_LIST_SUCCESS";
export const GET_OFFICE_OPTION_LIST_FAIL_ERROR = "GET_OFFICE_OPTION_LIST_FAIL_ERROR";