import { combineReducers } from "redux";
import {
    GET_WAREHOUSE_DETAIL_REQUEST,
    GET_WAREHOUSE_DETAIL_SUCCESS_INFO,
    GET_WAREHOUSE_DETAIL_FAIL_ERROR,
    GET_WAREHOUSES_BY_PROJECT_ID_REQUEST,
    GET_WAREHOUSES_BY_PROJECT_ID_SUCCESS,
    GET_WAREHOUSES_BY_PROJECT_ID_FAIL_ERROR,
    GET_WAREHOUSES_BY_PLAZA_ID_REQUEST,
    GET_WAREHOUSES_BY_PLAZA_ID_SUCCESS,
    GET_WAREHOUSES_BY_PLAZA_ID_FAIL_ERROR,
    GET_WAREHOUSE_OPTION_LIST_REQUEST,
    GET_WAREHOUSE_OPTION_LIST_SUCCESS,
    GET_WAREHOUSE_OPTION_LIST_FAIL_ERROR,
} from "../constants/WarehouseConstants";
function GetWareHouseDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_WAREHOUSE_DETAIL_REQUEST:
            return { loading: true };
        case GET_WAREHOUSE_DETAIL_SUCCESS_INFO:
            return { loading: false, successwarehouseDetail: action.payload };
        case GET_WAREHOUSE_DETAIL_FAIL_ERROR:
            return { loading: false, failwarehouseDetail: action.payload }
        default:
            return state;
    }
}

function GetWarehouseByProjectIdReducer(state = [], action) {
    switch (action.type) {
        case GET_WAREHOUSES_BY_PROJECT_ID_REQUEST:
            return { loading: true };
        case GET_WAREHOUSES_BY_PROJECT_ID_SUCCESS:
            return { loading: false, successWarehouseByPorjectId: action.payload };
        case GET_WAREHOUSES_BY_PROJECT_ID_FAIL_ERROR:
            return { loading: false, failedWarehouseByPorjectId: action.payload }
        default:
            return state;
    }
}

function GetWarehouseByPlazaIdReducer(state = [], action) {
    switch (action.type) {
        case GET_WAREHOUSES_BY_PLAZA_ID_REQUEST:
            return { loading: true };
        case GET_WAREHOUSES_BY_PLAZA_ID_SUCCESS:
            return { loading: false, successWarehouseByPlazaId: action.payload };
        case GET_WAREHOUSES_BY_PLAZA_ID_FAIL_ERROR:
            return { loading: false, failedWarehouseByPlazaId: action.payload }
        default:
            return state;
    }
}

function GetWarehouseOptionListReducer(state = [], action) {
    switch (action.type) {
        case GET_WAREHOUSE_OPTION_LIST_REQUEST:
            return { loading: true };
        case GET_WAREHOUSE_OPTION_LIST_SUCCESS:
            return { loading: false, successWarehouseOptionList: action.payload };
        case GET_WAREHOUSE_OPTION_LIST_FAIL_ERROR:
            return { loading: false, failedWarehouseOptionList: action.payload }
        default:
            return state;
    }
}
export default combineReducers({
    GetWareHouseDetailReducer, 
    GetWarehouseByProjectIdReducer, 
    GetWarehouseByPlazaIdReducer,
    GetWarehouseOptionListReducer
});