import { Link } from "react-router-dom";
import { TrashSvgIcon, FiEye, IoIosArrowUp } from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import React, { useState } from "react";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";

function NotificationsList(props) {
  const columns = [
    {
      Header: "ID",
      accessor: "id", // String-based value accessors!
      sortable: true,
    },

    {
      Header: "Message",
      accessor: "value",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Status",
      accessor: "is_read",
      maxWidth: 70,
      Cell: (props) => (
        <div
          className={
            "font-w-6 label_sp " +
            (props.original.is_read == "1" ? "color2" : "color3")
          }
        >
          <div
            className={`badge ${
              props.original.is_read === 1 ? "badge-danger" : "badge-success"
            }`}
          >
            {props.original.is_read === 1 ? "Unread" : "Read"}
          </div>
        </div>
      ),
    },
    {
      Header: "Created On",
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created_at}</span>, // Custom cell components!
    },
    {
      Header: (props) => <span>Action</span>, // Custom header components!
      Cell: (props) => (
        <span className="">
          <button
            onClick={() => deleteHandller(props.original.idval)}
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Remove Notification"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const search = "";
  const sorted = [];

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    setLoading(true);
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    postData("/api/notification_list", req)
      .then((response) => {
        var res = response.data;
        setData(res.data);
        let pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(pages);
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        ToastMessage(err.data.message, "e");
        setLoading(false);
      });
  }, []);

  const deleteHandller = (id) => {
    ConfirmationAlertBox({
      message: "Are you sure want to remove it",
    }).then((res) => {
      if (res.status) {
        postData(`/api/delete_notification`, { id: id })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            fetchData({ pageSize, page, search, sorted });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };

  const readMarkHandler = (key) => {
    ConfirmationAlertBox({
      message: `Are you sure you want to ${
        key == 1 ? "read all" : "read"
      } notification(s)`,
    }).then((res) => {
      if (res.status) {
        postData(`/api/read_notification`, { key: key })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            fetchData({ pageSize, page, search, sorted });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Notification Management
          </li>
        </ol>
        <Link
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          //  to="/addnewmaster"
          onClick={() => {
            readMarkHandler(1);
          }}
        >
          <FiEye className="mr-2" />
          Mark all as Read
        </Link>
      </nav>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">
                  Notification Management List
                </h6>
              </div>
              <Table
                data={data}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSize}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sorted}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default NotificationsList;
