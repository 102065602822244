import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { get_company_detail_action } from "../../actions/CompanyActions";
import { get_person_option_list_action } from "../../actions/PersonActions";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/ReactTable";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import SelectDropdown from "../../components/ReactSelectPlus";
import { postData } from "../../service/Common";
import $ from "jquery";
import { ToastMessage } from "../../service/ToastMessage";

function ViewCompanyPage(props) {
  const [companyData, setCompanyData] = useState({
    id: "",
    name: "",
    url: "",
    contact_person: "",
    address: "",
    lat: "",
    long: "",
  });
  const [loading, setLoading] = useState(false);
  const [showAddContactDiv, setShowAddContactDiv] = useState(false);
  const [personOptionList, setPersonOptionList] = useState([]);
  const [companiesPersonListData, setCompaniesPersonListData] = useState([]);
  const page_size = 10;
  const search = "";
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [loadingTbl, setLoadingTbl] = useState(false);
  const sortedBy = "";
  const [addpersonincompanyForm, setAddpersonincompanyForm] = useState({
    person_id: null,
    company_id: props.match.params.cmpId,
    primary_contact: "",
  });
  const dispatch = useDispatch();
  const getCompantDetail = useSelector((state) => {
    return {
      company: state.CompanyReducers.GetCompanyDetailReducer,
      person: state.PersonReducers.GetPersonOptionListReducer,
    };
  });
  useEffect(() => {
    if (props.match.params.cmpId) {
      dispatch(get_company_detail_action(props.match.params.cmpId));
      dispatch(get_person_option_list_action());
    } else {
      props.history.push("/companied");
    }
  }, [dispatch, props]);

  useEffect(() => {
    if (getCompantDetail.company.successCompanyDetail) {
      setCompanyData(getCompantDetail.company.successCompanyDetail.data);
      setAddpersonincompanyForm({
        ...addpersonincompanyForm,
        company_id: getCompantDetail.company.successCompanyDetail.data.id,
      });
    }
    if (getCompantDetail.person.successPersonOptionList) {
      setPersonOptionList(getCompantDetail.person.successPersonOptionList.data);
    }
  }, [getCompantDetail.company]);
  const submitHandllerAddPersoninCompany = (e) => {
    e.preventDefault();
    if ($("#addpersonincompanyForm").valid()) {
      setLoading(true);
      postData(`/api/add_person_in_company`, addpersonincompanyForm)
        .then((result) => {
          let res = result.data;
          setLoading(false);
          ToastMessage(res.message, "s");
          fetchData({ pageSize: 10, page: 0, filter: "", sorted: [] });
          setShowAddContactDiv(false);
          setAddpersonincompanyForm({
            ...addpersonincompanyForm,
            person_id: null,
            primary_contact: "",
          });
        })
        .catch((error) => {
          setLoading(false);
          ToastMessage(error.data.message, "e");
        });
    }
  };
  const columns = [
    {
      Header: "PersonID",
      accessor: "_p_id",
    },
    {
      Header: "Name",
      accessor: "p_name",
    },
    {
      Header: "Email",
      accessor: "p_email",
    },
    {
      Header: "IsPrimary",
      accessor: "isPrimary",
      Cell: (props) => (
        <span>{props.original.isPrimary === "1" ? "Yes" : "No"}</span>
      ),
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created}</span>,
    },
    {
      Header: (props) => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <Link
            to={`/person/${props.original.person_id}/show`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Show Detail"
          >
            <FiEye />
          </Link>
          <Link
            to={`/person/${props.original.person_id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Update"
          >
            <EditSvgIcon />
          </Link>
          {/* <button onClick={() => deleteHandller(props.original.person_id)} type="button" className="btn btn-danger btn-icon btn-sm-my" title="Delete">
            <TrashSvgIcon />
          </button> */}
        </span>
      ),
      sortable: false,
    },
  ];
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
      company_id: props.match.params.cmpId,
    };
    setLoadingTbl(true);
    postData(`/api/get_company_linked_person_list`, req)
      .then((result) => {
        var res = result.data;
        setCompaniesPersonListData(res.data);
        let tmppages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmppages);
        setPage(page);
        setLoadingTbl(false);
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setLoadingTbl(false);
      });
  }, []);

  const SetPrimary = (e) => {
    return <></>;
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/companies`}>Company</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Company Detail
          </li>
        </ol>
        <Link
          type="button"
          className="btn btn-dark btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/companies"
        >
          Back
        </Link>
      </nav>
      <Row className="justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Company Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {getCompantDetail.company.loading ? (
                      <div className="text-center text-info form-control">
                        Loading...
                      </div>
                    ) : getCompantDetail.company.successCompanyDetail ? (
                      <>
                        <div className="row">
                          <Col className="form-group" md={3}>
                            <label>Company Id</label>
                            <div className="form-control">
                              {companyData._company_id}
                            </div>
                          </Col>
                          <Col className="form-group" md={3}>
                            <label>Name</label>
                            <div className="form-control">
                              {companyData.name}
                            </div>
                          </Col>
                          <Col className="form-group" md={3}>
                            <label>Url</label>
                            <div className="form-control">
                              {companyData.url}
                            </div>
                          </Col>
                          <Col className="form-group" md={3}>
                            <label>Address</label>
                            <div className="form-control">
                              {companyData.address}
                            </div>
                          </Col>
                          <Col className="form-group" md={3}>
                            <label>Latitude, Longitude</label>
                            <div className="form-control">
                              {companyData.lat + " " + companyData.long}
                            </div>
                          </Col>
                          <Col className="form-group" md={3}>
                            <label>Created</label>
                            <div className="form-control">
                              {companyData.created}
                            </div>
                          </Col>
                        </div>
                        {/* <div className="row mt-2">
                        <Col md={12}>
                          <button
                            type="button"
                            onClick={() =>
                              setShowAddContactDiv(
                                showAddContactDiv ? false : true
                              )
                            }
                            className="btn btn-info"
                          >
                            Add person in company
                          </button>
                          <Link
                            to={`/person/addnew_person`}
                            className="btn btn-info"
                          >
                            Add new person
                          </Link>
                        </Col>
                        {showAddContactDiv ? (
                          <div className="row">
                            <Col md={12}>
                              <form
                                id="addpersonincompanyForm"
                                name="addpersonincompanyForm"
                                method="POST"
                              >
                                <div className="form-group">
                                  <label htmlFor="person_id"></label>
                                  <div className="cstm_select">
                                    <SelectDropdown
                                      id="person_id1"
                                      name="person_id1"
                                      options={personOptionList}
                                      required={true}
                                      joinValues={true}
                                      className="form-"
                                      autoComplete="off"
                                      placeholder="Select the person"
                                      onChange={(e) =>
                                        setAddpersonincompanyForm({
                                          ...addpersonincompanyForm,
                                          person_id:
                                            e !== null ? e.value : null,
                                        })
                                      }
                                      value={addpersonincompanyForm.person_id}
                                      inputRenderer={(props) => (
                                        <input
                                          type="text"
                                          {...props}
                                          name="person_id"
                                          data-msg-required="Please select the company."
                                        />
                                      )}
                                    />
                                  </div>
                                  <label
                                    className="error"
                                    htmlFor="person_id"
                                  ></label>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="primary_contact">
                                    <input
                                      type="checkbox"
                                      id="primary_contact"
                                      name="primary_contact"
                                      className="form-controlx"
                                      onChange={(e) =>
                                        setAddpersonincompanyForm({
                                          ...addpersonincompanyForm,
                                          primary_contact: e.target.value,
                                        })
                                      }
                                      value={1}
                                    />{" "}
                                    Set Primary Contact
                                  </label>
                                </div>
                                <div className="form-group">
                                  <buttn
                                    type="submit"
                                    onClick={submitHandllerAddPersoninCompany}
                                    className="btn btn-info"
                                    disabled={loading ? true : false}
                                  >
                                    {loading ? "Loading" : "Save"}
                                  </buttn>
                                </div>
                              </form>
                            </Col>
                          </div>
                        ) : (
                          ""
                        )}
                      </div> */}
                      </>
                    ) : getCompantDetail.company.failedCompanyDetail ? (
                      <div className="text-center">
                        <div className="text-center text-danger form-control">
                          {getCompantDetail.company.failedCompanyDetail}
                        </div>
                        <Link
                          to={`/person/addnew_person`}
                          className="btn btn-info mt-2"
                        >
                          Add new person
                        </Link>
                        <Link
                          to={"/companies"}
                          className="btn btn-dark mt-2 ml-1"
                        >
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                {props.match.params.cmpId ? (
                  <Row className="">
                    <Col lg={12} className="grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                            <h6 className="card-title mb-0">
                              {" "}
                              Companies Person List
                            </h6>

                            <Link
                              type="button"
                              className="btn btn-primary btn-icon-text btn-sm mb-2 align-self-center"
                              to={`/person/addnew_person`}
                            >
                              <PlusSvgIcon className="mr-2" />
                              Add new person
                            </Link>
                          </div>

                          <div className="row">
                            <form
                              id="addpersonincompanyForm"
                              name="addpersonincompanyForm"
                              method="POST"
                              className="w-100"
                            >
                              <Col md={12}>
                                <div className="row">
                                  <div className="form-group col-md-3">
                                    <div className="cstm_select">
                                      <SelectDropdown
                                        id="person_id1"
                                        name="person_id1"
                                        options={personOptionList}
                                        required={true}
                                        joinValues={true}
                                        className="form-"
                                        autoComplete="off"
                                        placeholder="Select the person"
                                        onChange={(e) =>
                                          setAddpersonincompanyForm({
                                            ...addpersonincompanyForm,
                                            person_id:
                                              e !== null ? e.value : null,
                                          })
                                        }
                                        value={addpersonincompanyForm.person_id}
                                        inputRenderer={(props) => (
                                          <input
                                            type="text"
                                            {...props}
                                            name="person_id"
                                            data-msg-required="Please select the company."
                                          />
                                        )}
                                      />
                                    </div>
                                    <label
                                      className="error select_error_span"
                                      htmlFor="person_id"
                                    ></label>
                                  </div>
                                  <div className="form-group col-md-9 align-self-center">
                                    <div className="d-flex">
                                      <div className="d-flex align-self-center">
                                        <label
                                          htmlFor="primary_contact"
                                          className="mb-0 mr-2"
                                        >
                                          <div>
                                            <input
                                              type="checkbox"
                                              id="primary_contact"
                                              required={true}
                                              name="primary_contact"
                                              className="form-controlx"
                                              onChange={(e) =>
                                                setAddpersonincompanyForm({
                                                  ...addpersonincompanyForm,
                                                  primary_contact:
                                                    e.target.value,
                                                })
                                              }
                                              value={1}
                                            />{" "}
                                            Set Primary Contact
                                          </div>
                                        </label>
                                        <label
                                          className="error mr-2"
                                          htmlFor="primary_contact"
                                        ></label>
                                      </div>

                                      <buttn
                                        type="submit"
                                        onClick={
                                          submitHandllerAddPersoninCompany
                                        }
                                        className="btn btn-primary"
                                        disabled={loading ? true : false}
                                      >
                                        {loading ? "Loading" : "Save"}
                                      </buttn>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </form>
                          </div>

                          <Table
                            data={companiesPersonListData}
                            columns={columns}
                            loading={loadingTbl}
                            defaultPageSize={page_size}
                            fetchData={fetchData}
                            page={page}
                            pages={pages}
                            sorted={sortedBy}
                            filter={search}
                            showPagination={true}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default ViewCompanyPage;
