import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../service/Common";
import { ToastMessage } from "../service/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { get_permission } from "../actions/PermissionActions";

function AddNewPermissionsPage(props) {
  const get_permission_success = useSelector(
    (state) => state.GetPermissionReducer
  );
  const dispatch = useDispatch();
  const [permissionData, setpermissionData] = useState({ name: "", slug: "" });
  const [loading, setLoading] = useState(false);
  const resetForm = () => {
    setpermissionData({ name: "", slug: "" });
  };
  useEffect(() => {
    if (props.match.params.pId) {
      dispatch(get_permission(props.match.params.pId));
    }
  }, [props.match.params.pId, dispatch]);

  useEffect(() => {
    if (get_permission_success.getSinglePermission) {
      if (props.match.params.pId) {
        setpermissionData(get_permission_success.getSinglePermission.data);
      }
      setLoading(get_permission_success.loading);
    }
    if (get_permission_success.failedSinglePermission) {
      ToastMessage(get_permission_success.failedSinglePermission.message, "e");
      setLoading(get_permission_success.loading);
    }
  }, [get_permission_success, props]);

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewPermissionForm").valid()) {
      setLoading(true);
      let url = props.match.params.pId
        ? `/api/update_permission`
        : `/api/addnew_permission`;
      let req = props.match.params.pId
        ? { ...permissionData, id: props.match.params.pId }
        : permissionData;
      postData(url, req)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          if (props.match.params.pId) {
            dispatch(get_permission(props.match.params.pId));
          } else {
            resetForm();
          }
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/permissions">Permission</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {props.match.params.pId ? "Edit Permission" : "Add New Permission"}
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">
                  {props.match.params.pId
                    ? "Edit Permission"
                    : "Add New Permission"}
                </h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={5}>
                    <form
                      id="addnewPermissionForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col lg={12} className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            autoComplete="off"
                            placeholder="Name"
                            value={permissionData.name}
                            onChange={(e) =>
                              setpermissionData({
                                ...permissionData,
                                name: e.target.value,
                              })
                            }
                            minLength={3}
                            maxLength={180}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col lg={12} className="form-group">
                          <label htmlFor="slug">Slug Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="slug"
                            name="slug"
                            autoComplete="off"
                            placeholder="Slug "
                            value={permissionData.slug}
                            onChange={(e) =>
                              setpermissionData({
                                ...permissionData,
                                slug: e.target.value,
                              })
                            }
                            minLength={6}
                            maxLength={180}
                            data-rule-required={true}
                            data-rule-slug={true}
                          />
                        </Col>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading
                              ? "Loading..."
                              : props.match.params.pId
                              ? "Update"
                              : "Submit"}
                          </button>
                          <Link className="btn btn-dark" to="/permissions">
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddNewPermissionsPage;
