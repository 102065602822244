import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditSvgIcon, FiEye, PlusSvgIcon } from "../../components/SvgIcon";
import { get_plaza_detail_action } from "../../actions/PlazaActions";
import { get_Lane_by_plaza_id_action } from "../../actions/LaneActions";
import { get_office_by_plaza_id_action } from "../../actions/OfficeActions";
import { get_warehouse_by_plaza_id_action } from "../../actions/WarehouseActions";
import { get_inventory_by_project_id_action } from "../../actions/InventoryActions";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Map from "../../components/MapShow/Map";

const ShowPlazaPage = (props) => {
  const get_plazaInfo = useSelector((state) => {
    return {
      plaza: state.PlazaReducers.GetPlazaDetailReducer,
      // LaneoptionList: state.LaneReducers.GetLaneOptionListReducer.successLaneOptionList,
      lane: state.LaneReducers.GetLaneByPlazaIdReducer,
      office: state.OfficeReducers.GetOfficeByPlazaIdReducer,
      warehouse: state.WarehouseReducers.GetWarehouseByPlazaIdReducer,
      inventory: state.InventoryReducers.GetInventoryListByFilterIdReducer,
    };
  });
  const dispatch = useDispatch();
  const [plazaData, setPlazaData] = useState({});
  const [activeTab, setActiveTab] = useState("lane");
  const [laneListData, setLaneListData] = useState({ loading: false });
  const [officeListData, setOfficeListData] = useState({ loading: false });
  const [warehouseListData, setWarehouseListData] = useState({
    loading: false,
  });
  const [inventoriesListData, setInventoriesListData] = useState({});
  // const [lane_option_list, setLane_option_list] = useState([]);

  useEffect(() => {
    if (props.match.params.pID) {
      dispatch(get_plaza_detail_action(props.match.params.pID));
      dispatch(get_Lane_by_plaza_id_action(props.match.params.pID));
    }
  }, [props.match.params.pID, dispatch]);

  useEffect(() => {
    if (get_plazaInfo.plaza.successPlazaDetail) {
      setPlazaData(get_plazaInfo.plaza.successPlazaDetail.data);
    }
    if (get_plazaInfo.lane) {
      setLaneListData(get_plazaInfo.lane);
    }
    // if (get_plazaInfo.LaneoptionList) {
    //     setLane_option_list(get_plazaInfo.LaneoptionList.data);
    // }
    if (get_plazaInfo.office) {
      setOfficeListData(get_plazaInfo.office);
    }
    if (get_plazaInfo.warehouse) {
      setWarehouseListData(get_plazaInfo.warehouse);
    }
    if (get_plazaInfo.inventory) {
      setInventoriesListData(get_plazaInfo.inventory);
    }
  }, [get_plazaInfo, setPlazaData]);

  const ShowLaneList = (prop) => {
    let listData = prop.data.lane;
    return (
      <div className="mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>ICD 2.5</th>
              <th>ICD 2.4</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._lane_id}</td>
                  <td>{val.name}</td>
                  <td>{val.icd_two_point_five}</td>
                  <td>{val.icd_two_point_four}</td>
                  <td>
                    {val.type === "1" ? (
                      <span>Dedicated</span>
                    ) : val.type === "2" ? (
                      <span>Handheld</span>
                    ) : val.type === "3" ? (
                      <span>Hybrid</span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <Link
                      to={`/lane/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const ShowOfficeList = (prop) => {
    let listData = prop.data.office;
    return (
      <div className=" mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Incharge Name</th>
              <th>Incharge Contact</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._office_id}</td>
                  <td>{val.name}</td>
                  <td>{val.incharge_name}</td>
                  <td>{val.incharge_contact_no}</td>
                  <td>
                    <Link
                      to={`/office/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/office/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const ShowWareHouseList = (prop) => {
    let wlistData = prop.data.warehouse;
    return (
      <div className="mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Incharge Name</th>
              <th>Incharge Contact</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {wlistData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._warehouse_id}</td>
                  <td>{val.name}</td>
                  <td>{val.incharge_name}</td>
                  <td>{val.incharge_contact_no}</td>
                  <td>
                    <Link
                      to={`/warehouse/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/warehouse/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const ShowInventoryList = (prop) => {
    let invtlistData = prop.data;
    return (
      <div className=" mt-2">
        <table className="static_custom_table_">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Serial Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invtlistData.map((val, i) => {
              return (
                <tr key={i}>
                  <td>{val._inventory_id}</td>
                  <td>{val.name}</td>
                  <td>{val.serial_number}</td>
                  <td>
                    <Link
                      to={`/inventory_management/${val.id}/show`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Show Detail"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/inventory_management/${val.id}/edit`}
                      className="btn btn-primary btn-icon btn-sm-my mr-2"
                      title="Edit"
                    >
                      <EditSvgIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            {plazaData.projectId ? (
              <Link to={`/project_management/${plazaData.projectId}/show`}>
                Projects
              </Link>
            ) : (
              <Link to={`/project_management`}>Projects</Link>
            )}
          </li>
          <li className="breadcrumb-item">
            <Link to="/plazas">Plazas</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Show Plaza Detail
          </li>
        </ol>
        <div>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mr-2 mb-md-0 align-self-center"
            to={`/plaza/${plazaData.id}/addnew_lane`}
          >
            <PlusSvgIcon className="mr-2" />
            Add Lane
          </Link>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mr-2 mb-md-0 align-self-center"
            to={`/office/plaza/${plazaData.id}/addnew_office`}
          >
            <PlusSvgIcon className="mr-2" />
            Add Office
          </Link>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mr-2 mb-md-0 align-self-center"
            to={`/warehouse/plaza/${plazaData.id}/addnew_warehouse`}
          >
            <PlusSvgIcon className="mr-2" />
            Add Warehouse
          </Link>
          <Link
            type="button"
            className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
            to={`/inventory_management/plaza/${plazaData.id}/add_inventory`}
          >
            <PlusSvgIcon className="mr-2" />
            Add New Inventory
          </Link>
        </div>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Show Plaza Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {get_plazaInfo.plaza.loading ? (
                      <div className="text-center text-info form-control">
                        <strong>Loading...</strong>
                      </div>
                    ) : get_plazaInfo.plaza.successPlazaDetail ? (
                      <Row>
                        <Col md={3}>
                          <div className="form-group">
                            <label>ID:</label>
                            <div className="form-control">{plazaData.id}</div>
                          </div>
                          <div className="form-group">
                            <label>Project Name :</label>
                            <div className="form-control input_inner_box">
                              <Link
                                className="input_inner_btn"
                                to={`/project_management/${plazaData.projectId}/show`}
                              >
                                {plazaData.projectName}
                              </Link>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Name :</label>
                            <div className="form-control">{plazaData.name}</div>
                          </div>
                          <div className="form-group">
                            <label>Contact :</label>
                            <div className="form-control">
                              {plazaData.plazaContactNumber}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Type :</label>
                            <div className="form-control">
                              {plazaData.plazaType === "1"
                                ? "National"
                                : "State"}
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="form-group">
                            <label>Agency Code :</label>
                            <div className="form-control">
                              {plazaData.agencyCode}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Latitude, Longitude :</label>
                            <div className="form-control">
                              {plazaData.latitude}, {plazaData.longitude}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Created On :</label>
                            <div className="form-control">
                              {plazaData.created}
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Address :</label>
                            <div className="form-control">
                              {plazaData.address}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>City :</label>
                            <div className="form-control">{plazaData.city}</div>
                          </div>
                        </Col>
                        <Col md={3}>
                        <div className="form-group">
                            <label>NPCI Plaza Id :</label>
                            <div className="form-control">
                              {plazaData.npci_paza_id}
                            </div>
                          </div>

                          <div className="form-group">
                            <label>State :</label>
                            <div className="form-control">
                              {plazaData.state}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Zip Code :</label>
                            <div className="form-control">
                              {plazaData.zipCode}
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Incharge Name :</label>
                            <div className="form-control">
                              {plazaData.plazaIncharge}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Incharge Contact :</label>
                            <div className="form-control">
                              {plazaData.plazaInchargeContactNumber}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Status :</label>
                            <div className="form-control">
                              <span
                                className={`badge ${
                                  plazaData.status === "1"
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {plazaData.status === "1"
                                  ? "Active"
                                  : "Deactive"}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <Map
                            center={[
                              Number(plazaData.latitude),
                              Number(plazaData.longitude),
                            ]}
                            zoom={8}
                            infoboxesWithPushPins={
                              plazaData.latitude
                                ? [
                                    {
                                      location: [
                                        Number(plazaData.latitude),
                                        Number(plazaData.longitude),
                                      ],
                                      addHandler: "mouseover", //on mouseover the pushpin, infobox shown
                                      infoboxOption: {
                                        title: plazaData.name,
                                        description:
                                          (plazaData.address
                                            ? plazaData.address
                                            : "") +
                                          (plazaData.city !== null
                                            ? ", " + plazaData.city
                                            : "") +
                                          (plazaData.state !== null
                                            ? ", " + plazaData.state
                                            : "") +
                                          (plazaData.zipCode !== null
                                            ? ", " + plazaData.zipCode
                                            : ""),
                                      },
                                      pushPinOption: {
                                        title: plazaData.name,
                                        description:
                                          (plazaData.address
                                            ? plazaData.address
                                            : "") +
                                          (plazaData.city !== null
                                            ? ", " + plazaData.city
                                            : "") +
                                          (plazaData.state !== null
                                            ? ", " + plazaData.state
                                            : "") +
                                          (plazaData.zipCode !== null
                                            ? ", " + plazaData.zipCode
                                            : ""),
                                      },
                                      infoboxAddHandler: {
                                        type: "click",
                                        callback: () =>
                                          (window.location.href = `/plaza/${plazaData.id}/show`),
                                      },
                                    },
                                  ]
                                : []
                            }
                          />
                        </Col>
                        <Col md={12}>
                          <div className="text-left">
                            {plazaData.id !== undefined ? (
                              <Link
                                to={`/assign/new/plaza/${plazaData.id}`}
                                className="btn btn-primary mr-2"
                              >
                                Assign
                              </Link>
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/plaza/${plazaData.id}/edit`}
                              className="btn btn-primary mr-2"
                            >
                              Edit
                            </Link>
                            <Link to={`/plazas`} className="btn btn-dark mr-2">
                              Back
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    ) : get_plazaInfo.plaza.failedPlazaDetail ? (
                      <div className="text-center">
                        <div className="text-danger text-center form-control">
                          <strong>
                            {get_plazaInfo.plaza.failedPlazaDetail}
                          </strong>
                        </div>
                        <Link to={`/plazas`} className="btn btn-dark mt-2">
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {plazaData.id !== undefined ? (
        <Row>
          <Col lg={12} className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs nav_tabs_custom">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "lane" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#home"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("lane");
                      }}
                    >
                      Lane
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "office" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu1"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("office");
                        dispatch(
                          get_office_by_plaza_id_action(props.match.params.pID)
                        );
                      }}
                    >
                      Office
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "warehouse" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu2"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("warehouse");
                        dispatch(
                          get_warehouse_by_plaza_id_action(
                            props.match.params.pID
                          )
                        );
                      }}
                    >
                      WareHouse
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "inventory" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#menu3"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("inventory");
                        dispatch(
                          get_inventory_by_project_id_action(
                            "plaza",
                            props.match.params.pID
                          )
                        );
                      }}
                    >
                      Inventory
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className={`tab-pane ${
                      activeTab === "lane" ? "active" : "fade"
                    }`}
                    id="lane"
                  >
                    {laneListData.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : laneListData.successLaneByPlazaId ? (
                      <ShowLaneList
                        data={laneListData.successLaneByPlazaId.data}
                      />
                    ) : laneListData.failedLaneByPlazaId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-info mt-3 form-control">
                            {laneListData.failedLaneByPlazaId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "office" ? "active" : "fade"
                    }`}
                    id="office"
                  >
                    {officeListData.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : officeListData.successOfficeByPlazaId ? (
                      <ShowOfficeList
                        data={officeListData.successOfficeByPlazaId.data}
                      />
                    ) : officeListData.failedOfficeByPlazaId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-info mt-3 form-control">
                            {officeListData.failedOfficeByPlazaId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "warehouse" ? "active" : "fade"
                    }`}
                    id="warehouse"
                  >
                    {warehouseListData.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : warehouseListData.successWarehouseByPlazaId ? (
                      <ShowWareHouseList
                        data={warehouseListData.successWarehouseByPlazaId.data}
                      />
                    ) : warehouseListData.failedWarehouseByPlazaId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-info mt-3 form-control">
                            {warehouseListData.failedWarehouseByPlazaId.message}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === "inventory" ? "active" : "fade"
                    }`}
                    id="inventory"
                  >
                    {inventoriesListData.loading ? (
                      <h3 className="text-center text-warning mt-3 form-control">
                        Loading...
                      </h3>
                    ) : inventoriesListData.successInventoryByprojectId ? (
                      <ShowInventoryList
                        data={
                          inventoriesListData.successInventoryByprojectId.data
                        }
                      />
                    ) : inventoriesListData.failedInventoryByprojectId ? (
                      <Row>
                        <Col md={12}>
                          <h6 className="text-info mt-3 form-control">
                            {inventoriesListData.failedInventoryByprojectId}
                          </h6>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
export default ShowPlazaPage;
