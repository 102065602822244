import {
	GET_PROJECT_DETAIL_INFO_REQUEST,
	GET_PROJECT_DETAIL_INFO_SUCCESS,
	GET_PROJECT_DETAIL_INFO_FAIL_ERROR,
	GET_PROJECT_OPTION_LIST_REQUEST,
	GET_PROJECT_OPTION_LIST_SUCCESS,
	GET_PROJECT_OPTION_LIST_FAIL_ERROR,
	GET_UN_ASSIGNED_OPTION_LIST_REQUEST,
	GET_UN_ASSIGNED_OPTION_LIST_SUCCESS,
	GET_UN_ASSIGNED_OPTION_LIST_FAIL_ERROR
} from "../constants/ProjectContants";
import { postData } from "../service/Common";

const get_project_detail_action = (id) => async (dispatch) => {
	dispatch({ type: GET_PROJECT_DETAIL_INFO_REQUEST, payload: {} });
	postData("/api/get_project_detail", { id: id })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_PROJECT_DETAIL_INFO_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_PROJECT_DETAIL_INFO_FAIL_ERROR, payload: error.data });
		});
};

const get_project_option_list_action = (id) => async (dispatch) => {
	dispatch({ type: GET_PROJECT_OPTION_LIST_REQUEST, payload: {} });
	postData("/api/get_project_option_list", '', { method_type: 'get' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_PROJECT_OPTION_LIST_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_PROJECT_OPTION_LIST_FAIL_ERROR, payload: error.data });
		});
};

const get_unassigned_option_list_action = () => async (dispatch) => {
	dispatch({ type: GET_UN_ASSIGNED_OPTION_LIST_REQUEST, payload: {} });
	postData("/api/get_unassigned_option_list", '', { method_type: 'get' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_UN_ASSIGNED_OPTION_LIST_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_UN_ASSIGNED_OPTION_LIST_FAIL_ERROR, payload: error.data });
		});
};
export { get_project_detail_action, get_project_option_list_action, get_unassigned_option_list_action };