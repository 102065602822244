import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AutocompleteField from "../components/AutocompleteField";
const TestPage = () => {
  const [dataTest, setDataTest] = useState({ name: "", address: "" });

  const setLocationHandller = (obj) => {
    if (obj) {
      let areaV,
        cityV,
        districtV,
        stateV,
        countryV,
        postal_codeV = "";
      for (let x = 0; x < obj.address_components.length; x++) {
        if (obj.address_components[x].types.includes("sublocality_level_1")) {
          areaV = obj.address_components[x].long_name;
        } else if (obj.address_components[x].types.includes("locality")) {
          cityV = obj.address_components[x].long_name;
        } else if (
          obj.address_components[x].types.includes(
            "administrative_areav_level_1"
          )
        ) {
          stateV = obj.address_components[x].short_name;
        } else if (
          obj.address_components[x].types.includes(
            "administrative_area_level_2"
          )
        ) {
          districtV = obj.address_components[x].long_name;
        } else if (obj.address_components[x].types.includes("country")) {
          countryV = obj.address_components[x].long_name;
        } else if (obj.address_components[x].types.includes("postal_code")) {
          postal_codeV = obj.address_components[x].long_name;
        } else {
        }
      }
      return {
        area: areaV,
        city: cityV,
        district: districtV,
        state: stateV,
        country: countryV,
        postal_code: postal_codeV,
        address: obj.formatted_address,
      };
    }
  };
  return (
    <>
      <form id="testform" name="testform" method="post">
        <Row>
          <Col md={6}>
            <label>Name</label>
            <input
              type="text"
              name="test"
              className="form-control"
              onChange={(e) =>
                setDataTest({ ...dataTest, name: e.target.value })
              }
              value={dataTest.name}
            />
          </Col>
          <Col md={6}>
            <label>Addresss</label>
            <AutocompleteField
              className="form-control"
              id="Address"
              name="address"
              placeholder="Address"
              data-rule-required={true}
              onPlaceSelected={(place) => {
                //let stemp = setLocationHandller(place);
              }}
            ></AutocompleteField>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default TestPage;
