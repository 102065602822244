import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import { getHwRequestStatus } from "./ViewRequestPage";
const AllRequestPage = (props) => {
  const pageSize = 10;
  const search = "";
  const sortBy = [];
  const [requestData, setRequestData] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const deleteHandller = (Id) => {
    ConfirmationAlertBox(`Are you sure want to remove?`, {}).then((result) => {
      if (result.status) {
        postData(`/api/delete_hardware_request`, { id: Id })
          .then((response) => {
            let res = response.data;
            ToastMessage(res.message, "s");
            fetchData({ pageSize, page, search, sortBy });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_hwr_id",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "With",
        accessor: "type_with",
        Cell: (prop) => (
          <span>
            {prop.original.type_with === "office"
              ? "Office"
              : prop.original.type_with === "plaza"
              ? "Plaza"
              : prop.original.type_with === "warehouse"
              ? "Warehouse"
              : prop.original.type_with === "project"
              ? "Project"
              : ""}
          </span>
        ),
      },
      {
        Header: "Status",
        Cell: (prop) => getHwRequestStatus(prop.original.status),
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Action",
        sortable: false,
        Cell: (prop) => (
          <span className="">
            <Link
              to={`request/${prop.original.id}/show`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
              title="Show Detail"
            >
              <FiEye />
            </Link>
            <Link
              to={`request/${prop.original.id}/edit`}
              className="btn btn-primary btn-icon btn-sm-my mr-2"
              title="Edit Detail"
            >
              <EditSvgIcon />
            </Link>
            <button
              onClick={() => deleteHandller(prop.original.id)}
              type="button"
              className="btn btn-danger btn-icon btn-sm-my"
              title="Remove User"
            >
              <TrashSvgIcon />
            </button>
          </span>
        ),
      },
    ],
    []
  );

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    setLoading(true);
    postData(`/api/get_list_hardware_request`, req)
      .then((response) => {
        let res = response.data;
        setLoading(false);
        setRequestData(res.data);
        let tmp = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmp);
        setPage(page);
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Hardware Requests
          </li>
        </ol>
        <Link
          to={`/request/hardware_request`}
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
        >
          <PlusSvgIcon className="mr-2" />
          Add New Requset
        </Link>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">All Hardware Requests</h6>
              </div>
              <Table
                data={requestData}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSize}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortBy}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AllRequestPage;
