import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import SelectDropdown from "../../components/ReactSelectPlus";
import { useEffect, useState } from "react";
import $ from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_project_option_list_action } from "../../actions/ProjectActions";
import { get_office_option_list_action } from "../../actions/OfficeActions";
import { get_plaza_option_list_action } from "../../actions/PlazaActions";
import { get_warehouse_option_list_action } from "../../actions/WarehouseActions";
import { get_product_option_list_action } from "../../actions/ProductActions";
import { useSelector, useDispatch } from "react-redux";
import { GrAddCircle, FiMinusCircle } from "../../components/SvgIcon";
const IMAGE_SIZE_ACCEPT = 25304060;
const IMAGE_TYPE_ACCEPT = ["image/jpg", "image/jpeg", "image/png"];

var typeOptions = [
  { value: "new", label: "New" },
  { value: "repair", label: "Repair" },
  { value: "replace", label: "Replace" },
];

function AddHardwareRequest(props) {
  const [hardwareData, setHardwareData] = useState({
    title: "",
    type_with: "",
    plaza_id: null,
    items: [{ item_name: "", quantity: "", type: null, image_uploads: [] }],
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [projectOptionList, setProjectOptionList] = useState([]);
  const [plazaOptionList, setPlazaOptionList] = useState([]);
  const [officeOptionList, setOfficeOptionList] = useState([]);
  const [whouseOptionList, setWhouseOptionList] = useState([]);
  const [productOptionList, setProductOptionList] = useState([]);

  const dispatch = useDispatch();
  const getopionsData = useSelector((state) => {
    return {
      project: state.ProjectReducers.GetProjectOptionListReducer,
      office: state.OfficeReducers.GetOfficeOptionListReducer,
      plaza: state.PlazaReducers.GetPlazaOptionListReducer,
      warehousse: state.WarehouseReducers.GetWarehouseOptionListReducer,
    };
  });
  const getProdOptionsList = useSelector(
    (state) =>
      state.ProductReducers.GetProductOptionListReducer.successProductOptionList
  );

  useEffect(() => {
    dispatch(get_project_option_list_action());
    dispatch(get_office_option_list_action());
    dispatch(get_plaza_option_list_action());
    dispatch(get_warehouse_option_list_action());
    dispatch(get_product_option_list_action(0));
  }, [dispatch]);

  useEffect(() => {
    if (getopionsData.project.successProjectOptionList) {
      setProjectOptionList(getopionsData.project.successProjectOptionList.data);
    }
    if (getopionsData.plaza.successPlazaOptionList) {
      setPlazaOptionList(getopionsData.plaza.successPlazaOptionList.data);
    }
    if (getopionsData.office.successOfficeOptionList) {
      setOfficeOptionList(getopionsData.office.successOfficeOptionList.data);
    }
    if (getopionsData.warehousse.successWarehouseOptionList) {
      setWhouseOptionList(
        getopionsData.warehousse.successWarehouseOptionList.data
      );
    }
    if (getProdOptionsList && typeof getProdOptionsList.data !== undefined) {
      setProductOptionList(getProdOptionsList ? getProdOptionsList.data : []);
    }
  }, [getopionsData]);
  const formReset = () => {
    setHardwareData({
      title: "",
      type_with: "",
      plaza_id: null,
      //items: [{ item_name: "", quantity: "", type: null, image_uploads: [] }],
      description: "",
    });
  };
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addhardwareRequestForm").valid()) {
      setLoading(true);
      postData(`/api/addnew_hardware_request`, hardwareData)
        .then((result) => {
          setLoading(false);
          ToastMessage(result.data.message, "s");
          formReset();
          props.history.push("/requests");
        })
        .catch((error) => {
          setLoading(false);
          // ToastMessage(error.data.message, "e");
        });
    }
  };

  function getswitchfields(typewith) {
    switch (typewith) {
      case "project":
        return (
          <>
            <label htmlFor="plaza">Project</label>
            <div className="cstm_select">
              <SelectDropdown
                id="projectid1"
                name="projectid1"
                options={projectOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select the prject"
                className={"w-100"}
                onChange={(e) =>
                  setHardwareData({
                    ...hardwareData,
                    project_id: e !== null ? e.value : null,
                  })
                }
                value={hardwareData.project_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="project_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="project_id"
              ></label>
            </div>
          </>
        );
      case "plaza":
        return (
          <>
            <label htmlFor="plaza">Plaza</label>
            <div className="cstm_select">
              <SelectDropdown
                id="plazaid1"
                name="plazaid1"
                options={plazaOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select the plaza"
                className={"w-100"}
                onChange={(e) =>
                  setHardwareData({
                    ...hardwareData,
                    plaza_id: e !== null ? e.value : null,
                  })
                }
                value={hardwareData.plaza_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="plaza_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="plaza_id"
              ></label>
            </div>
          </>
        );
      case "office":
        return (
          <>
            <label htmlFor="office1">Office</label>
            <div className="cstm_select">
              <SelectDropdown
                id="office1"
                name="office1"
                options={officeOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select the office"
                className={"w-100"}
                onChange={(e) =>
                  setHardwareData({
                    ...hardwareData,
                    office_id: e !== null ? e.value : null,
                  })
                }
                value={hardwareData.office_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="office_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="office_id"
              ></label>
            </div>
          </>
        );
      case "warehouse":
        return (
          <>
            <label htmlFor="wh_id">Warehouse</label>
            <div className="cstm_select">
              <SelectDropdown
                id="wh_id1"
                name="wh_id1"
                options={whouseOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select the warehouse"
                className={"w-100"}
                onChange={(e) =>
                  setHardwareData({
                    ...hardwareData,
                    wh_id: e !== null ? e.value : null,
                  })
                }
                value={hardwareData.wh_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="wh_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="wh_id"
              ></label>
            </div>
          </>
        );
      default:
        break;
    }
  }
  const fileHandller = (e, index) => {
    var formData = new FormData();
    if (e.target.files.length && e.target.files.length <= 5) {
      let tes = e.target.files;
      for (let x = 0; x < e.target.files.length; x++) {
        if (tes[x].name === "") {
          ToastMessage(`The File ${x} invalid file name.`, "e");
          e.target.value = null;
          return false;
        }
        if (tes[x].size > IMAGE_SIZE_ACCEPT) {
          ToastMessage(`The File ${x} upload minimum 2 MB.`, "e");
          e.target.value = null;
          return false;
        }
        if (IMAGE_TYPE_ACCEPT.includes(tes[x].type) === false) {
          ToastMessage(`The File ${x} invalid file format.`, "e");
          e.target.value = null;
          return false;
        }
        formData.append("imgFiles[]", tes[x]);
      }
      postData(`/api/upload_tempimagefiles`, formData)
        .then((result) => {
          let res = result.data;
          let temp = hardwareData.items;
          temp[index]["image_uploads"] = res.data;
          setHardwareData({ ...hardwareData, temp });
        })
        .catch((err) => {
          ToastMessage(err.data.message, "e");
          e.target.value = null;
        });
    } else {
      ToastMessage(`Maximum upload 5 files allowed only.`, "e");
      e.target.value = null;
      return false;
    }
  };
  const changeItemHandller = (value, field, index) => {
    let temp = hardwareData.items;
    temp[index][field] = value;
    setHardwareData({ ...hardwareData, items: temp });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/requests`}>Requests</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Hardware Request
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Hardware Request</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="addhardwareRequestForm"
                      name="addhardwareRequestForm"
                      method="POST"
                      encType="multipart/form-data"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col lg={4}>
                          <label htmlFor="title">Title</label>
                          <input
                            id="title"
                            name="title"
                            className="form-control"
                            onChange={(e) =>
                              setHardwareData({
                                ...hardwareData,
                                title: e.target.value,
                              })
                            }
                            value={hardwareData.title}
                            placeholder="Title"
                            autoComplete="off"
                            data-rule-required={true}
                            minLength={3}
                            maxLength={180}
                          />
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="type_with1">Type With</label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="type_with1"
                              name="type_with1"
                              options={[
                                { value: "project", label: "Project" },
                                { value: "plaza", label: "Plaza" },
                                { value: "office", label: "Office" },
                                { value: "warehouse", label: "Warehouse" },
                              ]}
                              required={true}
                              joinValues={true}
                              autoComplete="off"
                              placeholder="Select the type with"
                              className={"w-100"}
                              onChange={(e) =>
                                setHardwareData({
                                  ...hardwareData,
                                  type_with: e !== null ? e.value : null,
                                })
                              }
                              value={hardwareData.type_with}
                              inputRenderer={(e) => (
                                <input
                                  {...e}
                                  name="type_with"
                                  data-msg-required="This field is required"
                                />
                              )}
                            />
                            <label
                              className="error select_error_span"
                              htmlFor="type_with"
                            ></label>
                          </div>
                        </Col>
                        <Col lg={4}>
                          {getswitchfields(hardwareData.type_with)}
                        </Col>
                      </div>
                      {hardwareData.items &&
                        typeof hardwareData.items != undefined &&
                        hardwareData.items.map((val, i) => (
                          <div className="form-group row" key={i}>
                            <Col lg={3}>
                              <div className="cstm_select">
                                <label htmlFor="item_name">Item</label>
                                <SelectDropdown
                                  id={"product_id"}
                                  name={"product_id"}
                                  required={true}
                                  options={productOptionList}
                                  sampleValue={true}
                                  joinValues={true}
                                  searchable={true}
                                  clearable={true}
                                  autoComplete="off"
                                  placeholder="Select Product"
                                  onChange={(e) =>
                                    changeItemHandller(
                                      e !== null ? e.value : "",
                                      "item_name",
                                      i
                                    )
                                  }
                                  value={val.item_name}
                                  inputRenderer={(props) => (
                                    <input
                                      {...props}
                                      name="item_name"
                                      data-msg-required={
                                        "Please select product."
                                      }
                                    />
                                  )}
                                />
                                <label
                                  className="error select_error_span"
                                  htmlFor="item_name"
                                ></label>
                              </div>
                              {/* <label htmlFor="issue_detail">Item</label>
                          <input
                            type="text"
                            name={`name_` + i}
                            className={"form-control"}
                            onChange={(e) =>
                              changeItemHandller(e.target.value, "item_name", i)
                            }
                            value={val.item_name}
                            placeholder="Item"
                            data-rule-required={true}
                            minLength={3}
                            maxLength={180}
                          /> */}
                            </Col>
                            <Col lg={2}>
                              <label htmlFor="quantity">Quantity</label>
                              <input
                                type="number"
                                id={`quantity_` + i}
                                name={`quantity_` + i}
                                className={"form-control"}
                                onChange={(e) =>
                                  changeItemHandller(
                                    e.target.value,
                                    "quantity",
                                    i
                                  )
                                }
                                value={val.quantity}
                                placeholder="1"
                                data-rule-required={true}
                                min={1}
                                max={1000}
                              />
                            </Col>
                            <Col lg={2}>
                              <label htmlFor="type1">Add/Replace</label>
                              <div className="cstm_select validation_s">
                                <SelectDropdown
                                  id="type1"
                                  name="type1"
                                  options={typeOptions}
                                  required={true}
                                  joinValues={true}
                                  autoComplete="off"
                                  placeholder="Select the type"
                                  className={"w-100"}
                                  onChange={(e) =>
                                    changeItemHandller(
                                      e !== null ? e.value : null,
                                      "type",
                                      i
                                    )
                                  }
                                  value={val.type}
                                  inputRenderer={(e) => (
                                    <input
                                      {...e}
                                      name={`type_` + i}
                                      data-msg-required="This field is required"
                                    />
                                  )}
                                />
                                <label
                                  className="error select_error_span"
                                  htmlFor={`type_` + i}
                                ></label>
                              </div>
                            </Col>
                            {val.type === "repair" || val.type === "replace" ? (
                              <Col lg={4}>
                                <>
                                  <label htmlFor="images">Images</label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    id={`imgFiles_` + i}
                                    name={`imgFiles_` + i}
                                    accept="image/*"
                                    multiple={true}
                                    onChange={(e) => fileHandller(e, i)}
                                  />
                                </>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg={1} className="d-flex">
                              {i + 1 === hardwareData.items.length ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    let aa = hardwareData.items;
                                    aa.push({
                                      item_name: "",
                                      quantity: "",
                                      type: null,
                                      image_uploads: [],
                                    });
                                    setHardwareData({
                                      ...hardwareData,
                                      items: aa,
                                    });
                                  }}
                                  className="btn_plus_minus add_top_space_1"
                                >
                                  <GrAddCircle />
                                </button>
                              ) : (
                                ""
                              )}
                              {i !== 0 || hardwareData.items.length > 1 ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    let temp = hardwareData.items;
                                    temp.splice(i, 1);
                                    setHardwareData({
                                      ...hardwareData,
                                      items: temp,
                                    });
                                  }}
                                  className="btn_plus_minus add_top_space_1"
                                >
                                  <FiMinusCircle />
                                </button>
                              ) : (
                                ""
                              )}
                            </Col>
                          </div>
                        ))}
                      <div className="form-group row">
                        <Col lg={8}>
                          <label htmlFor="description">Description</label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            placeholder="Something write here..."
                            data-rule-required={true}
                            onChange={(e) =>
                              setHardwareData({
                                ...hardwareData,
                                description: e.target.value,
                              })
                            }
                            value={hardwareData.description}
                            minLength={3}
                            maxLength={300}
                          />
                        </Col>
                      </div>
                      <Row>
                        {/* <Col lg={3}></Col> */}
                        <Col lg={9}>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            name="submit"
                            disabled={loading ?? false}
                            onClick={submitHandller}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                          <Link className="btn btn-dark" to={"/requests"}>
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default AddHardwareRequest;
