import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { get_profile } from "../actions/UserActions";
// import noimageavailable from "./../assets/images/not-available.jpg";
import { ToastMessage } from "../service/ToastMessage";
import { postData } from "../service/Common";
import { USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY } from "../config/Constants";
import { SAVE_HEADER_USER_INFO } from "../constants/AuthContants";
import { get_roles_optionlist } from "../actions/RoleActions";

const ProfilePage = (props) => {
  var FILE_FORMAT_ACCEPT = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/svg",
    "image/bmp",
  ]; // image file format accept
  var FILE_SIZE_ACCEPT = 5120000; // 5 MB accept
  const UserProfileReducers = useSelector((state) => state.UserProfileReducers);
  const getRolesOptList = useSelector(
    (state) => state.GetRolesOptionListReducer.successRolesOptionList
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [acceptuploadImage, setAcceptuploadImage] = useState({
    isfile: false,
    image: null,
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [profileData, setProfileData] = useState(() => {
    return {
      firstName: "",
      lastname: "",
      lastName: "",
      email: "",
      mobile: "",
      image: "",
      role_id: "",
      role: "",
    };
  });
  useEffect(() => {
    dispatch(get_roles_optionlist());
    dispatch(get_profile());
    setLoading(true);
  }, [dispatch]);
  useEffect(() => {
    if (getRolesOptList) {
      setRoleOptions(getRolesOptList.data);
    }
    if (UserProfileReducers.userProfileInfo) {
      setProfileData(UserProfileReducers.userProfileInfo.data);
      setLoading(UserProfileReducers.loading);
    } else {
      if (UserProfileReducers.userProfileInfoEmpty !== undefined)
        ToastMessage(UserProfileReducers.userProfileInfoEmpty.message, "e");
      setLoading(false);
    }
  }, [UserProfileReducers, getRolesOptList]);
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#userProfileForm").valid()) {
      setLoading(true);
      postData("/api/update_profile", profileData)
        .then((response) => {
          dispatch(get_profile());
          ToastMessage(response.data.message, "s");
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const changeImageHandller = (e) => {
    e.preventDefault();
    if (e.target.files[0] !== undefined) {
      if (!FILE_FORMAT_ACCEPT.includes(e.target.files[0].type)) {
        e.target.value = null;
        ToastMessage("Accept only image png,jpg,jpeg,bmp and svg format.", "e");
        return false;
      }
      if (e.target.files[0].size >= FILE_SIZE_ACCEPT) {
        e.target.value = null;
        ToastMessage("Accept maximum upload 5 MB.", "e");
        return false;
      }
      var ele = URL.createObjectURL(e.target.files[0]);
      setProfileData({ ...profileData, image: ele });
      setAcceptuploadImage({ isfile: true, image: e.target.files[0] });
    } else {
      setAcceptuploadImage({ isfile: false, image: null });
    }
  };
  const uploadHandller = (e, form) => {
    e.preventDefault();
    setImageLoading(true);
    const formData = new FormData();
    formData.append("avatar", acceptuploadImage.image);
    postData("/api/update_profile_pic", formData)
      .then((response) => {
        var data = response.data;
        setProfileData({ ...profileData, image: data.success });
        setAcceptuploadImage({ isfile: false, image: null });
        setImageLoading(false);
        ToastMessage("Profile image uploaded.", "s");
        let temp =
          JSON.parse(
            localStorage.getItem(USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY)
          ) || {};
        temp.profile_image = data.success;
        localStorage.setItem(
          USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY,
          JSON.stringify(temp)
        );
        dispatch({ type: SAVE_HEADER_USER_INFO, payload: temp });
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setImageLoading(false);
      });
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Profile Page
          </li>
        </ol>
        {/* <Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
        >
          <PlusSvgIcon className="mr-2" />
          Add New User
        </Link> */}
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Profile</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <form
                      id="userProfileForm"
                      method="POST"
                      className="forms-sample"
                    >
                      <div className="form-group row">
                        <Col lg={4} className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            autoComplete="off"
                            placeholder="First Name"
                            name={"firstName"}
                            value={profileData.firstName || ""}
                            onChange={(e) =>
                              setProfileData({
                                ...profileData,
                                firstName: e.target.value,
                              })
                            }
                            maxLength={80}
                            minLength={3}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            autoComplete="off"
                            placeholder="Last Name"
                            name={"lastName"}
                            value={profileData.lastName || ""}
                            onChange={(e) =>
                              setProfileData({
                                ...profileData,
                                lastName: e.target.value,
                              })
                            }
                            maxLength={80}
                            minLength={3}
                            data-rule-required={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            name={"email"}
                            value={profileData.email || ""}
                            onChange={(e) =>
                              setProfileData({
                                ...profileData,
                                email: e.target.value,
                              })
                            }
                            maxLength={180}
                            data-rule-required={true}
                            data-rule-email={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="mobile">Mobile</label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            placeholder="Mobile number"
                            name={"mobile"}
                            value={profileData.mobile || ""}
                            onChange={(e) =>
                              setProfileData({
                                ...profileData,
                                mobile: e.target.value,
                              })
                            }
                            maxLength={15}
                            data-rule-required={true}
                            data-rule-phonenumber={true}
                          />
                        </Col>

                        <Col lg={4} className="form-group">
                          <label htmlFor="role">Role</label>
                          <select
                            className="form-control"
                            id="role"
                            placeholder="Role"
                            disabled={true}
                            value={profileData.role_id || 0}
                          >
                            <option value={0} key={0}>
                              Role
                            </option>
                            {roleOptions.map((val) => {
                              return (
                                <option value={val.id} key={val.id}>
                                  {val.name}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </div>
                      <Row>
                        <Col lg={12} className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? `Loading...` : `Update`}
                          </button>
                          <Link className="btn btn-dark" to="/dashboard">
                            Cancel
                          </Link>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                  {/* <Col lg={3}>
                  <div className="img-profile">
                    {loading?
                    (
                      'Loading...'
                    ):(
                      <img className="img-fluid" src={profileData.image!==null?profileData.image:noimageavailable} alt="Profile img" />
                    )}
                    <form name="imageUpdateForm" method="post" encType="multipart/form-data">
                      <label type="button" htmlFor="chngImg" title={`Change Image`}>
                        Change Image
                      </label>
                      <input type="file" onChange={changeImageHandller} id="chngImg" name="chngImxg" accept={`image/*`} style={{'visibility':'hidden'}} />
                      {acceptuploadImage.isfile ? (
											<button
												type="submit"
												className="btn btn-info btn-md"
												onClick={uploadHandller}
												disabled={imageLoading ? "disabled" : ""}
											>
												{imageLoading ? "Please wait..." : "Upload"}
											</button>
										) : (
											""
										)}
                    </form>
                  </div>
                </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ProfilePage;
