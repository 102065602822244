import {
    GET_PERSON_DETAIL_REQUEST, GET_PERSON_DETAIL_SUCCESS, GET_PERSON_DETAIL_FAILED_ERROR,
    GET_PERSON_OPTION_LIST_REQUEST, GET_PERSON_OPTION_LIST_SUCCESS, GET_PERSON_OPTION_LIST_FAILED_ERROR
} from "../constants/PersonConstants";
import { postData } from "../service/Common";

const get_person_detail_action = (id) => async (dispatch) => {
    dispatch({ type: GET_PERSON_DETAIL_REQUEST, payload: {} })
    postData(`/api/get_person_detail`, { id: id })
        .then((result) => {
            dispatch({ type: GET_PERSON_DETAIL_SUCCESS, payload: result.data })
        })
        .catch((error) => {
            dispatch({ type: GET_PERSON_DETAIL_FAILED_ERROR, payload: error.data.message })
        })
}
const get_person_option_list_action = () => async (dispatch) => {
    dispatch({ type: GET_PERSON_OPTION_LIST_REQUEST, payload: {} })
    postData(`/api/get_persons_option_list`, '', { method_type: 'get' })
        .then((result) => {
            dispatch({ type: GET_PERSON_OPTION_LIST_SUCCESS, payload: result.data })
        })
        .catch((error) => {
            dispatch({ type: GET_PERSON_OPTION_LIST_FAILED_ERROR, payload: error.data.message })
        })
}

export { get_person_detail_action, get_person_option_list_action };