import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown from "../../components/ReactSelectPlus";
import $, { map } from "jquery";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { get_product_detail_action } from "../../actions/ProductActions";
import { GET_PRODUCT_DETAIL_WITH_QTY_INFO_SUCCESS } from "../../constants/ProductConstants";

const AddGroupInventory = (props) => {

  const [prodData, setProdData] = useState({
    display: "",
    imgPath: {},
  });
  const [commonData, setCommonData] = useState({
    product_id: "",
    quantity: parseInt(1),
    assign_id:
      props.match.params.assignId !== undefined
        ? props.match.params.assignId
        : "",
    assign_with:
      props.match.params.assignWith !== undefined
        ? props.match.params.assignWith
        : "",
    project_id: "",
    plaza_id: "",
    office_id: "",
    warehouse_id: "",
  });

  const [loading, setLoading] = useState(false);
  const [productOptionList, setProductOptionList] = useState([]);
  const [projectOptionList, setProjectOptionList] = useState([]);
  const [plazaOptionList, setPlazaOptionList] = useState([]);
  const [officeOptionList, setOfficeOptionList] = useState([]);
  const [whouseOptionList, setWhouseOptionList] = useState([]);
  const [loopQty, setloopQty] = useState(1);
  const [blankInventory, setblankInventory] = useState([]);
  const [pageTitle, setPageTitle] = useState("New Group Inventory");
  const [editProductId, setEditProductId] = useState(false);
  const [viewProduct, setViewProduct] = useState(false);

  
  const getProductDetails = useSelector(
    (state) =>
      state.ProductReducers.GetProductDetailReducer.successgetProductDetail
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      commonData.product_id &&
      getProductDetails &&
      typeof getProductDetails.data.dataArr !== undefined
    ) {
      let display =
        typeof getProdOptionsList !== undefined
          ? getProductDetails.data.dataArr.display 
          : "";
      let imagePath =
        typeof getProdOptionsList !== undefined
          ? getProductDetails.data.dataArr.imgFiles[0]
          : "";
      setProdData({ ...prodData, display: display, imgPath: imagePath });
    }

  }, [  getProductDetails]);

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addNewInventory_Form").valid()) {
      var data = { inventory: blankInventory, commonData,inventoryId:props.match.params.inventoryId };
      postData(`/api/add_group_inventory`, data)
        .then((response) => {
          let data = response.data;
          ToastMessage(data.message, "s");
          //formReset();
          setLoading(false);
          props.history.push("/list_group_inventory");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const addInventoryHandller = (e) => {
    let quantity = commonData.quantity;
    let product = commonData.product_id;
	setloopQty(1);
    if ($("#addNewInventory_Form").valid()) {
        /*if (!product) {
            ToastMessage("Product field cannot be empty", "e");
            return false;
        }

        if (!quantity) {
            ToastMessage("Quantity field cannot be empty", "e");
            return false;
        }*/
        if (quantity > 10) {
            ToastMessage("only 10 items can be allowed", "e");
        } else {
            //dispatch(get_product_detail_with_qty_action(product,2,quantity)); 
			getProductInventoryDetailNew(product,2,quantity)
        }
    }
  };

  //1=project/2=plaza/3=office/4=warehouse
  var typeWithOptions = [
    { value: "project", label: "Project" },
    { value: "plaza", label: "Plaza" },
    { value: "office", label: "Office" },
    { value: "warehouse", label: "Warehouse" },
  ];

  const setgetProductValues = (val, key) => {
    setCommonData({
      ...commonData,
      product_id: val,
    });
    if (val !== null) {
      dispatch(get_product_detail_action(val, 2));
    } else {
      setProdData({ ...prodData, display: "", imgPath: [] });
    }
  };

  const returnLoopQty =()=>{
	  if(loopQty !=commonData.quantity && loopQty <= commonData.quantity ){
		let x = loopQty;
		setloopQty( parseInt(x) + 1);		
	  }	 
    }

   const stateHandlerNew =(e,mainIndex,objKey,fieldName,value,hero,SecondIndex,dynamic)=>{ 
	e.preventDefault();
	//let temp_state = JSON.parse(JSON.stringify(blankInventory));
	let temp_state = [...blankInventory];
    let temp_element = { ...temp_state[mainIndex] };
    let obj_element = { ...temp_element[objKey] };
	if(fieldName == 'set_name'){
		obj_element[fieldName] = value;
	}else{  
        let temp_item_detail =  {...obj_element['item_detail']}
        let temp_hero =  {...temp_item_detail[hero]}
        let temp_index2 =  {...temp_hero[SecondIndex]}
		temp_index2['serial'] = value;
        temp_hero[SecondIndex] = temp_index2;
        temp_item_detail[hero] = temp_hero;
        obj_element['item_detail'] = temp_item_detail;
	}
    temp_element[objKey] = obj_element;
    temp_state[mainIndex] = temp_element;
	//console.log('temp_state',temp_state)
    setblankInventory([])
    setblankInventory(temp_state)
   }

   const stateHandler =(e,mainIndex,objKey,fieldName,value,hero,SecondIndex,dynamic)=>{ 
	e.preventDefault();
	//let temp_state = JSON.parse(JSON.stringify(blankInventory));
	let temp_state = [...blankInventory];
	if(fieldName == 'set_name'){
		temp_state[mainIndex][objKey][fieldName] = value;
	}else{   
		temp_state[mainIndex][objKey]['item_detail'][hero][SecondIndex]['serial'] = value;
	}
	console.log('temp_state',temp_state)
  setblankInventory(temp_state)
   }

  const getswitchfields = (assignwith) => {
    switch (assignwith) {
      case "project":
        return (
          <>
            <label htmlFor="plaza">Project</label>
            <div className="cstm_select">
              <SelectDropdown
                id="projectid1"
                name="projectid1"
                options={projectOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Project"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
                disabled={viewProduct}
              />
              <label
                className="error select_error_span"
                htmlFor="project_id_"
              ></label>
            </div>
          </>
        );
      case "plaza":
        return (
          <>
            <label htmlFor="plaza">Plaza</label>
            <div className="cstm_select">
              <SelectDropdown
                id="plazaid1"
                name="plazaid1"
                options={plazaOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Plaza"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
                disabled={viewProduct}
              />
              <label
                className="error select_error_span"
                htmlFor="plaza_id_"
              ></label>
            </div>
          </>
        );
      case "office":
        return (
          <>
            <label htmlFor="office1">Office</label>
            <div className="cstm_select">
              <SelectDropdown
                id="office1"
                name="office1"
                options={officeOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Office"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
                disabled={viewProduct}
              />
              <label
                className="error select_error_span"
                htmlFor="office_id_"
              ></label>
            </div>
          </>
        );
      case "warehouse":
        return (
          <>
            <label htmlFor="wh_id">Warehouse</label>
            <div className="cstm_select">
              <SelectDropdown
                id="wh_id1"
                name="wh_id1"
                options={whouseOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Warehouse"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                  
                )}
                disabled={viewProduct}
              />
              <label
                className="error select_error_span"
                htmlFor="assign_id"
              ></label>
            </div>
          </>
        );
      default:
        break;
    }
  };

  useEffect(() => { 
    if (props.match.params.inventoryId) { 
      setEditProductId(true);
      checkOperatioMode();     
      //dispatch(get_product_detail_with_qty_action(props.match.params.inventoryId,2,0));  
	  getProductInventoryDetailNew(props.match.params.inventoryId,2,0);    
    }
  }, [props.match.params.inventoryId, dispatch]);

  const checkOperatioMode = () => {
    if (props.location.pathname.includes("view")) {
      setViewProduct(true);
      setPageTitle("View Inventory");
    } else {
      setPageTitle("Edit Inventory");
    }
  };

  useEffect(() => {
	getAllOptionList();
  }, []);

  const getAllOptionList=()=>{
	postData(`/api/option_list_grp_product`)
		.then((result) => {
		var res = result.data;
			if(res.status){
				setProjectOptionList(res.data.project_data);
				setPlazaOptionList(res.data.plaza_data);
				setOfficeOptionList(res.data.office_data);
				setWhouseOptionList(res.data.warehouse_data);
				setProductOptionList(res.data.product_data);
			}
		})
		.catch((error) => {
		ToastMessage('Error', "e");
		});	  
  }

  const getProductInventoryDetailNew=(product,productType,quantity)=>{  
	postData(`/api/get_group_inventory_detail`,{id:product,productType:productType,qty:quantity})
	.then((result) => {
	var res = result.data;
	console.log('res.datacommonData',res.data.commonData)
		if(res.status){ 
			if(props.match.params.inventoryId){
				setCommonData({ 
					//...commonData,
					assign_with: res.data.commonData?.assign_with,
					assign_id: res.data.commonData?.assign_id,
					quantity:parseInt(res.data.commonData?.quantity),
					product_id:res.data.commonData?.product_id,   
				})
				setblankInventory(res.data.inventoryDataItem);
			}else{
				//Add case
				setblankInventory(res.data.inventoryDataItem);
			}
		}
	})
	.catch((error) => {
	ToastMessage('Error', "e");
	});
  }
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/list_group_inventory">Group Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {pageTitle}
          </li>
        </ol>
      </nav>
      <form id="addNewInventory_Form" method="POST" encType="multipart/form-data" className="forms-sample" >
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">{pageTitle}</h6>
              </div>

              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <div className="row">
                      <Col lg={4} className="form-group">
                        <label htmlFor="Product">Product</label>
                        <div className="cstm_select">
                          <SelectDropdown
                            id={"product_id"}
                            name={"product_id"}
                            required={true}
                            options={productOptionList}
                            sampleValue={true}
                            joinValues={true}
                            searchable={true}
                            clearable={true}
                            autoComplete="off"
                            placeholder="Select Product"
                            onChange={(e) =>
                              setgetProductValues(
                                e != null ? e.value : null,
                                "product_id"
                              )
                            }
                            value={commonData.product_id}
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="product_id"
                                data-msg-required={"Please select product."}
                              />
                            )}
                            disabled={viewProduct || editProductId}
                          />
                           <label
                              className="error select_error_span"
                              htmlFor="product_id"
                            ></label>
                          <div style={{ fontSize: "10px" }}>
                            {prodData ? prodData.display : ""}
                            {prodData &&
                            prodData.imgPath &&
                            typeof prodData.imgPath != undefined &&
                            prodData.imgPath.src !== undefined ? (
                              <img
                                src={
                                  prodData.imgPath &&
                                  prodData.imgPath.src !== undefined
                                    ? prodData.imgPath.src
                                    : ""
                                }
                                alt={""}
                                height="50px"
                                width="50px"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
					
                      <Col lg={4} className="form-group">
                        <label htmlFor="password">Quantity</label>
                        <input
                          type="text"
                          className="form-control"
                          id="quantity"
                          name="quantity"
                          autoComplete="off"
                          placeholder="Quantity"
                          value={commonData.quantity}
                          onChange={(e) =>
                            setCommonData({
                              ...commonData,
                              quantity: e.target.value
                            })
                          }
                          maxLength={180}
                          disabled={viewProduct || editProductId}
                          required
                        />
                      </Col>
                        
                        {!viewProduct && !editProductId?
                      <Col lg={4} className="form-group pt-3">
                        <button
                          type="button"
                          className="btn btn-primary mr-2 mt-3"
                          onClick={addInventoryHandller}
                          disabled={loading ? true : false}
                        >
                          {loading ? "Loading..." : "Add Inventory"}
                        </button>
                      </Col>:''}

                    </div>
                  </Col>
                </Row>

                <Row className=" justify-content-center">
                  <Col lg={12} className="grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Row className=" justify-content-center">
                          <Col lg={12}>
                            
								{/*InventoryBox*/}

                               
								{ (commonData.product_id!='NaN' && blankInventory.length > 0 && commonData.product_id!='' && commonData.product_id!=null && typeof blankInventory !== undefined)?
								<>
                              <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                                <h6 className="card-title ">Item Detail</h6>
                              </div>
                              <div className="row">
							  {(blankInventory).map((el, index) =>{ 
								  let mainObj = el[Object.keys(el)[0]];
								  let objKey = Object.keys(el)[0];

                                  console.log('objKey',objKey)
								  returnLoopQty()
								  return (
                                <Col lg={6} className="mt-3">
                                  <div className="box_1to2"> 								  
                                    <input type="text" className="form-control" name={"set_name"+Math.random()} value={(mainObj)?mainObj.set_name:'N/A'} onChange={(e)=>stateHandler(e,index,objKey,'set_name',e.target.value)} disabled={viewProduct} />
                                  </div>
                                  <div className="box_1to1">
                                      
                                    <div className="box_1to1_serial">                                      
									  { 
                                      
										mainObj.item_detail && Object.keys(mainObj.item_detail).map((hero,innerIndex) => {
                                            
                                            console.log('hero',mainObj.item_detail);
                                            return (
											mainObj.item_detail[hero].map((val,ii)=>{
                                                let dynamic = Math.floor(Math.random() * (999 - 99 + 1)) + 99;
                                               return (
													<div className="box_1to1_serial_items" key={dynamic}>
														<div>{val.product_name +' '+ (ii+1)}</div>
														<div >		
														<input type="text" className="form-control" name={"serialNumber"+dynamic}  data-rule-required={true} id={dynamic}
														onChange={(e)=>stateHandler(e,index,objKey,'serial',e.target.value,hero,ii,dynamic)}
                                                        value={val.serial || ''}
                                                        disabled={viewProduct}
														/>                                                        
														</div>
													</div>
                                                    )
												
                                            })
                                        )})  
									}                                     
                                    </div>
                                  </div>								
                                </Col>
							  )  }
								   ) }                                
                              </div></>:''}
								{/*InventoryBox*/ }
                              <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-5">
                                <h6 className="card-title ">Assign</h6>
                              </div>
                              <div className="row">
                                <Col lg={4} className="form-group">
                                  <label htmlFor="type_with1">Type With</label>
                                  <div className="cstm_select">
                                    <SelectDropdown
                                      id="assign_with"
                                      name="assign_with"
                                      options={typeWithOptions}
                                      // required={true}
                                      joinValues={true}
                                      autoComplete="off"
                                      placeholder="Select the type with"
                                      className={"w-100"}
                                      onChange={(e) =>
                                        setCommonData({
                                          ...commonData,
                                          assign_with:
                                            e !== null ? e.value : null,
                                        })
                                      }
                                      value={commonData.assign_with}
                                      inputRenderer={(e) => (
                                        <input
                                          {...e}
                                          name="assign_with"
                                          data-msg-required="This field is required"
                                        />
                                      )}
                                      disabled={viewProduct}
                                    />
                                    <label
                                      className="error select_error_span"
                                      htmlFor="type_with"
                                    ></label>
                                  </div>
                                </Col>
                                <Col lg={4} className="form-group">
                                  {getswitchfields(commonData.assign_with)}
                                </Col>
                              </div>
                            
                          </Col>
                        </Row>
                       
                        <Row className="mt-3">                        
                          <div className="form-group  col-md-12 align-self-end">
                          {!viewProduct?
                          <>
                            <button type="submit" className="btn btn-primary mr-2" onClick={submitHandller} disabled={loading ? true : false} >
                              {loading ? "loading..." : "Submit"}
                            </button>
                            <Link className="btn btn-dark" to="/list_group_inventory">Cancel</Link>
                            </>
                            :<Link className="btn btn-dark" to="/list_group_inventory">Back</Link>}
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </form>
    </>
  );
};
export default AddGroupInventory;
