import axios from "axios";
import { login_token, clear_auth_token } from "../service/Authentication";
const createHistory = require("history").createHashHistory;
var history = createHistory();

export const postData = (
	url,
	data,
	options = {
		without_base_url: false,
		method_type: "post",
	}
) => {
	var BASE_URL = process.env.REACT_APP_API_BASE_URL;
	var request_url = BASE_URL + url;

	if (options.without_base_url) {
		request_url = url;
	}

	return new Promise((resolve, reject) => {
		axios({
			method: options.method_type,
			url: request_url,
			data: data,
			headers: {
				Authorization: "Bearer " + login_token(),
			},
		})
			.then(function (response) {
				resolve(response);
			})
			.catch(function (error) {
				if (error.response) {
					if (
						error.response.status === 401 &&
						error.response.data.message === "Unauthenticated."
					) {
						clear_auth_token();
						history.push("/login");
					}
					reject(error.response);
				} else {
					reject({ data: { message: "Network Error" } });
				}
			});
	});
};

export function handleRemoveInputElements( e, index, stateName ) {
	e.preventDefault();
	stateName(excistingState => excistingState.filter((s, sidx) => index !== sidx));
}

//e, setGrpProducts,value,grpProducts
export function handleAddInputElements(e, stateName, object_array_val,excistingState) {
	e.preventDefault();
	stateName([...excistingState,object_array_val])
}

export function handleShareholderNameChange(stateName,excistingState, index, fieldName, value) {
	// 1. Make a shallow copy of the array
	let temp_state = [...excistingState];
	
	// 2. Make a shallow copy of the element you want to mutate
	let temp_element = { ...temp_state[index] };
	
	// 3. Update the property you're interested in
	temp_element[fieldName] = value;
	
	// 4. Put it back into our array. N.B. we are mutating the array here, but that's why we made a copy first
	temp_state[index] = temp_element;
	//console.log('Ritesh',temp_state)
	// 5. Set the state to our new copy
	stateName( temp_state );
}