export const GET_INVENTORY_DETAIL_INFO_REQUEST = "GET_INVENTORY_DETAIL_INFO_REQUEST";
export const GET_INVENTORY_DETAIL_INFO_SUCCESS = "GET_INVENTORY_DETAIL_INFO_SUCCESS";
export const GET_INVENTORY_DETAIL_INFO_FAIL = "GET_INVENTORY_DETAIL_INFO_FAIL";

export const GET_INVENTORY_OPTION_LIST_REQUEST = "GET_INVENTORY_OPTION_LIST_REQUEST";
export const GET_INVENTORY_OPTION_LIST_SUCCESS_INFO = "GET_INVENTORY_OPTION_LIST_SUCCESS_INFO"; 
export const GET_INVENTORY_OPTION_LIST_FAIL_ERROR = "GET_INVENTORY_OPTION_LIST_FAIL_ERROR";

export const GET_INVENTORY_DETAIL_LIST_BYID_REQUEST = "GET_INVENTORY_DETAIL_LIST_BYID_REQUEST";
export const GET_INVENTORY_DETAIL_LIST_BYID_SUCCESS_INFO = "GET_INVENTORY_DETAIL_LIST_BYID_SUCCESS_INFO";
export const GET_INVENTORY_DETAIL_LIST_BYID_FAIL_ERROR = "GET_INVENTORY_DETAIL_LIST_BYID_FAIL_ERROR";

export const GET_BARCODE_DETAIL_REQUEST = "GET_BARCODE_DETAIL_REQUEST";
export const GET_BARCODE_DETAIL_SUCCESS = "GET_BARCODE_DETAIL_SUCCESS";
export const GET_BARCODE_DETAIL_FAIL = "GET_BARCODE_DETAIL_FAIL";

export const GET_SELECTED_BARCODE_DETAIL_REQUEST = "GET_SELECTED_BARCODE_DETAIL_REQUEST";
export const GET_SELECTED_BARCODE_DETAIL_SUCCESS = "GET_SELECTED_BARCODE_DETAIL_SUCCESS";
export const GET_SELECTED_BARCODE_DETAIL_FAIL = "GET_SELECTED_BARCODE_DETAIL_FAIL";
