import {
	GET_PLAZA_DETAIL_REQUEST,
	GET_PLAZA_DETAIL_SUCCESS_INFO,
	GET_PLAZA_DETAIL_FAIL_ERROR,
	GET_PLAZA_OPTION_LIST_REQUEST, GET_PLAZA_OPTION_LIST_SUCCESS_INFO, GET_PLAZA_OPTION_LIST_FAIL_ERROR,
	GET_PLAZAS_BY_PORJECT_ID_REQUEST,
	GET_PLAZAS_BY_PORJECT_ID_SUCCESS,
	GET_PLAZAS_BY_PORJECT_ID_FAIL_ERROR,
	GET_ASSIGN_PLAZA_LIST_REQUEST,GET_ASSIGN_PLAZA_OPTION_LIST, GET_ASSIGN_PLAZA_OPTION_LIST_FAIL_ERROR

} from "../constants/PlazaContants";

import { postData } from "../service/Common";

const get_plaza_detail_action = (id) => async (dispatch) => {
	dispatch({ type: GET_PLAZA_DETAIL_REQUEST, payload: {} });
	postData("/api/get_plaza_detail", { id: id })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_PLAZA_DETAIL_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_PLAZA_DETAIL_FAIL_ERROR, payload: error.data.message });
		});
};



const get_plaza_option_list_action = () => async (dispatch) => {
	dispatch({ type: GET_PLAZA_OPTION_LIST_REQUEST, payload: {} })
	postData(`/api/get_plaza_option_list`, '', { method_type: 'GET' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_PLAZA_OPTION_LIST_SUCCESS_INFO, payload: data });
		}).catch((error) => {
			dispatch({ type: GET_PLAZA_OPTION_LIST_FAIL_ERROR, payload: error.data.message });
		})
}

const get_plaza_by_project_id_action = (id) => async (dispatch) => {
	dispatch({ type: GET_PLAZAS_BY_PORJECT_ID_REQUEST, payload: {} })
	postData(`/api/get_plazas_by_project_id`, { project_id: id })
		.then((response) => {
			let data = response.data;
			dispatch({ type: GET_PLAZAS_BY_PORJECT_ID_SUCCESS, payload: data })
		})
		.catch((error) => {
			dispatch({ type: GET_PLAZAS_BY_PORJECT_ID_FAIL_ERROR, payload: error.data.message })
		})
}

const get_assign_plaza_list_action = () => async (dispatch) => {
	dispatch({ type: GET_ASSIGN_PLAZA_LIST_REQUEST, payload: {} })
	postData(`/api/get_active_plaza_option_list`, '', { method_type: 'GET' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_ASSIGN_PLAZA_OPTION_LIST, payload: data });
		}).catch((error) => {
			dispatch({ type: GET_ASSIGN_PLAZA_OPTION_LIST_FAIL_ERROR, payload: error.data.message });
		})
}
export { get_plaza_detail_action, get_plaza_option_list_action, get_plaza_by_project_id_action, get_assign_plaza_list_action };