import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./service/jquery.validate.js";
import "./service/custom_script.js";
import LoginLayout from "./layouts/LoginLayout";
import Login from "./pages/LoginPage.js";
import ForgotPassword from "./pages/ForgotPasswordPage";
import ResetPassword from "./pages/ResetPasswordPage";
import ProfilePage from "./pages/ProfilePage";
import AddNewPermissionsPage from "./pages/AddNewPermissionsPage";
import PermissionsListPage from "./pages/PermissionsListPage";
import RolesListPage from "./pages/role/RolesListPage";
import AddNewRolePage from "./pages/role/AddNewRolePage";
import ShowRolePage from "./pages/role/ShowRolePage";
import EditRolePage from "./pages/role/EditRolePage";
import MastersListPage from "./pages/master/MastersListPage";
import NotificationsList from "./pages/notification/NotificationsList";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import DashboardPage from "./pages/DashboardPage.js";
import UserManagement from "./pages/user/UserManagementPage.js";
import AddNewUserPage from "./pages/user/AddNewUserPage";
import EditUserPage from "./pages/user/EditUserPage";
import ShowUserPage from "./pages/user/ShowUserPage";
import ProjectListPage from "./pages/project/ProjectListPage";
import ShowProjectPage from "./pages/project/ShowProjectPage";
import ReportList from "./pages/ReportPage";
import InventoryManagement from "./pages/inventory/InventoryManagementPage.js";
import AddInventoryPage from "./pages/inventory/AddInventoryPage";
import EditInventoryPage from "./pages/inventory/EditInventoryPage";
import ViewInventoryPage from "./pages/inventory/ViewInventoryPage";
import ViewBarcodePage from "./pages/inventory/ViewBarCodePage";
import { isLoggedIn } from "./service/Authentication.js";
import PrivateRoute from "./service/PrivateRoute";
import PublicRoute from "./service/PublicRoute";
import PageNotFound from "./PageNotFound.js";
import ReportIssue from "./pages/ReportIssue";
import AddHardwareRequest from "./pages/request/AddHardwareRequest";
import PlazaListPage from "./pages/plaza/PlazaListPage";
import AddNewPlazaPage from "./pages/plaza/AddNewPlazaPage";
import ShowPlazaPage from "./pages/plaza/ShowPlazaPage";
import AddnewOfficePage from "./pages/office/AddnewOfficePage";
import ShowOfficePage from "./pages/office/ShowOfficePage";
import OfficeListPage from "./pages/office/OfficeListPage";
import AddnewWarehousePage from "./pages/warehouse/AddnewWarehousePage";
import ShowWarehousePage from "./pages/warehouse/ShowWarehousePage";
import WarehouseListPage from "./pages/warehouse/WarehouseListPage";
import LaneListPage from "./pages/lane/LaneListPage";
import AddNewLanePage from "./pages/lane/AddNewLanePage";
import LaneShowPage from "./pages/lane/LaneShowPage";
// import AssignListPage from "./pages/assign/AssignListPage";
import AddNewAssignPage from "./pages/assign/AddNewAssignPage.js";
// import EditAssignPage from "./pages/assign/EditAssignPage";
// import ShowAssignPage from "./pages/assign/ShowAssignPage";
import ScrollToTop from "./service/ScrollToTop.js";
import { ToastContainer } from "react-toastify";
import ViewLogsPage from "./pages/logs/ViewLogsPage";
import AllRequestPage from "./pages/request/AllRequestPage";
import ViewRequestPage from "./pages/request/ViewRequestPage";
import EditHardwareRequest from "./pages/request/EditHardwareRequest";
import TestPage from "./pages/TestPage";
import AddNewCompanyPage from "./pages/company/AddNewCompanyPage";
import EditCompanyPage from "./pages/company/EditCompanyPage";
import ViewCompanyPage from "./pages/company/ViewCompanyPage";
import CompaniesListPage from "./pages/company/CompaniesListPage";
import PersonsListPage from "./pages/person/PersonsListPage";
import AddPersonPage from "./pages/person/AddPersonPage";
import ViewPersonPage from "./pages/person/ViewPersonPage";
import EditPersonPage from "./pages/person/EditPersonPage";

import ProductManagement from "./pages/product/ProductManagementPage.js";
import ListGroupProduct from "./pages/product/ListGroupProduct.js";
import AddProductGroup from "./pages/product/AddGroupProduct";
import AddProductPage from "./pages/product/AddProductPage";
import ViewProductPage from "./pages/product/ViewProductPage";
import IdleLANE from "./pages/lane/IdleLane";

import ListGroupInventory from "./pages/inventory/ListGroupInventory";
import AddGroupInventory from "./pages/inventory/AddGroupInventory";
import Tracking from "./pages/shipping/Tracking";
import CarriersList from "./pages/shipping/CarriersList";
import ShippingHistory from "./pages/shipping/ShippingHistory";
import DocketDetail from "./pages/shipping/DocketDetail";

function App() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) =>
            !isLoggedIn() ? (
              <LoginLayout {...props}>
                <Login {...props} />
              </LoginLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <Route
          exact
          path="/forgot-password"
          render={(props) =>
            !isLoggedIn() ? (
              <LoginLayout {...props}>
                <ForgotPassword {...props} />
              </LoginLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <Route
          exact
          path="/user/reset_password"
          render={(props) =>
            !isLoggedIn() ? (
              <LoginLayout {...props}>
                <ResetPassword {...props} />
              </LoginLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <PrivateRoute exact path="/dashboard" component={DashboardPage} />
        <PrivateRoute exact path="/usermanagement" component={UserManagement} />
        <PrivateRoute exact path="/addnewuser" component={AddNewUserPage} />
        <PrivateRoute exact path="/user/:uIde/edit" component={EditUserPage} />
        <PrivateRoute exact path="/user/:uIds/show" component={ShowUserPage} />
        <PrivateRoute
          exact
          path="/project_management/:projId/show"
          component={ShowProjectPage}
        />
        <PrivateRoute
          exact
          path="/inventory_management/add_inventory"
          component={AddInventoryPage}
        />
        <PrivateRoute
          exact
          path="/inventory_management/:assignWith/:assignId/add_inventory"
          component={AddInventoryPage}
        />
        <PrivateRoute
          exact
          path="/inventory_management/:invtId/edit"
          component={EditInventoryPage}
        />
        <PrivateRoute
          exact
          path="/inventory_management/:invtId/show"
          component={ViewInventoryPage}
        />
        <PrivateRoute
          exact
          path="/inventory_barcode/:invtId"
          component={ViewBarcodePage}
        />


        <PrivateRoute exact path="/logs/:logId/show" component={ViewLogsPage} />
        <PrivateRoute
          exact
          path="/inventory_management"
          component={InventoryManagement}
        />
        <PrivateRoute
          exact
          path="/project_management"
          component={ProjectListPage}
        />
        <PrivateRoute exact path="/report_issue" component={ReportIssue} />
        <PrivateRoute
          exact
          path="/request/hardware_request"
          component={AddHardwareRequest}
        />
        <PrivateRoute
          exact
          path="/request/:reqId/show"
          component={ViewRequestPage}
        />
        <PrivateRoute
          exact
          path="/request/:reqId/edit"
          component={EditHardwareRequest}
        />
        <PrivateRoute exact path="/requests" component={AllRequestPage} />
        <PrivateRoute exact path="/plazas" component={PlazaListPage} />
        <PrivateRoute exact path="/report" component={ReportList} />
        <PrivateRoute
          exact
          path="/plaza/:projId/add_plaza"
          component={AddNewPlazaPage}
        />
        <PrivateRoute
          exact
          path="/plaza/add_plaza"
          component={AddNewPlazaPage}
        />

        <PrivateRoute
          exact
          path="/plaza/:pID/edit"
          component={AddNewPlazaPage}
        />
        <PrivateRoute exact path="/plaza/:pID/show" component={ShowPlazaPage} />
        <PrivateRoute
          exact
          path="/office/:type/:Id/addnew_office"
          component={AddnewOfficePage}
        />
        <PrivateRoute
          exact
          path="/office/addnew_office"
          component={AddnewOfficePage}
        />
        <PrivateRoute
          exact
          path="/office/:ofcID/edit"
          component={AddnewOfficePage}
        />

        <PrivateRoute
          exact
          path="/office/:ofcID/show"
          component={ShowOfficePage}
        />
        <PrivateRoute exact path="/offices" component={OfficeListPage} />
        <PrivateRoute
          exact
          path="/warehouse/addnew_warehouse"
          component={AddnewWarehousePage}
        />
        <PrivateRoute
          exact
          path="/warehouse/:type/:Id/addnew_warehouse"
          component={AddnewWarehousePage}
        />
        <PrivateRoute
          exact
          path="/warehouse/:wrhseId/edit"
          component={AddnewWarehousePage}
        />

        <PrivateRoute
          exact
          path="/warehouse/:wrhseId/show"
          component={ShowWarehousePage}
        />
        <PrivateRoute exact path="/warehouses" component={WarehouseListPage} />
        <PrivateRoute exact path="/profile" component={ProfilePage} />
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePasswordPage}
        />
        <PrivateRoute exact path="/addnewrole" component={AddNewRolePage} />
        <PrivateRoute exact path="/role/:rId/edit" component={EditRolePage} />
        <PrivateRoute exact path="/role/:rId/show" component={ShowRolePage} />
        <PrivateRoute exact path="/roles" component={RolesListPage} />
        <PrivateRoute exact path="/masters" component={MastersListPage} />
        <PrivateRoute
          exact
          path="/notifications"
          component={NotificationsList}
        />
        <PrivateRoute
          exact
          path="/permission/add"
          component={AddNewPermissionsPage}
        />
        <PrivateRoute
          exact
          path="/permission/:pId/edit"
          component={AddNewPermissionsPage}
        />
        <PrivateRoute
          exact
          path="/permissions"
          component={PermissionsListPage}
        />
        <PrivateRoute exact path="/lanes" component={LaneListPage} />
        <PrivateRoute
          exact
          path="/plaza/:plazaId/addnew_lane"
          component={AddNewLanePage}
        />
        <PrivateRoute exact path="/lane/add" component={AddNewLanePage} />
        {/* <PrivateRoute
          exact
          path="/lane/:laneId/edit"
          component={EditLanePage}
        /> */}
        <PrivateRoute
          exact
          path="/lane/:laneId/edit"
          component={AddNewLanePage}
        />
        <PrivateRoute
          exact
          path="/lane/:laneId/show"
          component={LaneShowPage}
        />
        <PrivateRoute exact path="/idle_lane" component={IdleLANE} />
        <PrivateRoute exact path="/test" component={TestPage} />
        {/* <PrivateRoute exact path="/assigns" component={AssignListPage} /> */}
        <PrivateRoute
          exact
          path="/assign/new/:type/:Id"
          component={AddNewAssignPage}
        />
        {/* <PrivateRoute exact path="/assign/:assignId/edit" component={EditAssignPage} />
        <PrivateRoute exact path="/assign/:assignId/show" component={ShowAssignPage} /> */}
        <PrivateRoute exact path="/company/add" component={AddNewCompanyPage} />
        <PrivateRoute
          exact
          path="/company/:cmpId/edit"
          component={EditCompanyPage}
        />
        <PrivateRoute
          exact
          path="/company/:cmpId/show"
          component={ViewCompanyPage}
        />
        <PrivateRoute exact path="/companies" component={CompaniesListPage} />
        <PrivateRoute exact path="/persons" component={PersonsListPage} />
        <PrivateRoute
          exact
          path="/person/addnew_person"
          component={AddPersonPage}
        />
        <PrivateRoute
          exact
          path="/person/:personId/show"
          component={ViewPersonPage}
        />
        <PrivateRoute
          exact
          path="/person/:personId/edit"
          component={EditPersonPage}
        />
        <PrivateRoute
          exact
          path="/product_management/add_product"
          component={AddProductPage}
        />
        <PrivateRoute
          exact
          path="/product_management/:prodId/edit"
          component={AddProductPage}
        />
        <PrivateRoute
          exact
          path="/product_management/:prodId/show"
          component={ViewProductPage}
        />
        <PrivateRoute
          exact
          path="/product_management"
          component={ProductManagement}
        />
        <PrivateRoute exact path="/list_group_product" component={ListGroupProduct} />
        <PrivateRoute exact path="/product_management/add_group_product" component={AddProductGroup} />
        <PrivateRoute exact path="/group_product/:prodId/edit" component={AddProductGroup} />
        <PrivateRoute exact path="/group_product/:prodId/view" component={AddProductGroup} />

		<PrivateRoute exact path="/list_group_inventory" component={ListGroupInventory} />
		<PrivateRoute exact path="/group_inventory/add_group_inventory" component={AddGroupInventory} />
		<PrivateRoute exact path="/group_inventory/:inventoryId/edit" component={AddGroupInventory} />
		<PrivateRoute exact path="/group_inventory/:inventoryId/view" component={AddGroupInventory} />

		<PrivateRoute exact path="/tracking" component={Tracking} />
		<PrivateRoute exact path="/carriers_list" component={CarriersList} />
		<PrivateRoute exact path="/shipping_history" component={ShippingHistory} />
		<PrivateRoute exact path="/docket_detail/:id/show" component={DocketDetail} />
        <PublicRoute path="*" component={PageNotFound} layout={""} />
      </Switch>
      <ToastContainer />
    </Router>
  );
}

export default App;
