import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
//import { postData } from "../../service/Common";
//import { ToastMessage } from "../../service/ToastMessage";
import { get_barcode_action } from "../../actions/InventoryActions.js";
import { useDispatch, useSelector } from "react-redux";
import { ComponentToPrint } from "./BarcodeToPrint";
import { useReactToPrint } from "react-to-print";

const ViewBarCodePage = (props) => {
  var IMG_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [barCodeData, setBarcodeData] = useState("");

  const getInventoryDetails = useSelector(
    (state) => state.InventoryReducers.GetBarcodeDetailReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.invtId) {
      dispatch(get_barcode_action(props.match.params.invtId));
    }
  }, [props.match.params.invtId, dispatch]);

  useEffect(() => {
    if (getInventoryDetails.successgetBarcodeDetail !== undefined) {
      setBarcodeData(getInventoryDetails.successgetBarcodeDetail.data);
    }
  }, [getInventoryDetails]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">
              Dashboard {console.log("testes", barCodeData.src)}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/inventory_management">Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Barcode
          </li>
        </ol>
        <Link className="btn btn-dark pull-right" to="/inventory_management">
          Back
        </Link>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">View Barcode</h6>
              </div>
              <div className="card_box_border">
                <Row className="justify-content-center">
                  <Col lg={12}>
                    {getInventoryDetails.loading ? (
                      <div className="form-control text-info text-center">
                        Loading...
                      </div>
                    ) : getInventoryDetails.successgetBarcodeDetail ? (
                      <div className="forms-sample row">
                        <div className="form-group col-md-12 text-center">
                          <label className="w-100" htmlFor="serialNumber">
                            Item Barcode
                          </label>
                          {barCodeData.src ? (
                            <img
                              src={"data:image/jpg;base64," + barCodeData.src}
                              alt="base64"
                            ></img>
                          ) : (
                            "Barcode not found."
                          )}
                        </div>
                        <div className="w-100 text-center">
                          <button
                            className="btn btn-dark"
                            onClick={handlePrint}
                          >
                            Print !
                          </button>
                        </div>
                        <div style={{ display: "none" }}>
                          <ComponentToPrint
                            ref={componentRef}
                            data={barCodeData.src}
                          />
                        </div>
                      </div>
                    ) : getInventoryDetails.failedgetBarcodeDetail ? (
                      <div className="text-center">
                        <div className=" form-control text-danger mb-3">
                          'Error'
                        </div>
                        <Link
                          to="/inventory_management"
                          className="btn btn-danger"
                        >
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewBarCodePage;
