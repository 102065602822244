import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_user_detail } from "../../actions/UserActions";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const ShowUserPage = (props) => {
  const get_user_info = useSelector((state) => state.GetUserDetailReducer);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (props.match.params.uIds) {
      dispatch(get_user_detail(props.match.params.uIds));
    }
  }, [props.match.params.uIds, dispatch]);

  useEffect(() => {
    if (get_user_info) {
      setUserData(get_user_info);
    }
  }, [get_user_info]);
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/usermanagement">User</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Show User Detail
          </li>
        </ol>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Show User Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {userData.loading ? (
                      <div className="text-center text-info mb-3 form-control">
                        <strong>Loading...</strong>
                      </div>
                    ) : userData.successgetuserDetail ? (
                      <>
                        <Row>
                          <Col md={4} className="form-group ">
                            <label>User ID</label>
                            <label className="form-control">
                              {userData.successgetuserDetail.data.id}
                            </label>
                          </Col>

                          <Col md={4} className="form-group ">
                            <label>Name</label>
                            <label className="form-control">
                              {userData.successgetuserDetail.data.firstName +
                                " " +
                                userData.successgetuserDetail.data.lastName}
                            </label>
                          </Col>

                          <Col md={4} className="form-group ">
                            <label>Email</label>
                            <label className="form-control">
                              {userData.successgetuserDetail.data.email}
                            </label>
                          </Col>

                          <Col md={4} className="form-group ">
                            <label>Mobile</label>
                            <label className="form-control">
                              {userData.successgetuserDetail.data.mobile}
                            </label>
                          </Col>

                          <Col md={4} className="form-group ">
                            <label>Role</label>
                            <label className="form-control">
                              {userData.successgetuserDetail.data.role}
                            </label>
                          </Col>

                          <Col md={4} className="form-group ">
                            <label>Status</label>
                            <label className="form-control">
                              <span className="">
                                <span
                                  className={`badge ${
                                    userData.successgetuserDetail.data
                                      .status === "1"
                                      ? "badge-success"
                                      : "badge-danger"
                                  }`}
                                >
                                  {userData.successgetuserDetail.data.status ===
                                  "1"
                                    ? "Active"
                                    : "Deactive"}
                                </span>
                              </span>
                            </label>
                          </Col>

                          <Col md={4} className="form-group ">
                            <label>Created On</label>
                            <label className="form-control">
                              {userData.successgetuserDetail.data.created}
                            </label>
                          </Col>
                        </Row>
                      </>
                    ) : userData.failedgetuserDetail ? (
                      <div className="form-control mb-4">
                        <h6 className="text-center text-danger">
                          {userData.failedgetuserDetail.message}
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-left">
                      <Link to={`/usermanagement`} className="btn btn-dark">
                        Back
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ShowUserPage;
