import Autocomplete from "react-google-autocomplete";

function AutocompleteField(props) {

    return (
        <Autocomplete
            {...props}
            apiKey={'AIzaSyChTcMUCY9Zw3j00st0uKkqTz0RGlOpea8'}
            options={{
                types: ["(regions)"],
                componentRestrictions: { country: "in" },
            }}
            onPlaceSelected={props.onPlaceSelected}
        />)
}
export default AutocompleteField;