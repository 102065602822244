
export const GET_PLAZA_DETAIL_REQUEST = "GET_PLAZA_DETAIL_REQUEST";
export const GET_PLAZA_DETAIL_SUCCESS_INFO = "GET_PLAZA_DETAIL_SUCCESS_INFO";
export const GET_PLAZA_DETAIL_FAIL_ERROR = "GET_PLAZA_DETAIL_FAIL_ERROR";

export const GET_OFFICE_WAREHOUSE_DETAIL_REQUEST = "GET_OFFICE_WAREHOUSE_DETAIL_REQUEST";
export const GET_OFFICE_WAREHOUSE_DETAIL_SUCCESS_INFO = "GET_OFFICE_WAREHOUSE_DETAIL_SUCCESS_INFO";
export const GET_OFFICE_WAREHOUSE_DETAIL_FAIL_ERROR = "GET_OFFICE_WAREHOUSE_DETAIL_FAIL_ERROR";

export const GET_PLAZA_OPTION_LIST_REQUEST="GET_PLAZA_OPTION_LIST_REQUEST";
export const GET_PLAZA_OPTION_LIST_SUCCESS_INFO="GET_PLAZA_OPTION_LIST_SUCCESS_INFO";
export const GET_PLAZA_OPTION_LIST_FAIL_ERROR="GET_PLAZA_OPTION_LIST_FAIL_ERROR";

export const GET_PLAZAS_BY_PORJECT_ID_REQUEST = "GET_PLAZAS_BY_PORJECT_ID_REQUEST";
export const GET_PLAZAS_BY_PORJECT_ID_SUCCESS = "GET_PLAZAS_BY_PORJECT_ID_SUCCESS";
export const GET_PLAZAS_BY_PORJECT_ID_FAIL_ERROR = "GET_PLAZAS_BY_PORJECT_ID_FAIL_ERROR";

export const GET_ASSIGN_PLAZA_LIST_REQUEST="GET_ASSIGN_PLAZA_LIST_REQUEST";
export const GET_ASSIGN_PLAZA_OPTION_LIST="GET_ASSIGN_PLAZA_OPTION_LIST";
export const GET_ASSIGN_PLAZA_OPTION_LIST_FAIL_ERROR="GET_ASSIGN_PLAZA_OPTION_LIST_FAIL_ERROR";