import {
	GET_WAREHOUSE_DETAIL_REQUEST,
	GET_WAREHOUSE_DETAIL_SUCCESS_INFO,
	GET_WAREHOUSE_DETAIL_FAIL_ERROR,
	GET_WAREHOUSES_BY_PROJECT_ID_REQUEST,
	GET_WAREHOUSES_BY_PROJECT_ID_SUCCESS,
	GET_WAREHOUSES_BY_PROJECT_ID_FAIL_ERROR,
	GET_WAREHOUSES_BY_PLAZA_ID_REQUEST,
	GET_WAREHOUSES_BY_PLAZA_ID_SUCCESS,
	GET_WAREHOUSES_BY_PLAZA_ID_FAIL_ERROR,
	GET_WAREHOUSE_OPTION_LIST_REQUEST,
	GET_WAREHOUSE_OPTION_LIST_SUCCESS,
	GET_WAREHOUSE_OPTION_LIST_FAIL_ERROR
} from "../constants/WarehouseConstants";
import { postData } from "../service/Common";

const get_warehouse_action = (Id) => async (dispatch) => {
	dispatch({ type: GET_WAREHOUSE_DETAIL_REQUEST, payload: {} });
	let req = { id: Id }
	postData("/api/get_warehouse_detail", req)
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_WAREHOUSE_DETAIL_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_WAREHOUSE_DETAIL_FAIL_ERROR, payload: error.data });
		});
};

const get_warehouse_by_project_id_action = (Id) => async (dispatch) => {
	dispatch({ type: GET_WAREHOUSES_BY_PROJECT_ID_REQUEST, payload: {} });
	postData("/api/get_warehouse_by_project_id", { project_id: Id })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_WAREHOUSES_BY_PROJECT_ID_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_WAREHOUSES_BY_PROJECT_ID_FAIL_ERROR, payload: error.data });
		});
}

const get_warehouse_by_plaza_id_action = (Id) => async (dispatch) => {
	dispatch({ type: GET_WAREHOUSES_BY_PLAZA_ID_REQUEST, payload: {} });
	postData("/api/get_warehouse_by_plaza_id", { plaza_id: Id })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_WAREHOUSES_BY_PLAZA_ID_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_WAREHOUSES_BY_PLAZA_ID_FAIL_ERROR, payload: error.data });
		});
}

const get_warehouse_option_list_action = () => async (dispatch) => {
	dispatch({ type: GET_WAREHOUSE_OPTION_LIST_REQUEST, payload: {} });
	postData("/api/get_warehouse_option_list", '', { method_type: 'get' })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_WAREHOUSE_OPTION_LIST_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_WAREHOUSE_OPTION_LIST_FAIL_ERROR, payload: error.data });
		});
}
export { get_warehouse_action, get_warehouse_by_project_id_action, get_warehouse_by_plaza_id_action, get_warehouse_option_list_action };