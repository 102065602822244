import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_product_detail_action } from "../../actions/ProductActions.js";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/ReactTable";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import { IoDocumentTextOutline } from "../../components/SvgIcon";

const ViewProductPage = (props) => {
  var IMG_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [productData, setProductData] = useState({
    prodName: "",
    brand: "",
    model: "",
    manufacturedBy: "",
    manufacturedDate: null,
    hasWarranty: "1",
    serviceProvider: "",
    purchaseFrom: "",
    purchaseDate: null,
    publisher: "",
    version: "",
    warrantyDuration: "0",
    warrantyExpiryDate: "",
    imgFiles: [],
    docFiles: [],
    unit: "",
    unitvalue: "",
  });
  const [productDataerr, setProductDataerr] = useState("");
  const [loading, setLoading] = useState(false);
  const pageSize = 10;
  const search = "";
  const [logDataList, setLogDataList] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const sortBy = [];
  const getProductDetails = useSelector(
    (state) => state.ProductReducers.GetProductDetailReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.match.params.prodId) {
      dispatch(get_product_detail_action(props.match.params.prodId));
    }
  }, [props.match.params.prodId, dispatch]);
  useEffect(() => {
    if (getProductDetails.successgetProductDetail !== undefined) {
      setProductData(getProductDetails.successgetProductDetail.data.dataArr);
    }
    if (getProductDetails.failedgetProductDetail !== undefined) {
      setProductDataerr(getProductDetails.failedgetProductDetail.message);
    }
  }, [getProductDetails]);

  /* fetchData() */
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_log_id",
        Cell: (prop) => (
          <span>
            <Link to={`/logs/${prop.original.id}/show`}>
              {prop.original._log_id}
            </Link>
          </span>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "User By",
        accessor: "u_name",
      },
      {
        Header: "Created",
        accessor: "created",
      },
    ],
    []
  );
  const fetchData = React.useCallback(
    ({ pageSize, page, filter, sorted }) => {
      let req = {
        page_size: pageSize,
        start: page,
        search: filter,
        sorted: sorted,
        product_id: props.match.params.prodId,
      };
      setLoading(true);
      postData(`/api/get_logs_of_product`, req)
        .then((result) => {
          let datares = result.data;
          setLogDataList(datares.data);
          let tmp_pages = Math.ceil(parseInt(datares.total) / pageSize);
          setPages(tmp_pages);
          setPage(page);
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    },
    [props]
  );

  const removeAttachment = (id) => {
    ConfirmationAlertBox({
      title: "Confirmation",
      message: "Are You sure want to delete?",
    }).then((result) => {
      if (result.status && id !== null) {
        postData(`/api/remove_attachment_by_id`, { id: id })
          .then((response) => {
            let res = response.data;
            ToastMessage(res.message, "s");
            dispatch(get_product_detail_action(props.match.params.prodId));
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/product_management">Product</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Product
          </li>
        </ol>
        <Link className="btn btn-dark pull-right" to="/product_management">
          Back
        </Link>
      </nav>
      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">View Product</h6>
              </div>
              <div className="card_box_border">
                <Row className="justify-content-center">
                  <Col lg={12}>
                    {getProductDetails.loading ? (
                      <div className="form-control text-info text-center">
                        Loading...
                      </div>
                    ) : getProductDetails.successgetProductDetail ? (
                      <div className="forms-sample row">
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="prodName">
                            Name:{" "}
                            <strong className="text-primary">
                              {productData._product_id}
                            </strong>
                          </label>
                          <div className="form-control">
                            {productData.prodName}
                          </div>
                        </div>
                        {productData.assign_with ? (
                          <div className="form-group col-md-4">
                            <label className="w-100" htmlFor="prodName">
                              Assign With:{" "}
                              <strong className="text-primary">
                                {productData.assign_with}
                              </strong>
                            </label>
                            <div className="form-control">
                              <Link
                                to={
                                  productData.assign_with === "project"
                                    ? "/project_management/" +
                                      productData.assign_id +
                                      "/show"
                                    : productData.assign_with === "plaza"
                                    ? "/plaza/" +
                                      productData.assign_id +
                                      "/show"
                                    : productData.assign_with === "office"
                                    ? "/office/" +
                                      productData.assign_id +
                                      "/show"
                                    : productData.assign_with === "warehouse"
                                    ? "/warehouse/" +
                                      productData.assign_id +
                                      "/show"
                                    : ""
                                }
                              >
                                {productData.assignName}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="manufacturedBy">
                            Manufactured By
                          </label>
                          <div className="form-control">
                            {productData.manufacturedBy_name}
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="model">
                            Model
                          </label>
                          <div className="form-control">
                            {productData.model_name}
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="brand">
                            Brands
                          </label>
                          <div className="form-control">
                            {productData.brand_name}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="productUrl">
                            Product Url
                          </label>
                          <div className="form-control">
                            {productData.productUrl}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                            Version
                          </label>
                          <div className="form-control">
                            {productData.version}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                            Measuring Unit
                          </label>
                          <div className="form-control">
                            {productData.unitname}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="version">
                            Value
                          </label>
                          <div className="form-control">
                            {productData.unitvalue}
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="created">
                            Created
                          </label>
                          <div className="form-control">
                            {productData.created}
                          </div>
                        </div>
                        {productData.imgFiles.length ? (
                          <div className="form-group col-md-12">
                            <label className="w-100" htmlFor="created">
                              Image Attachments
                            </label>
                            {productData.imgFiles.map((val, x) => (
                              <div className="upload_image_AR " key={x}>
                                {/* <span
                                  className="cgl-btn"
                                  data={val.id}
                                  onClick={() => removeAttachment(val.id)}
                                >
                                  x
                                </span> */}
                                <Link
                                  to={{
                                    pathname: val.src,
                                  }}
                                  target="_blank"
                                >
                                  <img
                                    src={val.src}
                                    alt={val.file_name}
                                    height="35px"
                                    width="auto"
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {productData.docFiles.length ? (
                          <div className="form-group col-md-12">
                            <label className="w-100" htmlFor="created">
                              Doc Attachments
                            </label>
                            {productData.docFiles.map((val, x) => (
                              <div className="doc_file_view_box_v1" key={x}>
                                {/* <span
                                  className="clg-btn"
                                  data={val.id}
                                  onClick={() => removeAttachment(val.id)}
                                >
                                  x
                                </span> */}
                                <Link
                                  to={{
                                    pathname: IMG_BASE_URL + "/" + val.src,
                                  }}
                                  target="_blank"
                                >
                                  <IoDocumentTextOutline />
                                  {val.file_name}
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : getProductDetails.failedgetProductDetail ? (
                      <div className="text-center">
                        <div className=" form-control text-danger mb-3">
                          {productDataerr}
                        </div>
                        <Link
                          to="/product_management"
                          className="btn btn-danger"
                        >
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewProductPage;
