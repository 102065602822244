import { Link } from "react-router-dom";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ToastMessage } from "../../service/ToastMessage";
import { get_role_details } from "../../actions/RoleActions";
import { get_permission_option_list } from "../../actions/PermissionActions";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../../service/Common";
import Table from "../../components/ReactTable";
import { TrashSvgIcon } from "../../components/SvgIcon";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import Select from "react-select-plus";
import "react-select-plus/dist/react-select-plus.css";
import $ from "jquery";

function ShowRolePage(props) {
  const get_roleDetail = useSelector((state) => state.GetRoleDetailReducer);
  const getPermissionOptionList = useSelector(
    (state) => state.GetPermissoinOptionListReducer.successPermissionOptionList
  );
  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState({
    id: "",
    name: "",
    slug: "",
    status: 1,
    created: "",
  });
  const [loading, setLoading] = useState(false);
  const [apLoading, setApLoading] = useState(false);
  const [showAssignedPermission, setShowAssignedPermission] = useState(true);
  const [showAddAssignedPermission, setShowAddAssignedPermission] =
    useState(false);
  const [permissionOptionList, setPermissionOptionList] = useState([]);
  const [selectPermission, setSelectPermission] = useState([]);
  const [rapData, setRapData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const search = "";
  const pageSize = 10;
  const sorted = [];

  const [key, setKey] = useState("Show_Role");

  useEffect(() => {
    if (props.match.params.rId) {
      dispatch(get_role_details(props.match.params.rId));
      dispatch(get_permission_option_list());
      setLoading(true);
    }
  }, [props.match.params.rId, dispatch]);

  useEffect(() => {
    if (get_roleDetail) {
      setRoleData(get_roleDetail);
    }

    if (getPermissionOptionList) {
      let tempDat = [];
      getPermissionOptionList.data.map((val, i) => {
        tempDat.push({ value: val.id, label: val.name });
        return true;
      });
      setPermissionOptionList(tempDat);
    }
  }, [get_roleDetail, props.history, getPermissionOptionList]);

  const columns = [
    {
      Header: "R Name",
      accessor: "name",
    } /*,
    {
      Header: "R Slug",
      accessor: "slug",
    }*/,
    {
      Header: "P Name",
      accessor: "pname",
    },
    {
      Header: "P Slug",
      accessor: "pslug",
    },
    {
      Header: "Action",
      Cell: (props) => (
        <span className="">
          <button
            onClick={() =>
              deleteHandller(
                roleData.successRolesDetail.data.id,
                props.original.pslug
              )
            }
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Remove User"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];
  const fetchData = React.useCallback(
    ({ pageSize, page, search, sorted }) => {
      let req = {
        page_size: pageSize,
        start: page,
        search: search,
        sorted: sorted,
        id: props.match.params.rId,
      };
      postData(`/api/get_role_assigned_permissions_list`, req)
        .then((res) => {
          setRapData(res.data.data);
          let pages = Math.ceil(parseInt(res.data.total) / pageSize);
          setPages(pages);
          setPage(page);
          setLoading(false);
        })
        .catch((err) => {
          ToastMessage(err.data.message, "e");
          setLoading(false);
        });
    },
    [props.match.params.rId]
  );
  const showClickHAndller = (name) => {
    if (name === "list") {
      setShowAssignedPermission(!showAssignedPermission ? true : false);
      if (showAssignedPermission === false) {
        setShowAddAssignedPermission(false);
      }
    }
    if (name === "add") {
      setShowAddAssignedPermission(!showAddAssignedPermission ? true : false);
      if (showAddAssignedPermission === false) {
        setShowAssignedPermission(false);
      }
    }
  };

  const deleteHandller = (rid, slug) => {
    ConfirmationAlertBox(
      "Are you sure want to delete role permission?",
      {}
    ).then((status) => {
      let req = { role_id: rid, slug: slug };
      postData(`/api/delete_assigned_role_permission`, req)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          fetchData({ pageSize, page, search, sorted });
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
        });
    });
  };
  const addrolePermissionHandller = (e) => {
    e.preventDefault();
    if ($("#assingnewrolePremissionForm").valid()) {
      let tmpreq = [];
      selectPermission.map((val) => {
        tmpreq.push(val.value);
        return true;
      });
      setApLoading(true);
      postData(`/api/set_assign_role_permission`, {
        role_id: roleData.successRolesDetail.data.id,
        permission_ids: tmpreq,
      })
        .then((result) => {
          ToastMessage(result.data.message, "s");
          setSelectPermission("");
          setApLoading(false);
        })
        .catch((err) => {
          ToastMessage(err.data.message, "e");
          setApLoading(false);
        });
    }
  };
  const selectHandller = (e) => {
    if (e) {
      setSelectPermission(e);
    }
  };
  const tabChange = (k) => {
    if (k == "Add_Role") {
      setShowAddAssignedPermission(true);
    } else if (k == "Show_Role") {
      setShowAssignedPermission(true);
      showClickHAndller("list");
    }
    setKey(k);
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/roles">Roles</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Show Role Detail
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box  margin_set_1v1">
                <h6 className="card-title ">Show Role Detail</h6>
              </div>
              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    {roleData.loading ? (
                      <div className="text-center mb-2">
                        <h6 className="form-control text-danger">Loading...</h6>
                      </div>
                    ) : roleData.successRolesDetail ? (
                      <>
                        <Row>
                          <Col lg={2}>
                            <label>Role ID</label>
                            <div className="form-control">
                              {roleData.successRolesDetail.data.id}
                            </div>
                          </Col>

                          <Col lg={3}>
                            <label>Role Name</label>
                            <div className="form-control">
                              {roleData.successRolesDetail.data.name}
                            </div>
                          </Col>

                          <Col lg={2}>
                            <label>Role Status</label>
                            <div className="form-control">
                              <span className="">
                                <span
                                  className={`badge ${
                                    roleData.successRolesDetail.data.status ===
                                    "1"
                                      ? "badge-success"
                                      : "badge-danger"
                                  }`}
                                >
                                  {roleData.successRolesDetail.data.status ===
                                  "1"
                                    ? "Active"
                                    : "Deactive"}
                                </span>
                              </span>
                            </div>
                          </Col>

                          <Col lg={3}>
                            <label>Role Created</label>
                            <label className="form-control">
                              {roleData.successRolesDetail.data.created}
                            </label>
                          </Col>
                        </Row>
                      </>
                    ) : roleData.failedRolesDetail ? (
                      <div className="mb-5 text-center">
                        <h6 className="form-control text-danger">
                          {roleData.failedRolesDetail.message}
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}

                    <div class="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                      <h6 class="card-title ">Role Permissoin</h6>
                    </div>

                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => tabChange(k)}
                      className="mb-3 custom_tab_"
                    >
                      <Tab eventKey="Show_Role" title="Show Role Permission">
                        {showAssignedPermission ? (
                          <div className="row">
                            <Col lg={12} className="grid-margin stretch-card">
                              <div className="card">
                                <div className="card-body">
                                  <Table
                                    data={rapData}
                                    columns={columns}
                                    loading={loading}
                                    defaultPageSize={pageSize}
                                    fetchData={fetchData}
                                    page={page}
                                    pages={pages}
                                    sorted={sorted}
                                    filter={search}
                                    showPagination={true}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        ) : (
                          ""
                        )}
                      </Tab>
                      <Tab eventKey="Add_Role" title="Add Role Permission">
                        {showAddAssignedPermission ? (
                          <div className="row">
                            <Col lg={12}>
                              <form
                                id="assingnewrolePremissionForm"
                                method="POST"
                              >
                                <div className="form-group row">
                                  <Col lg={12}>
                                    <p>
                                      Assigning Permissoin for "
                                      {roleData.successRolesDetail.data.name}"
                                      Role
                                    </p>
                                    <label>Select Permission</label>
                                    <div className="cstm_select">
                                      <Select
                                        id="permission1"
                                        name="permission1"
                                        options={permissionOptionList}
                                        className="form-"
                                        joinValues={true}
                                        multi={true}
                                        clearable={true}
                                        required={true}
                                        placeholder="Select the permission"
                                        autoComplete="off"
                                        onChange={(e) => selectHandller(e)}
                                        value={selectPermission}
                                        inputRenderer={(props) => (
                                          <input
                                            {...props}
                                            name="permissoin"
                                            data-msg-required="Please select the permission."
                                          />
                                        )}
                                      />
                                      <label
                                        className="error"
                                        htmlFor="permissoin"
                                      ></label>
                                    </div>
                                  </Col>

                                  <Col lg={12}>
                                    <button
                                      disabled={apLoading ? true : false}
                                      onClick={addrolePermissionHandller}
                                      type="submit"
                                      className="btn btn-success mr-2"
                                    >
                                      {apLoading ? "Sending..." : "Save"}
                                    </button>
                                    <button
                                      onClick={() =>
                                        setShowAddAssignedPermission(
                                          !showAddAssignedPermission
                                            ? true
                                            : false
                                        )
                                      }
                                      type="button"
                                      className="btn btn-dark"
                                    >
                                      Cancel
                                    </button>
                                  </Col>
                                </div>
                              </form>
                            </Col>
                          </div>
                        ) : (
                          ""
                        )}
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default ShowRolePage;
