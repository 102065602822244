import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { postData } from "../../service/Common";
import Table from "../../components/ReactTable";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";

function CarriersList(props) {
  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Courier Name",
      accessor: "courier_name",
    },
    {
      Header: "Logo",
      accessor: "image",
	  sortable: false,
	  Cell: (row) => {
		return <div><img height={34} src={row.original.image}/></div>
	  },
    }
  ];
  const [CarrierData, setCarrierData] = useState([]);
  const page_size = 20;
  const search = "";
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const sortedBy = "";

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    setLoading(true);
    postData(`/api/get_courier_name`, req)
      .then((result) => {
        var res = result.data;
        setCarrierData(res.data);
        let tmppages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmppages);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
		//ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
 
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
		  Carriers List
          </li>
        </ol>
        {/*<Link
          type="button"
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          to="/company/add"
        >
          <PlusSvgIcon className="mr-2" />
          Add New Company
		</Link>*/}
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0"> Carriers List</h6>
              </div>
              <Table
                data={CarrierData}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={false}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CarriersList;
