import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import React, { useEffect, useState } from "react";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import $ from "jquery";
import { get_master_details } from "../../actions/MasterActions";
import SelectDropdown from "../../components/ReactSelectPlus";
import { useDispatch, useSelector } from "react-redux";

function MastersListPage(props) {
  const columns = [
    {
      Header: "ID",
      accessor: "id", // String-based value accessors!
      sortable: true,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (props) => <span>{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => (
        <span className="">
          <span
            className={`badge ${
              props.value === "Active" ? "badge-success" : "badge-danger"
            }`}
          >
            {props.value}
          </span>
        </span>
      ),
    },
    {
      Header: "Created On",
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created}</span>, // Custom cell components!
    },
    {
      Header: (props) => <span>Action</span>, // Custom header components!
      Cell: (props) => (
        <span className="">
          {/* <Link
            to={`master/${props.original.id}/show`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Show Detail"
          >
            <FiEye />
          </Link> */}
          <Link
            //to={`master/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Edit Detail"
            onClick={(e) => editMasterClick(e, props.original.id)}
          >
            <EditSvgIcon />
          </Link>
          {/* <button
            onClick={() => deleteHandller(props.original.id)}
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Remove User"
          >
            <TrashSvgIcon />
          </button> */}
        </span>
      ),
      sortable: false,
    },
  ];

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  //const [pageSize, setPageSize] = useState(5);
  const pageSize = 5;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const search = "";
  const sorted = [];
  const [showMasterDiv, setShowMasterDiv] = useState(0);
  const [masterData, setMasterData] = useState({
    name: "",
    type: "",
    status: 1,
  });
  const [editId, setEditId] = useState(0);

  const get_masterDetail = useSelector(
    (state) => state.MasterReducers.GetMasterDetailReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      get_masterDetail.loading === false &&
      get_masterDetail &&
      get_masterDetail.successMasterDetail !== undefined
    ) {
      setMasterData(get_masterDetail.successMasterDetail.data);
      setLoading(get_masterDetail.loading);
    }

    if (get_masterDetail.failedMastersDetail) {
      ToastMessage(get_masterDetail.failedMasterDetail.message);
      setLoading(get_masterDetail.loading);
      props.history.push(`/masters`);
    }
    // }
  }, [get_masterDetail, props]);

  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    setLoading(true);
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    postData("/api/get_masterlist", req)
      .then((response) => {
        var res = response.data;
        setData(res.data);
        let pages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(pages);
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        ToastMessage(err.data.message, "e");
        setLoading(false);
      });
  }, []);

  const deleteHandller = (iD) => {
    ConfirmationAlertBox("Are you sure want to remove it", {}).then((res) => {
      if (res.status) {
        postData(`/api/delete_master`, { id: iD })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            fetchData({ pageSize, page, search, sorted });
          })
          .catch((error) => {
            ToastMessage(error.data.message, "e");
          });
      }
    });
  };

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewMasterForm").valid()) {
      setLoading(true);
      postData(`/api/add_master`, masterData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          fetchData(pageSize, page, search, sorted);
          formReset();
          setLoading(false);
          setShowMasterDiv(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const updateHandller = (e) => {
    e.preventDefault();
    if ($("#addnewMasterForm").valid()) {
      setLoading(true);
      postData(`/api/update_master`, masterData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          setLoading(false);
          fetchData(pageSize, page, search, sorted);
          formReset();
          setShowMasterDiv(false);
          props.history.push(`/masters`);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const formReset = () => {
    setMasterData({ name: "", type: "", status: 1 });
  };

  var masterTypeOptions = [
    { value: 1, label: "Manufacture" },
    { value: 2, label: "Models" },
    { value: 3, label: "Brands" },
    { value: 4, label: "Publisher" },
    { value: 5, label: "Measuring Unit" },
    { value: 6, label: "Courier Service" },
  ];

  const editMasterClick = (event, id) => {
    event.preventDefault();
    if (id) {
      setEditId(true);
      dispatch(get_master_details(id));
      setShowMasterDiv(true);
    } else {
      setEditId(false);
      setShowMasterDiv(false);
    }
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Master Management
          </li>
        </ol>
        <Link
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
          //  to="/addnewmaster"
          onClick={() => {
            setShowMasterDiv(showMasterDiv ? false : true);
            formReset();
          }}
        >
          <PlusSvgIcon className="mr-2" />
          Add New Master
        </Link>
      </nav>
      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0">Master Management List</h6>
              </div>

              {showMasterDiv ? (
                <div className="card_box_border mb-3">
                  <Row>
                    <Col>
                      <form
                        id="addnewMasterForm"
                        method="POST"
                        className="forms-sample"
                      >
                        <div className="form-group row">
                          <Col lg={12}>
                            <label for="master_name">Master Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              autocomplete="off"
                              placeholder="Name"
                              onChange={(e) =>
                                setMasterData({
                                  ...masterData,
                                  name: e.target.value,
                                })
                              }
                              value={masterData.name}
                              data-rule-required={true}
                            />
                          </Col>
                        </div>
                        <div className="form-group row">
                          <Col lg={12}>
                            <label for="master_name">Select Type</label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id={"type"}
                                name={"type"}
                                required={true}
                                options={masterTypeOptions}
                                sampleValue={true}
                                joinValues={true}
                                searchable={true}
                                clearable={true}
                                autoComplete="off"
                                placeholder="Select Type"
                                onChange={(e) =>
                                  setMasterData({
                                    ...masterData,
                                    type: e != null ? e.value : null,
                                  })
                                }
                                value={masterData.type}
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="type"
                                    data-msg-required={"Please select type."}
                                  />
                                )}
                              />
                              <label
                                className="error select_error_label"
                                htmlFor="type"
                              ></label>
                            </div>
                          </Col>
                        </div>
                        {editId ? (
                          <div className="form-group row">
                            <Col lg={12}>
                              <div className="d-flex">
                                Status:
                                <label
                                  className="d-inline-flex align-items-center mr-2 ml-2"
                                  for="master_name"
                                >
                                  <input
                                    type="radio"
                                    id="statiusactive"
                                    name="status"
                                    className="mr-1"
                                    value={1}
                                    onChange={(e) =>
                                      setMasterData({
                                        ...masterData,
                                        status: e.target.value,
                                      })
                                    }
                                    checked={
                                      masterData.status === "1" ? true : false
                                    }
                                  />{" "}
                                  Active
                                </label>
                                <label
                                  className="d-inline-flex align-items-center mr-2"
                                  htmlFor="statusdeactive"
                                >
                                  <input
                                    type="radio"
                                    id="statusdeactive"
                                    name="status"
                                    className="mr-1"
                                    value={0}
                                    onChange={(e) =>
                                      setMasterData({
                                        ...masterData,
                                        status: e.target.value,
                                      })
                                    }
                                    checked={
                                      masterData.status === "0" ? true : false
                                    }
                                  />{" "}
                                  Deactive
                                </label>
                              </div>
                            </Col>
                          </div>
                        ) : (
                          ""
                        )}

                        <Row>
                          <Col lg={12}>
                            {editId ? (
                              <button
                                type="submit"
                                className="btn btn-primary mr-2"
                                onClick={updateHandller}
                                disabled={loading ? true : false}
                              >
                                {loading ? "Loading" : "Update"}
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary mr-2"
                                onClick={submitHandller}
                                disabled={loading ? true : false}
                              >
                                {loading ? "Loading" : "Submit"}
                              </button>
                            )}

                            <button
                              type="button"
                              className="btn btn-primary mr-2"
                              onClick={() => {
                                setShowMasterDiv(showMasterDiv ? false : true);
                                formReset();
                              }}
                            >
                              {"Cancel"}
                            </button>
                          </Col>
                        </Row>
                      </form>
                      <br />
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}

              <Table
                data={data}
                columns={columns}
                loading={loading}
                defaultPageSize={pageSize}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sorted}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default MastersListPage;
