import {
	GET_ROLES_OPTLIST_REQUEST,
	GET_ROLES_OPTLIST_SUCCESS_INFO,
	GET_ROLES_OPTLIST_FAIL_ERROR,
	GET_ROLE_DETAIL_REQUEST,
	GET_ROLE_DETAIL_SUCCESS_INFO,
	GET_ROLE_DETAIL_FAIL_ERROR,
} from "../constants/RoleContants";
import { postData } from "../service/Common";

const get_roles_optionlist = () => async (dispatch) => {
	dispatch({ type: GET_ROLES_OPTLIST_REQUEST, payload: {} });
	postData("/api/get_roles_option_list", {})
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_ROLES_OPTLIST_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_ROLES_OPTLIST_FAIL_ERROR, payload: error.data });
		});
};
const get_role_details = (id) => async (dispatch) => {
	dispatch({ type: GET_ROLE_DETAIL_REQUEST, payload: {} });
	postData("/api/get_role", { id: id })
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_ROLE_DETAIL_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_ROLE_DETAIL_FAIL_ERROR, payload: error.data });
		});
};
export { get_roles_optionlist, get_role_details };