/*import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers/index'

export default function configureStore(preloadedState) {
  
  const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = compose(...enhancers)
  
  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}*/

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers/index'
import {
	USER_LOGIN_LOCALSTORAGE_TOKEN_KEY,
	USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY,
	USER_LOGIN_LOCALSTORAGE_TOKEN_EXPIRE,
} from "./config/Constants";

const checkedAuthtokenTime = () => (next) => (action) => {
  let exp_time = JSON.parse(localStorage.getItem(USER_LOGIN_LOCALSTORAGE_TOKEN_EXPIRE));
  if (exp_time < (Math.floor(new Date() / 1000))) {
		next(action);
		localStorage.removeItem(USER_LOGIN_LOCALSTORAGE_TOKEN_KEY);
		localStorage.removeItem(USER_LOGIN_LOCALSTORAGE_USER_INFO_KEY);
		localStorage.removeItem(USER_LOGIN_LOCALSTORAGE_TOKEN_EXPIRE);
	}
	next(action);
}

const composedEnhancer = composeWithDevTools(
  // EXAMPLE: Add whatever middleware you actually want to use here
  applyMiddleware(thunkMiddleware, checkedAuthtokenTime)
  // other store enhancers if any
)

const store = createStore(rootReducer, composedEnhancer)
export default store