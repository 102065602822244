import {
	GET_OFFICE_DETAIL_REQUEST,
	GET_OFFICE_DETAIL_SUCCESS_INFO,
	GET_OFFICE_DETAIL_FAIL_ERROR,
	GET_OFFICES_BY_PROJECT_ID_REQUEST,
	GET_OFFICES_BY_PROJECT_ID_SUCCESS,
	GET_OFFICES_BY_PROJECT_ID_FAIL_ERROR,
	GET_OFFICES_BY_PLAZA_ID_REQUEST,
	GET_OFFICES_BY_PLAZA_ID_SUCCESS,
	GET_OFFICES_BY_PLAZA_ID_FAIL_ERROR,
	GET_OFFICE_OPTION_LIST_REQUEST,
	GET_OFFICE_OPTION_LIST_SUCCESS,
	GET_OFFICE_OPTION_LIST_FAIL_ERROR
} from "../constants/OfficeConstants.js";
import { postData } from "../service/Common";
const get_office_action = (Id) => async (dispatch) => {
	dispatch({ type: GET_OFFICE_DETAIL_REQUEST, payload: {} });
	let req = { id: Id }
	postData("/api/get_office_detail", req)
		.then((response) => {
			var data = response.data;
			dispatch({ type: GET_OFFICE_DETAIL_SUCCESS_INFO, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_OFFICE_DETAIL_FAIL_ERROR, payload: error.data });
		});
};

const get_office_by_project_id_action = (id) => async (dispatch) => {
	dispatch({ type: GET_OFFICES_BY_PROJECT_ID_REQUEST, payload: {} });
	postData(`/api/get_office_by_project_id`, { project_id: id })
		.then((response) => {
			let data = response.data;
			dispatch({ type: GET_OFFICES_BY_PROJECT_ID_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_OFFICES_BY_PROJECT_ID_FAIL_ERROR, payload: error.data.message });
		})
}

const get_office_by_plaza_id_action = (id) => async (dispatch) => {
	dispatch({ type: GET_OFFICES_BY_PLAZA_ID_REQUEST, payload: {} });
	postData(`/api/get_office_by_plaza_id`, { plaza_id: id })
		.then((response) => {
			let data = response.data;
			dispatch({ type: GET_OFFICES_BY_PLAZA_ID_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_OFFICES_BY_PLAZA_ID_FAIL_ERROR, payload: error.data.message });
		})
}
const get_office_option_list_action = () => async (dispatch) => {
	dispatch({ type: GET_OFFICE_OPTION_LIST_REQUEST, payload: {} });
	postData(`/api/get_office_option_list`, '', { method_type: 'get' })
		.then((response) => {
			let data = response.data;
			dispatch({ type: GET_OFFICE_OPTION_LIST_SUCCESS, payload: data });
		})
		.catch((error) => {
			dispatch({ type: GET_OFFICE_OPTION_LIST_FAIL_ERROR, payload: error.data.message });
		})
}
export { get_office_action, get_office_by_project_id_action, get_office_by_plaza_id_action, get_office_option_list_action };