import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown from "../../components/ReactSelectPlus";
import $ from "jquery";
import moment from "moment";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_master_list } from "../../actions/MasterActions";
import { useDispatch, useSelector } from "react-redux";
import { get_product_option_list_action } from "../../actions/ProductActions";
import { get_product_detail_action } from "../../actions/ProductActions.js";
import { get_project_option_list_action } from "../../actions/ProjectActions";
import { get_office_option_list_action } from "../../actions/OfficeActions";
import { get_plaza_option_list_action } from "../../actions/PlazaActions";
import { get_warehouse_option_list_action } from "../../actions/WarehouseActions";

const AddInventoryPage = (props) => {
  var IMG_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [prodData, setProdData] = useState({
    display: "",
    imgPath: {},
  });
  const [commonData, setCommonData] = useState({
    product_id: "",
    quantity: 1,
    assign_id:
      props.match.params.assignId !== undefined
        ? props.match.params.assignId
        : "",
    assign_with:
      props.match.params.assignWith !== undefined
        ? props.match.params.assignWith
        : "",
    manufacturedDate: null,
    has_warranty: "1",
    serviceProvider: "",
    purchaseFrom: "",
    purchasePrice: 0,
    purchaseDate: null,
    publisher: "",
    //  version: "",
    warrantyDuration: "0",
    project_id: "",
    plaza_id: "",
    office_id: "",
    warehouse_id: "",
  });
  const [inventoryData, setInventoryData] = useState([
    {
      serialNumber: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [productOptionList, setProductOptionList] = useState([]);
  const [productQty, setProductQty] = useState(1);
  const [masterList, setMasterList] = useState([]);
  const [projectOptionList, setProjectOptionList] = useState([]);
  const [plazaOptionList, setPlazaOptionList] = useState([]);
  const [officeOptionList, setOfficeOptionList] = useState([]);
  const [whouseOptionList, setWhouseOptionList] = useState([]);

  const getProdOptionsList = useSelector(
    (state) =>
      state.ProductReducers.GetProductOptionListReducer.successProductOptionList
  );
  const getProductDetails = useSelector(
    (state) =>
      state.ProductReducers.GetProductDetailReducer.successgetProductDetail
  );

  const getMasterList = useSelector(
    (state) => state.MasterReducers.GetMasterListReducer.successMasterList
  );

  const getopionsData = useSelector((state) => {
    return {
      project: state.ProjectReducers.GetProjectOptionListReducer,
      office: state.OfficeReducers.GetOfficeOptionListReducer,
      plaza: state.PlazaReducers.GetPlazaOptionListReducer,
      warehousse: state.WarehouseReducers.GetWarehouseOptionListReducer,
    };
  });

  const dispatch = useDispatch();

  var publisherOptions = [];

  const allOptions = masterList.map((option) => {
    if (option.type == "4") {
      publisherOptions.push({
        value: option.id,
        label: option.name,
      });
    }
  });
  // let display = "";
  // let imagePath = {};
  useEffect(() => {
    dispatch(get_master_list());
    dispatch(get_product_option_list_action(1));
    dispatch(get_project_option_list_action());
    dispatch(get_office_option_list_action());
    dispatch(get_plaza_option_list_action());
    dispatch(get_warehouse_option_list_action());
  }, [dispatch]);
  useEffect(() => {
    if (getMasterList) {
      setMasterList(getMasterList.data);
    }
    if (getProdOptionsList && typeof getProdOptionsList.data !== undefined) {
      setProductOptionList(getProdOptionsList ? getProdOptionsList.data : []);
    }
    if (
      commonData.product_id &&
      getProductDetails &&
      typeof getProductDetails.data.dataArr !== undefined
    ) {
      let display =
        typeof getProdOptionsList.data.dataArr !== undefined
          ? getProductDetails.data.dataArr['display']
          : "";
      let imagePath =
        typeof getProdOptionsList.data.dataArr !== undefined
          ? getProductDetails.data.dataArr.imgFiles[0]
          : "";
      setProdData({ ...prodData, display: display, imgPath: imagePath });
    }

    if (getopionsData.project.successProjectOptionList) {
      setProjectOptionList(getopionsData.project.successProjectOptionList.data);
    }
    if (getopionsData.plaza.successPlazaOptionList) {
      setPlazaOptionList(getopionsData.plaza.successPlazaOptionList.data);
    }
    if (getopionsData.office.successOfficeOptionList) {
      setOfficeOptionList(getopionsData.office.successOfficeOptionList.data);
    }
    if (getopionsData.warehousse.successWarehouseOptionList) {
      setWhouseOptionList(
        getopionsData.warehousse.successWarehouseOptionList.data
      );
    }
  }, [getMasterList, getProdOptionsList, getProductDetails]);

  const formReset = () => {
    setCommonData({
      invtName: "",
      brand: "",
      // serialNumber: "",
      model: "",
      manufacturedBy: "",
      manufacturedDate: null,
      has_warranty: "1",
      serviceProvider: "",
      purchaseFrom: "",
      purchaseDate: null,
      publisher: "",
      version: "",
      warrantyDuration: "0",
      image_uploads: [],
      doc_uploads: [],
    });
  };
  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addNewInventory_Form").valid()) {
      var data = { inventory: inventoryData, commonData };
      postData(`/api/addnew_inventory`, data)
        .then((response) => {
          let data = response.data;
          ToastMessage(data.message, "s");
          formReset();
          setLoading(false);
          props.history.push("/inventory_management");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const addInventoryHandller = (e) => {
    let quantity = commonData.quantity;
    let product = commonData.product_id;
    if (!product) {
      ToastMessage("Product field cannot be empty", "e");
      return false;
    } else {
      if (quantity > 100) {
        ToastMessage("only 3 items can be allowed", "e");
      } else {
        setProductQty(quantity);
      }
    }
  };

  var warrantyDurationOptionList = [
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Year" },
    { value: "4", label: "4 Year" },
    { value: "5", label: "5 Year" },
  ];

  var typeWithOptions = [
    { value: "project", label: "Project" },
    { value: "plaza", label: "Plaza" },
    { value: "office", label: "Office" },
    { value: "warehouse", label: "Warehouse" },
  ];

  const setExpiryDate = (n) => {
    let d = commonData.purchaseDate;
    if (n !== null && d !== null) {
      let temp = moment(d).add(n, "y").format("Y-MM-DD");
      setCommonData({
        ...commonData,
        warrantyDuration: n,
        warrantyExpiryDate: temp,
      });
    } else {
      setCommonData({ ...commonData, warrantyDuration: n });
    }
  };

  const setInventoryValues = (n, i, key) => {
    let inventory = [...inventoryData];
    let data = { key: n };
    if (inventory[i]) {
      inventory[i][key] = n;
    } else {
      inventory[i] = data;
    }
    setInventoryData(inventory);
  };

  const setgetProductValues = (val, key) => {
    setCommonData({
      ...commonData,
      product_id: val,
    });
    if (val !== null) {
      dispatch(get_product_detail_action(val,1));
    } else {
      setProdData({ ...prodData, display: "", imgPath: [] });
    }
  };
  const getswitchfields = (assignwith) => {
    switch (assignwith) {
      case "project":
        return (
          <>
            <label htmlFor="plaza">Project</label>
            <div className="cstm_select">
              <SelectDropdown
                id="projectid1"
                name="projectid1"
                options={projectOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Project"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="assign_id"
              ></label>
            </div>
          </>
        );
      case "plaza":
        return (
          <>
            <label htmlFor="plaza">Plaza</label>
            <div className="cstm_select">
              <SelectDropdown
                id="plazaid1"
                name="plazaid1"
                options={plazaOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Plaza"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="assign_id"
              ></label>
            </div>
          </>
        );
      case "office":
        return (
          <>
            <label htmlFor="office1">Office</label>
            <div className="cstm_select">
              <SelectDropdown
                id="office1"
                name="office1"
                options={officeOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Office"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.assign_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="assign_id"
              ></label>
            </div>
          </>
        );
      case "warehouse":
        return (
          <>
            <label htmlFor="wh_id">Warehouse</label>
            <div className="cstm_select">
              <SelectDropdown
                id="wh_id1"
                name="wh_id1"
                options={whouseOptionList}
                required={true}
                joinValues={true}
                autoComplete="off"
                placeholder="Select Warehouse"
                className={"w-100"}
                onChange={(e) =>
                  setCommonData({
                    ...commonData,
                    assign_id: e !== null ? e.value : null,
                  })
                }
                value={commonData.wh_id}
                inputRenderer={(e) => (
                  <input
                    {...e}
                    name="assign_id"
                    data-msg-required="This field is required"
                  />
                )}
              />
              <label
                className="error select_error_span"
                htmlFor="assign_id"
              ></label>
            </div>
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/inventory_management">Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            New Inventory
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">New Inventory</h6>
              </div>

              <div className="card_box_border">
                <Row className=" justify-content-center">
                  <Col lg={12}>
                    <div className="row">
                      <Col lg={4} className="form-group">
                        <label htmlFor="Product">Product</label>
                        <div className="cstm_select">
                          <SelectDropdown
                            id={"product_id"}
                            name={"product_id"}
                            required={true}
                            options={productOptionList}
                            sampleValue={true}
                            joinValues={true}
                            searchable={true}
                            clearable={true}
                            autoComplete="off"
                            placeholder="Select Product"
                            onChange={(e) =>
                              setgetProductValues(
                                e != null ? e.value : null,
                                "product_id"
                              )
                            }
                            value={commonData.product_id}
                            inputRenderer={(props) => (
                              <input
                                {...props}
                                name="product_id"
                                data-msg-required={"Please select product."}
                              />
                            )}
                          />
                          <div style={{ fontSize: "10px" }}>
                            {prodData ? prodData.display : ""}
                            {prodData &&
                            prodData.imgPath &&
                            typeof prodData.imgPath != undefined &&
                            prodData.imgPath.src !== undefined ? (
                              <img
                                src={
                                  prodData.imgPath &&
                                  prodData.imgPath.src !== undefined
                                    ? prodData.imgPath.src
                                    : ""
                                }
                                alt={""}
                                height="50px"
                                width="50px"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg={4} className="form-group">
                        <label htmlFor="password">Quantity</label>
                        <input
                          type="text"
                          className="form-control"
                          id="quantity"
                          name="quantity"
                          autoComplete="off"
                          placeholder="Quantity"
                          value={commonData.quantity}
                          onChange={(e) =>
                            setCommonData({
                              ...commonData,
                              quantity: e.target.value
                                ? parseInt(e.target.value)
                                : "",
                            })
                          }
                          maxLength={180}
                        />
                      </Col>

                      <Col lg={4} className="form-group pt-3">
                        <button
                          type="button"
                          className="btn btn-primary mr-2 mt-3"
                          onClick={addInventoryHandller}
                          disabled={loading ? true : false}
                        >
                          {loading ? "Loading..." : "Add Inventory"}
                        </button>
                      </Col>
                    </div>
                  </Col>
                </Row>

                <Row className=" justify-content-center">
                  <Col lg={12} className="grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                          <h6 className="card-title ">Add New Invertory</h6>
                        </div>
                        <Row className=" justify-content-center">
                          <Col lg={12}>
                            <form
                              id="addNewInventory_Form"
                              method="POST"
                              encType="multipart/form-data"
                              className="forms-sample "
                            >
                              <div className="row">
                                <Col lg={4} className="form-group">
                                  <label htmlFor="type_with1">Type With</label>
                                  <div className="cstm_select">
                                    <SelectDropdown
                                      id="assign_with"
                                      name="assign_with"
                                      options={typeWithOptions}
                                      // required={true}
                                      joinValues={true}
                                      autoComplete="off"
                                      placeholder="Select the type with"
                                      className={"w-100"}
                                      onChange={(e) =>
                                        setCommonData({
                                          ...commonData,
                                          assign_with:
                                            e !== null ? e.value : null,
                                        })
                                      }
                                      value={commonData.assign_with}
                                      inputRenderer={(e) => (
                                        <input
                                          {...e}
                                          name="assign_with"
                                          data-msg-required="This field is required"
                                        />
                                      )}
                                    />
                                    <label
                                      className="error select_error_span"
                                      htmlFor="type_with"
                                    ></label>
                                  </div>
                                </Col>
                                <Col lg={4} className="form-group">
                                  {getswitchfields(commonData.assign_with)}
                                </Col>

                                <div className="form-group col-md-4">
                                  <label
                                    className="w-100"
                                    htmlFor="manufacturedDate1"
                                  >
                                    Date Of Manufacture
                                  </label>
                                  <DatePicker
                                    id="manufacturedDate"
                                    name="manufacturedDate"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    required={true}
                                    minDate={new Date("01-01-2020")}
                                    maxDate={new Date()}
                                    selected={
                                      commonData.manufacturedDate
                                        ? moment(
                                            commonData.manufacturedDate
                                          ).valueOf()
                                        : null
                                    }
                                    onChange={(date) => {
                                      setCommonData({
                                        ...commonData,
                                        manufacturedDate:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    autoComplete="off"
                                  />
                                </div>

                                <div className="form-group  col-md-4">
                                  <label
                                    className="w-100"
                                    htmlFor="purchaseDate"
                                  >
                                    Purchase Date
                                  </label>
                                  <DatePicker
                                    id="purchaseDate"
                                    name="purchaseDate"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    required={true}
                                    selected={
                                      commonData.purchaseDate
                                        ? moment(
                                            commonData.purchaseDate
                                          ).valueOf()
                                        : null
                                    }
                                    onChange={(date) => {
                                      let n = commonData.warrantyDuration;
                                      if (n !== null && date !== null) {
                                        let temp = moment(date)
                                          .add(n, "y")
                                          .format("Y-MM-DD");
                                        setCommonData({
                                          ...commonData,
                                          purchaseDate:
                                            moment(date).format("YYYY-MM-DD"),
                                          warrantyExpiryDate: temp,
                                        });
                                      } else {
                                        setCommonData({
                                          ...commonData,
                                          purchaseDate:
                                            moment(date).format("YYYY-MM-DD"),
                                        });
                                      }
                                    }}
                                    minDate={new Date("01-01-2020")}
                                    maxDate={new Date()}
                                  />
                                </div>

                                <div className="form-group  col-md-4">
                                  <label
                                    className="w-100"
                                    htmlFor="hasawarranty"
                                  >
                                    Has a warranty
                                  </label>
                                  <div className="d-inline-flex">
                                    <label
                                      htmlFor="yeswarranty"
                                      className="mr-2 d-inline-flex align-items-center"
                                    >
                                      <input
                                        type="radio"
                                        id="yeswarranty"
                                        name="has_warranty"
                                        className=""
                                        value={"1"}
                                        checked={
                                          commonData.has_warranty === "1" ??
                                          false
                                        }
                                        onChange={(e) =>
                                          setCommonData({
                                            ...commonData,
                                            has_warranty: e.target.value,
                                          })
                                        }
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      className="mr-2 ml-2 d-inline-flex align-items-center"
                                      htmlFor="nowarranty"
                                    >
                                      <input
                                        type="radio"
                                        id="nowarranty"
                                        name="has_warranty"
                                        className=""
                                        value={"0"}
                                        checked={
                                          commonData.has_warranty === "0" ??
                                          false
                                        }
                                        onChange={(e) =>
                                          setCommonData({
                                            ...commonData,
                                            has_warranty: e.target.value,
                                          })
                                        }
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>
                                {commonData.has_warranty === "1" ? (
                                  <div className="form-group  col-md-4">
                                    <label
                                      className="w-100"
                                      htmlFor="warrantyDuration1"
                                    >
                                      Warranty Duration
                                    </label>
                                    <div className="cstm_select">
                                      <SelectDropdown
                                        id="warrantyDuration1"
                                        name="warrantyDuration1"
                                        required={
                                          commonData.has_warranty === "1"
                                            ? true
                                            : false
                                        }
                                        options={warrantyDurationOptionList}
                                        className={"mr-2"}
                                        joinValues={true}
                                        searchable={true}
                                        clearable={true}
                                        autoComplete="off"
                                        placeholder="Select Warranty Duration"
                                        onChange={(e) => {
                                          setExpiryDate(
                                            e !== null ? e.value : null
                                          );
                                        }}
                                        value={
                                          commonData.has_warranty === "1"
                                            ? commonData.warrantyDuration
                                            : ""
                                        }
                                        inputRenderer={(props) => (
                                          <input
                                            {...props}
                                            name="warrantyDuration"
                                            data-msg-required={
                                              "Please select extend warranty."
                                            }
                                          />
                                        )}
                                        disabled={
                                          commonData.has_warranty === "1"
                                            ? false
                                            : true
                                        }
                                      />
                                      <label
                                        className="error select_error_span"
                                        htmlFor="warrantyDuration"
                                      ></label>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {commonData.has_warranty === "1" ? (
                                  <div className="form-group  col-md-4">
                                    <label
                                      className="w-100"
                                      htmlFor="warrantyExpiryDate"
                                    >
                                      Warranty Expiry Date
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="warrantyExpiryDate"
                                      name="warrantyExpiryDate"
                                      autoComplete="off"
                                      placeholder="Warranty Expiry Date"
                                      value={
                                        commonData.warrantyExpiryDate
                                          ? moment(
                                              commonData.warrantyExpiryDate
                                            ).format("DD/MM/Y")
                                          : ""
                                      }
                                      data-rule-required={true}
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="form-group  col-md-4">
                                  <label
                                    className="w-100"
                                    htmlFor="serviceProvider"
                                  >
                                    Service Provide
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="serviceProvider"
                                    name="serviceProvider"
                                    autoComplete="off"
                                    placeholder="Service Provider"
                                    onChange={(e) =>
                                      setCommonData({
                                        ...commonData,
                                        serviceProvider: e.target.value,
                                      })
                                    }
                                    value={commonData.serviceProvider}
                                    data-rule-required={true}
                                  />
                                </div>

                                <div className="form-group  col-md-4">
                                  <label
                                    className="w-100"
                                    htmlFor="purchaseFrom"
                                  >
                                    Purchase From (Seller)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="purchaseFrom"
                                    name="purchaseFrom"
                                    autoComplete="off"
                                    placeholder="Purchase From"
                                    onChange={(e) =>
                                      setCommonData({
                                        ...commonData,
                                        purchaseFrom: e.target.value,
                                      })
                                    }
                                    value={commonData.purchaseFrom}
                                    data-rule-required={true}
                                  />
                                </div>

                                <div className="form-group  col-md-4">
                                  <label className="w-100" htmlFor="publisher">
                                    publisher
                                  </label>
                                  <div className="cstm_select">
                                    <SelectDropdown
                                      id="publisher"
                                      name="publisher"
                                      required={true}
                                      options={publisherOptions}
                                      className={"mr-2"}
                                      joinValues={true}
                                      searchable={true}
                                      clearable={true}
                                      autoComplete="off"
                                      placeholder="Select Publisher"
                                      onChange={(e) =>
                                        setCommonData({
                                          ...commonData,
                                          publisher: e !== null ? e.value : "",
                                        })
                                      }
                                      value={
                                        commonData ? commonData.publisher : ""
                                      }
                                      inputRenderer={(props) => (
                                        <input
                                          {...props}
                                          name="publisher"
                                          data-msg-required={
                                            "Please select publisher."
                                          }
                                        />
                                      )}
                                    />
                                    <label
                                      className="error select_error_span"
                                      htmlFor="publisher"
                                    ></label>
                                  </div>
                                </div>

                                <div className="form-group  col-md-4">
                                  <label
                                    className="w-100"
                                    htmlFor="purchasePrice"
                                  >
                                    Purchase Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="purchasePrice"
                                    name="purchasePrice"
                                    autoComplete="off"
                                    placeholder="Purchase Price"
                                    onChange={(e) =>
                                      setCommonData({
                                        ...commonData,
                                        purchasePrice: e.target.value,
                                      })
                                    }
                                    value={commonData.purchasePrice}
                                    data-rule-required={true}
                                  />
                                </div>
                              </div>

                              <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 mt-3">
                                <h6 className="card-title ">Serial Number</h6>
                              </div>
                              <div className="row">
                                <Col lg={12}>
                                  <Row>
                                    {[...Array(productQty)].map((el, index) => (
                                      <div
                                        key={index}
                                        className="form-group col-md-4"
                                      >
                                        <label
                                          className="w-100"
                                          htmlFor="serialNumber"
                                        >
                                          Serial Number {index ? index + 1 : 1}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="serialNumber"
                                          name={"serialNumber"}
                                          autoComplete="off"
                                          placeholder="Serial Number"
                                          onChange={(e) =>
                                            setInventoryValues(
                                              e.target.value,
                                              index,
                                              "serialNumber"
                                            )
                                          }
                                          value={
                                            inventoryData[index]
                                              ? inventoryData[index]
                                                  .serialNumber
                                              : ""
                                          }
                                          data-rule-required={true}
                                        />
                                      </div>
                                    ))}
                                  </Row>
                                </Col>
                              </div>
                            </form>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <div className="form-group  col-md-12 align-self-end">
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              onClick={submitHandller}
                              disabled={loading ? true : false}
                            >
                              {loading ? "loading..." : "Submit"}
                            </button>
                            <Link
                              className="btn btn-dark"
                              to="/inventory_management"
                            >
                              Cancel
                            </Link>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AddInventoryPage;
