import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import SelectDropdown from "../../components/ReactSelectPlus";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import moment from "moment";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { get_inventory_detail_action } from "../../actions/InventoryActions.js";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import { get_master_list } from "../../actions/MasterActions";

const EditInventoryPage = (props) => {
  var API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [inventoryData, setInventoryData] = useState({
    invtName: "",
    brand: "",
    serialNumber: "",
    model: "",
    menufecturedBy: "",
    manufecturedDate: null,
    hasWarranty: "1",
    serviceProvider: "",
    vendorName: "",
    purchaseDate: null,
    publisher: "",
    version: "",
    extendWarranty: "0",
    warrantyDuration: "",
    productName: "",
    product_id: "",
  });
  const [inventoryDataerr, setInventoryDataerr] = useState("");
  const [loading, setLoading] = useState(false);
  const getInventoryDetails = useSelector(
    (state) => state.InventoryReducers.GetInventoryDetailReducer
  );
  const [masterList, setMasterList] = useState([]);
  const getMasterList = useSelector(
    (state) => state.MasterReducers.GetMasterListReducer.successMasterList
  );

  const dispatch = useDispatch();

  var warrantyDurationOptionList = [
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Year" },
    { value: "4", label: "4 Year" },
    { value: "5", label: "5 Year" },
  ];

  useEffect(() => {
    dispatch(get_master_list());
    if (props.match.params.invtId) {
      dispatch(get_inventory_detail_action(props.match.params.invtId));
    }
  }, [props.match.params.invtId, dispatch]);
  useEffect(() => {
    if (getInventoryDetails.successgetInventoryDetail !== undefined) {
      setInventoryData(getInventoryDetails.successgetInventoryDetail.data);
    }
    if (getInventoryDetails.failedgetInventoryDetail !== undefined) {
      setInventoryDataerr(getInventoryDetails.failedgetInventoryDetail.message);
    }
    if (getMasterList) {
      setMasterList(getMasterList.data);
    }
  }, [getInventoryDetails, getMasterList]);
  const formReset = () => {
    setInventoryData({
      invtName: "",
      brand: "",
      serialNumber: "",
      model: "",
      menufecturedBy: "",
      manufecturedDate: null,
      warranty: "1",
      serviceProvider: "",
      vendorName: "",
      purchaseDate: null,
      publisher: "",
      version: "",
      extendWarranty: "0",
      warrantyExpiryDate: "",
      imgFiles: [],
      docFiles: [],
    });
  };

  var publisherOptions = [];

  const allOptions = masterList.map((option) => {
    if (option.type == "4") {
      publisherOptions.push({
        value: option.id,
        label: option.name,
      });
    }
  });

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#editInventory_Form").valid()) {
      setLoading(true);
      postData(`/api/update_inventory`, inventoryData)
        .then((response) => {
          let data = response.data;
          ToastMessage(data.message, "s");
          formReset();
          setLoading(false);
          props.history.push("/inventory_management");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };
  const setExpiryDate = (n) => {
    let d = inventoryData.purchaseDate;
    if (n !== null && d !== null) {
      let temp = moment(d).add(n, "y").format("Y-MM-DD");
      setInventoryData({
        ...inventoryData,
        warrantyDuration: n,
        warrantyExpiryDate: temp,
      });
    } else {
      setInventoryData({ ...inventoryData, warrantyDuration: n });
    }
  };

  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/inventory_management">Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit Inventory
          </li>
        </ol>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title ">Edit Inventory</h6>
              </div>
              <div className="card_box_border">
                <Row className="justify-content-center">
                  <Col lg={12}>
                    {getInventoryDetails.loading ? (
                      <div className="form-control text-info text-center">
                        Loading...
                      </div>
                    ) : getInventoryDetails.successgetInventoryDetail ? (
                      <form
                        id="editInventory_Form"
                        method="POST"
                        encType="multipart/form-data"
                        className="forms-sample row"
                      >
                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="serialNumber">
                            Product Name :{" "}
                          </label>
                          <div className="form-control input_inner_box">
                            <Link
                              className="input_inner_btn"
                              to={`/product_management/${inventoryData.product_id}/show`}
                            >
                              {inventoryData.productName}
                            </Link>
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="w-100" htmlFor="serialNumber">
                            Serial Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="serialNumber"
                            name="serialNumber"
                            autoComplete="off"
                            placeholder="Serial Number"
                            onChange={(e) =>
                              setInventoryData({
                                ...inventoryData,
                                serialNumber: e.target.value,
                              })
                            }
                            value={inventoryData.serialNumber}
                            data-rule-required={true}
                          />
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="manufacturedDate1">
                            Date Of Manufacture
                          </label>
                          <DatePicker
                            id="manufacturedDate"
                            name="manufacturedDate"
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            required={true}
                            minDate={new Date("01-01-2020")}
                            maxDate={new Date()}
                            selected={
                              inventoryData.manufacturedDate
                                ? moment(
                                    inventoryData.manufacturedDate
                                  ).valueOf()
                                : null
                            }
                            onChange={(date) => {
                              setInventoryData({
                                ...inventoryData,
                                manufacturedDate:
                                  moment(date).format("YYYY-MM-DD"),
                              });
                            }}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="purchaseDate">
                            Purchase Date
                          </label>
                          <DatePicker
                            id="purchaseDate"
                            name="purchaseDate"
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            required={true}
                            selected={
                              inventoryData.purchaseDate
                                ? moment(inventoryData.purchaseDate).valueOf()
                                : null
                            }
                            onChange={(date) => {
                              let n = inventoryData.warrantyDuration;
                              if (n !== null && date !== null) {
                                let temp = moment(date)
                                  .add(n, "y")
                                  .format("Y-MM-DD");
                                setInventoryData({
                                  ...inventoryData,
                                  purchaseDate:
                                    moment(date).format("YYYY-MM-DD"),
                                  warrantyExpiryDate: temp,
                                });
                              } else {
                                setInventoryData({
                                  ...inventoryData,
                                  purchaseDate:
                                    moment(date).format("YYYY-MM-DD"),
                                });
                              }
                            }}
                            minDate={new Date("01-01-2020")}
                            maxDate={new Date()}
                          />
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="hasWarranty">
                            Has a warranty
                          </label>
                          <div className="d-inline-flex">
                            <label
                              htmlFor="yeswarranty"
                              className="mr-2 d-inline-flex align-items-center"
                            >
                              <input
                                type="radio"
                                id="yeswarranty"
                                name="hasWarranty"
                                className=""
                                value={"1"}
                                checked={
                                  inventoryData.hasWarranty === "1" ?? false
                                }
                                onChange={(e) =>
                                  setInventoryData({
                                    ...inventoryData,
                                    hasWarranty: e.target.value,
                                  })
                                }
                              />{" "}
                              Yes
                            </label>
                            <label
                              className="mr-2 ml-2 d-inline-flex align-items-center"
                              htmlFor="nowarranty"
                            >
                              <input
                                type="radio"
                                id="nowarranty"
                                name="hasWarranty"
                                className=""
                                value={"0"}
                                checked={
                                  inventoryData.hasWarranty === "0" ?? false
                                }
                                onChange={(e) =>
                                  setInventoryData({
                                    ...inventoryData,
                                    hasWarranty: e.target.value,
                                  })
                                }
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                        {inventoryData.hasWarranty === "1" ? (
                          <div className="form-group  col-md-4">
                            <label
                              className="w-100"
                              htmlFor="warrantyDuration1"
                            >
                              Warranty Duration
                            </label>
                            <div className="cstm_select">
                              <SelectDropdown
                                id="warrantyDuration1"
                                name="warrantyDuration1"
                                required={
                                  inventoryData.hasWarranty === "1"
                                    ? true
                                    : false
                                }
                                options={warrantyDurationOptionList}
                                className={"mr-2"}
                                joinValues={true}
                                searchable={true}
                                clearable={true}
                                autoComplete="off"
                                placeholder="Select Warranty Duration"
                                onChange={(e) =>
                                  setExpiryDate(e !== null ? e.value : null)
                                }
                                value={
                                  inventoryData.hasWarranty === "1"
                                    ? inventoryData.warrantyDuration
                                    : ""
                                }
                                inputRenderer={(props) => (
                                  <input
                                    {...props}
                                    name="warrantyDuration"
                                    data-msg-required={
                                      "Please select extend warranty."
                                    }
                                  />
                                )}
                                disabled={
                                  inventoryData.hasWarranty === "1"
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="error select_error_span"
                                htmlFor="warrantyDuration"
                              ></label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryData.hasWarranty === "1" ? (
                          <div className="form-group  col-md-4">
                            <label
                              className="w-100"
                              htmlFor="warrantyExpiryDate"
                            >
                              Warranty Expiry Date
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="warrantyExpiryDate"
                              name="warrantyExpiryDate"
                              autoComplete="off"
                              placeholder="Warranty Expiry Date"
                              value={
                                inventoryData.warrantyExpiryDate
                                  ? moment(
                                      inventoryData.warrantyExpiryDate
                                    ).format("DD/MM/Y")
                                  : ""
                              }
                              data-rule-required={true}
                              readOnly
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="serviceProvider">
                            Service Provide
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="serviceProvider"
                            name="serviceProvider"
                            autoComplete="off"
                            placeholder="Service Provider"
                            onChange={(e) =>
                              setInventoryData({
                                ...inventoryData,
                                serviceProvider: e.target.value,
                              })
                            }
                            value={inventoryData.serviceProvider}
                            data-rule-required={true}
                          />
                        </div>
                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="purchaseFrom">
                            Purchase From (Seller)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="purchaseFrom"
                            name="purchaseFrom"
                            autoComplete="off"
                            placeholder="Purchase From"
                            onChange={(e) =>
                              setInventoryData({
                                ...inventoryData,
                                purchaseFrom: e.target.value,
                              })
                            }
                            value={inventoryData.purchaseFrom}
                            data-rule-required={true}
                          />
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="publisher">
                            publisher
                          </label>
                          <div className="cstm_select">
                            <SelectDropdown
                              id="publisher"
                              name="publisher"
                              required={true}
                              options={publisherOptions}
                              className={"mr-2"}
                              joinValues={true}
                              searchable={true}
                              clearable={true}
                              autoComplete="off"
                              placeholder="Select Publisher"
                              onChange={(e) =>
                                setInventoryData({
                                  ...inventoryData,
                                  publisher: e !== null ? e.value : "",
                                })
                              }
                              value={inventoryData.publisher}
                              inputRenderer={(props) => (
                                <input
                                  {...props}
                                  name="publisher"
                                  data-msg-required={"Please select publisher."}
                                />
                              )}
                            />
                            <label
                              className="error select_error_span"
                              htmlFor="publisher"
                            ></label>
                          </div>
                        </div>

                        <div className="form-group  col-md-4">
                          <label className="w-100" htmlFor="purchasePrice">
                            Purchase Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="purchasePrice"
                            name="purchasePrice"
                            autoComplete="off"
                            placeholder="Purchase Price"
                            onChange={(e) =>
                              setInventoryData({
                                ...inventoryData,
                                purchasePrice: e.target.value,
                              })
                            }
                            value={inventoryData.purchasePrice}
                            data-rule-required={true}
                          />
                        </div>
                        <div className="form-group  col-md-12 align-self-end">
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={submitHandller}
                            disabled={loading ? true : false}
                          >
                            {loading ? "loading..." : "Update"}
                          </button>
                          <Link
                            className="btn btn-dark"
                            to="/inventory_management"
                          >
                            Cancel
                          </Link>
                        </div>
                      </form>
                    ) : getInventoryDetails.failedgetInventoryDetail ? (
                      <div className="text-center">
                        <div className=" form-control text-danger mb-3">
                          {inventoryDataerr}
                        </div>
                        <Link
                          to="/inventory_management"
                          className="btn btn-danger"
                        >
                          Back
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EditInventoryPage;
