import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditSvgIcon, TrashSvgIcon } from "../../components/SvgIcon";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { get_hardware_request_detail_action } from "../../actions/HardwareRequestActions";
import Table from "../../components/ReactTable";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";

const ViewRequestPage = (props) => {
  var IMG_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const pageSize = 10;
  const search = "";
  const sortBy = [];
  const [requestLogData, setRequestLogData] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_log_id",
        Cell: (prop) => (
          <span>
            <Link
              className="input_inner_btn"
              to={`/logs/${prop.original.id}/show`}
            >
              {prop.original._log_id}
            </Link>
          </span>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "User By",
        accessor: "u_name",
      },
      {
        Header: "Created",
        accessor: "created",
      },
    ],
    []
  );
  const getrequestData = useSelector(
    (state) => state.HardwareRequestReducers.GetHardwareRequestDetailReducer
  );
  const dispatch = useDispatch();
  const [requsetData, setRequestData] = useState({
    id: "",
    whrId: "",
    name: "",
    plaza_id: "",
    plazaName: "",
    quantity: "",
    status: "",
    createdBy: "",
    created: "",
    items: [],
  });
  useEffect(() => {
    if (props.match.params.reqId) {
      dispatch(get_hardware_request_detail_action(props.match.params.reqId));
    } else {
      return <Redirect to={`/requests`} />;
    }
  }, [dispatch, props]);
  useEffect(() => {
    if (getrequestData.successgetHwRequestDetail) {
      setRequestData(getrequestData.successgetHwRequestDetail.data);
    }
  }, [getrequestData]);

  const fetchData = React.useCallback(
    ({ pageSize, page, filter, sorted }) => {
      let req = {
        page_size: pageSize,
        start: page,
        search: filter,
        sorted: sorted,
        hwreq_id: props.match.params.reqId,
      };
      setLoading(true);
      postData(`/api/get_logs_of_hardware_request`, req)
        .then((result) => {
          let datares = result.data;
          setRequestLogData(datares.data);
          let tmp_pages = Math.ceil(parseInt(datares.total) / pageSize);
          setPages(tmp_pages);
          setPage(page);
          setLoading(false);
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    },
    [props]
  );

  const deleteAttachmentFile = (id) => {
    ConfirmationAlertBox({
      title: "Confirmation",
      message: "Are you sure want to delete?",
    }).then((result) => {
      if (result.status) {
        postData(`/api/remove_attachment_by_id`, { id: id })
          .then((response) => {
            ToastMessage(response.data.message, "s");
            dispatch(
              get_hardware_request_detail_action(props.match.params.reqId)
            );
          })
          .catch((err) => {
            ToastMessage(err.data.message, "e");
          });
      }
    });
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/requests`}>Request</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Hardware Request Detail
          </li>
        </ol>
        <Link to={`/requests`} className="btn btn-dark">
          Back
        </Link>
      </nav>

      <Row className=" justify-content-center">
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box  margin_set_1v1">
                <h6 className="card-title ">Hardware Request Detail</h6>
              </div>

              <div className="card_box_border">
                {getrequestData.loading ? (
                  <div className="text-center text-info form-control">
                    Loading...
                  </div>
                ) : getrequestData.successgetHwRequestDetail ? (
                  <Row>
                    <Col md={3} className="form-group">
                      <label>ID</label>
                      <div className="form-control">{requsetData.hwrId}</div>
                    </Col>
                    <Col md={3} className="form-group">
                      <label>Title</label>
                      <div className="form-control">{requsetData.title}</div>
                    </Col>
                    {requsetData.type_with === "office" ? (
                      <Col md={3} className="form-group">
                        <label>Office</label>
                        <div className="form-control input_inner_box">
                          <Link
                            className="input_inner_btn"
                            to={`/office/${requsetData.shareId}/show`}
                          >
                            {requsetData.officeName}
                          </Link>
                        </div>
                      </Col>
                    ) : requsetData.type_with === "project" ? (
                      <Col md={3} className="form-group">
                        <label>Project</label>
                        <div className="form-control">
                          <Link
                            className="input_inner_btn input_inner_box"
                            to={`/project_management/${requsetData.shareId}/show`}
                          >
                            {requsetData.projectName}
                          </Link>
                        </div>
                      </Col>
                    ) : requsetData.type_with === "plaza" ? (
                      <Col md={3} className="form-group">
                        <label>Plaza</label>
                        <div className="form-control input_inner_box">
                          <Link
                            className="input_inner_btn"
                            to={`/plaza/${requsetData.shareId}/show`}
                          >
                            {requsetData.plazaName}
                          </Link>
                        </div>
                      </Col>
                    ) : requsetData.type_with === "warehouse" ? (
                      <Col md={3} className="form-group">
                        <label>Warehouse</label>
                        <div className="form-control input_inner_box">
                          <Link
                            className="input_inner_btn"
                            to={`/warehouse/${requsetData.shareId}/show`}
                          >
                            {requsetData.warehouseName}
                          </Link>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md={3} className="form-group">
                      <label>Status</label>
                      <div className="form-control">
                        {getHwRequestStatus(requsetData.status)}
                      </div>
                    </Col>
                    <Col md={3} className="form-group">
                      <label>Created By</label>
                      <div className="form-control input_inner_box">
                        <Link
                          className="input_inner_btn"
                          to={`/user/${requsetData.userId}/show`}
                        >
                          {requsetData.createdBy}
                        </Link>
                      </div>
                    </Col>
                    <Col md={3} className="form-group">
                      <label>Created</label>
                      <div className="form-control">{requsetData.created}</div>
                    </Col>
                    <Col md={3} className="form-group">
                      <label>Updated</label>
                      <div className="form-control">{requsetData.updated}</div>
                    </Col>
                    <Col md={12} className="form-group">
                      <label>Description</label>
                      <div className="form-control">
                        {requsetData.description}
                      </div>
                    </Col>

                    {requsetData.items.length ? (
                      <Col md={12} className="form-group">
                        <table className="static_custom_table_">
                          {/* <div className="mt-3 mb-2"> */}
                          <tr>
                            <th>Items</th>
                            <th>Quantity</th>
                            <th>Request For</th>
                            <th>Attachments</th>
                          </tr>

                          <tbody>
                            {/* </div> */}
                            {requsetData.items.map((val, i) => (
                              <>
                                <tr>
                                  <td key={i}>
                                    {/* {val.item_name} */}
                                    {val.product_name}
                                    <div style={{ fontSize: "10px" }}>
                                      {val.display}
                                      {val.singleImage ? (
                                        <img
                                          src={val.singleImage}
                                          alt={""}
                                          height="25px"
                                          width="auto"
                                          className="ml-1"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                  <td>{val.quantity}</td>
                                  <td>{val.type}</td>
                                  <td>
                                    <div className="row">
                                      {(val.attachments.length &&
                                        val.type === "repair") ||
                                      val.type === "replace" ? (
                                        <Col md={12}>
                                          {val.attachments.map((val2, x) => (
                                            <div
                                              className=" upload_image_AR "
                                              key={x}
                                            >
                                              {/* <span
                                                className="cgl-btn"
                                                onClick={() =>
                                                  deleteAttachmentFile(val2.id)
                                                }
                                              >
                                                X
                                              </span> */}
                                              <Link
                                                to={{
                                                  pathname:
                                                    IMG_BASE_URL +
                                                    "/" +
                                                    val2.src,
                                                }}
                                                target="_blank"
                                              >
                                                <img
                                                  src={
                                                    IMG_BASE_URL +
                                                    "/" +
                                                    val2.src
                                                  }
                                                  alt={val2.file_name}
                                                  height="30px"
                                                  width="auto"
                                                />
                                              </Link>
                                            </div>
                                          ))}
                                        </Col>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : getrequestData.failedgetHwRequestDetail ? (
                  <Row>
                    <Col md={12} className="justify-content-center text-center">
                      <div className="form-control text-center text-danger">
                        {getrequestData.failedgetHwRequestDetail}
                      </div>
                      <Link to={`/requests`} className="mt-2 btn-dark btn">
                        Back
                      </Link>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="card_box_border">
        {getrequestData.successgetHwRequestDetail ? (
          <Row className=" justify-content-center">
            <Col lg={12} className="grid-margin stretch-card">
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-baseline heading_box_2  margin_set_1v1 ">
                  <h6 className="card-title ">Log's</h6>
                </div>

                <Table
                  data={requestLogData}
                  columns={columns}
                  defaultPageSize={pageSize}
                  loading={loading}
                  fetchData={fetchData}
                  pages={pages}
                  page={page}
                  filter={search}
                  sorted={sortBy}
                  showPagination={false}
                />
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export const getHwRequestStatus = (id) => {
  switch (id) {
    case "0":
      return <span className="badge badge-warning">Pending</span>;
    case "1":
      return <span className="badge badge-info">Processing</span>;
    case "2":
      return <span className="badge badge--success">Completed</span>;
    case "3":
      return <span className="badge badge--danger">Cancel</span>;
    default:
      return <span className="badge badge--warning">Pending</span>;
  }
};

export default ViewRequestPage;
