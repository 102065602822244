import { Link } from "react-router-dom";
import {
  PlusSvgIcon,
  EditSvgIcon,
  TrashSvgIcon,
  FiEye,
} from "../../components/SvgIcon";
import { Row, Col } from "react-bootstrap";
import Table from "../../components/ReactTable";
import React, { useEffect, useState } from "react";
import { postData } from "../../service/Common";
import { ToastMessage } from "../../service/ToastMessage";
import ConfirmationAlertBox from "../../components/ConfirmationAlertBox";
import $ from "jquery";
import { get_project_detail_action } from "../../actions/ProjectActions";
import { useDispatch, useSelector } from "react-redux";

function ProjectListPage(props) {
  const columns = [
    {
      Header: "Project ID",
      accessor: "_project_id",
    },
    {
      Header: "Project Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: (props) => <span>Created</span>,
      accessor: "created_at",
      Cell: (props) => <span>{props.original.created}</span>,
    },
    {
      Header: (props) => <span>Action</span>,
      Cell: (props) => (
        <span className="">
          <Link
            to={`/project_management/${props.original.id}/show`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Show Detail"
          >
            <FiEye />
          </Link>
          <Link
            // to={`/project_management/${props.original.id}/edit`}
            className="btn btn-primary btn-icon btn-sm-my mr-2"
            title="Update"
            onClick={(e) => editClick(e, props.original.id)}
          >
            <EditSvgIcon />
          </Link>
          <button
            onClick={() => deleteHandller(props.original.id)}
            type="button"
            className="btn btn-danger btn-icon btn-sm-my"
            title="Delete"
          >
            <TrashSvgIcon />
          </button>
        </span>
      ),
      sortable: false,
    },
  ];
  const [projectData, setProjectData] = useState([]);
  const [addprojectData, setAddProjectData] = useState({
    name: "",
    description: "",
  });
  const page_size = 10;
  const search = "";
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const sortedBy = "";
  const [showprojectDiv, setShowProjectDiv] = useState(0);
  const [editId, setEditId] = useState(0);
  const formReset = () => {
    setAddProjectData({ name: "", description: "" });
  };
  const get_project_detail = useSelector(
    (state) => state.ProjectReducers.GetProjectDetailReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (get_project_detail.successProjectDetail) {
      setAddProjectData(get_project_detail.successProjectDetail.data);
    }
  }, [get_project_detail]);
  const fetchData = React.useCallback(({ pageSize, page, filter, sorted }) => {
    let req = {
      page_size: pageSize,
      start: page,
      search: filter,
      sorted: sorted,
    };
    setLoading(true);
    postData(`/api/get_projects_list`, req)
      .then((result) => {
        var res = result.data;
        setProjectData(res.data);
        let tmppages = Math.ceil(parseInt(res.total) / pageSize);
        setPages(tmppages);
        setPage(page);
        setLoading(false);
        setShowProjectDiv(false);
        formReset();
      })
      .catch((error) => {
        ToastMessage(error.data.message, "e");
        setLoading(false);
      });
  }, []);
  const deleteHandller = (pID) => {
    ConfirmationAlertBox(`Are You sure want to remove it?`, {}).then(
      (status) => {
        if (status) {
          postData(`/api/delete_project`, { id: pID })
            .then((result) => {
              ToastMessage(result.data.message, "s");
              fetchData(page_size, page, search, sortedBy);
            })
            .catch((error) => {
              ToastMessage(error.data.message, "e");
            });
        }
      }
    );
  };

  const submitHandller = (e) => {
    e.preventDefault();
    if ($("#addnewprojectForm").valid()) {
      postData(`/api/addnew_project`, addprojectData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          formReset();
          setLoading(false);
          setShowProjectDiv(false);
          fetchData(page_size, page, search, sortedBy);
          props.history.push("/project_management");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const updateHandller = (e) => {
    e.preventDefault();
    if ($("#addnewprojectForm").valid()) {
      setLoading(true);
      postData(`/api/update_project`, addprojectData)
        .then((response) => {
          ToastMessage(response.data.message, "s");
          setLoading(false);
          setShowProjectDiv(false);
          fetchData(page_size, page, search, sortedBy);
          setEditId(false);
          props.history.push("/project_management");
        })
        .catch((error) => {
          ToastMessage(error.data.message, "e");
          setLoading(false);
        });
    }
  };

  const editClick = (event, id) => {
    event.preventDefault();
    if (id) {
      setEditId(true);
      dispatch(get_project_detail_action(id));
      setShowProjectDiv(true);
    } else {
      setEditId(false);
      setShowProjectDiv(false);
    }
  };
  return (
    <>
      <nav className="page-breadcrumb d-flex justify-content-between">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Project Management
          </li>
        </ol>
        <button
          type="button"
          onClick={() => {
            setShowProjectDiv(showprojectDiv ? false : true);
            formReset();
          }}
          className="btn btn-primary btn-icon-text btn-sm mb-2 mb-md-0 align-self-center"
        >
          Add New Project
        </button>
      </nav>

      <Row>
        <Col lg={12} className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline heading_box margin_set_1v1">
                <h6 className="card-title mb-0"> Project Management List</h6>
              </div>

              {showprojectDiv ? (
                <div className="card_box_border mb-3">
                  <Row className="justify-content-center">
                    <Col lg={12}>
                      <form
                        id="addnewprojectForm"
                        name="addnewprojectForm"
                        method="POST"
                        className="forms-sample"
                      >
                        <div className=" row">
                          <Col lg={12} className="form-group">
                            <label for="toll_name">Project Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              autocomplete="off"
                              placeholder="Name"
                              onChange={(e) =>
                                setAddProjectData({
                                  ...addprojectData,
                                  name: e.target.value,
                                })
                              }
                              value={addprojectData.name}
                              data-rule-required={true}
                            />
                          </Col>
                          <Col lg={12} className="form-group">
                            <label for="description">Project Description</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="description"
                              name="description"
                              autocomplete="off"
                              placeholder="Description"
                              rows="2"
                              onChange={(e) =>
                                setAddProjectData({
                                  ...addprojectData,
                                  description: e.target.value,
                                })
                              }
                              value={addprojectData.description}
                            />
                          </Col>
                        </div>

                        <Row>
                          <Col lg={12}>
                            {editId ? (
                              <button
                                type="submit"
                                className="btn btn-primary mr-2"
                                onClick={updateHandller}
                                disabled={loading ? true : false}
                              >
                                {loading ? "Loading" : "Update"}
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary mr-2"
                                onClick={submitHandller}
                                disabled={loading ? true : false}
                              >
                                {loading ? "Loading" : "Submit"}
                              </button>
                            )}

                            <button
                              type="button"
                              className="btn btn-dark mr-2"
                              onClick={() => {
                                setShowProjectDiv(
                                  showprojectDiv ? false : true
                                );
                                formReset();
                              }}
                            >
                              {"Cancel"}
                            </button>
                          </Col>
                        </Row>
                      </form>
                      <br />
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              <Table
                data={projectData}
                columns={columns}
                loading={loading}
                defaultPageSize={page_size}
                fetchData={fetchData}
                page={page}
                pages={pages}
                sorted={sortedBy}
                filter={search}
                showPagination={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default ProjectListPage;
