import {
    GET_ROLES_OPTLIST_REQUEST,
    GET_ROLES_OPTLIST_SUCCESS_INFO,
    GET_ROLES_OPTLIST_FAIL_ERROR,
    GET_ROLE_DETAIL_REQUEST,
    GET_ROLE_DETAIL_SUCCESS_INFO,
    GET_ROLE_DETAIL_FAIL_ERROR,
} from "../constants/RoleContants";

function GetRolesOptionListReducer(state = {}, action) {
    switch (action.type) {
        case GET_ROLES_OPTLIST_REQUEST:
            return { loading: true };
        case GET_ROLES_OPTLIST_SUCCESS_INFO:
            return { loading: false, successRolesOptionList: action.payload };
        case GET_ROLES_OPTLIST_FAIL_ERROR:
            return { loading: false, failedRolesOptionList: action.payload }
        default:
            return state;
    }
}

function GetRoleDetailReducer(state = {}, action) {
    switch (action.type) {
        case GET_ROLE_DETAIL_REQUEST:
            return { loading: true };
        case GET_ROLE_DETAIL_SUCCESS_INFO:
            return { loading: false, successRolesDetail: action.payload };
        case GET_ROLE_DETAIL_FAIL_ERROR:
            return { loading: false, failedRolesDetail: action.payload }
        default:
            return state;
    }
}

export { GetRolesOptionListReducer, GetRoleDetailReducer };